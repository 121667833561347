import React, { useEffect } from 'react';
import './patient.css';
import { Button, Col, message, Modal, Popconfirm, Result, Row, Space, Spin, Tooltip, Upload } from 'antd';
import { useSelector } from 'react-redux';
import { checkPresignUrl, createBioId } from '../service';
import { ContainerOutlined, FilePdfOutlined, UploadOutlined } from '@ant-design/icons';
import bioApi from '../../../api/bioApi';
import { PATIENT_UPLOAD_LAB_REPORT } from '../../../api/URLs';
import DataTable from '../../../components/data-table/data-table.component';
import convertLabResultTableData from './utils/convert-lab-result-table-data';
import { useNavigate } from 'react-router-dom';
import BioIDGenerateSettingComponent from './components/bioID-generate-setting.component';
import LabReportDataCollectingModal from './components/lab-report-data-collecting.modal';

function LabInfoPage() {
  const navigate = useNavigate();
  const currentPatient = useSelector((state) => state.currentPatient);
  const currentLabResult = useSelector((state) => state.currentLabResult);
  const [messageApi, contextHolder] = message.useMessage();

  const [labResultTableData, setLabResultTableData] = React.useState([]);
  const [labResultTableSetting, setLabResultTableSetting] = React.useState(null);
  const [openBioIdPreviewModal, setOpenBioIdPreviewModal] = React.useState(false);
  const [generateBioIdLoading, setGenerateBioIdLoading] = React.useState(false);
  const [bioIdGenerateResult, setBioIdGenerateResult] = React.useState(null);
  const [popupMsg, setPopupMsg] = React.useState('');
  const [hasTreatment, setHasTreatment] = React.useState(false);
  const [prefillReportData, setPrefillReportData] = React.useState(null);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [spinnerTip, setSpinnerTip] = React.useState('');

  const uploadProps = {
    name: 'pdf',
    maxCount: 1,
    action: bioApi.defaults.baseURL + PATIENT_UPLOAD_LAB_REPORT.replace('{id}', currentPatient.patientId),
    showUploadList: false,
    onChange(info) {
      if (info.file.status === 'uploading') {
        setShowSpinner(true);
        setSpinnerTip('Uploading Lab Report PDF...');
      } else {
        setShowSpinner(false);
        setSpinnerTip('');
        if (info.file.status === 'done') {
          setPrefillReportData({
            data: info.file.response,
            file: info.file,
          });
        } else if (info.file.status === 'error') {
          if (info.file.response.duplicated) {
            messageApi.open({
              type: 'error',
              content: `The lab report you are trying to upload has been uploaded before.`,
              duration: 0,
              onClick: () => {
                messageApi.destroy();
              },
            });
          } else {
            setPrefillReportData({
              data: {},
              file: info.file,
            });
          }
        }
      }
    },
    withCredentials: true,
  };

  const checkTreatmentStatus = () => {
    let message = 'We will generate a new BioID with no treatment plan. Please confirm your action';
    if (currentPatient.currentTreatmentId) {
      setHasTreatment(true);
      message = 'We will generate a new BioID with latest treatment plan. Please confirm your action';
    }
    setPopupMsg(message);
  };

  useEffect(() => {
    if (currentPatient) {
      const allLabResult = convertLabResultTableData(currentLabResult, currentPatient);

      const tableData = allLabResult.map((labRecord, index) => {
        const obj = {
          _id: index,
          biomarker: labRecord.biomarker,
          optimalRange: labRecord.optimalRange,
          symptom: labRecord.symptom,
        };

        labRecord.results.forEach((r, i) => {
          obj['result' + i] = r;
        });

        return obj;
      });

      const tableSetting = {
        appendable: false,
        removable: false,
        bordered: true,
        size: 'middle',
        pagination: false,
        scroll: { x: document.getElementById('app-content').getBoundingClientRect().width },
        columns: [],
      };

      tableSetting.columns.push({
        title: 'Biomarker',
        dataIndex: 'biomarker',
        fixed: 'left',
        width: '15%',
      });

      const currentLabReportDate =
        currentPatient.allLabResults.find((plr) => plr._id === currentPatient.currentLabReportId)?.collectionDate || '';

      tableSetting.columns.push({
        title: (
          <div>
            Lab Result
            <div style={{ opacity: 0.5, fontWeight: 'normal' }}>{currentLabReportDate}</div>
          </div>
        ),
        dataIndex: ['result0', 'value'],
        fixed: 'left',
        width: '10%',
        render: (text, record) => <span style={{ color: record.result0.isAbnormal ? 'red' : 'black' }}>{text}</span>,
      });

      let adjustRecentResultWidth = false;
      if (allLabResult.length) {
        tableSetting.scroll.x = tableSetting.scroll.x + 128 * allLabResult[0].results.length - 320;
        allLabResult[0].results.forEach((r, i) => {
          if (i === 0) {
            if (allLabResult[0].results.length < 2) {
              adjustRecentResultWidth = true;
            }
          } else {
            tableSetting.columns.push({
              title: (
                <div>
                  Lab Result
                  <div style={{ opacity: 0.5, fontWeight: 'normal' }}>{r.date}</div>
                </div>
              ),
              dataIndex: ['result' + i, 'value'],
              className: 'drag-to-scroll',
              width: '10%',
              render: (text, record) => (
                <div style={{ color: record['result' + i].isAbnormal ? 'red' : 'black' }}>{text}</div>
              ),
            });
          }
        });
      } else {
        adjustRecentResultWidth = true;
      }

      if (adjustRecentResultWidth) {
        delete tableSetting.scroll;
      }

      tableSetting.columns.push(
        ...[
          {
            title: 'Optimal Range',
            dataIndex: 'optimalRange',
            fixed: 'right',
            width: '10%',
          },
          {
            title: 'Symptom',
            dataIndex: 'symptom',
            fixed: 'right',
            width: '50%',
          },
        ]
      );

      setLabResultTableData(tableData);
      setLabResultTableSetting(tableSetting);

      checkTreatmentStatus();
    }
  }, [currentPatient]);

  const handleUrl = async (type, fileName) => {
    setShowSpinner(true);
    setSpinnerTip('Fetching Latest Lab Report PDF...');
    const urlData = await checkPresignUrl(type, currentPatient._id, fileName);
    setShowSpinner(false);
    setSpinnerTip('');
    if (urlData.data.status) {
      const newTab = window.open(urlData.data.url, '_blank');
      newTab.focus();
      return;
    }
    messageApi.open({
      type: 'error',
      content: 'Failed to open Lab Report, please try it again later.',
    });
  };

  const generateBioID = async ({ yourResult, monitor1, monitor2 }) => {
    setGenerateBioIdLoading(true);
    try {
      const res = await createBioId(currentPatient, yourResult, monitor1, monitor2);
      setBioIdGenerateResult(
        <Result
          status="success"
          title="Successfully Created BioId!"
          subTitle="What you can do next:"
          extra={[
            <Button
              type="primary"
              key="checkpdf"
              onClick={() => {
                const base64Data = res.data.base64;
                const src = 'data:application/pdf;base64,' + base64Data;
                const newTab = window.open();
                newTab.document.write('<iframe src="' + src + '" style="width:100%; height:100vh;"></iframe>');
                newTab.focus();
              }}
            >
              Check This BioId
            </Button>,
            <Button
              key="checkall"
              onClick={() => {
                setOpenBioIdPreviewModal(false);
                navigate(`/patient/${currentPatient.patientId}/files`);
              }}
            >
              Check All BioIds
            </Button>,
          ]}
        />
      );
    } catch (e) {
      message.error('Unable to generate BioId', 0);
      throw e;
    } finally {
      setGenerateBioIdLoading(false);
    }
  };

  return (
    <>
      <Spin spinning={showSpinner} fullscreen size={'large'} tip={spinnerTip} />
      {contextHolder}
      <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Space>
          <h4 style={{ marginBottom: 0 }}>
            Lab results
            <Tooltip title={'View Latest Lab Result PDF'}>
              <Button
                disabled={!currentPatient.currentLabReportPDFFileName}
                type="link"
                size={'large'}
                icon={<FilePdfOutlined />}
                onClick={() => handleUrl('lab-report', currentPatient.currentLabReportPDFFileName)}
                target="_blank"
              />
            </Tooltip>
          </h4>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Click to Upload Lab Report</Button>
          </Upload>
        </Space>

        <Popconfirm title={popupMsg} onConfirm={() => setOpenBioIdPreviewModal(true)}>
          <Button icon={<ContainerOutlined />} disabled={!currentLabResult.length}>
            Generate BioID from latest {hasTreatment ? 'treatment' : 'lab report'}
          </Button>
        </Popconfirm>
      </Row>
      <Row>
        <Col span={24}>
          {!!labResultTableSetting && <DataTable data={labResultTableData} settings={labResultTableSetting} />}
        </Col>
      </Row>

      <Modal
        title={'BioId Lab Results Data Setting'}
        open={openBioIdPreviewModal}
        destroyOnClose
        footer={null}
        maskClosable={false}
        onCancel={() => setOpenBioIdPreviewModal(false)}
        closable={!generateBioIdLoading}
        width={800}
      >
        {!!bioIdGenerateResult ? (
          <>{bioIdGenerateResult}</>
        ) : (
          <BioIDGenerateSettingComponent
            onOk={(selection) => generateBioID(selection)}
            onCancel={() => setOpenBioIdPreviewModal(false)}
          />
        )}
      </Modal>
      <LabReportDataCollectingModal
        patient={currentPatient}
        visible={!!prefillReportData}
        prefill={prefillReportData}
        onClose={() => {
          setPrefillReportData(null);
        }}
      />
    </>
  );
}

export default LabInfoPage;
