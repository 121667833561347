import { AutoComplete, Button, InputNumber, Modal, Select, Space, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { value } from 'lodash/seq';

const OptimalRangeLogicComponent = ({ logic, title, biomarkerList, onConfirm, disabled }) => {
  const [show, setShow] = useState(false);
  const [logicDisplay, setLogicDisplay] = useState('');
  const [currentLogic, setCurrentLogic] = useState(logic);
  const [inputs, setInputs] = useState([]);
  const [rangeType, setRangeType] = useState('');
  const [options] = useState([...new Set(biomarkerList)].map((s) => ({ value: s })));
  const lesserInput = useRef();
  const greaterInput = useRef();

  const calcSimpleLogicDisplay = (passingLogic) => {
    const optimalRangeValues = Object.values(passingLogic);
    if (optimalRangeValues.length === 2) {
      return `${Math.min(...optimalRangeValues)} - ${Math.max(...optimalRangeValues)}`;
    } else if (optimalRangeValues.length === 1) {
      return `${Object.keys(passingLogic)[0]} ${optimalRangeValues[0]}`;
    } else {
      console.error('unable to format this optimal range:', passingLogic);
      return '';
    }
  };

  const init = () => {
    if (logic) {
      setLogicDisplay(calcSimpleLogicDisplay(logic));
      setRangeType(
        Object.keys(logic).length > 1
          ? 'range'
          : '>' in logic || '>=' in logic
          ? 'gt'
          : '<' in logic || '<=' in logic
          ? 'lt'
          : '=' in logic
          ? 'eq'
          : ''
      );
      setInputs(Object.values(logic).sort((a, b) => a - b));
      setCurrentLogic(logic);
    }
  };

  const changeSingleComparatorCurrentLogic = (type, value) => {
    switch (type) {
      case 'gt':
        setCurrentLogic({ '>': value });
        break;
      case 'lt':
        setCurrentLogic({ '<': value });
        break;
      case 'eq':
        setCurrentLogic({ '=': value });
        break;
    }
  };

  useEffect(init, [logic]);

  useEffect(() => {
    if (currentLogic) {
      setInputs(Object.values(currentLogic).sort((a, b) => a - b));
    }
  }, [currentLogic]);

  return (
    <>
      <Button block onClick={() => setShow(true)} disabled={disabled}>
        {disabled ? 'Not Applicable' : <>{logicDisplay}</>}
      </Button>
      <Modal
        title={title}
        open={show}
        onOk={() => {
          const display = calcSimpleLogicDisplay(currentLogic);
          setLogicDisplay(display);
          onConfirm(currentLogic, display);
          setShow(false);
        }}
        onCancel={() => {
          setShow(false);
          init();
        }}
        width={550}
      >
        <Space>
          <Select
            value={rangeType}
            style={{ width: 150 }}
            onChange={(value) => {
              setRangeType(value);
              if (value === 'range') {
                if (!isNaN(Number(inputs[0]))) {
                  setCurrentLogic({ '>': 0, '<': Number(inputs[0]) });
                } else {
                  setCurrentLogic({ '>': 0, '<': 0 });
                }
              } else {
                changeSingleComparatorCurrentLogic(value, inputs[0]);
              }
            }}
            options={[
              { value: 'range', label: 'Range Between' },
              { value: 'gt', label: 'Greater Than' },
              { value: 'lt', label: 'Less Than' },
            ]}
          />
          {rangeType === 'range' && (
            <div>
              <InputNumber
                ref={lesserInput}
                value={Number(inputs[0]) || 0}
                onChange={(value) => {
                  if (value > inputs[1]) {
                    setCurrentLogic({ '<': value, '>': inputs[1] });
                    greaterInput.current.focus();
                  } else {
                    setCurrentLogic({ '>': value, '<': inputs[1] });
                  }
                }}
              />{' '}
              -{' '}
              <InputNumber
                ref={greaterInput}
                value={Number(inputs[1]) || 0}
                onChange={(value) => {
                  if (value < inputs[0]) {
                    setCurrentLogic({ '<': inputs[0], '>': value });
                    lesserInput.current.focus();
                  } else {
                    setCurrentLogic({ '>': inputs[0], '<': value });
                  }
                }}
              />
            </div>
          )}
          {['gt', 'lt', 'eq'].includes(rangeType) && (
            <Space>
              <AutoComplete
                options={options}
                style={{ width: 180 }}
                value={inputs[0]}
                onChange={(value) => {
                  if (isNaN(Number(value))) {
                    if (options.includes(value)) {
                      changeSingleComparatorCurrentLogic(rangeType, value);
                    }
                  } else {
                    changeSingleComparatorCurrentLogic(rangeType, value);
                  }
                }}
                onSelect={(value) => {
                  changeSingleComparatorCurrentLogic(rangeType, value);
                }}
              />
              <Typography.Text type={'secondary'}>(Number or another biomarker name)</Typography.Text>
            </Space>
          )}
        </Space>
      </Modal>
    </>
  );
};

export default OptimalRangeLogicComponent;
