import React, { useEffect, useState } from 'react';
import { Alert, Button, DatePicker, Modal, Select, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import bioApi from '../../../api/bioApi';
import { OPPORTUNITY_URL } from '../../../api/URLs';

export const CreateRefillOpportunityModal = ({ allPatients, afterCreate }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({ type: 'refill' });
  const [patients, setPatients] = useState([]);

  const createRefillOpportunity = async () => {
    setLoading(true);
    await bioApi.post(OPPORTUNITY_URL, payload);
    setLoading(false);
    setShow(false);
    afterCreate();
  };

  useEffect(() => {
    setPatients(
      allPatients
        .map((p) => ({
          ...p.patient,
          disabled:
            p.opportunities &&
            p.opportunities.length > 0 &&
            p.opportunities.some((r) => r.type === 'refill' && ['Open', 'No Answer', 'Considering'].includes(r.status)),
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [allPatients]);

  return (
    <>
      <Button type={'primary'} onClick={() => setShow(true)}>
        Create Refill Opportunity
      </Button>

      <Modal
        title={"New Refill Opportunity Against Patient's Current Treatment"}
        open={show}
        destroyOnClose
        confirmLoading={loading}
        okText={'Create Refill Opportunity'}
        okButtonProps={{ disabled: !payload.patient || !payload.followUpDate }}
        onCancel={() => setShow(false)}
        onOk={createRefillOpportunity}
      >
        <Space direction={'vertical'} style={{ width: '100%' }}>
          <Alert
            type={'info'}
            banner
            message={'Patients with Open, No Answer or Considering refill opportunity will be disabled.'}
          />
          <Space>
            <Select
              showSearch
              placeholder="Patient"
              style={{ width: 320 }}
              defaultActiveFirstOption={false}
              filterOption={true}
              value={payload.patient ? `${payload.patient}-${payload.treatmentPlan}` : undefined}
              onChange={(value) => {
                const patientObjectId = value.split('-')[0];
                const currentTreatmentPlan = value.split('-')[1];
                setPayload({ ...payload, patient: patientObjectId, treatmentPlan: currentTreatmentPlan });
              }}
              options={patients.map((p) => ({
                label: (
                  <Space>
                    <strong>{p.name}</strong>
                    <Typography.Text type={'secondary'}>{p.patientId}</Typography.Text>
                  </Space>
                ),
                value: `${p._id}-${p.currentTreatmentPlan}`,
                disabled: p.disabled,
              }))}
            />
            <DatePicker
              placeholder={'Follow up date'}
              minDate={dayjs()}
              value={payload.followUpDate ? dayjs(payload.followUpDate) : null}
              onChange={(newValue) => setPayload({ ...payload, followUpDate: newValue })}
            />
          </Space>
        </Space>
      </Modal>
    </>
  );
};
