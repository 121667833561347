import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, notification, Row, Spin } from 'antd';
import SelectComponent from '../../../components/select/SelectComponent';
import InputComponent from '../../../components/input/InputComponent';
import TextAreaComponent from '../../../components/input/TextAreaComponent';
import InputNumberComponent from '../../../components/input/InputNumberComponent';
import ImportOrderNumberModal from './ImportOrderNumberModal';
import TableComponent from '../../../components/table/TableComponent';
import { searchStaff } from '../../staff/staff-search.service';
import { searchPatients } from '../../patient/patient-search.service';
import { AllDataPost, getAllDataGet } from '../service';
import { ORDERS_CREATEL_URL, ORDERS_LIST_URL, PRODUCT_CRUD_URL } from '../../../api/URLs';

const OrderNumberModal = ({ open, setOpen, getAllDataRequest }) => {
  const [isSubmit, setIsSubmit] = useState(false)
  const [loading, setLoading] = useState(false);
  const [loadingOption, setLoadingption] = useState(false);
  const [dataObject, setDataObject] = useState({
    "pharmacie": "CRE8",
    "patientId": "",
    "orderId": "",
    "billingNotes": "",
    "providerId": "",
    "methodOfShipping": "", // UPS_Ground; UPS_Second_Day_Air
    "shippingAddress": {
      "street": "",
      "city": "",
      "state": "",
      "countryCode": "",
      "zip": ""
    },
  });
  const [providerData, setProviderData] = useState([])
  const [orderData, setOrderData] = useState([])
  const [patientIdData, setPatientIdData] = useState([])
  const [productData, setProductData] = useState([])
  const [dataSource, setDataSource] = useState([/*prescriptionOrder*/])

  const getPatientIdData = async () => {
    try {
      const result = await searchPatients('general-matching', 'all', false, false, true);
      let data = result.data && result.data
      if (data) {
        setPatientIdData([...data.patients.map(it => ({
          ...it,
          label: `${it.fullName}`,
          value: it.patientId,
        }))])
      } else {
        setPatientIdData([])
      }
    } catch (error) {
    }
  };

  const getProviderData = async () => {
    try {
      const result = await searchStaff('role', 'Provider');
      let data = result.data && result.data
      if (data) {
        setProviderData([...data.map(it => ({
          ...it,
          label: `${it.firstName} ${it.lastName}`,
          value: it.staffId,
        }))])
      } else {
        setProviderData([])
      }
    } catch (error) {
    }
  };

  const getProductData = async () => {
    try {
      const result = await AllDataPost(`${PRODUCT_CRUD_URL}options`, { name: "", pharmacie: "Cre8" });
      let data = result.data

      if (data) {
        setProductData([...data.map(it => ({
          ...it,
          label: `${it.name}`,
          value: it._id,
        }))])
      } else {
        setProductData([])
      }
    } catch (error) {
    }
  };

  const [isLoadOrder, setIsLoadOrder] = useState(false)
  const getOrderAll = async (providerId) => {
    if (!providerId) {
      return false
    }
    setIsLoadOrder(true)
    const result = await getAllDataGet(`${ORDERS_LIST_URL}/pharmacie/${providerId}`);
    setIsLoadOrder(false)
    if (result.status === 200 && result.data) {
      let data = result.data;

      let dataOrders = [...data.map(it => ({
        ...it,
        label: it.orderID,
        value: it.orderID,
        date: it.creationTS,
        quoteNumber: `${it.orderID}_prescription`,
        accountName: it.patientName,
        shippingInstructions: `${it.shippingAddress.street}, ${it.shippingAddress.city}, ${it.shippingAddress.state}, ${it.shippingAddress.zip}`,
        productId: it.product,
        product: it.product && it.product._id || "",
        providerId: it.treatment && it.treatment.createdBy,
        prescriptionOrder: {
          ...it.prescriptionOrder,
          contents: it.prescriptionOrder.contents ? it.prescriptionOrder.contents.map(pr => ({
            ...pr,
            productId: pr.product,
            product: pr.product && pr.product._id || "",
          })) : []
        },
      }))]
      setOrderData(dataOrders);
    } else {
      setOrderData([]);
    }
  }

  const getRequestAll = async () => {
    setLoadingption(true)
    await getPatientIdData();
    await getProviderData();
    await getProductData();
    setTimeout(() => {
      setLoadingption(false)
    }, 1000);
  }
  useEffect(() => {
    getRequestAll()
  }, [])

  const hanldeImport = (record) => {
    if(!record) {
      setDataObject({
        ...dataObject,
        "pharmacie": "CRE8",
        // "patientId": value,
        "orderId": '',
        "billingNotes": "",
        "providerId": "",
        "methodOfShipping": "",
        "shippingAddress": {
          "street": "",
          "city": "",
          "state": "",
          "countryCode": "",
          "zip": "",
        },
      })
      setDataSource([])
      return false
    }
    let findProvider = providerData.find(it => it.staffId === record.providerId)
    setDataObject({
      ...dataObject,
      "pharmacie": "CRE8",
      // "patientId": record.patientID,
      "orderId": record.orderID || '',
      "billingNotes": record.billingNotes || "",
      "providerId": findProvider && findProvider.providerId || "",
      "methodOfShipping": record.methodOfShipping || "",
      "shippingAddress": {
        "street": record.shippingAddress.street || "",
        "city": record.shippingAddress.city || "",
        "state": record.shippingAddress.state || "",
        "countryCode": record.shippingAddress.countryCode || "",
        "zip": record.shippingAddress.zip || "",
      },
    })
    setDataSource(record.prescriptionOrder.contents || [])
  }

  const hanldeChangePatient = (value) => {
    setDataObject({
      "pharmacie": "CRE8",
      "patientId": value,
      "orderId": '',
      "billingNotes": "",
      "providerId": "",
      "methodOfShipping": "",
      "shippingAddress": {
        "street": "",
        "city": "",
        "state": "",
        "countryCode": "",
        "zip": "",
      },
    })
    setDataSource([])
    setOrderData([]);
    value && getOrderAll(value)
  }

  const validateError = () => {

    let dataValid = {
      "pharmacie": dataObject.pharmacie,
      "patientId": dataObject.patientId,
      "orderId": dataObject.orderId,
      "billingNotes": dataObject.billingNotes,
      "providerId": dataObject.providerId,
      "methodOfShipping": dataObject.methodOfShipping,
      // "shippingAddress": {
      "street": dataObject.shippingAddress.street,
      "city": dataObject.shippingAddress.city,
      "state": dataObject.shippingAddress.state,
      "countryCode": dataObject.shippingAddress.countryCode,
      "zip": dataObject.shippingAddress.zip,
      // },
      "prescriptionOrder": dataSource.map(it => ({
        // "for": it.product.for,
        "product": it.product,
        "quantity": it.quantity,
        "refill": it.refill,
        "dosage": it.dosage,
        "note": it.note
      }))
    }

    const isAnyFieldEmpty = (obj) => {
      return Object.values(obj).some(value => value === null || value === undefined || value === "" || !value);
    };

    if (!dataSource.length) {
      return true
    }
    let prescriptionOrderValid = dataValid.prescriptionOrder.filter(it => isAnyFieldEmpty(it))

    if (isAnyFieldEmpty(dataValid) || prescriptionOrderValid.length > 0) {
      return true
    }

    return false
  }

  const handleSubmit = async () => {
    setIsSubmit(true)

    if (validateError()) {
      notification.error({ message: `Please fill in all required items before submitting`, duration: 10 });
      return false
    }

    let params = {
      "pharmacie": dataObject.pharmacie,
      "patientId": dataObject.patientId,
      "orderId": dataObject.orderId,
      "billingNotes": dataObject.billingNotes,
      "providerId": dataObject.providerId,
      "methodOfShipping": dataObject.methodOfShipping,
      "shippingAddress": {
        "street": dataObject.shippingAddress.street,
        "city": dataObject.shippingAddress.city,
        "state": dataObject.shippingAddress.state,
        "countryCode": dataObject.shippingAddress.countryCode,
        "zip": dataObject.shippingAddress.zip
      },
      "prescriptionOrder": dataSource.map(it => ({
        // "for": it.product.for,
        "product": it.product,
        "quantity": it.quantity,
        "refill": it.refill,
        "dosage": it.dosage,
        "note": it.note
      }))
    }
    setLoading(true)
    await AllDataPost(ORDERS_CREATEL_URL, params)
      .then((res) => {
        if(res.status === 200) {
          getAllDataRequest && getAllDataRequest()
          setLoading(false)
          notification.success({ message: res.message || "Successfully", duration: 10 });
          setOpen(false)
          return true;
        } else {
          setLoading(false)
          notification.error({ message: res.message || "Failed", duration: 10 });
          return false;
        }
      })
      .catch((error) => {
        setLoading(false)
        notification.error({ message: "Failed!", duration: 10 });
        return false;

      })

  }

  return (
    <>
      <Modal
        title={<>
          <div className='text-center' style={{ fontSize: "1.3rem" }}>New CRE8 Order Management</div>
        </>}
        open={open}
        cancelText={"Cancel"}
        okText={"Submit"}
        onCancel={() => setOpen(false)}
        maskClosable={false}
        keyboard={false}

        width={900}
        footer={[
          <div className='justify-content-around d-flex pt-2'>
            <Button size={'large'} type="primary" disabled={loading} onClick={handleSubmit}>
              Submit
            </Button>
            <Button key="cancel" size={'large'} onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </div>
        ]}
      >
        <Spin spinning={loading}>

          <Row>
            <Col span={18}>
            </Col>
            <Col span={6} className='text-end'>
              <ImportOrderNumberModal
                hanldeImport={(record) => {
                  hanldeImport(record)
                }}
                loading={loading} setLoading={setLoading}
              />
            </Col>
          </Row>

          <div className='w-100'>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Patient: </label>
                </div>
              </Col>
              <Col span={18}>
                <SelectComponent status={isSubmit && !dataObject.patientId && `error`} options={patientIdData} value={dataObject.patientId} setValue={(value) => hanldeChangePatient(value)} placeholder={loadingOption ? `Fetching data...` : `Select Patient`} />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Referent Order ID: </label>
                </div>
              </Col>
              <Col span={18}>
                <SelectComponent status={isSubmit && !dataObject.orderId && `error`} options={orderData} value={dataObject.orderId}
                  setValue={(value, values) => {
                    setDataObject({ ...dataObject, orderId: value })
                    hanldeImport(values)
                  }} placeholder={isLoadOrder ? `Fetching data...` : `Select Referent Order ID`} />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Provider: </label>
                </div>
              </Col>
              <Col span={18}>
                <SelectComponent status={isSubmit && !dataObject.providerId && `error`} options={providerData} value={dataObject.providerId} setValue={(value) => setDataObject({ ...dataObject, providerId: value })} placeholder={loadingOption ? `Fetching data...` : `Select Provider`} />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Method Of Shipping: </label>
                </div>
              </Col>
              <Col span={18}>
                <SelectComponent status={isSubmit && !dataObject.methodOfShipping && `error`}
                  options={[
                    { label: "UPS Ground", value: "UPS_Ground" },
                    { label: "UPS Second Day Air", value: "UPS_Second_Day_Air" },
                  ]}
                  value={dataObject.methodOfShipping} setValue={(value) => setDataObject({ ...dataObject, methodOfShipping: value })} placeholder={`Select Method Of Shipping`} />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Shipping Address: </label>
                </div>
              </Col>
              <Col span={18}>
                <InputComponent className={`mb-2`} status={isSubmit && !dataObject.shippingAddress.street && `error`} value={dataObject.shippingAddress.street}
                  setValue={(value) => setDataObject({ ...dataObject, shippingAddress: { ...dataObject.shippingAddress, street: value } })} placeholder={`Street`} />
                <InputComponent className={`mb-2`} status={isSubmit && !dataObject.shippingAddress.city && `error`} value={dataObject.shippingAddress.city}
                  setValue={(value) => setDataObject({ ...dataObject, shippingAddress: { ...dataObject.shippingAddress, city: value } })} placeholder={`City`} />
                <InputComponent className={`mb-2`} status={isSubmit && !dataObject.shippingAddress.state && `error`} value={dataObject.shippingAddress.state}
                  setValue={(value) => setDataObject({ ...dataObject, shippingAddress: { ...dataObject.shippingAddress, state: value } })} placeholder={`State`} />
                <InputComponent className={`mb-2`} status={isSubmit && !dataObject.shippingAddress.countryCode && `error`} value={dataObject.shippingAddress.countryCode}
                  setValue={(value) => setDataObject({ ...dataObject, shippingAddress: { ...dataObject.shippingAddress, countryCode: value } })} placeholder={`Country Code`} />
                <InputNumberComponent min={0} isOnlyNumber={true} maxLength={5} status={isSubmit && !dataObject.shippingAddress.zip && `error`} value={dataObject.shippingAddress.zip}
                  setValue={(value) => setDataObject({ ...dataObject, shippingAddress: { ...dataObject.shippingAddress, zip: value } })} placeholder={`Zipcode`} />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''><span className='text-danger'>*</span>Billing Notes: </label>
                </div>
              </Col>
              <Col span={18}>
                <TextAreaComponent status={isSubmit && !dataObject.billingNotes && `error`} value={dataObject.billingNotes} setValue={(value) => setDataObject({ ...dataObject, billingNotes: value })} placeholder={`Billing Notes`} />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor='' style={{ fontSize: "1rem" }}><span className='text-danger'>*</span>Prescription Order: </label>
                </div>
              </Col>
              <Col span={18}>
                <div className='text-end'>
                  <label
                    onClick={() => {
                      let prescriptionData = [...dataSource]
                      prescriptionData.push({
                        "for": "",
                        "product": "",
                        "quantity": "",
                        "refill": "",
                        "dosage": "",
                        "note": ""
                      })
                      setDataSource(prescriptionData)
                    }}
                    style={{ cursor: "pointer", color: "rgb(0, 117, 255)", fontSize: "1rem" }}>+ Add</label>
                </div>
              </Col>
              <Col span={24}>
                <TableComponent
                  disabled={true}
                  isSubmit={isSubmit}
                  dataSource={dataSource} setDataSource={setDataSource}
                  productData={productData} setProductData={setProductData}
                />
              </Col>
            </Row>
          </div>
        </Spin>

      </Modal>
    </>
  );
};
export default OrderNumberModal;