import bioApi from '../../api/bioApi';
import {
  APPOINTMENT_URL,
  CALENDAR_TIPS_URL,
  CANCEL_APPOINTMENT,
  COMPLETE_APPOINTMENT,
  CONFIRM_APPOINTMENT,
  DELETE_BLOCKED_TIME,
  GET_STAFF_SCHEDULE_URL,
  LOCATION_SHORT_LIST,
  NO_SHOW_APPOINTMENT,
  RESCHEDULE_APPOINTMENT,
  UPDATE_APPOINTMENT,
} from '../../api/URLs';
import { getAppointmentAvailableServices } from '../patient/service';
import { searchStaff } from '../staff/staff-search.service';
import {ApplicationRight} from "../../share/RightList";

export const getAppointment = (params) => {
  const paramsObject = new URLSearchParams();

  params.forEach((dataObject) => {
    Object.keys(dataObject).forEach((key) => {
      paramsObject.append(key, dataObject[key]);
    });
  });
  const url = APPOINTMENT_URL + '?' + paramsObject.toString();
  return bioApi.get(url);
};

export const getLocation = () => {
  return bioApi.post(LOCATION_SHORT_LIST);
};

export const generateTitle = (service, patient, { staff, location }, differentiator) => {
  let patientName = '*Nonexistent Patient*';
  if (patient) {
    patientName = `${patient.firstName} ${patient.lastName}`;
  }
  let icon;
  switch (service.type) {
    case 'Defy Age':
      icon = '⛲';
      break;
    case 'Replenish Life':
      icon = '🌿';
      break;
    case 'Restore Beauty':
      icon = '💉';
      break;
    case 'Phone Consultation':
      icon = '📞';
      break;
    case 'Virtual Consultation':
      icon = '🌐';
      break;
    case 'WeTreat':
      icon = '📝';
      break;
    default:
      icon = '';
  }

  return `${icon} ${service.serviceName} - ${patientName} ` + differentiator({ location, staff });
};

export const getStaffSchedules = ({ staff, location, service, timezone, date }) => {
  const paramsObject = new URLSearchParams();

  Object.entries({ location, service, timezone, date }).forEach(([key, value]) => {
    if (value) {
      paramsObject.append(key, value);
    }
  });

  let url = GET_STAFF_SCHEDULE_URL.replace(':staffId', staff);
  url = url + '?' + paramsObject.toString();
  return bioApi.get(url);
};

export const updateOrRescheduleAppointment = (
  appointmentId,
  date,
  start,
  end,
  staffId,
  locationId,
  serviceId,
  note,
  action
) => {
  let url;
  if (action === 'need-reschedule') {
    url = RESCHEDULE_APPOINTMENT;
  } else if (action === 'just-update') {
    url = UPDATE_APPOINTMENT.replace(':appointmentId', appointmentId);
  }
  const payload = {
    appointmentId,
    date,
    start,
    end,
    staffId,
    locationId,
    serviceId,
    note,
  };
  return bioApi.post(url, payload);
};

export const cancelAppointment = (appointmentId) => {
  const url = CANCEL_APPOINTMENT;
  const payload = {
    appointmentId,
  };
  return bioApi.post(url, payload);
};

export const deleteBlockedTime = (staffId, blockedTimeId) => {
  const url = DELETE_BLOCKED_TIME;
  const payload = {
    staffId,
    blockedTimeId,
  };
  return bioApi.delete(url, { data: payload });
};

export const patientNotAppear = (appointmentId) => {
  const url = NO_SHOW_APPOINTMENT;
  const payload = {
    appointmentId,
  };
  return bioApi.post(url, payload);
};

export const completeAppointment = (appointmentId) => {
  const url = COMPLETE_APPOINTMENT;
  const payload = {
    appointmentId,
  };
  return bioApi.post(url, payload);
};

export const getEventClass = (event) => {
  let className = event.service.colorScheme + '-event';
  className = className + (event.apptConfirm ? ' confirmed-event' : ' not-confirmed-event');
  className = className + (!!event.rescheduleRequest ? ' reschedule-requested' : '');
  if (event.status === 'Canceled') {
    className = className + ' canceled-event';
  } else if (event.status === 'Rescheduled') {
    className = className + ' rescheduled-event';
  } else if (event.status === 'PatientAbsents') {
    className = className + ' no-show-event';
  } else if (event.status === 'Completed') {
    className = className + ' completed-event';
  }

  return className;
};

export const calendarEssentialDataInitializer = async (setLocationOptions, setProviders, setProvidingServices) => {
  const locationOptions = [];
  const locationInformation = await getLocation();
  const services = await getAppointmentAvailableServices();
  const providers = await searchStaff('right', ApplicationRight.Schedule_And_Appointment_Acceptance);

  if (locationInformation.data.data) {
    locationInformation.data.data.forEach((location) => {
      locationOptions.push({
        value: location.stationId,
        label: `${location.name}, ${location.address.state}`,
        name: location.name,
        timezone: location.timezone,
      });
    });
  }
  setLocationOptions(locationOptions);
  setProviders(
    providers.data.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName))
  );
  setProvidingServices(services.data.data);
};

export const getTipsBtwDates = async (startDate, endDate, locationId) => {
  return await bioApi.get(CALENDAR_TIPS_URL, { params: { startDate, endDate, locationId } });
};

export const addTipOnDate = async (content, targetDate, locationId) => {
  return await bioApi.post(CALENDAR_TIPS_URL, { content, targetDate, locationId });
};

export const modifyTip = async (id, content) => {
  return await bioApi.put(`${CALENDAR_TIPS_URL}/${id}`, { content });
};

export const deleteTip = async (id) => {
  return await bioApi.delete(`${CALENDAR_TIPS_URL}/${id}`);
};

export const confirmAppointment = (appointmentId) => {
  const url = CONFIRM_APPOINTMENT.replace(':appointmentId', appointmentId);
  const payload = {
    appointmentId,
  };
  return bioApi.post(url, payload);
};
