import React, { useEffect, useState } from 'react';
import { Alert, AutoComplete, Button, Card, Divider, Modal, Select, Space, Typography } from 'antd';
import { convertLogicToString } from '../../utils/json-logic-object.parser';
import { DeleteOutlined, PlusCircleTwoTone } from '@ant-design/icons';

const MedicineConditionLogicComponent = ({ logic, optimalRangeDisplay, title, biomarkerList, onConfirm, disabled }) => {
  const [show, setShow] = useState(false);
  const [logicDisplay, setLogicDisplay] = useState('');
  const [currentLogic, setCurrentLogic] = useState(JSON.parse(JSON.stringify(logic || {})));
  const [modifyingLogicDisplay, setModifyingLogicDisplay] = useState('');
  const [options] = useState([...new Set(biomarkerList)].map((s) => ({ value: s })));

  const calcDisplay = (logic) => {
    const display = convertLogicToString(logic || {}, true);
    return !!display.trim() ? display.trim() : 'When Out Of Optimal Range';
  };
  const init = () => {
    const display = calcDisplay(logic);
    setLogicDisplay(display);
    setModifyingLogicDisplay(display);
    setCurrentLogic(logic);
  };

  const logicComponentRendering = () => (
    <>
      {Object.entries(currentLogic).map(([key, value], index) => {
        return (
          <React.Fragment key={index}>
            {!!index && <Divider orientation={'left'}>And</Divider>}

            <Space>
              <Select
                value={key}
                style={{ width: 200 }}
                onSelect={(selection) => {
                  delete currentLogic[key];
                  currentLogic[selection] = '';
                  setCurrentLogic({ ...currentLogic });
                }}
                options={[
                  { value: '>', label: 'Greater Than', disabled: currentLogic.hasOwnProperty('>') },
                  { value: '<', label: 'Less Than', disabled: currentLogic.hasOwnProperty('<') },
                  {
                    value: 'biologicalStage',
                    label: 'Biological stage is',
                    disabled: currentLogic.hasOwnProperty('biologicalStage'),
                  },
                ]}
              />
              {['>', '<'].includes(key) ? (
                <Space>
                  <AutoComplete
                    options={options}
                    style={{ width: 180 }}
                    value={value}
                    onChange={(newValue) => {
                      if (isNaN(Number(newValue))) {
                        if (options.includes(newValue)) {
                          currentLogic[key] = newValue;
                        }
                      } else {
                        currentLogic[key] = newValue;
                      }
                      setCurrentLogic({ ...currentLogic });
                    }}
                    onSelect={(selection) => {
                      currentLogic[key] = selection;
                      setCurrentLogic({ ...currentLogic });
                    }}
                  />
                  <Typography.Text type={'secondary'}>(Number or another biomarker name)</Typography.Text>
                  <Button
                    size={'small'}
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      delete currentLogic[key];
                      setCurrentLogic({ ...currentLogic });
                    }}
                  />
                </Space>
              ) : (
                <Select
                  value={value}
                  style={{ width: 150 }}
                  onSelect={(selection) => {
                    currentLogic[key] = selection;
                    setCurrentLogic({ ...currentLogic });
                  }}
                  options={[
                    { value: 'menopause', label: 'Menopause' },
                    { value: 'luteal', label: 'Luteal' },
                  ]}
                />
              )}
            </Space>
          </React.Fragment>
        );
      })}
      <Divider />
      <Button icon={<PlusCircleTwoTone />} onClick={() => setCurrentLogic({ ...currentLogic, '': '' })}>
        And
      </Button>
    </>
  );

  useEffect(init, [logic]);

  useEffect(() => {
    setModifyingLogicDisplay(calcDisplay(currentLogic));
  }, [currentLogic]);

  return (
    <>
      <Button size={'small'} onClick={() => setShow(true)} disabled={disabled}>
        {disabled ? (
          'Applying Condition Not Applicable'
        ) : (
          <Typography.Text type={'secondary'}>{logicDisplay}</Typography.Text>
        )}
      </Button>
      <Modal
        title={title}
        open={show}
        onOk={() => {
          setLogicDisplay(calcDisplay(currentLogic));
          onConfirm(currentLogic);
          setShow(false);
        }}
        onCancel={() => {
          setCurrentLogic(logic);
          setShow(false);
        }}
        destroyOnClose
        width={888}
      >
        <Alert
          type={'warning'}
          banner
          message={
            <>
              <div>System will not validate your input, please make sure the conditions are logical.</div>
              <div>
                All conditions operate under AND relation. If an OR relation is required, include the same medicine in
                the associated medicine list and configure the desired logic for that specific entry.
              </div>
            </>
          }
        ></Alert>
        <div className="d-flex justify-content-between" style={{ marginBlock: 4 }}>
          <Space>
            <Typography.Text type={'secondary'}>Current Logic:</Typography.Text>
            <Typography.Text code>{modifyingLogicDisplay}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text type={'secondary'}>Biomarker Optimal Range:</Typography.Text>
            <Typography.Text type={'success'}>{optimalRangeDisplay}</Typography.Text>
          </Space>
        </div>
        <Card style={{ backgroundColor: '#f7f7f7' }}>
          {!currentLogic || !Object.keys(currentLogic).length ? (
            <div>
              <p>
                <Typography.Text mark>
                  No conditions are defined. Medicine/ product will be applicable as long as the lab data is out of
                  range
                </Typography.Text>
              </p>
              <div>
                <span style={{ marginRight: 4 }}>You can: </span>
                <Select
                  style={{ width: 150 }}
                  onSelect={(value) => {
                    setCurrentLogic({ [value]: '' });
                  }}
                  placeholder={'Add Condition'}
                  options={[
                    { value: '>', label: 'Greater Than' },
                    { value: '<', label: 'Less Than' },
                    { value: 'biologicalStage', label: 'Biological stage is' },
                  ]}
                />
              </div>
            </div>
          ) : (
            <Card>{logicComponentRendering(currentLogic)}</Card>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default MedicineConditionLogicComponent;
