import React, { useRef, useState } from 'react';
import { Button, Col, message, Modal, Row } from 'antd';
import InputComponent from '../../../components/input/InputComponent';
import TableOrderComponent from '../../../components/table/TableOrderComponent';
import { pdfjs } from 'react-pdf';
import { searchOrders } from '../service';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ImportOrderNumberModal = ({
  hanldeImport,
  loading, setLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [isViewPdf, setIsviewPdf] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [fileBase64, setFileBase64] = useState(null);
  const [prescriptionId, setPrescriptionId] = useState(null);

  const getOrderAll = async (prescriptionIdFound, prescriptionIdFound0, base64String) => {
    setPrescriptionId(prescriptionIdFound || "");
    setLoading && setLoading(true);
    const patientDataRequest = await searchOrders(
      `orderID`, prescriptionIdFound,
    );
    if (patientDataRequest.data.status === 200 && patientDataRequest.data.data) {
      let data = patientDataRequest.data.data;
      setDataSource([...data.map(it => ({
        ...it,
        quoteNumber: `${it.orderID}_prescription`,
        accountName: it.patientName,
        shippingInstructions: `${it.shippingAddress.street}, ${it.shippingAddress.city}, ${it.shippingAddress.state}, ${it.shippingAddress.zip}`,//`8063 Severn Dr, Boca Raton, FL 33433`,
        url: base64String,
      }))]);
      setOpen(true)
    } else {
      message.error("Please upload a valid PDF file");
      setOpen(false)
      setDataSource([]);
    }
    setLoading && setLoading(false);
  }

  const extractPrescriptionId = async (file, base64String) => {
    try {
      const pdf = await pdfjs.getDocument(URL.createObjectURL(file)).promise;
      let prescriptionIdFound0 = null;
      let prescriptionIdFound = null;

      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map(item => item.str).join(' ');

        const prescriptionMatch = pageText.match(/([A-Za-z0-9]+)_prescription/);
        if (prescriptionMatch && prescriptionMatch[1]) {
          prescriptionIdFound0 = prescriptionMatch[0];
          prescriptionIdFound = prescriptionMatch[1];
          break;
        }

      }

      if (prescriptionIdFound) {
        getOrderAll(prescriptionIdFound, prescriptionIdFound0, base64String)

      } else {
        setDataSource([]);
        message.error("Please upload a valid PDF file");
      }
    } catch (error) {
      setDataSource([]);
      console.error("Error extracting text from PDF:", error);
    }
  };

  const handleFileChange = async (e) => {
    setLoading && setLoading(true);
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);

      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        const base64String = reader.result;
        setFileBase64(base64String);
        extractPrescriptionId(selectedFile, base64String);
      };
      reader.onerror = (error) => {
      };
    } else {
      message.error("Please upload a valid PDF file");
    }
    setLoading && setLoading(false);
  };

  const handleImportClick = () => {
    fileInputRef.current.value = "";
    fileInputRef.current.click();
  };

  return (
    <>
      <span onClick={handleImportClick} style={{ cursor: "pointer", display: "none", color: "rgb(0, 117, 255)", fontSize: "1rem" }}>Import</span>
      <input
        type="file"
        accept="application/pdf"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Modal
        title={<>
          <div className='text-center' style={{ fontSize: "1.3rem" }}>Order Import</div>
        </>}
        open={open}
        cancelText={"Cancel"}
        okText={"Submit"}
        onCancel={() => {
          isViewPdf ? setIsviewPdf(false) : setOpen(false)
        }}
        maskClosable={false}
        keyboard={false}

        width={900}
        footer={[
          <div className='justify-content-around d-flex pt-2'>
            {
              isViewPdf ? <>
                <Button key="cancel" size={'large'} onClick={() => setIsviewPdf(false)}>
                  Back
                </Button>
              </> : <>
                <Button key="cancel" size={'large'} onClick={() => setOpen(false)}>
                  Cancel
                </Button>
              </>
            }

          </div>
        ]}
      >

        <div className='w-100'>
          {isViewPdf ? <>
            <iframe
              src={`${isViewPdf}`}
              style={{ width: "100%", height: "100vh" }}
            ></iframe>
          </>
            :
            <>
              <Row className='mt-3'>
                <Col className='d-flex align-items-center' span={10}>
                  <label htmlFor='' className='text-nowrap me-2'>Order ID: </label>
                  <InputComponent
                    value={prescriptionId}
                    setValue={(value) => {
                      setPrescriptionId(value)
                    }}
                    placeholder={`Order ID`} />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col span={6}>
                  <div>
                    <label style={{ display: "none" }} htmlFor=''>Order: </label>
                  </div>
                </Col>
                <Col span={24}>
                  <TableOrderComponent
                    setIsviewPdf={(record) => {
                      setIsviewPdf(record.url)
                    }}
                    hanldeImport={(record) => {
                      hanldeImport && hanldeImport(record)
                      setOpen(false)
                    }}
                    disabled={true}
                    dataSource={dataSource}
                    setDataSource={setDataSource}
                    placeholder={`Create Date`} />
                </Col>
              </Row>

            </>}
        </div>

      </Modal>
    </>
  );
};
export default ImportOrderNumberModal;