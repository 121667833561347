import React, { useEffect, useState } from 'react';
import bioApi from '../../../api/bioApi';
import { CONSENT_URL } from '../../../api/URLs';
import { useSelector } from 'react-redux';
import { Button, Col, message, Modal, Popconfirm, Radio, Row, Select, Space } from 'antd';
import DataTable from '../../../components/data-table/data-table.component';
import moment from 'moment';
import { checkPresignUrl } from '../service';
import { Document, Page } from 'react-pdf';

const ConsentPage = () => {
  const [consentTemplates, setConsentTemplates] = useState([]);
  const [patientConsents, setPatientConsents] = useState([]);
  const [showSendConsentModal, setShowSendConsentModal] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [consentType, setConsentType] = useState(null);
  const currentPatient = useSelector((state) => state.currentPatient);
  const [buttonLoading, setButtonLoading] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  const fetchConsentTemplates = async () => {
    // fetch consent templates
    const templates = await bioApi.get(`${CONSENT_URL}/templateList`);
    setConsentTemplates(templates.data || []);
  };

  const fetchPatientConsents = async () => {
    // fetch patient consents
    setTableLoading(true);
    const patientConsents = await bioApi.get(`${CONSENT_URL}/${currentPatient.patientId}`);
    setPatientConsents(patientConsents.data.sort((a, b) => moment(b.creationTS) - moment(a.creationTS)));
    setTableLoading(false);
  };

  const handleUrl = async (type, patientId, consentId) => {
    setButtonLoading((prevState) => ({ ...prevState, [consentId]: true }));
    try {
      console.log(type, patientId, consentId);
      const urlData = await checkPresignUrl(type, patientId, null, consentId);
      if (urlData.data.status) {
        const pdfData = urlData.data.pdfs;
        Modal.success({
          width: '700px',
          title: `Consent Lists:`,
          content: (
            <Row style={{ justifyContent: 'center', gap: '5px' }}>
              {pdfData.map((file) => (
                <>
                  <Col span={10} style={{ padding: '12px', background: '#F9F9F9' }}>
                    <p>{file.fileName}</p>

                    <Document
                      file={file.pdfLink}
                      onLoadError={console.error}
                      noData={'Consent form is not generated'}
                      className="cursor-pointer"
                      onClick={() => window.open(file.pdfLink, '_blank')}
                    >
                      <Page pageNumber={1} width={250} scale={0.95} renderMode="canvas" />
                    </Document>
                  </Col>
                </>
              ))}
            </Row>
          ),
        });
      } else {
        messageApi.open({
          type: 'error',
          content: 'Failed to open consent',
        });
      }
    } catch (err) {
      messageApi.open({
        type: 'error',
        content: 'Failed to open consent',
      });
    }
    setButtonLoading((prevState) => ({ ...prevState, [consentId]: false }));
  };

  const dataTableSetting = {
    operationColumnTitle: 'Action',
    columns: [
      {
        title: 'Consent',
        dataIndex: 'name',
      },
      {
        title: 'Create Date',
        dataIndex: 'creationTS',
        render: (date) => moment(date).format('MMMM Do, YYYY hh:mm A'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: (type) => (type === 'SIGN' ? 'Email Delivered' : 'In Person Sign'),
      },
    ],
    customActions: [
      {
        element: (record, data) => (
          <Space wrap>
            <Button
              className="action-btn"
              disabled={record?.pdfs.length == 0}
              loading={buttonLoading[record._id] || false}
              onClick={() => handleUrl('consent', record.patientId, record._id)}
              target="_blank"
            >
              View
            </Button>
          </Space>
        ),
      },
    ],
  };

  useEffect(() => {
    fetchConsentTemplates();
    fetchPatientConsents();
  }, []);

  return (
    <div>
      {contextHolder}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
        <h5>Patient Consents</h5>
        <Button onClick={() => setShowSendConsentModal(!showSendConsentModal)}>Create New Consent</Button>
      </div>
      <DataTable data={patientConsents} settings={dataTableSetting} loading={tableLoading} />
      <Modal
        open={showSendConsentModal}
        title={'Create Consent'}
        footer={null}
        onCancel={() => {
          setShowSendConsentModal(false);
          setSelectedTemplate(null);
          setConsentType(null);
        }}
        destroyOnClose={true}
      >
        <h6>Select Consent</h6>
        <div>
          <Select
            style={{ width: '100%' }}
            options={consentTemplates.map((template) => ({
              label: template.template_name,
              value: template.template_id,
            }))}
            onChange={(value) => setSelectedTemplate(value)}
          />
        </div>

        <div style={{ marginBlock: 8 }}>
          <Radio.Group onChange={(e) => setConsentType(e.target.value)} value={consentType}>
            <Radio value={'email'}>Email Sign</Radio>
            <Radio value={'in-person'}>In Person Sign</Radio>
          </Radio.Group>
        </div>

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Popconfirm
            title={`You are about to send a consent to patient ${
              consentType === 'in-person' ? 'to fill and sign IN PERSON' : 'via email'
            }, please confirm.`}
            onConfirm={async () => {
              // send consent
              setCreateLoading(true);
              try {
                await bioApi.post(`${CONSENT_URL}/${currentPatient.patientId}`, {
                  templateId: selectedTemplate,
                  type: consentType,
                });
                fetchPatientConsents();
                setShowSendConsentModal(false);
                setSelectedTemplate(null);
                setConsentType(null);
                message.success('Consent created successfully');
              } catch (e) {
                message.error('Failed to create consent');
              } finally {
                setCreateLoading(false);
              }
            }}
          >
            <Button loading={createLoading} disabled={!consentType || !selectedTemplate}>
              Create Consent
            </Button>
          </Popconfirm>
        </div>
      </Modal>
    </div>
  );
};

export default ConsentPage;
