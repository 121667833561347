export const calcPriceReducer = (previousValue, currentValue) => {
  return (
    previousValue +
    (currentValue.cancelled
      ? 0
      : isNaN(currentValue.price)
      ? 0
      : Number(
          currentValue.price * ((currentValue.overridingQuantity ?? currentValue.quantity) || 0) +
            (currentValue.medicalSupplies || []).reduce(calcPriceReducer, 0)
        ))
  );
};
