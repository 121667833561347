import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataTable from '../../components/data-table/data-table.component';
import './patient-search.css';
import { Input } from 'antd';
import { searchPatients } from './patient-search.service';

function PatientSearchPage() {
  const [searchProspectPatientResult, setProspectPatientResult] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchThrottling, setSearchThrottling] = useState(false);

  const patientTableSettings = {
    appendable: false,
    removable: false,
    bordered: false,
    size: 'middle',
    pagination: {
      showLessItems: true,
      pageSize: 20,
    },
    columns: [
      {
        title: 'Patient ID',
        dataIndex: 'patientId',
      },
      {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
      },
      {
        title: 'Service Location',
        dataIndex: 'serviceCenter',
        filterable: true,
      },
    ],
    customActions: [
      {
        element: (record, data) => <Link to={`/patient/${record.patientId}`}>{record.fullName}</Link>,
      },
    ],
    operationColumnTitle: 'Name',
    actionColumnIndex: 1,
  };
  const prospectPatienttableSettings = {
    appendable: false,
    removable: false,
    bordered: false,
    size: 'middle',
    pagination: {
      showLessItems: true,
      pageSize: 20,
    },
    columns: [
      {
        title: 'APPUSER ID',
        dataIndex: 'appuserId',
      },
      {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
      },
    ],
    customActions: [
      {
        element: (record, data) => <Link to={`/prospect-patient/${record.appuserId}`}>{record.fullName}</Link>,
      },
    ],
    operationColumnTitle: 'Name',
    actionColumnIndex: 1,
  };

  let criteriaChangingDebounce;
  let searchCriteria;
  let lastSearch = '';
  const debouncedSearch = (newValue) => {
    clearTimeout(criteriaChangingDebounce);
    criteriaChangingDebounce = setTimeout(async () => {
      if (newValue && newValue.length > 3) {
        searchCriteria = newValue;
        await immediateSearch(newValue, true);
      }
    }, 1000);
  };

  const immediateSearch = async (newValue, fromDebounce) => {
    try {
      if (lastSearch === newValue || searchThrottling) return;
      setLoading(true);
      if (newValue) {
        lastSearch = newValue;
        setSearchThrottling(true);
        const result = await searchPatients('general-matching', newValue, false, true);
        if (fromDebounce) {
          if (searchCriteria === newValue) {
            setProspectPatientResult(result.data.prospectPatients || []);
            setSearchResult(result.data.patients);
          }
        } else {
          setProspectPatientResult(result.data.prospectPatients || []);
          setSearchResult(result.data.patients);
        }
      } else {
        setProspectPatientResult([]);
        setSearchResult([]);
      }
    } finally {
      setTimeout(() => setSearchThrottling(false), 1000);
      setLoading(false);
    }
  };

  return (
    <div style={{ background: '#F0F2F5 0% 0% no-repeat padding-box', padding: 32, height: '100%' }}>
      <div className="d-flex justify-content-between">
        <h2>Patients Management</h2>
      </div>
      <br />
      <div className="d-flex">
        <Input.Search
          placeholder="Search by Patient ID, Name, Phone Number and Email or App User ID"
          size="large"
          allowClear
          style={{ width: '50%' }}
          onChange={(e) => debouncedSearch(e.target.value)}
          onSearch={async (value) => await immediateSearch(value, false)}
        />
      </div>
      {searchResult && (
        <Row className="section">
          <Col sm={12}>
            <h3>Patients</h3>
          </Col>
          <Col sm={12}>
            <div className="section-content">
              <DataTable loading={loading} data={searchResult} settings={patientTableSettings} />
            </div>
          </Col>
        </Row>
      )}
      {searchProspectPatientResult && (
        <Row className="section">
          <Col sm={12}>
            <h3>Prospect Patients</h3>
          </Col>
          <Col sm={12}>
            <div className="section-content">
              <DataTable loading={loading} data={searchProspectPatientResult} settings={prospectPatienttableSettings} />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default PatientSearchPage;
