import { createSlice } from '@reduxjs/toolkit';

/*
 * [
 *   {
 *     patient: {}
 *     hasUnreadMessage: boolean,
 *     latestMessageTime: number,
 *     latestMessageFrom: "staff" | "patient",
 *     messages: null,
 *   }
 * ]
 * */
const patientConversationsSlice = createSlice({
  name: 'patient-conversations',
  initialState: [],
  reducers: {
    initializeConversationList: (state, action) => {
      return action.payload;
    },
    loadMessagesToConversation: (state, action) => {
      const index = state.findIndex((c) => c.patient.appuserId === action.payload.appuserId);

      if (index !== -1) {
        return [
          ...state.slice(0, index),
          { ...state[index], messages: [...action.payload.messages] },
          ...state.slice(index + 1),
        ];
      } else {
        return state;
      }
    },
    startNewConversation: (state, action) => {
      const newState = [...state];

      newState.push({
        patient: action.payload,
        hasUnreadMessage: false,
        latestMessageTime: null,
        latestMessageFrom: null,
        messages: [],
      });

      return [...newState];
    },
    markConversationRead: (state, action) => {
      const index = state.findIndex((c) => c.patient.appuserId === action.payload);

      if (index !== -1) {
        return [...state.slice(0, index), { ...state[index], hasUnreadMessage: false }, ...state.slice(index + 1)];
      } else {
        return state;
      }
    },
    markConversationUnread: (state, action) => {
      const index = state.findIndex((c) => c.patient.appuserId === action.payload);

      if (index !== -1) {
        return [...state.slice(0, index), { ...state[index], hasUnreadMessage: true }, ...state.slice(index + 1)];
      } else {
        return state;
      }
    },
    addReplyMessage: (state, action) => {
      const index = state.findIndex((c) => c.patient.appuserId === action.payload.appuserId);

      if (index !== -1) {
        return [
          ...state.slice(0, index),
          {
            ...state[index],
            latestMessageTime: new Date(),
            latestMessageFrom: 'staff',
            messages: [...state[index].messages, action.payload.message],
          },
          ...state.slice(index + 1),
        ];
      } else {
        return state;
      }
    },
    receiveNewMessage: (state, action) => {
      const index = state.findIndex((c) => c.patient.appuserId === action.payload.patient.appuserId);

      let updateState = false;
      if (!(state[index].messages || []).find((m) => m._id === action.payload.message._id)) {
        updateState = true;
      }

      if (index !== -1 && updateState) {
        return [
          ...state.slice(0, index),
          {
            ...state[index],
            hasUnreadMessage: true,
            latestMessageTime: action.payload.latestMessageTime,
            latestMessageFrom: action.payload.message.from,
            // if messages is not null, then add new message, if null, then just mark unread
            messages: state[index].messages ? [...state[index].messages, action.payload.message] : null,
          },
          ...state.slice(index + 1),
        ];
      } else {
        return state;
      }
    },
  },
});

export const {
  initializeConversationList,
  markConversationRead,
  addReplyMessage,
  loadMessagesToConversation,
  receiveNewMessage,
  startNewConversation,
  markConversationUnread,
} = patientConversationsSlice.actions;
export default patientConversationsSlice.reducer;
