import { useEffect, useState } from 'react';
import { Affix, Avatar, Collapse, Divider, Space, Typography } from 'antd';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { DownSquareOutlined, UpSquareOutlined } from '@ant-design/icons';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';

const MessageStreamDisplayComponent = ({ chatBoxContainer }) => {
  const [chatBoxContent, setChatBoxContent] = useState([]);
  const [affixedKey, setAffixedKey] = useState(0);

  const selectedConversation = useSelector((state) => state.ongoingConversation);

  const getFileNameFromURL = (url) => {
    // Split the URL by '/'
    const urlParts = url.split('/');

    // Get the last part of the URL (likely the file name)
    const lastPart = urlParts[urlParts.length - 1];

    const staffIdAndTimeRemoved = lastPart.split('___');

    // If the last part contains a query string, remove it
    return staffIdAndTimeRemoved[staffIdAndTimeRemoved.length - 1].split('?')[0];
  };

  const renderText = (message) => {
    return (
      <>
        <div>{message.content}</div>
        {message.attachment && <div>{renderImage(message.attachment)}</div>}
      </>
    );
  };
  const renderImage = (message) => {
    return (
      <Space>
        <Collapse
          defaultActiveKey={['1']}
          expandIcon={(panelProps) => (panelProps.isActive ? <UpSquareOutlined /> : <DownSquareOutlined />)}
          size={'small'}
          ghost
          items={[
            {
              key: '1',
              label: <span>{getFileNameFromURL(message.content)}</span>,
              children: (
                <PhotoProvider maskOpacity={0.9}>
                  <div style={{ display: 'flex', columnGap: 8, rowGap: 8, flexWrap: 'wrap' }}>
                    <PhotoView key={1} src={message.content}>
                      <img
                        src={message.content}
                        alt=""
                        loading="lazy"
                        height={window.innerHeight * 0.2}
                        style={{ borderRadius: 8, cursor: 'pointer' }}
                      />
                    </PhotoView>
                  </div>
                </PhotoProvider>
              ),
            },
          ]}
        />
      </Space>
    );
  };

  useEffect(() => {
    const convertToConversationRender = (messages) => {
      const from = messages[0].from;
      const name = from === 'patient' ? selectedConversation.patient.name : messages[0].staff.name;
      const avatar = from === 'patient' ? selectedConversation.patient.avatar : messages[0].staff.avatar;
      const nameSuffix = from === 'patient' ? '' : '(Patient Advocate)';
      const avatarBg = from === 'patient' ? '#f56a00' : '#1677ff';

      let baseTime = messages[0].timestamp;
      let startIndex = 0;
      let currentIndex = 1;
      const groups = [];
      while (currentIndex <= messages.length - 1) {
        if (messages[currentIndex].timestamp > baseTime + 10 * 60 * 1000) {
          const group = {
            name,
            nameSuffix,
            avatar,
            avatarBg,
            time: baseTime,
            timeDisplay: moment(baseTime).format('hh:mmA'),
            type: 'conversation',
            messages: messages.slice(startIndex, currentIndex),
          };
          groups.push(group);
          startIndex = currentIndex;
          baseTime = messages[currentIndex].timestamp;
        }
        currentIndex += 1;
      }
      if (startIndex <= currentIndex) {
        const group = {
          name,
          nameSuffix,
          avatar,
          avatarBg,
          time: baseTime,
          timeDisplay: moment(baseTime).format('hh:mmA'),
          type: 'conversation',
          messages: messages.slice(startIndex, currentIndex + 1),
        };
        groups.push(group);
      }
      return groups;
    };

    const insertDateStrings = (sourceGroupedSortedMessage) => {
      const resultArray = [];

      let dateEntryIndex = 0;
      for (let i = 0; i < sourceGroupedSortedMessage.length; i++) {
        const currentTimestamp = sourceGroupedSortedMessage[i].time;
        const currentDate = moment(currentTimestamp).format('MMM DD, yyyy');

        // Insert date string at the first position of each new date
        if (i === 0 || moment(sourceGroupedSortedMessage[i - 1].time).format('MMM DD, yyyy') !== currentDate) {
          resultArray.push({
            type: 'date',
            date: currentDate,
            entryIndex: dateEntryIndex,
          });
          dateEntryIndex++;
        }

        // Insert the sorted element
        resultArray.push(sourceGroupedSortedMessage[i]);
      }

      return resultArray;
    };

    if (selectedConversation && !!selectedConversation.messages.length) {
      const allMessages = [...selectedConversation.messages];

      const messagesDependsOnOthers = allMessages.filter((m) => m.associatedTo);

      messagesDependsOnOthers.forEach((dependentMessage) => {
        const associatedMessageIndex = allMessages.findIndex((m) => !!m && m._id === dependentMessage.associatedTo);
        const dependentMessageIndex = allMessages.findIndex((m) => !!m && m._id === dependentMessage._id);
        if (associatedMessageIndex > -1) {
          //attach dependency message to its dependent message
          const newAssociatedMessage = {
            ...allMessages[associatedMessageIndex],
            attachment: { ...dependentMessage },
          };
          allMessages.push(newAssociatedMessage);
          allMessages[associatedMessageIndex] = null;
          allMessages[dependentMessageIndex] = null;
        }
      });

      const attachmentProcessedMessages = allMessages.filter((m) => !!m);

      const sourceGroupedMessagesInTimeSequence = [];
      const sortedMessages = [...attachmentProcessedMessages].sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      );

      let startIndex = 0;
      let currentIndex = 0;
      while (currentIndex < sortedMessages.length - 1) {
        if (
          sortedMessages[currentIndex].from !== sortedMessages[currentIndex + 1].from ||
          (sortedMessages[currentIndex].from === 'staff' &&
            sortedMessages[currentIndex].staff.staffId !== sortedMessages[currentIndex + 1].staff.staffId)
        ) {
          sourceGroupedMessagesInTimeSequence.push(
            ...convertToConversationRender(sortedMessages.slice(startIndex, currentIndex + 1))
          );
          startIndex = currentIndex + 1;
        }
        currentIndex += 1;
      }
      if (startIndex <= currentIndex) {
        sourceGroupedMessagesInTimeSequence.push(
          ...convertToConversationRender(sortedMessages.slice(startIndex, currentIndex + 1))
        );
      }

      const dateAddedRenderings = insertDateStrings(sourceGroupedMessagesInTimeSequence);

      setChatBoxContent(dateAddedRenderings);
    } else {
      setChatBoxContent([]);
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (chatBoxContent) {
      setTimeout(() => {
        document.querySelector('.chatBox').scrollIntoView({ behavior: 'instant', block: 'end' });
      }, 150);
    }
  }, [chatBoxContent]);

  useEffect(() => {
    let scrollBarAtBottom;
    const handleScroll = () => {
      const element = chatBoxContainer;

      // Check if the scrollbar is at the bottom
      scrollBarAtBottom = element.scrollTop + element.clientHeight + 16 >= element.scrollHeight;
    };

    const observer = new ResizeObserver((entries) => {
      if (entries[0] && scrollBarAtBottom) {
        document.querySelector('.chatBox').scrollIntoView({ behavior: 'auto', block: 'end' });
      }
    });

    // Attach the event listener when the component mounts
    const scrollableElement = chatBoxContainer;
    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', handleScroll);
      observer.observe(scrollableElement);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleScroll);
        observer.unobserve(scrollableElement);
      }
    };
  }, []);

  return (
    <div
      className={'chatBox'}
      style={{
        padding: '0 8px',
      }}
    >
      {chatBoxContent.map((content, i) => {
        if (content.type === 'conversation') {
          return (
            <Space key={`c-${i}`} style={{ display: 'flex', alignItems: 'start', marginBlock: 4, columnGap: 10 }}>
              {content.avatar ? (
                <Avatar shape={'square'} style={{ marginTop: 8 }} src={<img src={content.avatar} alt="avatar" />} />
              ) : (
                <Avatar shape={'square'} style={{ marginTop: 8, backgroundColor: content.avatarBg }}>
                  {content.name
                    .split(' ')
                    .map((n) => n.slice(0, 1))
                    .join('')
                    .toUpperCase()}
                </Avatar>
              )}

              <div style={{ width: '100%' }}>
                {content.messages.map((message, mi) => {
                  return (
                    <div
                      key={`c-${i}-${mi}`}
                      className={mi !== 0 ? 'message-display-entry with-time' : 'message-display-entry'}
                      data-entry-time={moment(message.timestamp).format('hh:mmA')}
                    >
                      {mi === 0 && (
                        <div>
                          <Typography.Text strong style={{ marginRight: 4, fontSize: 16 }}>
                            {content.name}
                            <Typography.Text style={{ marginLeft: 2 }} type={'secondary'}>
                              {content.nameSuffix}
                            </Typography.Text>
                          </Typography.Text>
                          <Typography.Text style={{ fontSize: 12 }} type={'secondary'}>
                            {content.timeDisplay}
                          </Typography.Text>
                        </div>
                      )}
                      {message.type === 'text' && renderText(message)}
                      {message.type === 'image_url' && renderImage(message)}
                    </div>
                  );
                })}
              </div>
            </Space>
          );
        } else if (content.type === 'date') {
          return (
            <Affix
              key={`d-${i}`}
              target={() => chatBoxContainer}
              onChange={(affixed) => {
                if (affixed) {
                  setAffixedKey(content.entryIndex);
                } else {
                  setAffixedKey(content.entryIndex - 1);
                }
              }}
              offsetTop={-12}
            >
              <Divider
                style={{
                  opacity: affixedKey <= content.entryIndex ? '100' : '0',
                  position: 'relative',
                  margin: 0,
                }}
              >
                <Typography.Text type={'secondary'}>{content.date}</Typography.Text>
              </Divider>
            </Affix>
          );
        }
      })}
    </div>
  );
};

export default MessageStreamDisplayComponent;
