import bioApi from "../../api/bioApi";
import {
    ORDERS_OPTIONS_URL,
    ORDERS_PHARMACIES_URL,
    SEARCH_ORDERS_URL,
    UPDATE_TRACKING_NUMBER_URL
} from "../../api/URLs";

export const getShippingOptions = (searchType = "orderID",searchContent = "") => {
    const url = ORDERS_OPTIONS_URL;
    return bioApi.post(url,{
        'type' : searchType,
        'content' : searchContent,
        'tracking' : false
    });
}

export const searchOrders = (searchType = "orderID",searchContent = "",sortType = "orderID",rangeDate = null, pharmacie="CRE8") => {
    const url = SEARCH_ORDERS_URL;
    return bioApi.post(url,{
        'type' : searchType,
        'pharmacie' : pharmacie,
        'content' : searchContent,
        'sortType' : sortType,
        'rangeDate' : rangeDate,
        'tracking' : false
    });
}

export const searchOrdersPharmacies = (searchType = "orderID",searchContent = "",sortType = "orderID",rangeDate = null, pharmacie="CRE8", watermark=null) => {
    const url = ORDERS_PHARMACIES_URL;
    return bioApi.post(url,{
        'searchType' : searchType,
        'pharmacie' : pharmacie,
        'searchContent' : searchContent,
        'sortType' : sortType,
        'rangeDate' : rangeDate,
        'watermark' : watermark
    });
}

export const searchTracking = (searchType = "orderID",searchContent = "",sortType = "orderID",rangeDate = null) => {
    const url = SEARCH_ORDERS_URL;
    return bioApi.post(url,{
        'type' : searchType,
        'content' : searchContent,
        'sortType' : sortType,
        'rangeDate' : rangeDate,
        'tracking' : true
    });
}

export const updateTrackingNumber = (data = null) => {
    const url = UPDATE_TRACKING_NUMBER_URL;
    return bioApi.post(url,data);
}

export const getAllDataPost = async (url, data) => {
    let result = await bioApi.post(url, data);
    return result.data
}

export const AllDataPost = async (url, params) => {
    let result = await bioApi.post(url, params);
    return result.data
}

export const getAllDataGet = async (url, params) => {
    let result = await bioApi.get(url, params);
    return result.data
}