import {
  calendarEssentialDataInitializer,
  deleteBlockedTime,
  generateTitle,
  getAppointment,
  getEventClass,
} from './service';
import moment from 'moment';
import 'moment-timezone';
import { BsCalendar } from 'react-icons/bs';
import { Avatar, Button, Dropdown, message, Popconfirm, Select } from 'antd';
import AppointmentCalendarComponent from './appointment-calendar.component';
import NewAppointmentModal from './new-appointment.modal';
import { useEffect, useState } from 'react';
import { AppstoreAddOutlined, DeleteOutlined } from '@ant-design/icons';
import AddBlockedTimeModal from './add-blocked-time.modal';
import { useSearchParams } from 'react-router-dom';
import { AccessRight } from '../../components/guarded-route/guarded-routes';
import { ApplicationRight } from '../../share/RightList';

const AppointmentCalendarByProviderPage = () => {
  const [searchParam] = useSearchParams();

  const [locationOptions, setLocationOptions] = useState([]);
  const [events, setEvents] = useState(undefined);
  const [providingServices, setProvidingServices] = useState([]);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [viewInDate, setViewInDate] = useState('');
  const [showNewAppointmentModal, setShowNewAppointmentModal] = useState(false);
  const [showAddBlockedTimeModal, setShowAddBlockedTimeModal] = useState(false);
  const item = [
    {
      key: '1',
      isHas: AccessRight(ApplicationRight.Staff_Schedule_Access),
      label: <a href={`/staff/schedule?staffId=${selectedProvider}`}>Add Shift</a>,
    },
    {
      key: '2',
      isHas: AccessRight(ApplicationRight.Staff_Blocked_Time_Management),
      label: <a onClick={() => setShowAddBlockedTimeModal(true)}>New Blocked Time</a>,
    },
    {
      key: '3',
      isHas: AccessRight(ApplicationRight.Appointment_Creation),
      label: <a onClick={() => setShowNewAppointmentModal(true)}>New Appointment</a>,
    },
  ];
  const items = item.filter((it) => it.isHas);

  const checkIfStaffIsSameAfterReschedule = (oldAppointment, newAppointment) =>
    oldAppointment.staff.staffId === newAppointment.staff.staffId;

  const init = () => {
    if (selectedProvider) {
      setEvents(null);
      (async () => {
        const appointmentsOfTheProvider = await getAppointment([{ provider: selectedProvider }]);
        const allData = [];

        if (appointmentsOfTheProvider.data?.data.appointments) {
          appointmentsOfTheProvider.data.data.appointments.forEach((appointment) => {
            allData.push({
              end: moment(appointment.end).toDate(),
              start: moment(appointment.start).toDate(),
              title: generateTitle(
                appointment.service,
                appointment.patient,
                { location: appointment.location },
                ({ location }) => `[${location.name}]`
              ),
              className: getEventClass(appointment),
              type: 'appointment',
              appointment,
            });
          });
        }
        if (appointmentsOfTheProvider.data?.data.blockedTimes) {
          appointmentsOfTheProvider.data.data.blockedTimes.forEach((bt) => {
            allData.push({
              end: moment(bt.end).toDate(),
              start: moment(bt.start).toDate(),
              title: (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>Blocked Time For:</div>
                  <div style={{ fontSize: 16 }}>{bt.description}</div>
                  <div style={{ position: 'absolute', top: 4, right: -12 }}>
                    {AccessRight(ApplicationRight.Staff_Blocked_Time_Management) && (
                      <Popconfirm
                        title={'Are you sure the delete this blocked time?'}
                        onConfirm={async () => {
                          await deleteBlockedTime(selectedProvider, bt._id);
                          message.success('Blocked time deleted successfully');
                          init();
                        }}
                      >
                        <Button icon={<DeleteOutlined />} shape={'circle'} danger size={'small'} />
                      </Popconfirm>
                    )}
                  </div>
                </div>
              ),
              type: 'blocked-time',
              className: 'blocked-event',
            });
          });
        }
        setEvents(allData);
      })();
    }
  };

  useEffect(() => {
    init();
  }, [selectedProvider]);

  useEffect(() => {
    const staffId = searchParam.get('staffId');
    const date = searchParam.get('date');
    if (date) {
      setViewInDate(date);
    }
    if (providers && providers.length && staffId) {
      const preSelectedProvider = providers.find((p) => p.staffId === staffId);
      if (preSelectedProvider) {
        setSelectedProvider(preSelectedProvider.staffId);
      }
    }
  }, [providers]);

  useEffect(() => {
    (async () => calendarEssentialDataInitializer(setLocationOptions, setProviders, setProvidingServices))();
  }, []);

  return (
    <div className="appointment-management">
      <div className="d-flex justify-content-between m-3">
        <div className="d-flex align-items-center">
          <span
            style={{
              padding: 6,
              color: 'white',
              background: '#5284C2',
              borderRadius: 4,
            }}
          >
            <BsCalendar style={{ fontSize: 18, position: 'relative', top: -2 }} />
          </span>
          <span
            style={{
              fontSize: 18,
              marginLeft: 8,
              marginRight: 16,
              fontWeight: 700,
            }}
          >
            Appointment By Provider
          </span>
          <Select
            placeholder="Select Provider"
            size="large"
            style={{ minWidth: 255 }}
            defaultActiveFirstOption={false}
            filterOption={false}
            value={selectedProvider}
            onChange={setSelectedProvider}
          >
            {(providers || []).map((s, i) => (
              <Select.Option key={i} value={s.staffId} label={`${s.firstName} ${s.lastName}`}>
                <div className="d-flex align-items-center">
                  <Avatar size="30" src={s.coverImage} style={{ marginRight: 10 }} />
                  <div>{`${s.firstName} ${s.lastName}`}</div>
                </div>
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          {selectedProvider && (
            <Dropdown menu={{ items }} arrow className="dropdown">
              <Button type="primary" size="large" shape="round" icon={<AppstoreAddOutlined />}>
                New
              </Button>
            </Dropdown>
          )}
        </div>
      </div>
      {events && (
        <AppointmentCalendarComponent
          appointmentEvents={events}
          providers={providers}
          eventTitleDifferentiator={({ staff, location }) =>
            `(${staff.firstName} ${staff.lastName} @ ${location.name})`
          }
          locationOptions={locationOptions}
          providingServices={providingServices}
          checkAfterReschedule={checkIfStaffIsSameAfterReschedule}
          date={viewInDate}
          staffId={selectedProvider}
        />
      )}
      {showNewAppointmentModal && (
        <NewAppointmentModal
          show={showNewAppointmentModal}
          locations={locationOptions}
          defaultLocation={undefined}
          services={providingServices}
          onFinish={() => setShowNewAppointmentModal(false)}
        />
      )}
      {showAddBlockedTimeModal && (
        <AddBlockedTimeModal
          show={showAddBlockedTimeModal}
          staff={providers.find((p) => p.staffId === selectedProvider)}
          onFinish={(result) => {
            setShowAddBlockedTimeModal(false);
            if (result) {
              init();
            }
          }}
        />
      )}
    </div>
  );
};

export default AppointmentCalendarByProviderPage;
