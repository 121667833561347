export const TimezoneList = [
  {
    zoneName: 'Eastern Time Zone (ET)',
    timeZones: [
      'America/New_York',
      'America/Detroit',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/Indiana/Indianapolis',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Vevay',
    ],
  },
  {
    zoneName: 'Central Time Zone (CT)',
    timeZones: [
      'America/Chicago',
      'America/Indiana/Tell_City',
      'America/Indiana/Knox',
      'America/Menominee',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/North_Dakota/Beulah',
    ],
  },
  {
    zoneName: 'Mountain Time Zone (MT)',
    timeZones: ['America/Denver', 'America/Boise', 'America/Phoenix'],
  },
  {
    zoneName: 'Pacific Time Zone (PT)',
    timeZones: ['America/Los_Angeles'],
  },
  {
    zoneName: 'Alaska Time Zone (AKT)',
    timeZones: [
      'America/Anchorage',
      'America/Juneau',
      'America/Sitka',
      'America/Metlakatla',
      'America/Nome',
      'America/Yakutat',
    ],
  },
  {
    zoneName: 'Hawaii-Aleutian Time Zone (HAT)',
    timeZones: ['Pacific/Honolulu', 'America/Adak'],
  },
  {
    zoneName: 'Atlantic Time Zone (AT)',
    timeZones: ['America/Puerto_Rico', 'America/Virgin'],
  },
  {
    zoneName: 'Chamorro Time Zone (ChT)',
    timeZones: ['Pacific/Guam', 'Pacific/Saipan'],
  },
  {
    zoneName: 'Samoa Time Zone (SST)',
    timeZones: ['Pacific/Pago_Pago'],
  },
];

export const isInSameTimezone = (timezone1, timezone2) => {
  if (!timezone1 || !timezone2) return true;
  return !!TimezoneList.find((zone) => zone.timeZones.includes(timezone1) && zone.timeZones.includes(timezone2));
};
