import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, message, Modal, Typography } from 'antd';
import DataTable from '../../../components/data-table/data-table.component';
import './patient.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAppointments } from '../service';
import moment from 'moment';
import AppointmentDetail from '../../appointments/appointment-detail.component';
import { FcCheckmark } from 'react-icons/fc';
import { ConnectWithoutContact } from '@mui/icons-material';
import ModifyAppointmentModal from '../../appointments/modify-appointment.modal';
import { calendarEssentialDataInitializer, confirmAppointment, generateTitle } from '../../appointments/service';

function AppointmentPage() {
  const currentPatient = useSelector((state) => state.currentPatient);

  const [appointmentList, setAppointmentList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [appointmentModal, setAppointmentModal] = useState({ show: false, appointment: {} });
  const [modifyingAppointment, setModifyingAppointment] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [providingServices, setProvidingServices] = useState([]);
  const [providers, setProviders] = useState([]);

  const appointmentToNow = (start, end) => {
    const now = moment();
    if (now.isBetween(start, end)) {
      return 'OnGoing';
    }
    if (now.isAfter(end)) {
      return 'Passed';
    }
    if (now.isBefore(start)) {
      return 'InComing';
    }
  };

  const tableSettings = {
    appendable: false,
    removable: false,
    bordered: false,
    size: 'middle',
    pagination: {
      showLessItems: true,
      pageSize: 6,
    },
    columns: [
      {
        title: 'Date | Time',
        dataIndex: 'start',
        render: (text, record) => moment(text).tz(record.location.timezone).format('MM/DD/YYYY | hh:mma (z)'),
      },
      {
        title: 'Service [Type]',
        dataIndex: ['service'],
        render: (service) => service.serviceName + ' [' + service.type + ']',
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'Location',
        dataIndex: ['location', 'name'],
      },
      {
        title: 'Staff',
        render: (text, record) => `${record.staff.firstName} ${record.staff.lastName}`,
      },
      {
        title: 'Patient Confirmation',
        render: (_, record) => {
          return (
            <>
              {record.apptConfirm && (
                <div>
                  <FcCheckmark color={'green'} style={{ fontSize: 'small' }} /> Confirmed
                </div>
              )}
              {record.rescheduleRequest && (
                <div>
                  <ConnectWithoutContact color={'warning'} style={{ fontSize: 'small' }} /> Request Reschedule
                </div>
              )}
            </>
          );
        },
      },
    ],
    customActions: [
      {
        text: 'View',
        shallNotRender: (record) => !['Completed', 'Canceled'].includes(record.status),
        whenPerform: (record) => {
          const appointment = {
            start: record.start,
            end: record.end,
            service: record.service,
            patient: {
              patientId: currentPatient.patientId,
              firstName: currentPatient.profile.firstName,
              lastName: currentPatient.profile.lastName,
            },
            location: record.location,
            staff: record.staff,
            note: record.note,
            status: record.status,
            changeMadeBy: record.changeMadeBy,
          };
          setAppointmentModal({
            show: true,
            appointment,
          });
        },
      },
      {
        text: 'Make Changes',
        shallNotRender: (record) => !['Booked', 'Rescheduled', 'PatientAbsents'].includes(record.status),
        whenPerform: (record) => {
          setModifyingAppointment({
            start: record.start,
            end: record.end,
            service: record.service,
            patient: { ...currentPatient.profile, patientId: currentPatient.patientId },
            location: record.location,
            staff: record.staff,
            note: record.note,
            status: record.status,
            appointmentId: record.appointmentId,
            changeMadeBy: record.changeMadeBy,
            title: generateTitle(
              record.service,
              currentPatient.profile,
              { staff: record.staff, location: record.location },
              ({ staff, location }) => `(${staff.firstName} ${staff.lastName} @ ${location.name})`
            ),
          });
        },
      },
      {
        text: 'Confirm',
        shallNotRender: (record) =>
          record.apptConfirm ||
          moment().isAfter(record.start) ||
          ['Rescheduled', 'PatientAbsents', 'Canceled', 'Completed'].includes(record.status),
        needConfirm: {
          text: () => `Are you sure you want to confirm this appointment for patient?`,
        },
        whenPerform: async (record) => {
          try {
            await confirmAppointment(record.appointmentId);
            message.success('Appointment confirmed');
            await getAppointmentOfPatient();
          } catch (e) {
            message.error('Failed to confirm appointment');
          }
        },
      },
    ],
  };

  const getAppointmentOfPatient = async () => {
    setLoading(true);
    const appointmentData = await getAppointments(currentPatient);
    setAppointmentList(appointmentData.data.sort((a, b) => Date.parse(b.start) - Date.parse(a.start)));
    setLoading(false);
  };

  React.useEffect(() => {
    (async () => {
      await calendarEssentialDataInitializer(setLocationOptions, setProviders, setProvidingServices);
      await getAppointmentOfPatient();
    })();
  }, []);

  return (
    <>
      <Row className="profile">
        <Col sm={6}>
          <h2 className="section-title">Appointment</h2>
        </Col>
        <Col sm={6} className="create-row">
          <Link to={`/patient/${currentPatient.patientId}/new-appointment`}>
            <Button type="primary">New Appointment</Button>
          </Link>
        </Col>
        <Col sm={12}>
          <Row className="section-content">
            <DataTable loading={loading} data={appointmentList} settings={tableSettings} />
          </Row>
        </Col>
      </Row>
      <Modal
        open={appointmentModal.show}
        onCancel={() => setAppointmentModal({ show: false, appointment: {} })}
        footer={null}
      >
        <div>
          <AppointmentDetail appointment={appointmentModal.appointment} />
          {appointmentModal.appointment.status === 'Booked' && (
            <div className={'text-end'}>
              <Typography.Text type={'secondary'} style={{ fontSize: 'smaller' }}>
                This appointment is booked by {appointmentModal.appointment.changeMadeBy}
                {!!appointmentModal.appointment.statusChangeTS && (
                  <div>@ {moment(appointmentModal.appointment.statusChangeTS).format('MM/DD/YYYY | hh:mma (z)')}</div>
                )}{' '}
              </Typography.Text>
            </div>
          )}
          {appointmentModal.appointment.status === 'Rescheduled' && (
            <div>
              <hr />
              <div className="text-center">
                This appointment has been <Typography.Text type="warning">rescheduled</Typography.Text>{' '}
                {!!appointmentModal.appointment.changeMadeBy && <>by {appointmentModal.appointment.changeMadeBy}</>}
                {!!appointmentModal.appointment.statusChangeTS && (
                  <div>@ {moment(appointmentModal.appointment.statusChangeTS).format('MM/DD/YYYY | hh:mma (z)')}</div>
                )}
              </div>
            </div>
          )}
          {appointmentModal.appointment.status === 'Canceled' && (
            <div>
              <hr />
              <div className="text-center">
                This appointment has been <Typography.Text type="danger">canceled</Typography.Text>{' '}
                {!!appointmentModal.appointment.changeMadeBy && <>by {appointmentModal.appointment.changeMadeBy}</>}
                {!!appointmentModal.appointment.statusChangeTS && (
                  <div>@ {moment(appointmentModal.appointment.statusChangeTS).format('MM/DD/YYYY | hh:mma (z)')}</div>
                )}{' '}
              </div>
            </div>
          )}
          {appointmentModal.appointment.status === 'Completed' && (
            <div>
              <hr />
              <div className="text-center">
                This appointment has been <Typography.Text type="success">completed</Typography.Text>
              </div>
              {!!appointmentModal.appointment.changeMadeBy && (
                <div className="text-end mt-2">
                  <Typography.Text type={'secondary'} style={{ fontSize: 'smaller' }}>
                    {appointmentModal.appointment.changeMadeBy} marked this appointment as completed
                    {!!appointmentModal.appointment.statusChangeTS && (
                      <div>
                        @ {moment(appointmentModal.appointment.statusChangeTS).format('MM/DD/YYYY | hh:mma (z)')}
                      </div>
                    )}
                  </Typography.Text>
                </div>
              )}
            </div>
          )}
          {appointmentModal.appointment.status === 'PatientAbsents' && (
            <div>
              <hr />
              <div className="text-center">Patient didn't show up for this appointment</div>
              {!!appointmentModal.appointment.changeMadeBy && (
                <div className="text-end mt-2">
                  <Typography.Text type={'secondary'} style={{ fontSize: 'smaller' }}>
                    {appointmentModal.appointment.changeMadeBy} marked this appointment as no-show
                    {!!appointmentModal.appointment.statusChangeTS && (
                      <div>
                        @ {moment(appointmentModal.appointment.statusChangeTS).format('MM/DD/YYYY | hh:mma (z)')}
                      </div>
                    )}
                  </Typography.Text>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
      {!!modifyingAppointment && (
        <ModifyAppointmentModal
          show={!!modifyingAppointment}
          services={providingServices}
          providers={providers}
          locations={locationOptions}
          event={modifyingAppointment}
          eventTitleDifferentiator={({ staff, location }) =>
            `(${staff.firstName} ${staff.lastName} @ ${location.name})`
          }
          onFinish={async (result) => {
            if (result) {
              await getAppointmentOfPatient();
            }
            setModifyingAppointment(null);
          }}
          showCancel={appointmentToNow(modifyingAppointment.start, modifyingAppointment.end) === 'InComing'}
          showNoShow={['OnGoing', 'Passed'].includes(
            appointmentToNow(modifyingAppointment.start, modifyingAppointment.end)
          )}
          showComplete={['OnGoing', 'Passed'].includes(
            appointmentToNow(modifyingAppointment.start, modifyingAppointment.end)
          )}
        />
      )}
    </>
  );
}

export default AppointmentPage;
