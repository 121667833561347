import { createSlice } from '@reduxjs/toolkit';

const ongoingConversationSlice = createSlice({
  name: 'ongoingConversation',
  initialState: {
    patient: null,
    messages: [],
  },
  reducers: {
    setOngoingConversation: (state, action) => {
      state.patient = action.payload.patient;
      state.messages = action.payload.messages;
    },
    appendToOngoingConversationIfMatches: (state, action) => {
      if (state.patient && state.patient.patientId === action.payload.patient.patientId) {
        if (!state.messages.find((m) => m._id === action.payload.message._id)) {
          return {
            ...state,
            messages: [...state.messages, action.payload.message],
          };
        }
      }
    },
  },
});

export const { setOngoingConversation, appendToOngoingConversationIfMatches } = ongoingConversationSlice.actions;
export default ongoingConversationSlice.reducer;
