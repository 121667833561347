import { Avatar, Input } from 'antd';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataTable from '../../components/data-table/data-table.component';
import { formatPhoneNumber } from '../../methods/data-formatter';
import { listAllLocations } from '../patient/patient-search.service';
import { getAppointmentAvailableServices } from '../patient/service';
import StaffDetailsModal from './staff-details.modal';
import './staff-search.css';
import { listAllStaff } from './staff-search.service';

function StaffSearchPage() {
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [showStaff, setShowStaff] = useState({ show: false, staffId: '' });
  const [allLocations, setAllLocations] = useState([]);
  const [allServices, setAllServices] = useState([]);

  useEffect(() => {
    initLoadStaff();
    fetchAllLocations();
    fetchAllServices();
  }, []);

  useEffect(() => {
    filterTableResult('');
  }, [searchResult]);

  const initLoadStaff = () => {
    setLoading(true);
    listAllStaff().then((response) => {
      if (response.data.status === 200) {
        formatStaffData(response.data?.data);
      } else {
        alert(response.data.message);
      }
      setLoading(false);
    });
  };

  const fetchAllLocations = () => {
    listAllLocations().then((response) => {
      if (response.data.status !== 200) {
        alert(response.data.message);
      } else {
        setAllLocations(response.data.data);
      }
    });
  };

  const fetchAllServices = () => {
    getAppointmentAvailableServices().then((response) => {
      if (response.data.status !== 200) {
        alert(response.data.message);
      } else {
        setAllServices(response.data.data);
      }
    });
  };

  const displayStaff = (staffId) => {
    setShowStaff({ show: true, staffId });
  };

  const tableSettings = {
    appendable: false,
    removable: false,
    bordered: false,
    numbered: true,
    size: 'middle',
    pagination: {
      showLessItems: true,
      showSizeChanger: false,
      pageSize: 20,
    },
    columns: [
      {
        title: 'Email Address',
        dataIndex: 'email',
      },
      {
        title: 'Phone Number',
        dataIndex: ['phoneNumber'],
      },
      {
        title: 'Role',
        dataIndex: 'role',
      },
      {
        title: 'Staff ID',
        dataIndex: 'staffId',
      },
      {
        title: 'Salesforce ID',
        dataIndex: 'sfId',
      },
    ],
    customActions: [
      {
        element: (record, data) => (
          <div>
            <Avatar style={{ margin: '0 0.5em' }} size={50} src={record.coverImage} />
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                displayStaff(record.staffId);
              }}
            >
              {record.name}
            </Link>
          </div>
        ),
      },
    ],
    operationColumnTitle: 'Name',
    actionColumnIndex: 1,
  };

  const formatStaffData = (staffData) => {
    let staffDataCopy = JSON.parse(JSON.stringify(staffData));
    staffDataCopy.forEach((staff) => {
      staff.name = staff.firstName + ' ' + staff.lastName;
      if (staff.phone) {
        staff.phoneNumber = formatPhoneNumber(staff.phone.number);
      }
    });
    setSearchResult(
      staffData.map((staff) => ({
        name: staff.firstName + ' ' + staff.lastName,
        phoneNumber: staff.phone ? formatPhoneNumber(staff.phone.number) : '',
        email: staff.email || '',
        staffId: staff.staffId || '',
        sfId: staff.sfId || '',
        role: staff.role || '',
        coverImage: staff.coverImage || '',
      }))
    );
  };

  const filterTableResult = (value) => {
    const regex = new RegExp(value, 'i');
    const newStaffList = searchResult.filter((staff) => {
      return (
        regex.test(staff.phoneNumber) ||
        regex.test(staff.name) ||
        regex.test(staff.staffId) ||
        regex.test(staff.sfId) ||
        regex.test(staff.email) ||
        regex.test(staff.role)
      );
    });
    setStaffList(newStaffList);
  };

  return (
    <>
      <StaffDetailsModal
        show={showStaff.show}
        staffId={showStaff.staffId}
        handleClose={() => setShowStaff({ show: false, staffId: '' })}
        allLocations={allLocations}
        allServices={allServices}
      />
      <div style={{ background: '#F0F2F5 0% 0% no-repeat padding-box', padding: 32 }}>
        <div className="d-flex justify-content-between">
          <h2>Staff Management</h2>
          <Link to="/staff/create">
            <Button>Create New Staff</Button>
          </Link>
        </div>
        <br />
        <div className="d-flex justify-content-between">
          <Input
            placeholder="Search by Staff's Name, Phone Number, Email, Staff ID, Salesforce ID and Role"
            size="large"
            allowClear
            style={{ width: '50%' }}
            onChange={(e) => filterTableResult(e.target.value)}
          />
        </div>
        <Row className="section">
          <Col sm={12}>
            <div className="section-content">
              <DataTable loading={loading} data={staffList} settings={tableSettings} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default StaffSearchPage;
