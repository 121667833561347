import React, { useEffect, useState } from 'react';
import DataTable from '../../../../components/data-table/data-table.component';
import { Button, Select, Space, Typography } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const BioIDGenerateSettingComponent = ({ onCancel, onOk }) => {
  const currentPatient = useSelector((state) => state.currentPatient);
  const currentLabResult = useSelector((state) => state.currentLabResult);

  const [tableSetting, setTableSetting] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [initialSelectionList, setInitialSelectionList] = useState([]);
  const [yourResultList, setYourResultList] = useState([]);
  const [selection1List, setSelection1List] = useState([]);
  const [selection2List, setSelection2List] = useState([]);
  const [selectedYourResult, setSelectedYourResult] = useState(null);
  const [selectedMonitor1, setSelectedMonitor1] = useState(null);
  const [selectedMonitor2, setSelectedMonitor2] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    const first3Biomarkers = [currentLabResult[0], currentLabResult[1], currentLabResult[2]];
    const data = first3Biomarkers.map((b) => ({
      biomarker: b.biomarker,
      current: {},
      monitor1: {},
      monitor2: {},
      optimalRange: b.optimalRange,
    }));
    setTableData(data);
    const initialSelectionList = currentPatient.allLabResults
      .map((plr) => ({
        value: plr._id,
        label: plr.collectionDate,
      }))
      .sort((a, b) => new Date(b.label || '01/01/1970') - new Date(a.label || '01/01/1970'));
    setInitialSelectionList(initialSelectionList);
    setYourResultList([...initialSelectionList]);
    setSelection1List([...initialSelectionList]);
    setSelection2List([...initialSelectionList]);
  }, []);

  useEffect(() => {
    setTableSetting({
      pagination: false,
      bordered: true,
      columns: [
        {
          title: 'Measured Biomarker',
          className: 'bioid-grey-cell',
          dataIndex: 'biomarker',
        },
        {
          title: (
            <div>
              Your Result
              <Select
                style={{
                  width: '100%',
                }}
                placeholder={'Collected at...'}
                size={'small'}
                allowClear
                options={yourResultList}
                onChange={(value) => setSelectedYourResult(value || '')}
              ></Select>
            </div>
          ),
          className: 'bioid-green-cell',
          dataIndex: ['current', 'value'],
          render: (text, record) => <span style={{ color: record.current.isAbnormal ? 'red' : 'black' }}>{text}</span>,
        },
        {
          title: (
            <div>
              Monitoring
              <Select
                style={{
                  width: '100%',
                }}
                placeholder={'Collected at...'}
                size={'small'}
                allowClear
                options={selection1List}
                onChange={(value) => setSelectedMonitor1(value || '')}
              ></Select>
            </div>
          ),
          className: 'bioid-purple-cell',
          dataIndex: ['monitor1', 'value'],
          render: (text, record) => <span style={{ color: record.monitor1.isAbnormal ? 'red' : 'black' }}>{text}</span>,
        },
        {
          title: (
            <div>
              Monitoring
              <Select
                style={{
                  width: '100%',
                }}
                placeholder={'Collected at...'}
                allowClear
                size={'small'}
                options={selection2List}
                onChange={(value) => setSelectedMonitor2(value || '')}
              ></Select>
            </div>
          ),
          className: 'bioid-purple-cell',
          dataIndex: ['monitor2', 'value'],
          render: (text, record) => <span style={{ color: record.monitor2.isAbnormal ? 'red' : 'black' }}>{text}</span>,
        },
        {
          title: 'the biostation Optimal Range',
          width: '20%',
          className: 'bioid-blue-cell',
          dataIndex: 'optimalRange',
        },
      ],
    });
  }, [selection1List, selection2List, yourResultList]);

  useEffect(() => {
    if (selectedMonitor1 !== null) {
      setSelection2List(
        initialSelectionList.filter((el) => ![selectedMonitor1, selectedYourResult].includes(el.value))
      );
      setYourResultList(initialSelectionList.filter((el) => ![selectedMonitor1, selectedMonitor2].includes(el.value)));

      if (selectedMonitor1) {
        const selectedLabResult = currentPatient.allLabResults.find((r) => r._id === selectedMonitor1);
        tableData.forEach((td) => {
          const theRecord = selectedLabResult.data.find((slr) => slr.biomarker === td.biomarker) || {
            labData: '',
            isAbnormal: false,
          };

          td.monitor1 = {
            value: theRecord.labData,
            isAbnormal: theRecord.isAbnormal,
          };
        });
      } else {
        tableData.forEach((td) => (td.monitor1 = {}));
      }
      setTableData([...tableData]);
    }
  }, [selectedMonitor1]);
  useEffect(() => {
    if (selectedMonitor2 !== null) {
      setSelection1List(
        initialSelectionList.filter((el) => ![selectedMonitor2, selectedYourResult].includes(el.value))
      );
      setYourResultList(initialSelectionList.filter((el) => ![selectedMonitor1, selectedMonitor2].includes(el.value)));

      if (selectedMonitor2) {
        const selectedLabResult = currentPatient.allLabResults.find((r) => r._id === selectedMonitor2);
        tableData.forEach((td) => {
          const theRecord = selectedLabResult.data.find((slr) => slr.biomarker === td.biomarker) || {
            labData: '',
            isAbnormal: false,
          };

          td.monitor2 = {
            value: theRecord.labData,
            isAbnormal: theRecord.isAbnormal,
          };
        });
      } else {
        tableData.forEach((td) => (td.monitor2 = {}));
      }
      setTableData([...tableData]);
    }
  }, [selectedMonitor2]);
  useEffect(() => {
    if (selectedYourResult !== null) {
      setSelection1List(
        initialSelectionList.filter((el) => ![selectedMonitor2, selectedYourResult].includes(el.value))
      );
      setSelection2List(
        initialSelectionList.filter((el) => ![selectedMonitor1, selectedYourResult].includes(el.value))
      );

      if (selectedYourResult) {
        const selectedLabResult = currentPatient.allLabResults.find((r) => r._id === selectedYourResult);
        tableData.forEach((td) => {
          const theRecord = selectedLabResult.data.find((slr) => slr.biomarker === td.biomarker) || {
            labData: '',
            isAbnormal: false,
          };

          td.current = {
            value: theRecord.labData,
            isAbnormal: theRecord.isAbnormal,
          };
        });
      } else {
        tableData.forEach((td) => (td.current = {}));
      }
      setTableData([...tableData]);
    }
  }, [selectedYourResult]);
  const submit = async () => {
    const yourResult = currentPatient.allLabResults.find((alr) => alr._id === selectedYourResult);
    const monitor1 = currentPatient.allLabResults.find((alr) => alr._id === selectedMonitor1);
    const monitor2 = currentPatient.allLabResults.find((alr) => alr._id === selectedMonitor2);

    setButtonLoading(true);

    try {
      await onOk({ yourResult, monitor1, monitor2 });
    } catch (e) {
      setButtonLoading(false);
    }
  };
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <Typography.Title level={5}>Please select result and monitoring column data</Typography.Title>
        {tableSetting && <DataTable data={tableData} settings={tableSetting}></DataTable>}
        <div
          style={{
            position: 'relative',
            height: 100,
            top: -100,
            background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff)',
          }}
        />
        <div style={{ position: 'absolute', bottom: 80, right: '48%' }}>
          <EllipsisOutlined style={{ fontSize: 32 }} />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Space size="small">
          <Button onClick={onCancel} disabled={buttonLoading}>
            Cancel
          </Button>
          <Button type={'primary'} loading={buttonLoading} disabled={buttonLoading} onClick={submit}>
            OK
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default BioIDGenerateSettingComponent;
