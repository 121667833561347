import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import DataTable from '../../../../components/data-table/data-table.component';
import { Button, Card as SimpleCard, Checkbox, Col, Modal, Row, Select, Tag, Upload } from 'antd';
import { Tab, Tabs } from '@mui/material';
import SignaturePad from 'react-signature-pad-wrapper';
import { fileToBase64 } from '../../../../share/StringHelper';
import bioApi from '../../../../api/bioApi';
import { UPLOAD_IMAGE_URL } from '../../../../api/URLs';
import { UploadFile } from '@mui/icons-material';
import { RxCornerBottomLeft } from 'react-icons/rx';
import { FaSyringe } from 'react-icons/fa';
import { getTreatmentReviewerList } from '../../service';
import { ApplicationRight } from '../../../../share/RightList';
import { useSelector } from 'react-redux';

const ESignForm = ({ signingTreatment, goBackButtonClick, onSigning, confirmButtonText, preSelectedReviewers }) => {
  const user = useSelector((state) => state.authenticatedUser) || { rightList: [] };

  const [tableData, setTableData] = useState([]);
  const [tableSetting, setTableSetting] = useState(null);
  const [signatureLink, setSignatureLink] = useState('');
  const [signatureBase64, setSignatureBase64] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [signAll, setSignAll] = useState(null);
  const [signaturePadRef, setSignaturePadRef] = useState();
  const [loading, setLoading] = useState(false);
  const [reviewers, setReviewers] = useState([]);
  const [selectedReviewers, setSelectedReviewers] = useState([]);

  const signatureUploadProps = {
    action: async (file) => {
      const base64 = await fileToBase64(file);

      const response = await bioApi.post(UPLOAD_IMAGE_URL, {
        folderType: 'doctor-signature',
        image: base64.split(',')[1],
      });
      if (response.data.result) {
        signaturePadRef.fromDataURL(base64);
        setSignatureBase64(base64);
        setActiveTab(0);
        setSignatureLink(response.data.data);
        return response.data.data;
      }
      return '';
    },
    listType: 'picture-card',
    maxCount: 1,
    fileList: signatureLink
      ? [
          {
            uid: '-1',
            name: 'signature',
            status: 'done',
            url: signatureLink,
          },
        ]
      : [],
    onPreview: () =>
      Modal.info({
        title: 'Signature Preview',
        content: <img src={signatureLink} alt="signature" style={{ maxWidth: 280, maxHeight: 280 }} />,
      }),
    onChange: (upload) => {
      if (upload.file.status === 'removed') {
        setSignatureLink('');
        setSignatureBase64('');
      }
    },
  };

  const submitSignature = async () => {
    const response = await bioApi.post(UPLOAD_IMAGE_URL, {
      folderType: 'doctor-signature',
      image: signatureBase64.split(',')[1],
    });
    if (response.data.result) {
      setSignatureBase64(signaturePadRef.toDataURL());
      setSignatureLink(response.data.data);
    }
  };

  const onStrokeEnd = () => {
    setSignatureBase64(signaturePadRef.toDataURL());
  };

  const init = () => {
    const plans = [...signingTreatment.forLabResult, ...signingTreatment.forSymptomsAndGoals];
    const approvedMedicineMap = new Map();

    const basePlan = plans.filter((p) => p.level === 'Base');
    const correctionalPlan = plans.filter((p) => p.level === 'Correctional');
    const electivePlan = plans.filter((p) => p.level === 'Elective');
    const ciPlan = plans.filter((p) => p.level === 'Cardiovascular & Inflammation');
    const gwPlan = plans.filter((p) => p.level === 'General wellness');
    const otherPlan = plans.filter((p) => p.level === 'Other');

    const planMapFunc = (p, i) => {
      const signatureLink = approvedMedicineMap.get(p._id);
      if (i === 0) {
        return { ...p, signed: !!signatureLink, signature: signatureLink };
      } else {
        return {
          ...p,
          level: '',
          signed: !!signatureLink,
          signature: signatureLink,
        };
      }
    };

    const basePlanTableData = basePlan.map(planMapFunc);
    const correctionalPlanTableData = correctionalPlan.map(planMapFunc);
    const electivePlanTableData = electivePlan.map(planMapFunc);
    const ciPlanTableData = ciPlan.map(planMapFunc);
    const gwPlanTableData = gwPlan.map(planMapFunc);
    const otherPlanTableData = otherPlan.map(planMapFunc);

    const rowSpan = {
      '': 0,
      Base: basePlanTableData.length,
      Correctional: correctionalPlanTableData.length,
      Elective: electivePlanTableData.length,
      'Cardiovascular & Inflammation': ciPlanTableData.length,
      'General wellness': gwPlanTableData.length,
      Other: otherPlanTableData.length,
    };

    setTableData([
      ...basePlanTableData,
      ...correctionalPlanTableData,
      ...electivePlanTableData,
      ...ciPlanTableData,
      ...gwPlanTableData,
      ...otherPlanTableData,
    ]);

    let theTableSetting = {
      pagination: false,
      bordered: true,
      columns: [
        {
          title: 'Therapy',
          dataIndex: 'level',
          onCell: (record) => ({
            rowSpan: rowSpan[record.level],
          }),
        },
        {
          title: 'Medication / Product',
          dataIndex: ['product', 'name'],
          render: (text, record) => (
            <>
              {!!record.medicalSupplies?.length ? (
                <div>
                  <div>{text}</div>
                  {record.medicalSupplies.map((ms) => (
                    <div style={{ marginLeft: 6, marginBottom: 2 }}>
                      <RxCornerBottomLeft size={18} style={{ position: 'relative', top: -4 }} />
                      <Tag
                        icon={<FaSyringe />}
                        color="processing"
                        style={{
                          userSelect: 'none',
                        }}
                      >
                        {ms.name}
                      </Tag>
                    </div>
                  ))}
                </div>
              ) : (
                <span>{text}</span>
              )}
            </>
          ),
        },
        {
          title: 'Dosage',
          dataIndex: 'dosage',
        },
        {
          title: 'Refill',
          dataIndex: 'refill',
        },
      ],
      operationColumnTitle: (
        <div className="d-flex justify-content-between align-items-center" style={{ width: 150 }}>
          <span>E-Sign</span>
          <span>
            <Checkbox
              disabled={!signatureLink}
              onChange={(event) => {
                setSignAll(event.target.checked);
              }}
            >
              Select All
            </Checkbox>
          </span>
        </div>
      ),
      operationColumnClass: 'action-column',
      customActions: [
        {
          element: (record, data) =>
            !record.isMedicalSupply && (
              <>
                <Checkbox
                  checked={record.signed}
                  disabled={true}
                  onChange={(event) => {
                    record.signed = event.target.checked;
                    if (!event.target.checked) {
                      record.signature = '';
                    } else {
                      record.signature = signatureLink;
                    }
                    setTableData([...data]);
                  }}
                />
                <img
                  className="ms-1"
                  src={record.signature}
                  hidden={!record.signed || !record.signature}
                  height={30}
                  alt="signature"
                />
              </>
            ),
        },
      ],
    };

    setTableSetting(theTableSetting);
  };

  const signTreatment = async () => {
    try {
      setLoading(true);
      await onSigning(signatureLink, selectedReviewers);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
    (async () => {
      const seniorProviders = await getTreatmentReviewerList();
      setReviewers(seniorProviders.data);

      if (!user.rightList.includes(ApplicationRight.Treatment_Review)) {
        if (preSelectedReviewers.length) {
          setSelectedReviewers(preSelectedReviewers);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (signAll !== null) {
      if (signAll) {
        tableData.forEach((d) => {
          d.signed = true;
          d.signature = signatureLink;
        });
      } else {
        tableData.forEach((d) => {
          d.signed = false;
          d.signature = '';
        });
      }
      setTableData([...tableData]);
    }
  }, [signAll]);

  useEffect(() => {
    if (signaturePadRef) {
      signaturePadRef.instance.addEventListener('endStroke', onStrokeEnd);
      if (activeTab === 0 && signatureBase64) {
        signaturePadRef.fromDataURL(signatureBase64);
      }
    }
  }, [signaturePadRef]);

  useEffect(() => {
    if (signatureLink) {
      setTableSetting(null);
      setTableData([]);
      setTimeout(() => {
        init();
      });
    }
  }, [signatureLink]);

  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <h4>Sign</h4>
        <div>
          <Button className="me-2" onClick={goBackButtonClick}>
            Go back and edit
          </Button>
          <Button
            type="primary"
            loading={loading}
            onClick={signTreatment}
            disabled={
              !tableData.some((d) => d.signed) ||
              (!user.rightList.includes(ApplicationRight.Treatment_Review) && !selectedReviewers.length)
            }
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
      <Row gutter={16}>
        <Col span={17} style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
          <div>
            {tableSetting && <DataTable data={tableData} settings={tableSetting} />}
            <SimpleCard title={'Follow up notes'} style={{ marginTop: 4 }}>
              {signingTreatment.followUpNotes}
            </SimpleCard>
          </div>
        </Col>
        <Col span={7} style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
          {!user.rightList.includes(ApplicationRight.Treatment_Review) && (
            <Card style={{ borderRadius: 20, marginBottom: 16 }}>
              <Card.Body>
                <Card.Title>Choose Reviewers</Card.Title>
                <Select
                  mode="multiple"
                  style={{ width: '100%', marginTop: 20 }}
                  placeholder="Please select reviewers"
                  value={selectedReviewers}
                  onChange={(value) => setSelectedReviewers(value)}
                  options={reviewers.map((r) => ({ value: r.staffId, label: `${r.firstName} ${r.lastName}` }))}
                />
              </Card.Body>
            </Card>
          )}

          <Card style={{ borderRadius: 20 }}>
            <Card.Body>
              <div className="d-flex align-items-center justify-content-between">
                <Card.Title>Signature</Card.Title>
                <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
                  <Tab label="Draw" />
                  <Tab label="Upload" />
                </Tabs>
              </div>
              <div role="tabpanel" hidden={activeTab !== 0}>
                {activeTab === 0 && (
                  <div className="mt-1">
                    <SignaturePad
                      ref={(node) => {
                        if (node) {
                          setSignaturePadRef(node);
                        }
                      }}
                      options={{ backgroundColor: '#EDEDED' }}
                    />
                    <div className="d-flex justify-content-around">
                      <Button
                        onClick={() => {
                          signaturePadRef.clear();
                          setSignatureBase64('');
                        }}
                      >
                        Clear
                      </Button>
                      <Button type="primary" disabled={!signatureBase64} onClick={submitSignature}>
                        Use This Signature
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div role="tabpanel" hidden={activeTab !== 1}>
                {activeTab === 1 && (
                  <div className="mt-1 d-flex justify-content-center">
                    <Upload {...signatureUploadProps} className="text-center">
                      {!signatureLink && (
                        <div>
                          <UploadFile />
                          <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                      )}
                    </Upload>
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default ESignForm;
