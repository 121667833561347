import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import DataTable from '../../../components/data-table/data-table.component';
import { Button, message, Popconfirm, Space, Upload } from 'antd';
import './patient.css';
import { DeleteOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import {
  checkPresignUrl,
  deleteBioId,
  deleteFiles,
  deleteLabReport,
  getBioIdFiles,
  getFiles,
  getLabreportsFiles,
} from '../service';
import moment from 'moment';
import { UPLOAD_FILE_URL } from '../../../api/URLs';
import bioApi from '../../../api/bioApi';

function FilePage() {
  const currentPatient = useSelector((state) => state.currentPatient);
  const [fileLoading, setFileLoading] = React.useState(true);
  const [fileData, setFileData] = React.useState([]);
  const [fileSetting, setFileSetting] = React.useState(null);
  const [bioDataLoading, setBioDataLoading] = React.useState(true);
  const [bioIdData, setPreviousBioIdData] = React.useState([]);
  const [bioIdTableSetting, setPreviousBioIdTableSetting] = React.useState(null);
  const [showLoading, setShowLoading] = React.useState(true);
  const [labData, setLabData] = React.useState([]);
  const [labDataSetting, setLabDataTableSetting] = React.useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const fetchFiles = async () => {
    const response = await getFiles(currentPatient);
    if (response.status === 200) {
      setFileData(response.data);
    }
    setFileSetting({
      columns: [
        {
          title: 'Title',
          dataIndex: 'name',
          width: '400px',
        },
        {
          title: 'Upload Date',
          dataIndex: 'creationDate',
          width: '350px',
          render: (text) => moment(text).format('MMM Do, YYYY - hh:mma '),
        },
      ],
      customActions: [
        {
          element: (record, data) => (
            <Space wrap>
              <Button onClick={() => handleUrl('file', record.name)} icon={<DownloadOutlined />}>
                Download
              </Button>
              <Popconfirm
                title={`Are you sure to remove file: ${record.name}`}
                onConfirm={() => onConfirmDelete('file', record._id)}
              >
                <Button danger icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          ),
        },
      ],
      pagination: {
        showLessItems: true,
        pageSize: 6,
      },
      bordered: false,
      operationColumnTitle: 'Action',
      tableClassName: 'white-header-table mb-0-table',
    });
  };

  const fetchLabreportData = async () => {
    const response = await getLabreportsFiles(currentPatient);
    if (response.status === 200) {
      setLabData(response.data.sort((a, b) => moment(b.collectionDate).diff(a.collectionDate)));
    }
    setLabDataTableSetting({
      columns: [
        {
          title: 'Current',
          dataIndex: '_id',
          render: (text) => (text === currentPatient.currentLabReportId ? '✅' : ''),
        },
        {
          title: 'Lab Name',
          dataIndex: 'lab',
        },
        {
          title: 'Specimen Id',
          dataIndex: 'specimenId',
        },
        {
          title: 'Upload Date',
          dataIndex: 'arrivalDate',
          render: (text) => moment(text).format('MMM Do, YYYY - hh:mma'),
        },
        {
          title: 'Specimen Collection Date',
          dataIndex: 'collectionDate',
        },
      ],
      customActions: [
        {
          element: (record, data) => (
            <Space wrap>
              <Button onClick={() => handleUrl('lab-report', record.fileName)} icon={<DownloadOutlined />}>
                Download
              </Button>
              <Popconfirm
                title={`Are you sure to delete lab report which is collected at ${moment(record.collectionDate).format(
                  'MMM Do, YYYY'
                )}? If it was patient's current lab report, system will assign the report which has next latest Specimen Collection Date as current.`}
                onConfirm={() => onConfirmDelete('lab-report', record._id, true)}
              >
                <Button danger icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          ),
        },
      ],
      pagination: {
        showLessItems: true,
        pageSize: 6,
      },
      bordered: false,
      operationColumnTitle: 'Action',
      tableClassName: 'white-header-table mb-0-table',
    });
  };

  const uploadProps = {
    name: 'pdf',
    maxCount: 1,
    action: bioApi.defaults.baseURL + UPLOAD_FILE_URL.replace(':id', currentPatient.patientId),
    async onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} uploaded successfully`);
        await fetchData();
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} upload failed.`);
      }
    },
    withCredentials: true,
  };

  const fetchBioIdFiles = async () => {
    const response = await getBioIdFiles(currentPatient);
    if (response.status === 200) {
      setPreviousBioIdData(response.data);
    }
    setPreviousBioIdTableSetting({
      columns: [
        {
          title: 'Title',
          dataIndex: 'name',
          width: '400px',
          render: (text) => text + "'s bioID",
        },
        {
          title: 'Generate Date',
          dataIndex: 'creationDate',
          width: '350px',
          render: (text) => moment(text).format('MMM DD, YYYY - hh:mma '),
        },
      ],
      customActions: [
        {
          element: (record, data) => (
            <Space wrap>
              <Button onClick={() => handleUrl('bio-id', record.fileName)} icon={<DownloadOutlined />}>
                Download
              </Button>
              <Popconfirm
                title={`Are you sure to remove patient's bio-id`}
                onConfirm={() => onConfirmDelete('bio-id', record._id)}
              >
                <Button danger icon={<DeleteOutlined />}>
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          ),
        },
      ],
      bordered: false,
      operationColumnTitle: 'Action',
      tableClassName: 'white-header-table mb-0-table',
      pagination: {
        showLessItems: true,
        pageSize: 6,
      },
    });
  };

  const fetchData = async () => {
    setShowLoading(true);
    setBioDataLoading(true);
    setFileLoading(true);
    await fetchLabreportData();
    await fetchBioIdFiles();
    await fetchFiles();
    setBioDataLoading(false);
    setShowLoading(false);
    setFileLoading(false);
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  const onConfirmDelete = async (type, deleteID, refreshPage) => {
    if (type === 'lab-report') {
      await deleteLabReport(deleteID);
    } else if (type === 'bio-id') {
      await deleteBioId(deleteID);
    } else if (type === 'file') {
      await deleteFiles({ _id: deleteID });
    }
    if (refreshPage) {
      window.location.reload();
    } else {
      await fetchData();
    }
  };

  const getFileExtension = (fileName) => {
    const parts = fileName.split('.');
    return parts.length > 1 ? parts[parts.length - 1].toLowerCase() : '';
  };

  const handleUrl = async (type, fileName) => {
    const urlData = await checkPresignUrl(type, currentPatient._id, fileName);
    if (urlData.data.status) {
      const base64Data = urlData.data.base64;
      const extension = getFileExtension(fileName);
      const src = `data:application/${extension};base64,${base64Data}`;
      const newTab = window.open();
      if (newTab) {
        newTab.document.write('<iframe src="' + src + '" style="width:100%; height:100vh;"></iframe>');
        newTab.focus();
      } else {
        console.error('The pop-up was blocked by the browser.');
      }
    } else {
      messageApi.open({
        type: 'error',
        content: 'Failed to open Lab Report, please try it again later.',
      });
    }
  };

  return (
    <>
      {contextHolder}
      {fileSetting && (
        <Row className="profile mt-4">
          <Col sm={8}>
            <h2 className="section-title">File</h2>
          </Col>
          <Col sm={4} className="create-row">
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Col>
          <Col sm={12}>
            <Row className="section-content">
              <Col sm={12}>
                <div style={{ padding: '12px' }}>
                  <DataTable loading={fileLoading} data={fileData} settings={fileSetting}></DataTable>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {labDataSetting && (
        <Row className="profile mt-4">
          <Col sm={6}>
            <h2 className="section-title">Lab Report</h2>
          </Col>
          <Col sm={12}>
            <Row className="section-content">
              <Col sm={12}>
                <div style={{ padding: '12px' }}>
                  <DataTable loading={showLoading} data={labData} settings={labDataSetting}></DataTable>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {bioIdTableSetting && (
        <Row className="profile mt-4">
          <Col sm={6}>
            <h2 className="section-title">BioID</h2>
          </Col>
          <Col sm={12}>
            <Row className="section-content">
              <Col sm={12}>
                <div style={{ padding: '12px' }}>
                  <DataTable data={bioIdData} loading={bioDataLoading} settings={bioIdTableSetting}></DataTable>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}

export default FilePage;
