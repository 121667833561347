import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import React, { forwardRef, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-timezone';
import './appointment-calendar.css';
import {
  Button,
  Card,
  Divider,
  List,
  message,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
} from 'antd';
import { AiOutlineCaretLeft, AiOutlineCaretRight } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';
import {
  addTipOnDate,
  cancelAppointment,
  completeAppointment,
  confirmAppointment,
  deleteTip,
  generateTitle,
  getEventClass,
  getStaffSchedules,
  getTipsBtwDates,
  modifyTip,
  patientNotAppear,
} from './service';
import DatePicker from 'react-datepicker';
import ModifyAppointmentModal from './modify-appointment.modal';
import dayjs from 'dayjs';
import AppointmentDetail from './appointment-detail.component';
import MDEditor from '@uiw/react-md-editor';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TbFocusCentered } from 'react-icons/tb';
import { setTimezone } from '../../stores/timezone.store';
import { isInSameTimezone } from '../../share/TimezoneList';

const AppointmentCalendarComponent = ({
  appointmentEvents,
  locationOptions,
  providers,
  providingServices,
  eventTitleDifferentiator,
  checkAfterReschedule,
  locationId,
  staffId,
  date,
}) => {
  const user = useSelector((state) => state.authenticatedUser);
  const pageTimezone = useSelector((state) => state.timezone);
  const dispatch = useDispatch();

  const localizer = momentLocalizer(moment);

  const [view, setView] = useState(date ? Views.DAY : Views.WEEK);
  const [selectedDate, setSelectedDate] = useState(
    moment(date || new Date())
      .startOf('day')
      .toDate()
  );
  const [dateString, setDateString] = useState(moment(date || new Date()).format('YYYY-MM-DD'));
  const [events, setEvents] = useState(appointmentEvents);
  const [appointmentWindow, setAppointmentWindow] = useState({
    show: false,
    appointment: null,
  });
  const [showCompleted, setShowCompleted] = useState(false);
  const [showCanceled, setShowCanceled] = useState(false);
  const [showRescheduled, setShowRescheduled] = useState(false);
  const [showNoShow, setShowNoShow] = useState(false);
  const [tipsOfTheWeekByDay, setTipsOfTheWeekByDay] = useState({});
  const [tipModal, setTipModal] = useState({
    show: false,
    tip: null,
  });
  const [submittingTip, setSubmittingTip] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [staffScheduleOfTheWeek, setStaffScheduleOfTheWeek] = useState([]);

  useEffect(() => setEvents(appointmentEvents), [appointmentEvents]);

  useEffect(() => {
    if (staffId) {
      (async () => {
        const startOfWeek = moment(selectedDate).startOf('week').toDate();
        const endOfWeek = moment(selectedDate).endOf('week').toDate();
        let currentDate = moment(startOfWeek);
        const dates = [];

        while (currentDate <= moment(endOfWeek)) {
          dates.push(currentDate.format('YYYY-MM-DD'));
          currentDate = currentDate.add(1, 'days');
        }

        const staffSchedule = await getStaffSchedules({ staff: staffId, date: dates.join(',') });
        setStaffScheduleOfTheWeek(staffSchedule.data.data);
      })();
    }
  }, [staffId, selectedDate]);

  useEffect(() => {
    if (locationId && selectedDate) {
      (async () => {
        const startOfWeek = moment(selectedDate).startOf('week').toDate();
        const endOfWeek = moment(selectedDate).endOf('week').toDate();
        const tipsOfTheWeek = await getTipsBtwDates(startOfWeek, endOfWeek, locationId);
        // group tips by target day
        const tipsOfTheWeekByDay = tipsOfTheWeek.data.reduce((acc, tip) => {
          const targetDate = moment(tip.targetDate).format('YYYY-MM-DD');
          if (!acc[targetDate]) {
            acc[targetDate] = [];
          }
          acc[targetDate].push(tip);
          return acc;
        }, {});
        setTipsOfTheWeekByDay(tipsOfTheWeekByDay);
      })();
    }
  }, [selectedDate, locationId]);

  useEffect(() => {
    if (locationId && locationOptions.length > 0) {
      messageApi.destroy();
      const locationTimezone = locationOptions.find((loc) => loc.value === locationId)?.timezone;
      if (locationTimezone && !isInSameTimezone(locationTimezone, pageTimezone)) {
        messageApi.warning(
          <div>
            <Typography.Text>This location's timezone is different from yours.</Typography.Text>
            <Typography.Link
              style={{ marginInline: 4 }}
              strong
              onClick={() => {
                dispatch(setTimezone(locationTimezone));
                messageApi.destroy();
              }}
            >
              CLICK HERE
            </Typography.Link>
            <Typography.Text>to change your timezone to match this location.</Typography.Text>
          </div>,
          0
        );
      }
    }
  }, [locationId, locationOptions]);

  useEffect(() => {
    if (staffId) {
      messageApi.destroy();
      if (view === Views.DAY) {
        const scheduleOfDate = staffScheduleOfTheWeek.find((s) => s.date === moment(selectedDate).format('YYYY-MM-DD'));
        if (scheduleOfDate && scheduleOfDate.locationDetail.timezone !== pageTimezone) {
          messageApi.warning(
            <div style={{ width: 500 }}>
              <Typography.Text>
                On this day, <Typography.Text strong>{moment(selectedDate).format('MMM DD, YYYY')}</Typography.Text>,{' '}
                the staff's working location in{' '}
                <Typography.Text strong>{scheduleOfDate.locationDetail.name}</Typography.Text>, operates in a timezone
                that is different from your current settings.
              </Typography.Text>
              <br />
              <Typography.Link
                style={{ marginInline: 4 }}
                strong
                onClick={() => {
                  dispatch(setTimezone(scheduleOfDate.locationDetail.timezone));
                  messageApi.destroy();
                }}
              >
                CLICK HERE
              </Typography.Link>
              <Typography.Text>to change your timezone to match this location.</Typography.Text>
            </div>,
            0
          );
        }
      }
    }
  }, [view, staffScheduleOfTheWeek]);

  useEffect(() => {
    setSelectedDate(moment(dateString, 'YYYY-MM-DD').startOf('day').toDate());
  }, [pageTimezone, dateString]);

  const calendarHeaderPopoverContent = (date) => {
    if (locationId) {
      return (
        <div style={{ width: (window.innerWidth * 4) / 10, maxHeight: window.innerHeight - 275 }}>
          <Space style={{ width: '100%', justifyContent: 'center' }}>
            <Typography.Text strong>{moment(date).format('MMM DD, yyyy')}</Typography.Text>
            <Button
              type={'primary'}
              size={'small'}
              onClick={() =>
                setTipModal({
                  show: true,
                  tip: {
                    targetDate: date,
                    content: '',
                  },
                })
              }
            >
              Add tip to this day for this location
            </Button>
          </Space>
          <div style={{ maxHeight: window.innerHeight - 290, overflowY: 'auto' }}>
            <List
              itemLayout="vertical"
              dataSource={tipsOfTheWeekByDay[moment(date).format('YYYY-MM-DD')] || []}
              renderItem={(item, index) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    title={
                      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography.Text type={'secondary'}>
                          {item.createdBy.staffName} @ {moment(item.creationTs).format('MMM DD, yyyy | hh:mm a')}
                          {item.modifiedTs && (
                            <Popover
                              content={
                                <Typography.Text type="secondary">
                                  Last Modified: {moment(item.modifiedTs).format('MMM DD, yyyy | hh:mm a')}
                                </Typography.Text>
                              }
                            >
                              <InfoCircleOutlined style={{ marginLeft: 4 }} />
                            </Popover>
                          )}
                        </Typography.Text>

                        {item.createdBy.staffId === user.staffId && (
                          <div>
                            <Typography.Link
                              onClick={() => {
                                setTipModal({
                                  show: true,
                                  tip: {
                                    _id: item._id,
                                    targetDate: item.targetDate,
                                    content: item.content,
                                  },
                                });
                              }}
                            >
                              Modify
                            </Typography.Link>
                            <Divider type={'vertical'} />
                            <Popconfirm
                              title={'Are you sure to delete this tip'}
                              onConfirm={async () => {
                                await deleteTip(item._id);
                                const targetDate = moment(item.targetDate).format('YYYY-MM-DD');
                                const targetTipIndex = tipsOfTheWeekByDay[targetDate].findIndex(
                                  (t) => t._id === item._id
                                );
                                tipsOfTheWeekByDay[targetDate].splice(targetTipIndex, 1);
                                setTipsOfTheWeekByDay({ ...tipsOfTheWeekByDay });
                                messageApi.success('Successfully deleted tip');
                              }}
                            >
                              <Typography.Link type={'danger'}>Delete</Typography.Link>
                            </Popconfirm>
                          </div>
                        )}
                      </Space>
                    }
                    style={{ marginBottom: 0 }}
                  />
                  <Card styles={{ body: { padding: 12 } }}>
                    <MDEditor.Markdown source={item.content} />
                  </Card>
                </List.Item>
              )}
            />
          </div>
        </div>
      );
    } else if (staffId) {
      const scheduleOfDate = staffScheduleOfTheWeek.find((s) => s.date === moment(date).format('YYYY-MM-DD'));
      if (scheduleOfDate && !isInSameTimezone(scheduleOfDate.locationDetail.timezone, pageTimezone)) {
        return (
          <div style={{ width: 300 }}>
            <Typography.Text>
              On this day,{' '}
              <Typography.Text strong>{moment(scheduleOfDate.date).format('MMM DD, YYYY')}</Typography.Text>, the
              staff's working location in <Typography.Text strong>{scheduleOfDate.locationDetail.name}</Typography.Text>
              , operates in a timezone that is different from your current settings.
              <br />
              <Typography.Link
                onClick={() => {
                  dispatch(setTimezone(scheduleOfDate.locationDetail.timezone));
                  messageApi.destroy();
                }}
              >
                CLICK HERE
              </Typography.Link>{' '}
              to change your timezone to match this location.
            </Typography.Text>
          </div>
        );
      }
    }
  };

  const weekHeader = ({ date }) => (
    <Popover content={calendarHeaderPopoverContent(date)} trigger={'click'} placement={'bottom'}>
      <div style={{ fontSize: 18, fontWeight: 700, position: 'relative', userSelect: 'none' }}>
        {moment(date).format('D').padStart(2, '0')}

        {!!locationId && (
          <Typography.Text style={{ fontSize: 24, position: 'absolute', right: -30, top: 5 }}>📝</Typography.Text>
        )}
        {(() => {
          const scheduleOfDate = staffScheduleOfTheWeek.find((s) => s.date === moment(date).format('YYYY-MM-DD'));
          if (scheduleOfDate && !isInSameTimezone(scheduleOfDate.locationDetail.timezone, pageTimezone)) {
            return (
              <Typography.Text style={{ fontSize: 16, position: 'absolute', right: -24, top: 12 }}>🌎</Typography.Text>
            );
          }
        })()}
      </div>
      <div style={{ fontWeight: 500, position: 'relative', userSelect: 'none' }}>
        <TbFocusCentered
          style={{ position: 'absolute', left: -24, top: 4, color: 'black', opacity: 0.3 }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDateString(moment(date).format('YYYY-MM-DD'));
            setView(Views.DAY);
          }}
        />
        {moment(date).format('dddd')}
      </div>
    </Popover>
  );

  const isWeekday = (date) => {
    const day = moment(date).day();
    return day !== 0 && day !== 6;
  };

  const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <Button type="text" onClick={onClick} ref={ref}>
      {view === Views.DAY
        ? moment(value, 'MM/DD/YYYY').startOf('day').format('MMMM Do, YYYY')
        : `${moment(value).startOf('week').format('MMM DD, YYYY')} - ${moment(value)
            .endOf('week')
            .format('MMM DD, YYYY')}`}{' '}
      <BiChevronDown style={{ marginLeft: 5, position: 'relative', top: -2 }} />
    </Button>
  ));

  const changeDate = (direction) => {
    let newDate;
    if (direction) {
      newDate = moment(selectedDate).add(direction, 'day').toDate();
    } else {
      newDate = moment(new Date()).startOf('day').toDate();
    }
    setDateString(moment(newDate).format('YYYY-MM-DD'));
  };

  const CustomToolbar = () => (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ fontWeight: 500, marginBottom: 5, marginInline: 16 }}
    >
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <Space style={{ marginRight: 8 }}>
          <span className="d-flex align-items-center">
            <AiOutlineCaretLeft
              style={{ cursor: 'pointer' }}
              onClick={() => changeDate(view === Views.DAY ? -1 : -7)}
            />
          </span>
          <Typography.Link style={{ color: 'black' }} onClick={() => changeDate(0)}>
            {view === Views.DAY ? 'Today' : 'This Week'}
          </Typography.Link>
          <span className="d-flex align-items-center">
            <AiOutlineCaretRight style={{ cursor: 'pointer' }} onClick={() => changeDate(view === Views.DAY ? 1 : 7)} />
          </span>
        </Space>
        <DatePicker
          selected={moment.tz(dateString, moment.tz.guess()).startOf('day').toDate()}
          disabledKeyboardNavigation
          onChange={(date) => {
            //setSelectedDate(moment.tz(date, moment.tz.guess()).toDate());
            setDateString(moment.tz(date, moment.tz.guess()).format('YYYY-MM-DD'));
          }}
          customInput={<CustomDatePickerInput />}
          dayClassName={(date) => {
            if (view === Views.DAY) {
              return 'in-day-view';
            }
            return dayjs(selectedDate.toDateString()).isSame(date.toDateString(), 'week')
              ? 'react-datepicker__day--same-week'
              : '';
          }}
        />
      </div>
      <Space className="d-flex align-items-center">
        <Space.Compact
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => setShowCompleted(!showCompleted)}
        >
          <Switch
            size={'small'}
            checkedChildren={'𝍎'}
            checked={showCompleted}
            onClick={() => setShowCompleted(!showCompleted)}
          />
          Show Completed
        </Space.Compact>

        <Space.Compact
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => setShowRescheduled(!showRescheduled)}
        >
          <Switch
            size={'small'}
            checkedChildren={<span className="fs-5">↬</span>}
            checked={showRescheduled}
            onClick={() => setShowRescheduled(!showRescheduled)}
          />
          Show Rescheduled
        </Space.Compact>

        <Space.Compact
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => setShowCanceled(!showCanceled)}
        >
          <Switch
            size={'small'}
            checkedChildren="Ⓧ"
            checked={showCanceled}
            onClick={() => setShowCanceled(!showCanceled)}
          />
          Show Canceled
        </Space.Compact>

        <Space.Compact
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => setShowNoShow(!showNoShow)}
        >
          <Switch
            size={'small'}
            checkedChildren={'Ø'}
            checked={showNoShow}
            onClick={() => setShowNoShow(!showNoShow)}
          />
          Show No-show
        </Space.Compact>
        <div>
          <Select
            value={view}
            style={{ width: 80 }}
            onChange={(value) => setView(value)}
            options={[
              { value: Views.WEEK, label: 'Week' },
              { value: Views.DAY, label: 'Day' },
            ]}
            variant={'borderless'}
          />
        </div>
      </Space>
    </div>
  );

  const editClick = (event) => {
    setAppointmentWindow({
      show: true,
      appointment: {
        ...event.appointment,
        title: event.title,
      },
    });
  };

  const cancelEvent = async (event) => {
    let cancelStatus;
    const hide = messageApi.loading('Cancelling in progress..', 0, () => {
      if (cancelStatus === 'success') {
        messageApi.success('Successfully Canceled Appointment!');
      } else if (cancelStatus === 'fail') {
        messageApi.error('We ran into problems when canceling the appointment');
      }
    });

    try {
      await cancelAppointment(event.appointment.appointmentId);

      const oldEvent = events
        .filter((e) => e.type === 'appointment')
        .find((e) => e.appointment.appointmentId === event.appointment.appointmentId);
      oldEvent.className = `${oldEvent.className} canceled-event`;
      oldEvent.appointment.status = 'Canceled';
      setEvents([...events]);
      cancelStatus = 'success';
    } catch (e) {
      cancelStatus = 'fail';
    } finally {
      hide();
    }
  };

  const markPatientAbsent = async (event) => {
    let cancelStatus;
    const hide = messageApi.loading('Putting Appointment in no-show...', 0, () => {
      if (cancelStatus === 'success') {
        messageApi.success('Successfully mark appointment in no show status!');
      } else if (cancelStatus === 'fail') {
        messageApi.error('Unable to mark appointment no-show!');
      }
    });

    try {
      await patientNotAppear(event.appointment.appointmentId);

      const oldEvent = events
        .filter((e) => e.type === 'appointment')
        .find((e) => e.appointment.appointmentId === event.appointment.appointmentId);
      oldEvent.className = `${oldEvent.className} no-show-event`;
      oldEvent.appointment.status = 'PatientAbsents';
      setEvents([...events]);
      cancelStatus = 'success';
    } catch (e) {
      cancelStatus = 'fail';
    } finally {
      hide();
    }
  };

  const completeEvent = async (event) => {
    let status;
    const hide = messageApi.loading('Completing appointment in progress..', 0, () => {
      if (status === 'success') {
        messageApi.success('Successfully Completed Appointment!');
      } else if (status === 'fail') {
        messageApi.error('We ran into problems when completing the appointment');
      }
    });

    try {
      await completeAppointment(event.appointment.appointmentId);

      const oldEvent = events
        .filter((e) => e.type === 'appointment')
        .find((e) => e.appointment.appointmentId === event.appointment.appointmentId);
      oldEvent.className = `${oldEvent.className} completed-event`;
      oldEvent.appointment.status = 'Completed';
      setEvents([...events]);
      status = 'success';
    } catch (e) {
      status = 'fail';
    } finally {
      hide();
    }
  };

  const updateEvent = (result) => {
    if (result && result.type === 'reschedule') {
      const oldEvent = events
        .filter((e) => e.type === 'appointment')
        .find((e) => e.appointment.appointmentId === appointmentWindow.appointment.appointmentId);
      oldEvent.className = `${oldEvent.className} rescheduled-event`;
      oldEvent.appointment.status = 'Rescheduled';
      if (checkAfterReschedule(oldEvent.appointment, result.data)) {
        const newEvent = {
          end: moment(result.data.end).toDate(),
          start: moment(result.data.start).toDate(),
          title: generateTitle(
            result.data.service,
            result.data.patient,
            { staff: result.data.staff, location: result.data.location },
            eventTitleDifferentiator
          ),
          className: getEventClass(result.data),
          appointment: result.data,
          type: 'appointment',
          note: result.note,
        };

        setEvents([...events, newEvent]);
      } else {
        setEvents([...events]);
      }
    } else if (result && result.type === 'cancel') {
      const oldEvent = events
        .filter((e) => e.type === 'appointment')
        .find((e) => e.appointment.appointmentId === appointmentWindow.appointment.appointmentId);
      oldEvent.className = `${oldEvent.className} canceled-event`;
      oldEvent.appointment.status = 'Canceled';
      setEvents([...events]);
    } else if (result && result.type === 'update') {
      const oldEventIndex = events
        .filter((e) => e.type === 'appointment')
        .findIndex((e) => e.appointment.appointmentId === appointmentWindow.appointment.appointmentId);
      const newEvent = {
        end: moment(result.data.end).toDate(),
        start: moment(result.data.start).toDate(),
        title: generateTitle(
          result.data.service,
          result.data.patient,
          { staff: result.data.staff, location: result.data.location },
          eventTitleDifferentiator
        ),
        className: getEventClass(result.data),
        appointment: result.data,
        type: 'appointment',
        note: result.note,
      };
      events.splice(oldEventIndex, 1, newEvent);
      setEvents([...events]);
    }
    setAppointmentWindow({ show: false });
  };

  const appointmentToNow = (start, end) => {
    const now = moment();
    if (now.isBetween(start, end)) {
      return 'OnGoing';
    }
    if (now.isAfter(end)) {
      return 'Passed';
    }
    if (now.isBefore(start)) {
      return 'InComing';
    }
  };

  const eventStatusDisplay = (event) => {
    const apptConfirmed = !!event.appointment.apptConfirm;
    const rescheduleRequested = !!event.appointment.rescheduleRequest;
    if (apptConfirmed && !rescheduleRequested) {
      return (
        <>
          <Typography.Text type="success">🥳 Patient Confirmed Appointment</Typography.Text>
          <hr />
        </>
      );
    }
    if (!apptConfirmed && rescheduleRequested) {
      return (
        <>
          <Typography.Text type="warning">🙋 Patient Requested Reschedule</Typography.Text>
          <hr />
        </>
      );
    }
    if (apptConfirmed && rescheduleRequested) {
      return (
        <>
          <Typography.Text type="danger">🤔 Patient Confirmed But Then Requested Reschedule</Typography.Text>
          <hr />
        </>
      );
    }
  };

  const eventPopOver = (eventWrapperProps) =>
    eventWrapperProps.event.type !== 'blocked-time' ? (
      <Popover
        placement="right"
        title={null}
        content={
          <div style={{ padding: 8, width: 350 }}>
            <AppointmentDetail appointment={eventWrapperProps.event.appointment} />
            <hr />
            {eventStatusDisplay(eventWrapperProps.event)}
            <div style={{ marginTop: 16 }}>
              {eventWrapperProps.event.appointment.status === 'Booked' && (
                <>
                  {['OnGoing', 'Passed'].includes(
                    appointmentToNow(eventWrapperProps.event.start, eventWrapperProps.event.end)
                  ) && (
                    <div className="text-center d-flex justify-content-around">
                      <Button type="dashed" danger onClick={() => markPatientAbsent(eventWrapperProps.event)}>
                        No Show
                      </Button>
                      <Button onClick={() => completeEvent(eventWrapperProps.event)}>Complete Appointment</Button>
                    </div>
                  )}
                  {appointmentToNow(eventWrapperProps.event.start, eventWrapperProps.event.end) === 'InComing' && (
                    <div className="text-center d-flex justify-content-around">
                      <Button onClick={() => editClick(eventWrapperProps.event)}>Edit</Button>
                      <Button danger onClick={() => cancelEvent(eventWrapperProps.event)}>
                        Cancel Appointment
                      </Button>
                      {!eventWrapperProps.event.appointment.apptConfirm && (
                        <Popconfirm
                          title={'Are you sure to confirm this appointment?'}
                          onConfirm={async () => {
                            try {
                              await confirmAppointment(eventWrapperProps.event.appointment.appointmentId);
                              const oldEvent = events
                                .filter((e) => e.type === 'appointment')
                                .find(
                                  (e) =>
                                    e.appointment.appointmentId === eventWrapperProps.event.appointment.appointmentId
                                );
                              oldEvent.className = oldEvent.className.replace('not-confirmed-event', 'confirmed-event');
                              oldEvent.appointment.apptConfirm = true;
                              setEvents([...events]);
                              messageApi.success('Successfully confirmed appointment');
                            } catch (e) {
                              messageApi.error('Failed to confirm appointment');
                            }
                          }}
                        >
                          <Button type={'primary'}>Confirm</Button>
                        </Popconfirm>
                      )}
                    </div>
                  )}
                  {!!eventWrapperProps.event.appointment.changeMadeBy && (
                    <div className={'text-end'}>
                      <Typography.Text type={'secondary'} style={{ fontSize: 'smaller' }}>
                        This appointment is booked by {eventWrapperProps.event.appointment.changeMadeBy}
                        {!!eventWrapperProps.event.appointment.statusChangeTS && (
                          <div>
                            @{' '}
                            {moment(eventWrapperProps.event.appointment.statusChangeTS).format(
                              'MM/DD/YYYY | hh:mma (z)'
                            )}
                          </div>
                        )}
                      </Typography.Text>
                    </div>
                  )}
                </>
              )}
              {eventWrapperProps.event.appointment.status === 'Rescheduled' && (
                <div className="text-center">
                  This appointment has been <Typography.Text type="warning">rescheduled</Typography.Text>{' '}
                  {!!eventWrapperProps.event.appointment.changeMadeBy && (
                    <>by {eventWrapperProps.event.appointment.changeMadeBy}</>
                  )}
                  {!!eventWrapperProps.event.appointment.statusChangeTS && (
                    <div>
                      @ {moment(eventWrapperProps.event.appointment.statusChangeTS).format('MM/DD/YYYY | hh:mma (z)')}
                    </div>
                  )}
                </div>
              )}
              {eventWrapperProps.event.appointment.status === 'Canceled' && (
                <div className="text-center">
                  This appointment has been <Typography.Text type="danger">canceled</Typography.Text>{' '}
                  {!!eventWrapperProps.event.appointment.changeMadeBy && (
                    <>by {eventWrapperProps.event.appointment.changeMadeBy}</>
                  )}
                  {!!eventWrapperProps.event.appointment.statusChangeTS && (
                    <div>
                      @ {moment(eventWrapperProps.event.appointment.statusChangeTS).format('MM/DD/YYYY | hh:mma (z)')}
                    </div>
                  )}
                </div>
              )}
              {eventWrapperProps.event.appointment.status === 'Completed' && (
                <div>
                  <div className="text-center">This appointment has been completed.</div>
                  <div className="text-end mt-2">
                    <Typography.Text type={'secondary'} style={{ fontSize: 'smaller' }}>
                      {eventWrapperProps.event.appointment.changeMadeBy} marked this appointment as completed
                      {!!eventWrapperProps.event.appointment.statusChangeTS && (
                        <div>
                          @{' '}
                          {moment(eventWrapperProps.event.appointment.statusChangeTS).format('MM/DD/YYYY | hh:mma (z)')}
                        </div>
                      )}
                    </Typography.Text>
                  </div>
                </div>
              )}
              {eventWrapperProps.event.appointment.status === 'PatientAbsents' && (
                <div>
                  <div className="text-center">Patient didn't show up for this appointment.</div>
                  <div className="text-end mt-2">
                    <Typography.Text type={'secondary'} style={{ fontSize: 'smaller' }}>
                      {eventWrapperProps.event.appointment.changeMadeBy} marked this appointment as no-show
                      {!!eventWrapperProps.event.appointment.statusChangeTS && (
                        <div>
                          @{' '}
                          {moment(eventWrapperProps.event.appointment.statusChangeTS).format('MM/DD/YYYY | hh:mma (z)')}
                        </div>
                      )}
                    </Typography.Text>
                  </div>
                </div>
              )}
            </div>
          </div>
        }
        trigger="click"
      >
        {eventWrapperProps.children}
      </Popover>
    ) : (
      <>{eventWrapperProps.children}</>
    );

  return (
    <>
      {/*{events === undefined && (*/}
      {/*  <Typography.Title level={4} style={{ marginTop: 20, opacity: 0.3 }}>*/}
      {/*    Select Location/ Provider From The List*/}
      {/*  </Typography.Title>*/}
      {/*)}*/}
      {events === null && <Spin fullscreen size={'large'} />}
      {contextHolder}
      {!!events && (
        <Calendar
          date={selectedDate}
          localizer={localizer}
          defaultView={Views.WEEK}
          view={view}
          views={[Views.WEEK, Views.DAY]}
          components={{
            week: {
              header: weekHeader,
            },
            toolbar: CustomToolbar,
            eventWrapper: eventPopOver,
          }}
          timeslots={1}
          step={60}
          slotPropGetter={(date) => {
            // Disable times from 7:00 AM to 8:00 AM and from 9:00 PM to 10:00 PM
            const isDisabled = moment(date).hour() < 8 || moment(date).hour() > 20 || !isWeekday(date);
            if (isDisabled) {
              return {
                className: 'disabled-time',
              };
            }
          }}
          dayLayoutAlgorithm={'no-overlap'}
          eventPropGetter={(event, start, end, isSelected) => ({
            className: event.className,
          })}
          events={events
            .filter((e) => {
              if (e.type === 'appointment' && !showRescheduled) {
                return e.appointment.status !== 'Rescheduled';
              } else {
                return true;
              }
            })
            .filter((e) => {
              if (e.type === 'appointment' && !showCanceled) {
                return e.appointment.status !== 'Canceled';
              } else {
                return true;
              }
            })
            .filter((e) => {
              if (e.type === 'appointment' && !showNoShow) {
                return e.appointment.status !== 'PatientAbsents';
              } else {
                return true;
              }
            })
            .filter((e) => {
              if (e.type === 'appointment' && !showCompleted) {
                return e.appointment.status !== 'Completed';
              } else {
                return true;
              }
            })}
          scrollToTime={moment(new Date()).startOf('day').set('hour', 8).toDate()}
        />
      )}
      {appointmentWindow.appointment && (
        <ModifyAppointmentModal
          event={appointmentWindow.appointment}
          show={appointmentWindow.show}
          locations={locationOptions}
          services={providingServices}
          providers={providers}
          eventTitleDifferentiator={eventTitleDifferentiator}
          onFinish={updateEvent}
        />
      )}
      {tipModal.show && (
        <Modal
          title={
            <Space>
              {(tipModal.tip._id ? 'Modify Your Tip ' : `Add Tip `) +
                `at ${moment(tipModal.tip.targetDate).format('MMM DD, yyyy')} for ${
                  locationOptions.find((l) => locationId === l.value).label
                }`}
              <Typography.Link type={'secondary'}>
                <a href={'https://www.markdownguide.org/cheat-sheet/'} target={'_blank'}>
                  MarkDown Cheatsheet
                </a>
              </Typography.Link>
            </Space>
          }
          open={tipModal.show}
          onCancel={() => setTipModal({ show: false, tip: null })}
          width={800}
          confirmLoading={submittingTip}
          onOk={async () => {
            setSubmittingTip(true);
            try {
              if (tipModal.tip._id) {
                // modify tip
                await modifyTip(tipModal.tip._id, tipModal.tip.content);
                const targetDate = moment(tipModal.tip.targetDate).format('YYYY-MM-DD');
                const targetTip = tipsOfTheWeekByDay[targetDate].find((t) => t._id === tipModal.tip._id);
                targetTip.content = tipModal.tip.content;
                targetTip.modifiedTs = new Date();
              } else {
                const newTipResp = await addTipOnDate(tipModal.tip.content, tipModal.tip.targetDate, locationId);
                const newTip = {
                  ...newTipResp.data,
                  createdBy: {
                    staffId: user.staffId,
                    staffName: user.name,
                  },
                };
                if (tipsOfTheWeekByDay[moment(tipModal.tip.targetDate).format('YYYY-MM-DD')]) {
                  tipsOfTheWeekByDay[moment(tipModal.tip.targetDate).format('YYYY-MM-DD')].push({
                    ...newTip,
                  });
                } else {
                  tipsOfTheWeekByDay[moment(tipModal.tip.targetDate).format('YYYY-MM-DD')] = [{ ...newTip }];
                }
              }
              setTipsOfTheWeekByDay({ ...tipsOfTheWeekByDay });
              setTipModal({ show: false, tip: null });
              messageApi.success('Successfully submitted tip');
            } catch (e) {
              messageApi.error('Failed to submit tip');
            } finally {
              setSubmittingTip(false);
            }
          }}
        >
          <MDEditor
            value={tipModal.tip.content}
            onChange={(content) => {
              setTipModal({
                ...tipModal,
                tip: {
                  ...tipModal.tip,
                  content,
                },
              });
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default AppointmentCalendarComponent;
