import React from 'react';

const Layout = ({ children, style }) => {
  return (
    <div className="layout" style={{ ...style, zIndex: 0 }}>
      {children}
    </div>
  );
};

export default Layout;
