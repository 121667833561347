import { useEffect, useMemo, useState } from 'react';
import { getBiomarkers } from './service';
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Divider,
  List,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import ExpandableText from '../../components/expandable-text/expandable-text';
import { convertLogicToString } from '../../utils/json-logic-object.parser';
import ModifyBiomarkerModal from './modify-biomarker.modal';

const BiomarkerManagementPage = () => {
  const [biomarkers, setBiomarkers] = useState([]);
  const [displayingNumber, setDisplayingNumber] = useState(null);
  const [loading, setLoading] = useState(true);
  const [biomarkerFilter, setBiomarkerFilter] = useState({
    gender: 'unisex',
    biologicalStage: '',
    type: 'all',
  });
  const [medicineFilter, setMedicineFilter] = useState({
    recommendOnly: false,
    defaultOnly: false,
  });

  const [modifyingBiomarker, setModifyingBiomarker] = useState(null);

  const filterBiomarkers = () => {
    return biomarkers.filter((b) => {
      let typeCompare = false;
      if (biomarkerFilter.type === 'all') {
        typeCompare = true;
      } else {
        typeCompare = b.type === biomarkerFilter.type;
      }

      let genderCompare = false;
      if (biomarkerFilter.gender === 'unisex') {
        genderCompare = true;
      } else {
        const biomarkerGenders = b.tabs.flatMap((t) => (t.gender === 'unisex' ? ['female', 'male'] : [t.gender]));
        genderCompare = biomarkerGenders.includes(biomarkerFilter.gender);
      }

      let stageCompare = false;
      if (biomarkerFilter.biologicalStage === '') {
        stageCompare = true;
      } else {
        const females = b.tabs.filter((t) => t.gender === 'female');
        stageCompare =
          !!females.length && females.map((f) => f.biologicalStage || '').includes(biomarkerFilter.biologicalStage);
      }
      return genderCompare && stageCompare && typeCompare;
    });
  };

  const visibleBiomarkers = useMemo(filterBiomarkers, [biomarkers, biomarkerFilter]);

  const filterMedicines = (medicine) => {
    let recommendCompare = true;
    if (medicineFilter.recommendOnly) {
      recommendCompare = medicine.recommend;
    }
    let defaultCompare = true;
    if (medicineFilter.defaultOnly) {
      defaultCompare = medicine.isDefault;
    }

    return recommendCompare && defaultCompare;
  };

  useEffect(() => {
    (async () => {
      const convertedBiomarkers = [];
      const biomarkerResponse = await getBiomarkers();
      biomarkerResponse.data.forEach((b) => {
        const cb = convertedBiomarkers.find((cb) => cb.name === b.name);
        b.medications.forEach((m) => {
          if (!m.when) {
            m.when = {};
          }
        });
        if (cb) {
          if (cb.tabs.find((t) => t.gender === b.gender && t.biologicalStage === b.biologicalStage)) {
            alert(`${cb.name} has duplicated gender and biological stage combination, please contact developer to fix`);
            throw new Error();
          }
          cb.tabs.push({
            _id: b._id,
            optimalRange: b.optimalRange,
            gender: b.gender,
            range: b.range,
            symptoms: b.symptoms,
            description: b.description,
            biologicalStage: b.biologicalStage,
            medications: b.medications,
          });
        } else {
          const tabs = [];
          tabs.push({
            _id: b._id,
            optimalRange: b.optimalRange,
            gender: b.gender,
            range: b.range,
            symptoms: b.symptoms,
            description: b.description,
            biologicalStage: b.biologicalStage,
            medications: b.medications,
          });
          convertedBiomarkers.push({
            name: b.name,
            type: b.type,
            tabs,
          });
        }
      });
      setBiomarkers(convertedBiomarkers.sort((a, b) => a.name.localeCompare(b.name)));
      setDisplayingNumber(convertedBiomarkers.length);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    setDisplayingNumber(visibleBiomarkers.length);
  }, [visibleBiomarkers]);

  return (
    <div style={{ paddingInline: 16 }}>
      <h4>Biomarker / Symptoms and Goals Management</h4>
      <Space style={{ marginBottom: 32 }} align={'end'}>
        <div>
          <div>
            <Typography.Text type={'secondary'}>Gender:</Typography.Text>
          </div>
          <Select
            defaultValue="unisex"
            onChange={(value) => setBiomarkerFilter({ ...biomarkerFilter, gender: value, biologicalStage: '' })}
            style={{ width: '120px' }}
            options={[
              {
                value: 'unisex',
                label: 'All',
              },
              {
                value: 'male',
                label: 'Male',
              },
              {
                value: 'female',
                label: 'Female',
              },
            ]}
          />
        </div>

        {biomarkerFilter.gender === 'female' && (
          <div>
            <div>
              <Typography.Text type={'secondary'}>Female Biological Stage:</Typography.Text>
            </div>
            <Select
              defaultValue=""
              onChange={(value) => setBiomarkerFilter({ ...biomarkerFilter, biologicalStage: value })}
              style={{ width: '200px' }}
              options={[
                {
                  value: '',
                  label: 'Not Specified / All',
                },
                {
                  value: 'menopause',
                  label: 'Menopause',
                },
                {
                  value: 'luteal',
                  label: 'Luteal',
                },
              ]}
            />
          </div>
        )}
        <div>
          <div>
            <Typography.Text type={'secondary'}>Type:</Typography.Text>
          </div>
          <Select
            defaultValue="all"
            style={{ width: '240px' }}
            onChange={(value) => setBiomarkerFilter({ ...biomarkerFilter, type: value })}
            options={[
              {
                value: 'all',
                label: 'All',
              },
              {
                value: 'General Wellness',
                label: 'General Wellness',
              },
              {
                value: 'Hormones',
                label: 'Hormones',
              },
              {
                value: 'Cardiovascular & Inflammation',
                label: 'Cardiovascular & Inflammation',
              },
              {
                value: 'Thyroid Health',
                label: 'Thyroid Health',
              },
              {
                value: 'Symptoms and Goals',
                label: 'Symptoms and Goals',
              },
            ]}
          />
        </div>
        {displayingNumber !== null && (
          <Typography.Title level={5} type={'secondary'}>
            Displaying {displayingNumber} / {biomarkers.length}
          </Typography.Title>
        )}
        <Divider type="vertical" />
        <div>
          <div>
            <Typography.Text type={'secondary'}>Medicine Filter:</Typography.Text>
          </div>
          <Space>
            <Tooltip title="Only recommended medicines can appear in the treatment dropdown when creating treatment plan">
              <Checkbox onChange={(e) => setMedicineFilter({ ...medicineFilter, recommendOnly: e.target.checked })}>
                Recommended Only
              </Checkbox>
            </Tooltip>
            <Tooltip title="Default medicine will be automatically selected when creating treatment plan for this biomarker">
              <Checkbox onChange={(e) => setMedicineFilter({ ...medicineFilter, defaultOnly: e.target.checked })}>
                Default Only
              </Checkbox>
            </Tooltip>
          </Space>
        </div>
      </Space>

      <div style={{ height: window.innerHeight - 200, overflowY: 'auto', overflowX: 'hidden' }}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 2,
            xxl: 2,
          }}
          dataSource={visibleBiomarkers}
          loading={loading}
          renderItem={(biomarker) => (
            <List.Item key={biomarker.name}>
              <Card
                title={biomarker.name}
                extra={
                  <Button type="link" onClick={() => setModifyingBiomarker(biomarker)}>
                    Modify
                  </Button>
                }
              >
                <Tabs
                  tabBarExtraContent={{ right: <Tag color="default">{biomarker.type}</Tag> }}
                  defaultActiveKey="1"
                  items={biomarker.tabs.map((biomarker, i) => {
                    let tabHeader;
                    const maleTabHeader = <Badge count={'Male'} color={'rgb(45, 183, 245)'} />;
                    const femaleTabHeader = <Badge count={'Female'} color={'#f50'} />;
                    const femaleMenopauseTabHeader = <Badge count={'Female - Menopause'} color={'#f50'} />;
                    const femaleLutealTabHeader = <Badge count={'Female - Luteal'} color={'#f50'} />;

                    if (biomarker.gender === 'male') {
                      tabHeader = maleTabHeader;
                    } else if (biomarker.gender === 'unisex') {
                      tabHeader = (
                        <>
                          {femaleTabHeader} & {maleTabHeader}
                        </>
                      );
                    } else {
                      if (biomarker.biologicalStage) {
                        if (biomarker.biologicalStage === 'menopause') {
                          tabHeader = femaleMenopauseTabHeader;
                        } else {
                          tabHeader = femaleLutealTabHeader;
                        }
                      } else {
                        tabHeader = femaleTabHeader;
                      }
                    }

                    const content = (
                      <Row gutter={8}>
                        <Col span={12}>
                          <Row gutter={16}>
                            <Col span={12}>
                              <div>
                                <Typography.Text type={'secondary'}>Range</Typography.Text>
                              </div>
                              <div>
                                <Typography.Text strong>{biomarker.range}</Typography.Text>
                              </div>
                            </Col>
                            {biomarker.gender === 'female' && (
                              <Col span={12}>
                                <div>
                                  <Typography.Text type={'secondary'}>Biological Stage</Typography.Text>
                                </div>
                                <div>
                                  <Typography.Text strong>{biomarker.biologicalStage}</Typography.Text>
                                </div>
                              </Col>
                            )}
                          </Row>
                          <Divider orientation="left">
                            <Typography.Text type={'secondary'}>Symptoms</Typography.Text>
                          </Divider>
                          <ExpandableText text={biomarker.symptoms} maxChars={100}></ExpandableText>
                          <Divider orientation="left">
                            <Typography.Text type={'secondary'}>Description</Typography.Text>
                          </Divider>
                          <ExpandableText text={biomarker.description} maxChars={100}></ExpandableText>
                        </Col>
                        <Col span={12}>
                          <Collapse
                            size="small"
                            items={[
                              {
                                key: 'medicine',
                                label: `Associated Medicines (${
                                  biomarker.medications.filter(filterMedicines).length
                                } / ${biomarker.medications.length})`,
                                children: (
                                  <div style={{ height: 200, overflowY: 'auto' }}>
                                    <List
                                      itemLayout="vertical"
                                      dataSource={biomarker.medications.filter(filterMedicines)}
                                      size="small"
                                      renderItem={(medicine, i) => (
                                        <List.Item key={i}>
                                          <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                              <div style={{ marginRight: 12, wordBreak: 'break-all' }}>
                                                {medicine.product.name}
                                              </div>
                                              <div>
                                                <Typography.Text type={'secondary'}>
                                                  {convertLogicToString(medicine.when || {})}
                                                </Typography.Text>
                                              </div>
                                            </div>

                                            <div style={{ minWidth: 70 }}>
                                              <Tag color={medicine.recommend ? 'warning' : 'default'} title="Recommend">
                                                R
                                              </Tag>
                                              <Tag color={medicine.isDefault ? 'warning' : 'default'} title="Default">
                                                D
                                              </Tag>
                                            </div>
                                          </div>
                                        </List.Item>
                                      )}
                                    />
                                  </div>
                                ),
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                    );

                    return {
                      key: i,
                      label: tabHeader,
                      children: content,
                    };
                  })}
                />
              </Card>
            </List.Item>
          )}
        />
      </div>
      {modifyingBiomarker && (
        <ModifyBiomarkerModal
          open={!!modifyingBiomarker}
          biomarker={modifyingBiomarker}
          onClose={() => setModifyingBiomarker(null)}
          biomarkerList={biomarkers.flatMap((b) => b.tabs.map((bt) => ({ name: b.name, gender: bt.gender })))}
          onSubmit={(newTabData) => {
            const thisBiomarker = biomarkers.find((b) => b.name === modifyingBiomarker.name);
            const modifiedTabIndex = thisBiomarker.tabs.findIndex((t) => t._id === newTabData._id);
            thisBiomarker.tabs[modifiedTabIndex] = newTabData;
            setBiomarkers([...biomarkers]);
            setModifyingBiomarker(null);
          }}
        />
      )}
    </div>
  );
};

export default BiomarkerManagementPage;
