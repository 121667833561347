import { Link } from 'react-router-dom';
import { DatePicker, Dropdown, Modal, Space, Tag, Typography } from 'antd';
import { ArrowRightOutlined, DownOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import DataTable from '../../components/data-table/data-table.component';
import bioApi from '../../api/bioApi';
import { OPPORTUNITY_URL } from '../../api/URLs';
import moment from 'moment';
import { CreateRefillOpportunityModal } from './component/create-refill-opportunity.modal';
import dayjs from 'dayjs';

export const OpportunitiesPage = () => {
  const [followUpDateRequest, setFollowUpDateRequest] = React.useState({
    opportunity: null,
    toStatus: null,
    followUpDate: null,
  });
  const [loading, setLoading] = React.useState(false);
  const [opportunities, setOpportunities] = React.useState([]);

  const init = async () => {
    setLoading(true);
    const opportunitiesReq = await bioApi.get(OPPORTUNITY_URL);
    setOpportunities(opportunitiesReq.data.map((op, index) => ({ ...op, key: index })));
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const changeStatus = async (toStatus, opportunity, followUpDate) => {
    setLoading(true);
    await bioApi.post(`${OPPORTUNITY_URL}/${opportunity._id}/status`, {
      status: toStatus,
      followUpDate: followUpDate || null,
    });
    opportunity.status = toStatus;
    opportunity.followUpDate = followUpDate ? dayjs(followUpDate).toDate() : null;
    if (followUpDate) {
      opportunities.forEach((patient) => {
        patient.opportunities.forEach((op) => {
          if (op._id === opportunity._id) {
            if (dayjs(patient.nearestFollowUpDate).isAfter(dayjs(followUpDate))) {
              patient.nearestFollowUpDate = dayjs(followUpDate).format('YYYY-MM-DD');
            }
          }
        });
      });
    }
    setOpportunities([...opportunities]);
    setLoading(false);
  };

  const availableDestinationStatus = [
    {
      status: 'Open',
      element: (opportunity) => ({
        label: (
          <a
            onClick={async () => {
              setFollowUpDateRequest({
                opportunity,
                toStatus: 'Open',
              });
            }}
          >
            <Tag>{opportunity.status}</Tag>
            <ArrowRightOutlined style={{ marginRight: 8 }} />
            <Tag>Open</Tag>
          </a>
        ),
        key: 'Open',
      }),
    },
    {
      status: 'No Answer',
      element: (opportunity) => ({
        label: (
          <a
            onClick={() =>
              setFollowUpDateRequest({
                opportunity,
                toStatus: 'No Answer',
              })
            }
          >
            <Tag>{opportunity.status}</Tag>
            <ArrowRightOutlined style={{ marginRight: 8 }} />
            <Tag color="warning">No Answer</Tag>
          </a>
        ),
        key: 'No Answer',
      }),
    },
    {
      status: 'Considering',
      element: (opportunity) => ({
        label: (
          <a
            onClick={() =>
              setFollowUpDateRequest({
                opportunity,
                toStatus: 'Considering',
              })
            }
          >
            <Tag>{opportunity.status}</Tag>
            <ArrowRightOutlined style={{ marginRight: 8 }} />
            <Tag color="gold">Considering</Tag>
          </a>
        ),
        key: 'Considering',
      }),
    },
    {
      status: 'Declined',
      element: (opportunity) => ({
        label: (
          <a onClick={() => changeStatus('Declined', opportunity)}>
            <Tag>{opportunity.status}</Tag>
            <ArrowRightOutlined style={{ marginRight: 8 }} />
            <Tag color="error">Declined</Tag>
          </a>
        ),
        key: 'Declined',
      }),
    },
    {
      status: 'Order Confirmed',
      element: (opportunity) => ({
        label: (
          <a onClick={() => changeStatus('Order Confirmed', opportunity)}>
            <Tag>{opportunity.status}</Tag>
            <ArrowRightOutlined style={{ marginRight: 8 }} />
            <Tag color="processing">Order Confirmed</Tag>
          </a>
        ),
        key: 'Order Confirmed',
      }),
    },
    {
      key: 'Order Paid',
      element: (opportunity) => ({
        label: (
          <a onClick={() => changeStatus('Order Paid', opportunity)}>
            <Tag>{opportunity.status}</Tag>
            <ArrowRightOutlined style={{ marginRight: 8 }} />
            <Tag color="cyan">Order Paid</Tag>
          </a>
        ),
        key: 'Order Paid',
      }),
    },
    {
      key: 'Order Placed',
      element: (opportunity) => ({
        label: (
          <a onClick={() => changeStatus('Order Placed', opportunity)}>
            <Tag>{opportunity.status}</Tag>
            <ArrowRightOutlined style={{ marginRight: 8 }} />
            <Tag color="success">Order Placed</Tag>
          </a>
        ),
        key: 'Order Placed',
      }),
    },
  ];

  const innerTableSetting = {
    bordered: false,
    numbered: true,
    size: 'small',
    pagination: false,
    columns: [
      {
        title: (
          <span>
            Treatment
            <Typography.Text type={'secondary'} style={{ marginLeft: 4 }}>
              🛎️current
            </Typography.Text>
          </span>
        ),
        dataIndex: ['treatment', 'name'],
        render: (text, record) =>
          record.treatment.isCurrent ? (
            <Typography.Text>
              <Space>🛎{text}</Space>
            </Typography.Text>
          ) : (
            <>{text}</>
          ),
      },
      {
        title: 'Opportunity Type',
        dataIndex: 'type',
      },
      {
        title: 'Creation Date',
        dataIndex: 'creationTS',
        render: (text) => moment(text).format('YYYY-MM-DD'),
      },
      {
        title: 'Follow Up Date',
        dataIndex: 'followUpDate',
        render: (text) => (text ? moment(text).format('YYYY-MM-DD') : text),
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
    ],
    customActions: [
      {
        element: (record, data) => {
          const status = record.status || '';

          return (
            <Dropdown
              disabled={['Order Placed', 'Declined'].includes(status)}
              menu={{
                items: availableDestinationStatus.filter((s) => s.status !== status).map((s) => s.element(record)),
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Change Status
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          );
        },
      },
    ],
  };

  const outerTableSetting = {
    bordered: false,
    size: 'middle',
    pagination: {
      showLessItems: true,
      showSizeChanger: false,
      pageSize: 30,
    },
    columns: [
      {
        title: 'Patient',
        dataIndex: ['patient', 'name'],
        render: (text, record) => <Link to={`/patient/${record.patient.patientId}`}>{text}</Link>,
      },
      {
        title: 'Last Opportunity Type',
        render: (text, record) => record.opportunities[0].type,
      },
      {
        title: 'Earliest Follow Up Date (Based On Non-Closed Opportunities)',
        dataIndex: 'nearestFollowUpDate',
        render: (text, record) => (text ? moment(text).format('YYYY-MM-DD') : 'No Follow Ups'),
        sorter: (a, b) => {
          const aDate = a.nearestFollowUpDate
            ? moment(a.nearestFollowUpDate).toDate().getTime()
            : Number.MAX_SAFE_INTEGER;
          const bDate = b.nearestFollowUpDate
            ? moment(b.nearestFollowUpDate).toDate().getTime()
            : Number.MAX_SAFE_INTEGER;
          return aDate - bDate;
        },
        defaultSortOrder: 'ascend',
      },
    ],
    expandable: {
      expandedRowRender: (record) => <DataTable data={record.opportunities} settings={innerTableSetting} />,
    },
  };

  return (
    <div style={{ marginInline: 12 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h4>Opportunities</h4>
        <CreateRefillOpportunityModal allPatients={opportunities} afterCreate={init} />
      </div>
      <DataTable data={opportunities} settings={outerTableSetting} loading={loading} />
      <Modal
        width={280}
        open={!!followUpDateRequest.opportunity}
        title={'Choose Follow Up Date'}
        onCancel={() => setFollowUpDateRequest({ opportunity: null, followUpDate: null, toStatus: null })}
        onOk={async () => {
          await changeStatus(
            followUpDateRequest.toStatus,
            followUpDateRequest.opportunity,
            followUpDateRequest.followUpDate
          );
          setFollowUpDateRequest({ opportunity: null, followUpDate: null, toStatus: null });
        }}
        confirmLoading={loading}
      >
        <DatePicker
          style={{ width: 230 }}
          minDate={dayjs()}
          value={followUpDateRequest.followUpDate ? dayjs(followUpDateRequest.followUpDate) : null}
          onChange={(date) => setFollowUpDateRequest({ ...followUpDateRequest, followUpDate: date })}
          placeholder={'Follow Up Date'}
        />
      </Modal>
    </div>
  );
};
