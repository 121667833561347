import { MESSAGE_URL } from '../../api/URLs';
import bioApi from '../../api/bioApi';

export const loadMessages = async (appuserId) => {
  return bioApi.get(MESSAGE_URL + '/' + appuserId + '/list');
};

export const replyMessage = async (appuserId, message) => {
  return bioApi.post(MESSAGE_URL + '/' + appuserId, { content: message });
};

export const markRead = async (appuserId) => {
  return bioApi.post(MESSAGE_URL + '/' + appuserId + '/markRead');
};

export const markUnread = async (appuserId) => {
  return bioApi.post(MESSAGE_URL + '/' + appuserId + '/markUnread');
};
