import React, { useState } from 'react';
import { Button } from 'antd';

const ExpandableText = ({ title, text, maxChars, style }) => {
  const [expanded, setExpanded] = useState(false);

  const truncatedText = !!text && text.length > maxChars ? text.slice(0, maxChars) + '...' : text;

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      {title && <h2>{title}</h2>}
      <p style={style}>
        <span>{expanded ? text : truncatedText}</span>
        {!!text && text.length > maxChars && (
          <Button size={'small'} type={'link'} onClick={handleToggle}>
            {expanded ? 'Show Less' : 'Show More'}
          </Button>
        )}
      </p>
    </div>
  );
};

export default ExpandableText;
