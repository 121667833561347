import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Avatar, Col, Row, Spin, Statistic } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentAppuser } from '../../stores/current-appuser.store';
import { getAppuserInfoById } from './service';

const ProspectPatientPage = () => {
  const [userSummary, setUserSummary] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { prospectPatientId } = useParams();
  const currentAppuser = useSelector((state) => state.currentAppuser);

  useEffect(() => {
    (async () => {
      console.log(currentAppuser);
      if (!currentAppuser || currentAppuser.appuserId !== prospectPatientId) {
        try {
          const appuserDataRequest = await getAppuserInfoById(prospectPatientId);
          dispatch(
            setCurrentAppuser({
              ...appuserDataRequest.data.patient,
            })
          );
          console.log(appuserDataRequest.data.patient);
        } catch (error) {
          console.log(error);
        }
      } else {
        setLoading(false);
        setUserSummary([
          {
            key: 'Type',
            value: currentAppuser.status.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
          },
          {
            key: 'Phone',
            value: currentAppuser.phone.countryCode && currentAppuser.phone.number  ? `+${currentAppuser.phone.countryCode} ${currentAppuser.phone.number}` : '-' ,
          },
          {
            key: 'Email',
            value: currentAppuser.email,
          },
          {
            key: 'Gender',
            value: currentAppuser.gender.charAt(0).toUpperCase() + currentAppuser.gender.slice(1) ,
          },
          {
            key: 'Date of Birth',
            value: currentAppuser.dob,
          },
          // {
          //     key: 'Most Recent Service Date',
          //     value: '3/23/2022'
          // },
          // {
          //     key: 'Membership',
          //     value: currentAppuser.rewardStatus
          // }
        ]);
      }
    })();
  }, [currentAppuser]);

  return (
    <>
      {!loading ? (
        <>
          <Outlet /> :
          <div
            style={{
              background: '#F0F2F5 0% 0% no-repeat padding-box',
              padding: 32,
            }}
          >
            <h2>Prospect Patients</h2>
            <Row
              align="middle"
              style={{
                height: 127,
                background: 'white',
                padding: '0 12px',
                border: '1px solid white',
                borderRadius: 10,
              }}
              gutter={12}
            >
              <Col span={2} style={{ textAlign: 'center' }}>
                <Avatar size={87} src={currentAppuser.image} />
              </Col>
              <Col span={22} style={{ textAlign: 'center' }}>
                <Row>
                  <h4>
                    {currentAppuser.firstName}&nbsp;{currentAppuser.lastName}
                  </h4>
                </Row>
                <Row align="middle" justify="space-between">
                  {userSummary.map((summary, i) => (
                    <Statistic
                      title={<div style={{ textAlign: 'left' }}>{summary.key}</div>}
                      value={summary.value}
                      formatter={(value) => value || ''}
                      valueStyle={{ fontSize: 14, textAlign: 'left' }}
                      key={i}
                    />
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <div className="page-loader">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default ProspectPatientPage;
