export const convertLogicToString = (logic) => {
  const logicArray = [];
  Object.entries(logic).forEach(([comparator, value]) => {
    if (comparator !== 'biologicalStage') {
      logicArray.push(`${comparator} ${value}`);
    } else {
      logicArray.push(`${value}`);
    }
  });

  return logicArray.join(` AND `);
};
