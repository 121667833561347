import { Descriptions, Empty } from 'antd';
import React from 'react';
import Table from 'react-bootstrap/Table';

const MedicalNoteDisplay = ({ medicalNote, gender }) => {
  return (
    <div>
      <Descriptions bordered title="Chief Complaint(s)">
        <Descriptions.Item label="HPI" span={24}>
          {medicalNote.age} years old {gender}
          <br />
          {medicalNote.complains}
        </Descriptions.Item>
        <Descriptions.Item label="Labs being reviewed were drawn on">
          {medicalNote.lab_collection_date}
        </Descriptions.Item>
        <Descriptions.Item label="Labs were completed">
          {gender === 'Male' ? (
            <>{medicalNote.day_number} days post injection</>
          ) : (
            <>on day {medicalNote.day_number} of cycle</>
          )}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title={'General Health'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item label="Sleep">{medicalNote.sleep}</Descriptions.Item>
        <Descriptions.Item label="Energy">{medicalNote.energy}</Descriptions.Item>
        <Descriptions.Item label="Stress">{medicalNote.stress}</Descriptions.Item>
        <Descriptions.Item label="Libido">{medicalNote.libido}</Descriptions.Item>
        <Descriptions.Item label="Exercise">{medicalNote.exercise}</Descriptions.Item>
        <Descriptions.Item label="Diet">{medicalNote.diet}</Descriptions.Item>
        <Descriptions.Item label="Gut health">{medicalNote.gut_health}</Descriptions.Item>
        <Descriptions.Item label="Cognitive function">{medicalNote.cognitive_function}</Descriptions.Item>
        <Descriptions.Item label="Weight management">{medicalNote.weight_management}</Descriptions.Item>
      </Descriptions>
      <Descriptions title={`Sexual Health (${gender})`} bordered style={{ marginTop: 16 }}>
        {gender === 'Male' ? (
          <>
            <Descriptions.Item label={'Hx of TRT/Anabolic steroid use'}>{medicalNote.steroid_use}</Descriptions.Item>
            <Descriptions.Item label={'ED'}>{medicalNote.ed}</Descriptions.Item>
            <Descriptions.Item label={'STI'}>{medicalNote.sti}</Descriptions.Item>
            <Descriptions.Item label={'Last Colonoscopy'}>{medicalNote.last_colonoscopy}</Descriptions.Item>
            <Descriptions.Item label={'Last Prostate Exam'}>{medicalNote.last_prostate_exam}</Descriptions.Item>
          </>
        ) : (
          <>
            <Descriptions.Item label={'GP'} name={['gp']}>
              {medicalNote.gp}
            </Descriptions.Item>
            <Descriptions.Item label={'Menstrual Cycle'} name={['menstrual_cycle']}>
              {medicalNote.menstrual_cycle}
            </Descriptions.Item>
            <Descriptions.Item label={'LMP'} name={['lmp']}>
              {medicalNote.lmp}
            </Descriptions.Item>
            <Descriptions.Item label={'PMS'} name={['pms']}>
              {medicalNote.pms}
            </Descriptions.Item>
            <Descriptions.Item label={'Birth Control'} name={['birth_control']}>
              {medicalNote.birth_control}
            </Descriptions.Item>
            <Descriptions.Item label={'HX of Fibroids'} name={['hx_of_fibroids']}>
              {medicalNote.hx_of_fibroids}
            </Descriptions.Item>
            <Descriptions.Item label={'HX of Endometriosis'} name={['hx_of_endometriosis']}>
              {medicalNote.hx_of_endometriosis}
            </Descriptions.Item>
            <Descriptions.Item label={'STIs'} name={['sti']}>
              {medicalNote.sti}
            </Descriptions.Item>
            <Descriptions.Item label={'Hormonal imbalance symptoms'} name={'hormonal_imbalance_symptoms'}>
              {medicalNote.hormonal_imbalance_symptoms}
            </Descriptions.Item>
            <Descriptions.Item label={'Last Pap Smear'} name={'last_pap_smear'}>
              {medicalNote.last_pap_smear}
            </Descriptions.Item>
            <Descriptions.Item label={'Last Mammogram'} name={'last_mammogram'}>
              {medicalNote.last_mammogram}
            </Descriptions.Item>
            <Descriptions.Item label={'Last Bone Density'} name={'last_bone_density'}>
              {medicalNote.last_bone_density}
            </Descriptions.Item>
            <Descriptions.Item label={'Last Colonoscopy'} name={'last_colonoscopy'}>
              {medicalNote.last_colonoscopy}
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
      <Descriptions title={'Allergies'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item label={'Overall Allergy'}>{medicalNote.overall_allergy}</Descriptions.Item>
      </Descriptions>
      <Descriptions title={'Outside Products'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item label={'Outside Medications'}>{medicalNote.outside_medications}</Descriptions.Item>
        <Descriptions.Item label={'Outside Supplements'}>{medicalNote.outside_supplements}</Descriptions.Item>
      </Descriptions>
      <Descriptions title={'Medical History'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item label={'Family history'} name={'family_history'}>
          {medicalNote.family_history}
        </Descriptions.Item>
        <Descriptions.Item label={'Past Medical History'} name={'past_medical_history'}>
          {medicalNote.past_medical_history}
        </Descriptions.Item>
        <Descriptions.Item label={'Past Surgeries/Hospitalizations'} name={'past_surgeries'}>
          {medicalNote.past_surgeries}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title={'Social History'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item label={'ETOH'}>{medicalNote.etoh}</Descriptions.Item>
        <Descriptions.Item label={'Tobacco'}>{medicalNote.tobacco}</Descriptions.Item>
        <Descriptions.Item label={'RD'}>{medicalNote.rd}</Descriptions.Item>
        <Descriptions.Item label={'Occupation'}>{medicalNote.occupation}</Descriptions.Item>
        <Descriptions.Item label={'Marital Status'}>{medicalNote.marital_status}</Descriptions.Item>
        <Descriptions.Item label={'Children'}>{medicalNote.children}</Descriptions.Item>
        <Descriptions.Item label={'Heritage'}>{medicalNote.heritage}</Descriptions.Item>
      </Descriptions>
      <Descriptions title={'ROS'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item label={'Constitution'}>{medicalNote.constitution}</Descriptions.Item>
        <Descriptions.Item label={'HEENT'}>{medicalNote.heent}</Descriptions.Item>
        <Descriptions.Item label={'CV'}>{medicalNote.cv}</Descriptions.Item>
        <Descriptions.Item label={'Resp'}>{medicalNote.resp}</Descriptions.Item>
        <Descriptions.Item label={'GI'}>{medicalNote.gi}</Descriptions.Item>
        <Descriptions.Item label={'MSK'}>{medicalNote.msk}</Descriptions.Item>
        <Descriptions.Item label={'Neuro'}>{medicalNote.neuro}</Descriptions.Item>
        <Descriptions.Item label={'Derm'}>{medicalNote.derm}</Descriptions.Item>
        <Descriptions.Item label={'Endo'}>{medicalNote.endo}</Descriptions.Item>
        <Descriptions.Item label={'Hemat'}>{medicalNote.hemat}</Descriptions.Item>
        <Descriptions.Item label={'Psych'}>{medicalNote.psych}</Descriptions.Item>
        <Descriptions.Item label={'GU'}>{medicalNote.gu}</Descriptions.Item>
        <Descriptions.Item label={'Lymph'}>{medicalNote.lymph}</Descriptions.Item>
        <Descriptions.Item label={'Allergy'}>{medicalNote.allergy}</Descriptions.Item>
        <Descriptions.Item label={'Sexual Health'}>{medicalNote.sexual_health}</Descriptions.Item>
      </Descriptions>
      <Descriptions title={'Physical'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item labelStyle={{ display: 'none' }}>{medicalNote.physical}</Descriptions.Item>
      </Descriptions>
      <Descriptions title={'AREAS OF HEALTH CONCERN'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item labelStyle={{ display: 'none' }}>{medicalNote.health_concern.join(', ')}</Descriptions.Item>
      </Descriptions>
      <Descriptions title={'LAB REVIEW'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item labelStyle={{ display: 'none' }}>
          {!medicalNote.lab_review || !medicalNote.lab_review.length ? (
            <Empty description={'No lab result was found'} />
          ) : (
            <Table bordered>
              <thead>
                <tr>
                  <th></th>
                  <th>Biomarker</th>
                  <th>Lab Data</th>
                  <th>Optimal Range</th>
                </tr>
              </thead>
              <tbody>
                {[
                  ...medicalNote.lab_review.reduce(
                    (entryMap, e) => entryMap.set(e.type, [...(entryMap.get(e.type) || []), e]),
                    new Map()
                  ),
                ].map(([type, value], i) =>
                  value.map((r, ii) => (
                    <tr key={ii}>
                      {ii === 0 && (
                        <td
                          rowSpan={value.length}
                          style={{
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            fontWeight: 'bold',
                          }}
                        >
                          {type}
                        </td>
                      )}
                      <td>{r.biomarker}</td>
                      <td>{r.labData}</td>
                      <td>{r.optimalRange}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          )}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title={'RECOMMENDATIONS'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item labelStyle={{ display: 'none' }}>{medicalNote.recommendations}</Descriptions.Item>
      </Descriptions>
      <Descriptions title={'OTHER'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item labelStyle={{ display: 'none' }}>{medicalNote.other}</Descriptions.Item>
      </Descriptions>
      <Descriptions title={'Specialty Testing'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item labelStyle={{ display: 'none' }}>{medicalNote.specialty_testing}</Descriptions.Item>
      </Descriptions>
      <Descriptions title={'Follow up'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item labelStyle={{ display: 'none' }}>{medicalNote.follow_up}</Descriptions.Item>
      </Descriptions>
      <Descriptions title={'Notes'} bordered style={{ marginTop: 16 }}>
        <Descriptions.Item labelStyle={{ display: 'none' }}>{medicalNote.notes}</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default MedicalNoteDisplay;
