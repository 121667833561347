import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { changePatientAdvocateGroup, getOrders, getPatientAdvocateGroup } from '../service';
import DataTable from '../../../components/data-table/data-table.component';
import { Avatar, Badge, Button, List, Tooltip, Transfer } from 'antd';
import moment from 'moment';
import { CheckCircleFilled, ClockCircleOutlined } from '@ant-design/icons';

const PatientAdvocateGroupPage = () => {
  const currentPatient = useSelector((state) => state.currentPatient);

  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [allPatientAdvocateStaff, setAllPatientAdvocateStaff] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [accountOwnerId, setAccountOwnerId] = useState('');

  const onChange = async (nextTargetKeys) => {
    setSending(true);
    await changePatientAdvocateGroup(
      currentPatient.patientId,
      nextTargetKeys.filter((k) => k !== accountOwnerId)
    );
    setTargetKeys(nextTargetKeys);
    setSending(false);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const filterOption = (inputValue, option) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  useEffect(() => {
    (async () => {
      const patientAdvocateGroup = await getPatientAdvocateGroup(currentPatient.patientId);

      const ownerKey = [];
      if (patientAdvocateGroup.data.accountOwner) {
        setAccountOwnerId(patientAdvocateGroup.data.accountOwner.key);
        ownerKey.push(patientAdvocateGroup.data.accountOwner.key);
      }

      setTargetKeys([...ownerKey, ...patientAdvocateGroup.data.assignedPA.map((pa) => pa.key)]);

      const ownerData = [];

      if (ownerKey.length) {
        const isOwnerPA = !!patientAdvocateGroup.data.allPAs.find((pa) => pa.key === ownerKey[0]);
        if (!isOwnerPA) {
          ownerData.push({
            key: patientAdvocateGroup.data.accountOwner.key,
            title: patientAdvocateGroup.data.accountOwner.name,
            coverImage: patientAdvocateGroup.data.accountOwner.coverImage,
            staffId: patientAdvocateGroup.data.accountOwner.staffId,
            disabled: true,
          });
        }
      }

      const allPAs = patientAdvocateGroup.data.allPAs.map((pa, i) => ({
        key: pa.key,
        title: pa.name,
        coverImage: pa.coverImage,
        staffId: pa.staffId,
        disabled: ownerKey.includes(pa.key),
      }));

      setAllPatientAdvocateStaff([...ownerData, ...allPAs]);
      setLoading(false);
    })();
  }, []);

  return (
    <List itemLayout="horizontal" loading={loading}>
      <Transfer
        dataSource={allPatientAdvocateStaff}
        titles={[
          'Patient Advocate List',
          `Assigned To ${currentPatient.profile.firstName} ${currentPatient.profile.lastName}`,
        ]}
        disabled={sending}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        filterOption={filterOption}
        showSearch
        onChange={onChange}
        onSelectChange={onSelectChange}
        render={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Badge
                  count={targetKeys.includes(item.key) ? <CheckCircleFilled style={{ color: '#108ee9' }} /> : <></>}
                >
                  <Avatar src={item.coverImage} />
                </Badge>
              }
              title={item.title}
              description={item.disabled ? 'Account Owner' : 'Patient Advocate'}
            />
          </List.Item>
        )}
        listStyle={{
          width: '100%',
          height: window.innerHeight - 200,
        }}
        operations={['Assign To Patient', 'Remove from Patient']}
      />
    </List>
  );
};

export default PatientAdvocateGroupPage;
