import React, { useEffect, useState } from 'react';
import { Col, Modal, notification, Row, Spin } from 'antd';
import TableComponent from '../../../components/table/TableComponent';
import { getAllDataGet } from '../service';
import { ORDERS_PHARMACIES_DETAIL_URL } from '../../../api/URLs';

const OrderNumberDetailModal = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(false);
  const [dataObject, setDataObject] = useState({
    "pharmacie": "CRE8",
    "patientId": "",
    "orderId": "",
    "billingNotes": "",
    "providerId": "",
    "methodOfShipping": "",
    "shippingAddress": {
      "street": "",
      "city": "",
      "state": "",
      "countryCode": "",
      "zip": ""
    },
    shippingAddressFull:"",
  });
  const [dataSource, setDataSource] = useState([/*prescriptionOrder */])

  const getOrderAll = async (orderID) => {
    const result = await getAllDataGet(`${ORDERS_PHARMACIES_DETAIL_URL}/${orderID}`);
    if (result.status === 200 && result.data) {
      hanldeImport(result.data)
    } else {
      notification.error({ message: `Failed!`, duration: 10 });
      hanldeChangePatient()
    }
  }

  const getRequestAll = async () => {
    setLoading(true)
    if(open && open.orderId ) {
      await getOrderAll(open.orderId);
    }
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }
  useEffect(() => {
    getRequestAll()
  }, []) 

  const hanldeImport = (record) => {
    setDataObject({
      ...dataObject,
      "pharmacie": record.pharmacie || "CRE8",
      "status": record.status || '',
      "patientId": record.patientName || '',
      "orderId": record.orderId || '',
      "orderReference": record.orderReference || '',
      "billingNotes": record.billingNotes || '',
      "providerId": `${record.providerId && record.providerId.firstName || ""} ${record.providerId && record.providerId.lastName || ""}`,
      "methodOfShipping": record.methodOfShipping ? record.methodOfShipping.replace(/_/g, ' ') : "", // UPS_Ground; UPS_Second_Day_Air
      "shippingAddress": {
        "street": record.shippingAddress.street || "",
        "city": record.shippingAddress.city || "",
        "state": record.shippingAddress.state || "",
        "countryCode": record.shippingAddress.countryCode || "",
        "zip": record.shippingAddress.zip || "",
      },
      "shippingAddressFull": `${record.shippingAddress.street || ""}, ${record.shippingAddress.city || ""}, ${record.shippingAddress.state || ""} ${record.shippingAddress.zip || ""}${record.shippingAddress.countryCode ? `, ${record.shippingAddress.countryCode}` : ""}`,
    })
    setDataSource([])
    if(record.prescriptions) {
      let prescriptionOrder = record.prescriptions
      setDataSource([...prescriptionOrder.map(it => ({
        ...it,
        "quantity": it.quantity,
        "refill": it.refills,
        "dosage": it.sigDirections,
        "note": it.notes,
        productId: it.itemId,
        product: it.itemId && it.itemId.name || "",
      }))])
    }
  }

  const hanldeChangePatient = (value) => {
    setDataObject({
      "pharmacie": "CRE8",
      "patientId": "",
      "orderId": '',
      "billingNotes": "",
      "providerId": "",
      "methodOfShipping": "",
      "shippingAddress": {
        "street": "",
        "city": "",
        "state": "",
        "countryCode": "",
        "zip": "",
      },
      "shippingAddressFull": "",
    })
    setDataSource([])
  }


  return (
    <>
      <Modal
        title={<>
          <div className='text-center' style={{ fontSize: "1.3rem" }}>Detail CRE8 Order Management</div>
        </>}
        open={open}
        cancelText={"Cancel"}
        okText={"Submit"}
        onCancel={() => setOpen(false)}
        maskClosable={false}
        keyboard={false} 

        width={900}
        footer={[
          <div className='justify-content-around d-flex pt-2'>
          </div>
        ]}
      >
        <Spin spinning={loading}>

          <Row>
            <Col span={18}>
            </Col>
            <Col span={6} className='text-end'>
            </Col>
          </Row>

          <div className='w-100'>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''>Patient: </label>
                </div>
              </Col>
              <Col span={18}>
                <div>
                  <label htmlFor=''>{dataObject.patientId || ""}</label>
                </div>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''>Order ID: </label>
                </div>
              </Col>
              <Col span={18}>
                <div>
                  <label htmlFor=''>{dataObject.orderId || ""}</label>
                </div>              
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''>Referent Order ID: </label>
                </div>
              </Col>
              <Col span={18}>
                <div>
                  <label htmlFor=''>{dataObject.orderReference || ""}</label>
                </div>              
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''>Status: </label>
                </div>
              </Col>
              <Col span={18}>
                <div>
                  <label htmlFor=''>{dataObject.status || ""}</label>
                </div>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''>Provider: </label>
                </div>
              </Col>
              <Col span={18}>
                <div>
                  <label htmlFor=''>{dataObject.providerId || ""}</label>
                </div>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''>Method Of Shipping: </label>
                </div>
              </Col>
              <Col span={18}>
                <div>
                  <label htmlFor=''>{dataObject.methodOfShipping || ""}</label>
                </div>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''>Shipping Address: </label>
                </div>
              </Col>
              <Col span={18}>
                <div>
                  <label htmlFor=''>{dataObject.shippingAddressFull || ""}</label>
                </div>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor=''>Billing Notes: </label>
                </div>
              </Col>
              <Col span={18}>
                <div>
                  <label htmlFor=''>{dataObject.billingNotes || ""}</label>
                </div>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={6}>
                <div>
                  <label htmlFor='' style={{ fontSize: "1rem" }}>Prescription Order: </label>
                </div>
              </Col>
              <Col span={18}>
                <div className='text-end d-none'>
                  <label
                    onClick={() => {
                      let prescriptionData = [...dataSource]
                      prescriptionData.push({
                        "for": "",
                        "product": "",
                        "quantity": "",
                        "refill": "",
                        "dosage": "",
                        "note": ""
                      })
                      setDataSource(prescriptionData)
                    }}
                    style={{ cursor: "pointer", color: "rgb(0, 117, 255)", fontSize: "1rem" }}>+ Add</label>
                </div>
              </Col>
              <Col span={24}>
                <TableComponent
                  disabled={true}
                  dataSource={dataSource} setDataSource={setDataSource}
                  showText="view"
                />
              </Col>
            </Row>
          </div>
        </Spin>

      </Modal>
    </>
  );
};
export default OrderNumberDetailModal;