import { Avatar, Button as Btn, Select, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { BiCertification } from 'react-icons/bi';
import { BsPerson } from 'react-icons/bs';
import { FaBirthdayCake, FaUniversity } from 'react-icons/fa';
import {
  MdAssignmentInd,
  MdDriveFileRenameOutline,
  MdLanguage,
  MdOutlineDescription,
  MdOutlineEmail,
  MdOutlineHeadsetMic,
  MdOutlineLocationCity,
  MdOutlineMedicalServices,
  MdOutlineMyLocation,
  MdPhone,
} from 'react-icons/md';
import ConfirmationPopup from '../../components/dialog/confirmation.popup';
import SettingInput from '../../components/setting/SettingInput';
import SettingTextAreaInput from '../../components/setting/SettingTextAreaInput';
import './staff.css';
import { deleteStaff, getStaffByid, updateStaff } from './staff.service';
import { AiOutlineFieldNumber } from 'react-icons/ai';
import bioApi from '../../api/bioApi';
import { LIST_ROLE_URL } from '../../api/URLs';

const StaffDetailsModal = (props) => {
  const { staffId, show, handleClose, allLocations, allServices } = props;
  const [loading, setLoading] = useState(true);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [currentStaff, setCurrentStaff] = useState(null);
  const [allRoles, setAllRoles] = useState([]);
  const { Option } = Select;
  const { Text } = Typography;

  const servicesByType = allServices.reduce((groups, service) => {
    const group = (groups[service.type] = groups[service.type] || []);
    group.push(service);
    return groups;
  }, {});

  const serviceOptions = Object.entries(servicesByType).map(([type, services]) => ({
    label: (
      <span>
        {type}{' '}
        <Btn
          shape={'round'}
          size={'small'}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            const staff = { ...currentStaff };
            const currentServices = new Set(staff.services);
            services.forEach((service) => {
              if (currentServices.has(service.serviceId)) {
                currentServices.delete(service.serviceId);
              } else {
                currentServices.add(service.serviceId);
              }
            });
            staff.services = Array.from(currentServices);
            setCurrentStaff(staff);
          }}
        >
          Select / Deselect all
        </Btn>
      </span>
    ),
    title: type,
    options: services
      .sort((a, b) => a.serviceName.localeCompare(b.serviceName))
      .map((service) => ({
        label: service.serviceName,
        value: service.serviceId,
      })),
  }));

  useEffect(() => {
    if (!!staffId) {
      (async () => {
        try {
          const staffData = await getStaffByid(staffId);
          setCurrentStaff(staffData.data[0]);
        } catch (err) {
          console.log(err);
          alert('Fail to get staff!');
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [staffId]);

  const fetchAllRoles = () => {
    (async () => {
      const rolesResponse = await bioApi.get(LIST_ROLE_URL);
      rolesResponse.data && setAllRoles(rolesResponse.data);
    })();
  };

  useEffect(() => {
    fetchAllRoles();
  }, []);

  const changeRole = (value, option) => {
    let staff = { ...currentStaff };
    staff.role = { _id: option.value, name: option.label };
    setCurrentStaff(staff);
  };

  const changeLocation = (value) => {
    let staff = { ...currentStaff };
    staff.locations =
      value?.map((item) => allLocations.find((location) => location.stationId === item).stationId) || [];
    setCurrentStaff(staff);
  };

  const changeService = (value) => {
    let staff = { ...currentStaff };
    staff.services = value;
    setCurrentStaff(staff);
  };

  const addDescription = () => {
    const staff = { ...currentStaff };
    if (currentStaff.description) {
      staff.description = [...currentStaff.description, ''];
    } else {
      staff.description = [''];
    }
    setCurrentStaff(staff);
  };

  const removeDescription = () => {
    const staff = { ...currentStaff };
    if (staff.description) {
      let description = [...staff.description];
      description.pop();
      staff.description = description;
      setCurrentStaff(staff);
    }
  };

  const addAffiliation = () => {
    const staff = { ...currentStaff };
    if (currentStaff.affiliations) {
      staff.affiliations = [...currentStaff.affiliations, ''];
    } else {
      staff.affiliations = [''];
    }
    setCurrentStaff(staff);
  };

  const removeAffiliation = () => {
    const staff = { ...currentStaff };
    if (staff.affiliations) {
      let affiliations = [...staff.affiliations];
      affiliations.pop();
      staff.affiliations = affiliations;
      setCurrentStaff(staff);
    }
  };

  const addAccreditation = () => {
    const staff = { ...currentStaff };
    if (currentStaff.accreditations) {
      staff.accreditations = [...currentStaff.accreditations, ''];
    } else {
      staff.accreditations = [''];
    }
    setCurrentStaff(staff);
  };

  const removeAccreditation = () => {
    const staff = { ...currentStaff };
    if (staff.accreditations) {
      let accreditations = [...staff.accreditations];
      accreditations.pop();
      staff.accreditations = accreditations;
      setCurrentStaff(staff);
    }
  };

  const handleUpdate = () => {
    const staff = { ...currentStaff };
    delete staff.staffId;
    delete staff.creationTS;
    delete staff.name;
    delete staff.phoneNumber;
    delete staff.coverImage;

    if (staff.locations) {
      staff.locations = staff.locations.filter((item) => item.trim() !== '');
    }
    if (staff.description) {
      staff.description = staff.description.filter((item) => item.trim() !== '');
    }
    if (staff.affiliations) {
      staff.affiliations = staff.affiliations.filter((item) => item.trim() !== '');
    }
    if (staff.accreditations) {
      staff.accreditations = staff.accreditations.filter((item) => item.trim() !== '');
    }

    staff.role = staff.role._id;

    updateStaff(staffId, staff)
      .then((response) => {
        alert(response.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          alert(err.response.data?.message);
        } else {
          alert('Fail to update staff!');
        }
      });
  };

  const showUpdateConfirmation = () => {
    setShowUpdateDialog(true);
  };

  const closeUpdateConfirmation = () => {
    setShowUpdateDialog(false);
  };

  const handleDelete = () => {
    deleteStaff(staffId)
      .then((response) => {
        alert(response.data?.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        if (err.response) {
          console.log('RESPONSE', err.response);
        }
        console.log(err);
        alert(`Fail to delete staff ${staffId}!`);
      });
  };

  const showDeleteConfirmation = () => {
    setShowDeleteDialog(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteDialog(false);
  };

  return (
    <>
      {currentStaff && (
        <Modal show={!loading && show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Row className="justify-content-center" title="Staff Role">
              {currentStaff.role.name}
            </Row>

            <Modal.Title id="staff-modal-title">
              <Row className="justify-content-center">
                <Avatar size={87} src={currentStaff.coverImage} />
              </Row>
              <Row className="justify-content-center">{currentStaff.name}</Row>
            </Modal.Title>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdDriveFileRenameOutline className="staff-detail-icon" />
                  <SettingInput
                    key={0}
                    label={'First Name'}
                    value={currentStaff.firstName}
                    editable={true}
                    editingRender={{
                      elementType: 'text',
                      save: (value) => {
                        let staff = { ...currentStaff };
                        staff.firstName = value;
                        setCurrentStaff(staff);
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdDriveFileRenameOutline className="staff-detail-icon" />
                  <SettingInput
                    key={1}
                    label={'Last Name'}
                    value={currentStaff.lastName}
                    editable={true}
                    editingRender={{
                      elementType: 'text',
                      save: (value) => {
                        let staff = { ...currentStaff };
                        staff.lastName = value;
                        setCurrentStaff(staff);
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdDriveFileRenameOutline className="staff-detail-icon" />
                  <SettingInput
                    key={2}
                    label={'Title'}
                    value={currentStaff.title}
                    editable={true}
                    editingRender={{
                      elementType: 'text',
                      save: (value) => {
                        let staff = { ...currentStaff };
                        staff.title = value;
                        setCurrentStaff(staff);
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdOutlineEmail className="staff-detail-icon" />
                  <SettingInput
                    key={3}
                    label={'Email Address'}
                    value={currentStaff.email}
                    editable={true}
                    editingRender={{
                      elementType: 'text',
                      save: (value) => {
                        let staff = { ...currentStaff };
                        staff.email = value;
                        setCurrentStaff(staff);
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdPhone className="staff-detail-icon" />
                  <SettingInput
                    key={4}
                    label={'Phone Number'}
                    value={currentStaff.phone?.number}
                    editable={true}
                    editingRender={{
                      elementType: 'text',
                      save: (value) => {
                        let staff = { ...currentStaff };
                        if (staff.phone) {
                          staff.phone = { ...staff.phone, number: value };
                        } else {
                          staff.phone = { number: value };
                        }
                        setCurrentStaff(staff);
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <FaBirthdayCake className="staff-detail-icon" />
                  <SettingInput
                    key={5}
                    label={'Date of Birth'}
                    value={new Date(currentStaff.dob).toISOString().split('T')[0]}
                    editable={true}
                    editingRender={{
                      elementType: 'date',
                      save: (value) => {
                        let staff = { ...currentStaff };
                        staff.dob = value;
                        setCurrentStaff(staff);
                      },
                    }}
                  />
                </div>
              </Col>
              <Col>
                <div className="staff-detail">
                  <BsPerson className="staff-detail-icon" />
                  <SettingInput
                    key={6}
                    label={'Gender'}
                    value={currentStaff.gender || ''}
                    editable={true}
                    editingRender={{
                      elementType: 'dropdown',
                      items: ['Male', 'Female', 'Other'],
                      save: (value) => {
                        let staff = { ...currentStaff };
                        staff.gender = value;
                        setCurrentStaff(staff);
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <AiOutlineFieldNumber className="staff-detail-icon" />
                  <SettingInput
                    key={5}
                    label={'ME #'}
                    value={currentStaff.me || ''}
                    editable={true}
                    editingRender={{
                      elementType: 'text',
                      save: (value) => {
                        let staff = { ...currentStaff };
                        staff.me = value;
                        setCurrentStaff(staff);
                      },
                    }}
                  />
                </div>
              </Col>
              <Col>
                <div className="staff-detail">
                  <AiOutlineFieldNumber className="staff-detail-icon" />
                  <SettingInput
                    key={6}
                    label={'DEA #'}
                    value={currentStaff.dea || ''}
                    editable={true}
                    editingRender={{
                      elementType: 'text',
                      save: (value) => {
                        let staff = { ...currentStaff };
                        staff.dea = value;
                        setCurrentStaff(staff);
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdLanguage className="staff-detail-icon" />
                  <SettingInput
                    key={7}
                    label={'Languages'}
                    value={currentStaff.languages ? currentStaff.languages.join(', ') : ''}
                    editable={true}
                    editingRender={{
                      elementType: 'text',
                      save: (value) => {
                        let staff = { ...currentStaff };
                        staff.languages = value.split(/[, ]+/);
                        setCurrentStaff(staff);
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <MdOutlineHeadsetMic className="staff-detail-icon" />
                  <SettingInput
                    key={'virtualClinicLink'}
                    label={'Virtual Clinic Link'}
                    value={currentStaff.virtualClinicLink}
                    editable={true}
                    editingRender={{
                      elementType: 'text',
                      save: (value) => {
                        let staff = { ...currentStaff };
                        staff.virtualClinicLink = value;
                        setCurrentStaff(staff);
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="section-content">
              <div className="staff-detail select-tag">
                <Row className="d-flex" style={{ padding: '8px 0' }}>
                  <Col className="d-flex align-items-center">
                    <MdOutlineLocationCity className="staff-detail-icon" />
                    <Text type="secondary" className="input-label">
                      Locations
                    </Text>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center" style={{ paddingLeft: '16px' }}>
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Please select station location"
                    onChange={changeLocation}
                    optionLabelProp="label"
                    value={currentStaff.locations}
                    dropdownStyle={{ zIndex: 2000 }}
                    maxTagCount="responsive"
                    bordered={false}
                  >
                    {allLocations.map((location) => {
                      return (
                        <Option value={location.stationId} label={location.name}>
                          {location.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Row>
              </div>
            </Row>
            <Row className="section-content">
              <div className="staff-detail select-tag">
                <Row className="d-flex" style={{ padding: '8px 0' }}>
                  <Col className="d-flex align-items-center">
                    <MdOutlineMyLocation className="staff-detail-icon" />
                    <Text type="secondary" className="input-label">
                      Primary Location
                    </Text>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center" style={{ paddingLeft: '16px' }}>
                  <Select
                    mode="single"
                    allowClear
                    placeholder="Please select station location"
                    onChange={(value) => {
                      let staff = { ...currentStaff };
                      staff.primaryLocation = value;
                      setCurrentStaff(staff);
                    }}
                    optionLabelProp="label"
                    value={currentStaff.primaryLocation}
                    dropdownStyle={{ zIndex: 2000 }}
                    maxTagCount="responsive"
                    variant={'borderless'}
                    status={currentStaff.locations.includes(currentStaff.primaryLocation) ? '' : 'error'}
                  >
                    {allLocations
                      .filter((l) => currentStaff.locations.includes(l.stationId))
                      .map((location) => {
                        return (
                          <Option value={location.stationId} label={location.name}>
                            {location.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Row>
              </div>
            </Row>
            <Row className="section-content">
              <div className="staff-detail select-tag">
                <Row className="d-flex" style={{ padding: '8px 0' }}>
                  <Col className="d-flex align-items-center">
                    <MdAssignmentInd className="staff-detail-icon" />
                    <Text type="secondary" className="input-label">
                      Role
                    </Text>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center" style={{ paddingLeft: '16px' }}>
                  <Select
                    allowClear
                    placeholder="Please select role"
                    onChange={changeRole}
                    optionLabelProp="label"
                    value={currentStaff.role._id}
                    dropdownStyle={{ zIndex: 2000 }}
                    maxTagCount="responsive"
                    bordered={false}
                  >
                    {allRoles.map((role) => {
                      return (
                        <Option value={role._id} label={role.name}>
                          {role.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Row>
              </div>
            </Row>
            <Row className="section-content">
              <div className="staff-detail select-tag">
                <Row className="d-flex" style={{ padding: '8px 0' }}>
                  <Col className="d-flex align-items-center">
                    <MdOutlineMedicalServices className="staff-detail-icon" />
                    <Text type="secondary" className="input-label">
                      Services
                    </Text>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center" style={{ paddingLeft: '16px' }}>
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Please select provider service"
                    onChange={changeService}
                    optionLabelProp="label"
                    value={currentStaff.services}
                    dropdownStyle={{ zIndex: 2000 }}
                    maxTagCount="responsive"
                    variant={'borderless'}
                    options={serviceOptions}
                  ></Select>
                </Row>
              </div>
            </Row>

            <Row className="section-content">
              <div id="description">
                {currentStaff.description?.map((detail, i) => {
                  return (
                    <div className="staff-detail">
                      <MdOutlineDescription className="staff-detail-icon" />
                      <SettingInput
                        key={i + 28}
                        label={`Description ${i + 1}`}
                        value={detail}
                        editable={true}
                        editingRender={{
                          elementType: 'text',
                          save: (value) => {
                            let staff = { ...currentStaff };
                            let description = [...staff.description];
                            description[i] = value;
                            staff.description = description;
                            setCurrentStaff(staff);
                          },
                        }}
                      />
                    </div>
                  );
                })}
                <div style={{ display: 'flex' }}>
                  <Button className="update-description" variant="secondary" onClick={addDescription}>
                    Add Description
                  </Button>
                  <Button
                    className="update-description"
                    variant="secondary"
                    onClick={removeDescription}
                    disabled={!currentStaff.description || currentStaff.description.length === 0}
                  >
                    Remove Last Description
                  </Button>
                </div>
              </div>
            </Row>
            <Row className="section-content">
              <div id="affiliations">
                {currentStaff.affiliations?.map((detail, i) => {
                  return (
                    <div className="staff-detail">
                      <FaUniversity className="staff-detail-icon" />
                      <SettingInput
                        key={i + 48}
                        label={`Affiliations ${i + 1}`}
                        value={detail}
                        editable={true}
                        editingRender={{
                          elementType: 'text',
                          save: (value) => {
                            let staff = { ...currentStaff };
                            let affiliations = [...staff.affiliations];
                            affiliations[i] = value;
                            staff.affiliations = affiliations;
                            setCurrentStaff(staff);
                          },
                        }}
                      />
                    </div>
                  );
                })}
                <div style={{ display: 'flex' }}>
                  <Button className="update-description" variant="secondary" onClick={addAffiliation}>
                    Add Affiliations
                  </Button>
                  <Button
                    className="update-description"
                    variant="secondary"
                    onClick={removeAffiliation}
                    disabled={!currentStaff.affiliations || currentStaff.affiliations.length === 0}
                  >
                    Remove Last Affiliation
                  </Button>
                </div>
              </div>
            </Row>
            <Row className="section-content">
              <div id="accreditations">
                {currentStaff.accreditations?.map((detail, i) => {
                  return (
                    <div className="staff-detail">
                      <BiCertification className="staff-detail-icon" />
                      <SettingInput
                        key={i + 68}
                        label={`Accreditation & Certification ${i + 1}`}
                        value={detail}
                        editable={true}
                        editingRender={{
                          elementType: 'text',
                          save: (value) => {
                            let staff = { ...currentStaff };
                            let accreditations = [...staff.accreditations];
                            accreditations[i] = value;
                            staff.accreditations = accreditations;
                            setCurrentStaff(staff);
                          },
                        }}
                      />
                    </div>
                  );
                })}
                <div style={{ display: 'flex' }}>
                  <Button className="update-description" variant="secondary" onClick={addAccreditation}>
                    Add Accreditation & Certification
                  </Button>
                  <Button
                    className="update-description"
                    variant="secondary"
                    onClick={removeAccreditation}
                    disabled={!currentStaff.accreditations || currentStaff.accreditations.length === 0}
                  >
                    Remove Last Accreditation & Certification
                  </Button>
                </div>
              </div>
            </Row>
            <Row className="section-content">
              <Col>
                <div className="staff-detail">
                  <SettingTextAreaInput
                    key={7}
                    label={'Introduction'}
                    value={currentStaff.introduction}
                    editable={true}
                    editingRender={{
                      save: (value) => {
                        let staff = { ...currentStaff };
                        staff.introduction = value;
                        setCurrentStaff(staff);
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" style={{ margin: 'auto' }} onClick={showUpdateConfirmation}>
              Update
            </Button>
            <Button variant="danger" style={{ margin: 'auto' }} onClick={showDeleteConfirmation}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <ConfirmationPopup
        show={showUpdateDialog}
        title="Update Staff"
        body="Are you sure you want to make changes to this staff?"
        onConfirm={handleUpdate}
        onClose={closeUpdateConfirmation}
      ></ConfirmationPopup>
      <ConfirmationPopup
        show={showDeleteDialog}
        title="Delete Staff"
        body="Are you sure you want to delete this staff?"
        onConfirm={handleDelete}
        onClose={closeDeleteConfirmation}
      ></ConfirmationPopup>
    </>
  );
};

export default StaffDetailsModal;
