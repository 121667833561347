import { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Collapse,
  Input,
  List,
  message,
  Popconfirm,
  Popover,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import bioApi from '../../api/bioApi';
import { GET_PRODUCTS_BY_PAGE, GET_PRODUCTS_COUNT, PRODUCT_CRUD_URL, SEARCH_PRODUCTS } from '../../api/URLs';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import VirtualList from 'rc-virtual-list';
import ProductModal from './product.modal';
import { FaSyringe } from 'react-icons/fa';

const ProductsPage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [productCount, setProductCount] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [targetProduct, setTargetProduct] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    productId: null,
    checking: false,
    biomarkerInUse: [],
    symptomsAndGoalsInUse: [],
  });
  const [searchThrottling, setSearchThrottling] = useState(false);

  const loadMoreData = (requestingPage) => {
    let page = 1;
    if (requestingPage) {
      page = requestingPage;
    } else {
      page = lastPage;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    bioApi
      .get(GET_PRODUCTS_BY_PAGE, { params: { page } })
      .then((body) => {
        if (page === 1) {
          setData([...body.data]);
        } else {
          setData([...data, ...body.data]);
        }
        setLastPage(page + 1);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onScroll = (e) => {
    if (
      !isSearching &&
      Math.trunc(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) === Math.trunc(window.innerHeight - 200)
    ) {
      loadMoreData();
    }
  };

  useEffect(() => {
    bioApi.get(GET_PRODUCTS_COUNT).then((body) => {
      setProductCount(body.data.count);
      loadMoreData();
    });
  }, []);

  useEffect(() => {
    setShowModal(targetProduct !== undefined);
  }, [targetProduct]);

  useEffect(() => {
    if (deleteConfirmation.checking) {
      bioApi.get(`${PRODUCT_CRUD_URL}${deleteConfirmation.productId}/in-use`).then((body) => {
        setDeleteConfirmation({
          ...deleteConfirmation,
          checking: false,
          biomarkerInUse: body.data.biomarkers,
          symptomsAndGoalsInUse: body.data.symptomsAndGoals,
        });
      });
    }
  }, [deleteConfirmation.checking]);

  let searchDebounceTimeout;
  let searchCriteria;
  let lastSearch;
  const debouncedSearch = (e) => {
    const newValue = e.target.value;
    clearTimeout(searchDebounceTimeout);
    searchDebounceTimeout = setTimeout(async () => {
      if (newValue && newValue.length > 3) {
        searchCriteria = newValue;
        await immediateSearch(newValue, true);
      }
    }, 1000);
  };

  const immediateSearch = async (newValue, fromDebounce) => {
    if (newValue === lastSearch || searchThrottling) return;
    setIsSearching(true);
    if (newValue) {
      lastSearch = newValue;
      setSearchThrottling(true);
      const result = await bioApi.get(SEARCH_PRODUCTS, { params: { query: newValue } });
      if (fromDebounce) {
        if (searchCriteria === newValue) {
          setData(result.data);
        }
      } else {
        setData(result.data);
      }
    } else {
      loadMoreData(1);
    }
    setIsSearching(false);
    setTimeout(() => setSearchThrottling(false), 1000);
  };

  const modifyProduct = (product) => {
    if (product.dosageList) {
      const dosageList = product.dosageList.map((d) => ({
        ...d,
        medicalSupplies: [...d.medicalSupplies.map((ms) => ({ product: ms.product._id, quantity: ms.quantity }))],
      }));
      setTargetProduct({ ...product, dosageList });
    } else {
      setTargetProduct(product);
    }
  };
  const confirmDelete = (product) => {
    message.loading({ content: 'Deleting In Process', duration: 0, key: 'deleting' });
    bioApi
      .delete(PRODUCT_CRUD_URL.concat(product._id))
      .then(() => {
        message.destroy('deleting');
        message.info(`Product [${product.name}] has been deleted`, 15);
        setData([...data.filter((d) => d._id !== product._id)]);
        setProductCount(productCount - 1);
      })
      .catch(() => {
        message.destroy('deleting');
        message.error(`Failed to delete product [${product.name}]`, 15);
      });
  };
  const createProduct = () => {
    setTargetProduct(null);
  };

  const onSubmit = (product) => {
    setShowModal(false); // ensure new product creation also close the modal
    setTargetProduct(undefined);
    const index = data.findIndex((item) => item._id === product._id);

    if (index !== -1) {
      data[index] = product; // Update existing object
    } else {
      data.unshift(product); // Insert new object
      setProductCount(productCount + 1);
    }
    setData([...data]);
  };

  return (
    <div style={{ padding: '0px 8px' }}>
      <h4>Product Management</h4>
      <div className="d-flex justify-content-between align-items-end" style={{ marginBlock: 16, paddingInline: 32 }}>
        <Input.Search
          placeholder="Search by product name"
          allowClear
          size="large"
          onChange={debouncedSearch}
          onSearch={async (value) => await immediateSearch(value, false)}
          style={{ width: '50%' }}
        />
        <Button type="primary" size="large" onClick={createProduct}>
          Add New Product
        </Button>
        <Typography.Text type="secondary">
          showing {data.length} / {productCount} products
        </Typography.Text>
      </div>
      <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)', marginBottom: 5 }} />
      <List style={{ paddingInline: 16 }}>
        <VirtualList data={data} height={window.innerHeight - 200} itemHeight={200} itemKey="_id" onScroll={onScroll}>
          {(item) => {
            const costs = [
              {
                key: '1',
                label: `Pharmacies & Costs (${item.cost?.length || 0})`,
                children: item.cost?.map((c) => (
                  <Tag bordered={false} color="processing" style={{ marginBlock: 1 }}>
                    {c.pharmacy}: ${c.cost}
                  </Tag>
                )),
              },
            ];

            return (
              <List.Item key={item._id}>
                <div style={{ width: '100%' }}>
                  <Row>
                    <Col span={16}>
                      <Row style={{ marginBottom: 8 }}>
                        <Space>
                          {item.active ? <Badge status="processing" /> : <Badge status="default" />}
                          <strong style={{ fontSize: 16 }}>{item.name}</strong>
                        </Space>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <Space direction={'vertical'}>
                            <div>
                              <span style={{ color: 'rgba(0, 0, 0, 0.5)', marginRight: 5 }}>List Price:</span>
                              <span>${item.listPrice}</span>
                            </div>
                            {item.type !== 'MedicalSupply' && (
                              <div>
                                <span style={{ color: 'rgba(0, 0, 0, 0.5)', marginRight: 5 }}>Therapy Level:</span>
                                <span>{item.therapyLevel}</span>
                              </div>
                            )}

                            <div>
                              <span style={{ color: 'rgba(0, 0, 0, 0.5)', marginRight: 5 }}>Type:</span>
                              <span>{item.type}</span>
                            </div>
                            {item.type !== 'MedicalSupply' && (
                              <div>
                                <span style={{ color: 'rgba(0, 0, 0, 0.5)', marginRight: 5 }}>Default Quantity:</span>
                                <span>{item.defaultQuantity}</span>
                              </div>
                            )}
                          </Space>
                        </Col>
                        {item.type !== 'MedicalSupply' && (
                          <Col span={16}>
                            <div style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                              <span>Preset Dosages:</span>
                            </div>
                            <ul style={{ paddingLeft: 16 }}>
                              {item.dosageList.map((d) => (
                                <li>
                                  <Space>
                                    <span>{d.dosage}</span>
                                    {!!d.medicalSupplies?.length && (
                                      <Popover
                                        placement={'right'}
                                        content={
                                          <ul>
                                            {d.medicalSupplies.map((ms) => (
                                              <li>
                                                <Space>
                                                  <span>{ms.product.name}</span>x<b>{ms.quantity}</b>
                                                </Space>
                                              </li>
                                            ))}
                                          </ul>
                                        }
                                        title={'Associated Medical Supplies'}
                                      >
                                        <FaSyringe color={'#1677ff'} />
                                      </Popover>
                                    )}
                                  </Space>
                                </li>
                              ))}
                            </ul>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col span={7}>
                      <Collapse ghost items={costs} />
                    </Col>
                    <Col span={1}>
                      <Space direction="vertical">
                        <Tooltip title={'Modify Product'}>
                          <Button icon={<EditOutlined />} onClick={() => modifyProduct(item)} />
                        </Tooltip>
                        <Popconfirm
                          title="Are you sure to delete this product?"
                          description={
                            <div>
                              {deleteConfirmation.checking && <Spin size={'small'} />}
                              {!deleteConfirmation.checking && (
                                <div>
                                  {deleteConfirmation.biomarkerInUse.length > 0 && (
                                    <div>
                                      This product is used in Biomarkers:
                                      <Typography.Text type={'secondary'}>
                                        [
                                        {deleteConfirmation.biomarkerInUse
                                          .map((b) => b.name + ' of ' + b.gender)
                                          .join(', ')}
                                        ]
                                      </Typography.Text>
                                    </div>
                                  )}
                                  {deleteConfirmation.symptomsAndGoalsInUse.length > 0 && (
                                    <div>
                                      This product is used in Symptoms and Goals:
                                      <Typography.Text type={'secondary'}>
                                        [
                                        {deleteConfirmation.symptomsAndGoalsInUse
                                          .map((b) => b.name + ' of ' + b.gender)
                                          .join(', ')}
                                        ]
                                      </Typography.Text>
                                    </div>
                                  )}
                                  {(deleteConfirmation.biomarkerInUse.length > 0 ||
                                    deleteConfirmation.symptomsAndGoalsInUse.length > 0) && (
                                    <Typography.Text type={'danger'}>
                                      Deleting this product will remove it from the above mentioned Biomarkers and / or
                                      Symptoms and Goals.
                                    </Typography.Text>
                                  )}
                                </div>
                              )}
                            </div>
                          }
                          onConfirm={() => confirmDelete(item)}
                          okButtonProps={{ loading: deleteConfirmation.checking }}
                          okText="Yes"
                          cancelText="No"
                          onOpenChange={(open) => {
                            if (open) {
                              setDeleteConfirmation({
                                productId: item._id,
                                checking: true,
                                biomarkerInUse: [],
                                symptomsAndGoalsInUse: [],
                              });
                            }
                          }}
                        >
                          <Button danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </List.Item>
            );
          }}
        </VirtualList>
      </List>
      {!!showModal && (
        <ProductModal
          show={showModal}
          originalProduct={targetProduct}
          onCancel={() => {
            setTargetProduct(undefined);
          }}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

export default ProductsPage;
