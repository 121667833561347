import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Result,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import { Paper } from '@mui/material';
import DataTable from '../../../components/data-table/data-table.component';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkPresignUrl,
  confirmTreatmentOrderWithPatient,
  createBioId,
  placeOrder,
  updateMedicineCancellation,
  updateTreatmentPlanFeeAndDiscount,
} from '../service';
import { updateTreatmentPlan } from '../../../stores/patient-treatment-plan.store';
import { Document, Page, pdfjs } from 'react-pdf';
import { AccessRight } from '../../../components/guarded-route/guarded-routes';
import { ApplicationRight } from '../../../share/RightList';
import BioIDGenerateSettingComponent from './components/bioID-generate-setting.component';
import { FilePdfOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { OrderConfirmModal } from './components/order-confirm-modal';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { RefillOrderSelectionModal } from './components/refill-order-selection.modal';
import SettingInput from '../../../components/setting/SettingInput';
import { calcPriceReducer } from './utils/price-sum-reducer';
import { FaSyringe } from 'react-icons/fa';
import { RxCornerBottomLeft } from 'react-icons/rx';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
dayjs.extend(utc);

const BioIdBriefPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const patientTreatmentPlans = useSelector((state) => state.patientTreatmentPlans);
  const currentPatient = useSelector((state) => state.currentPatient);
  const currentLabResult = useSelector((state) => state.currentLabResult);

  const [abnormalBiomarkers, setAbnormalBiomarkers] = useState([]);
  const [treatmentPlan, setTreatmentPlan] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableSetting, setTableSetting] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [patientGoals, setPatientGoals] = useState([]);
  const [changingPlan, setChangingPlan] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [popupMsg, setPopupMsg] = React.useState(null);
  const [hasTreatment, setHasTreatment] = React.useState(false);
  const [openBioIdPreviewModal, setOpenBioIdPreviewModal] = React.useState(false);
  const [generateBioIdLoading, setGenerateBioIdLoading] = React.useState(false);
  const [bioIdGenerateResult, setBioIdGenerateResult] = React.useState(null);
  const [fee, setFee] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [currentTreatmentPlan, setCurrentTreatmentPlan] = useState(null);

  const init = (plans, isEditing) => {
    const basePlan = plans.filter((p) => p.level === 'Base');
    const correctionalPlan = plans.filter((p) => p.level === 'Correctional');
    const electivePlan = plans.filter((p) => p.level === 'Elective');
    const ciPlan = plans.filter((p) => p.level === 'Cardiovascular & Inflammation');
    const gwPlan = plans.filter((p) => p.level === 'General wellness');
    const otherPlan = plans.filter((p) => p.level === 'Other');

    const basePlanPriceRow = {
      level: 'priceRow',
      price: basePlan.reduce(calcPriceReducer, 0),
    };

    const correctionalPlanPriceRow = {
      level: 'priceRow',
      price: correctionalPlan.reduce(calcPriceReducer, 0),
    };

    const electivePlanPriceRow = {
      level: 'priceRow',
      price: electivePlan.reduce(calcPriceReducer, 0),
    };

    const ciPlanPriceRow = {
      level: 'priceRow',
      price: ciPlan.reduce(calcPriceReducer, 0),
    };

    const gwPlanPriceRow = {
      level: 'priceRow',
      price: gwPlan.reduce(calcPriceReducer, 0),
    };

    const otherPlanPriceRow = {
      level: 'priceRow',
      price: otherPlan.reduce(calcPriceReducer, 0),
    };

    const planMapFunc = (p, i) => {
      if (i === 0) {
        return { ...p };
      } else {
        return {
          ...p,
          level: '',
        };
      }
    };

    const planIterateFunc = (planTableData) => (p) => {
      planTableData.push(p);
      if (p.medicalSupplies && p.medicalSupplies.length > 0) {
        p.medicalSupplies.forEach((ms) => {
          planTableData.push({
            for: p._id,
            level: '',
            product: {
              name: ms.name,
              _id: ms._id,
            },
            quantity: ms.quantity,
            overridingQuantity: ms.overridingQuantity,
            price: ms.price,
            refill: '',
            dosage: '',
            isMedicalSupply: true,
          });
        });
      }
    };

    const basePlanTableData = [];
    basePlan.map(planMapFunc).forEach(planIterateFunc(basePlanTableData));
    const correctionalPlanTableData = [];
    correctionalPlan.map(planMapFunc).forEach(planIterateFunc(correctionalPlanTableData));
    const electivePlanTableData = [];
    electivePlan.map(planMapFunc).forEach(planIterateFunc(electivePlanTableData));
    const ciPlanTableData = [];
    ciPlan.map(planMapFunc).forEach(planIterateFunc(ciPlanTableData));
    const gwPlanTableData = [];
    gwPlan.map(planMapFunc).forEach(planIterateFunc(gwPlanTableData));
    const otherPlanTableData = [];
    otherPlan.map(planMapFunc).forEach(planIterateFunc(otherPlanTableData));

    const rowSpan = {
      '': 0,
      priceRow: 0,
      Base: basePlanTableData.length + (isEditing ? 0 : 1),
      Correctional: correctionalPlanTableData.length + (isEditing ? 0 : 1),
      Elective: electivePlanTableData.length + (isEditing ? 0 : 1),
      'Cardiovascular & Inflammation': ciPlanTableData.length + (isEditing ? 0 : 1),
      'General wellness': gwPlanTableData.length + (isEditing ? 0 : 1),
      Other: otherPlanTableData.length + (isEditing ? 0 : 1),
    };

    const finalTableData = [];
    if (basePlanTableData.length) {
      finalTableData.push(...basePlanTableData);
      if (!isEditing) {
        finalTableData.push(basePlanPriceRow);
      }
    }
    if (correctionalPlanTableData.length) {
      finalTableData.push(...correctionalPlanTableData);
      if (!isEditing) {
        finalTableData.push(correctionalPlanPriceRow);
      }
    }
    if (electivePlanTableData.length) {
      finalTableData.push(...electivePlanTableData);
      if (!isEditing) {
        finalTableData.push(electivePlanPriceRow);
      }
    }
    if (ciPlanTableData.length) {
      finalTableData.push(...ciPlanTableData);
      if (!isEditing) {
        finalTableData.push(ciPlanPriceRow);
      }
    }
    if (gwPlanTableData.length) {
      finalTableData.push(...gwPlanTableData);
      if (!isEditing) {
        finalTableData.push(gwPlanPriceRow);
      }
    }
    if (otherPlanTableData.length) {
      finalTableData.push(...otherPlanTableData);
      if (!isEditing) {
        finalTableData.push(otherPlanPriceRow);
      }
    }

    setTableData([...finalTableData.map((t) => ({ ...t, key: t._id || Math.random() }))]);

    setTableSetting({
      pagination: false,
      bordered: true,
      columns: [
        {
          title: 'Therapy',
          dataIndex: 'level',
          onCell: (record) => ({
            rowSpan: rowSpan[record.level] || 0,
          }),
        },
        {
          title: 'Medication / Product',
          dataIndex: ['product', 'name'],
          render: (text, record) => {
            const plan = plans.find((t) => t._id === record._id || t._id === record.for);
            if (plan) {
              if (!isEditing) {
                if (plan.cancelled) {
                  if (record.isMedicalSupply) {
                    return (
                      <span style={{ marginLeft: 6 }}>
                        <RxCornerBottomLeft size={18} style={{ position: 'relative', top: -4 }} />
                        <Tag icon={<FaSyringe />} color="error">
                          <Typography.Text delete>{text}</Typography.Text>
                        </Tag>
                      </span>
                    );
                  }
                  return <Typography.Text delete>{text}</Typography.Text>;
                } else {
                  if (record.isMedicalSupply) {
                    const showError = record.overridingQuantity === 0 || (plan && plan.cancelled);
                    return (
                      <span style={{ marginLeft: 6 }}>
                        <RxCornerBottomLeft size={18} style={{ position: 'relative', top: -4 }} />
                        <Tag
                          icon={<FaSyringe />}
                          color={showError ? 'error' : 'processing'}
                          style={{
                            userSelect: 'none',
                          }}
                        >
                          <Typography.Text delete={plan && plan.cancelled}>{text}</Typography.Text>
                        </Tag>
                      </span>
                    );
                  }
                  return text;
                }
              } else {
                if (!record.isMedicalSupply) {
                  return (
                    <Checkbox
                      checked={!plan.cancelled}
                      onChange={(event) => {
                        plan.cancelled = !event.target.checked;

                        if (plan._id in changingPlan) {
                          delete changingPlan[plan._id];
                        } else {
                          changingPlan[plan._id] = plan.cancelled;
                        }
                        setChangingPlan({ ...changingPlan });
                        setTableSetting(null);
                        setTreatmentPlan([...treatmentPlan]);
                      }}
                    >
                      {plan.cancelled ? <Typography.Text delete>{text}</Typography.Text> : text}
                    </Checkbox>
                  );
                } else {
                  const showError = record.overridingQuantity === 0 || (plan && plan.cancelled);
                  return (
                    <span style={{ marginLeft: 6 }}>
                      <RxCornerBottomLeft size={18} style={{ position: 'relative', top: -4 }} />
                      <Tag
                        icon={<FaSyringe />}
                        color={showError ? 'error' : 'processing'}
                        style={{
                          userSelect: 'none',
                        }}
                      >
                        <Typography.Text delete={plan && plan.cancelled}>{text}</Typography.Text>
                      </Tag>
                    </span>
                  );
                }
              }
            }
          },
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          render: (text, record) => {
            if (!record.isMedicalSupply) {
              const plan = plans.find((t) => t._id === record._id);

              if (plan && plan.cancelled) {
                return <Typography.Text delete>{text}</Typography.Text>;
              }
              return text;
            } else {
              const plan = plans.find((t) => t._id === record.for);
              if (isEditing) {
                return (
                  <InputNumber
                    disabled={plan && plan.cancelled}
                    min={0}
                    value={record.overridingQuantity ?? record.quantity}
                    onChange={(value) => {
                      const medicalSupply = plan.medicalSupplies.find((m) => m._id === record.product._id);
                      medicalSupply.overridingQuantity = value;
                      setTableSetting(null);
                      setTreatmentPlan([...treatmentPlan]);
                      try {
                        const currentPlanMedicalSupply = currentTreatmentPlan.forLabResult
                          .find((m) => m._id === record.for)
                          .medicalSupplies.find((m) => m._id === record.product._id);
                        currentPlanMedicalSupply.overridingQuantity = value;
                      } catch (e) {
                        const currentPlanMedicalSupply = currentTreatmentPlan.forSymptomsAndGoals
                          .find((m) => m._id === record.for)
                          .medicalSupplies.find((m) => m._id === record.product._id);
                        currentPlanMedicalSupply.overridingQuantity = value;
                      } finally {
                        setCurrentTreatmentPlan({ ...currentTreatmentPlan });
                      }
                    }}
                  />
                );
              } else {
                const showError = record.overridingQuantity === 0 || (plan && plan.cancelled);
                return (
                  <Tag
                    color={showError ? 'error' : 'processing'}
                    style={{
                      userSelect: 'none',
                    }}
                  >
                    <Typography.Text delete={plan && plan.cancelled}>
                      {record.overridingQuantity ?? text}
                    </Typography.Text>
                  </Tag>
                );
              }
            }
          },
        },
        {
          title: 'Refill',
          dataIndex: 'refill',
          render: (text, record) => {
            const plan = plans.find((t) => t._id === record._id);
            if (plan && plan.cancelled) {
              return <Typography.Text delete>{text}</Typography.Text>;
            }
            return text;
          },
        },
        {
          title: 'Dosage',
          dataIndex: 'dosage',
          render: (text, record) => {
            const plan = plans.find((t) => t._id === record._id);
            if (plan && plan.cancelled) {
              return <Typography.Text delete>{text}</Typography.Text>;
            }
            return text;
          },
        },
        {
          title: 'Price',
          dataIndex: 'price',
          render: (text, record) => {
            const plan = plans.find((t) => t._id === record._id || t._id === record.for);
            if (plan && plan.cancelled) {
              return (
                <Typography.Text delete>{`$${
                  text ? text * ((record.overridingQuantity ?? record.quantity) || 0) : 'TBD'
                }`}</Typography.Text>
              );
            }
            if (record.level === 'priceRow') {
              return <span style={{ fontWeight: 'bold' }}>{`$${text ? Number(text) : 'TBD'}`}</span>;
            } else {
              return (
                <span>{`$${text ? Number(text) * ((record.overridingQuantity ?? record.quantity) || 0) : 'TBD'}`}</span>
              );
            }
          },
        },
      ],
    });
  };

  const submitTreatmentChange = async () => {
    setEditMode(false);
    setTableSetting(null);
    setTreatmentPlan([...treatmentPlan]);
    const newPlan = JSON.parse(JSON.stringify(currentTreatmentPlan));
    Object.entries(changingPlan).forEach(([id, value]) => {
      try {
        newPlan.forLabResult.find((m) => m._id === id).cancelled = value;
      } catch (e) {
        newPlan.forSymptomsAndGoals.find((m) => m._id === id).cancelled = value;
      }
    });

    await updateMedicineCancellation(newPlan);
    dispatch(updateTreatmentPlan(newPlan));
  };

  const enableEdit = () => {
    setEditMode(true);
    setTableSetting(null);
    setChangingPlan({});
  };

  const placeOrders = async (
    type,
    submittingMedicines,
    shipment,
    location,
    refillFollowUpDate,
    fee,
    discount,
    totalPrice,
    onSuccess
  ) => {
    const {
      data: { links, refillOpportunity, orderId },
    } = await placeOrder(
      {
        firstName: shipment.firstName,
        lastName: shipment.lastName,
        dob: currentPatient.profile.dob,
        phone: currentPatient.profile.phone,
        patientId: currentPatient.patientId,
        shippingAddress: shipment.shippingAddress,
        billingAddress: shipment.billingAddress,
      },
      location,
      submittingMedicines,
      currentLabResult,
      currentTreatmentPlan._id,
      type,
      refillFollowUpDate,
      fee,
      discount,
      totalPrice
    );

    Modal.success({
      width: '1030px',
      title: `Generated ${type} Orders`,
      content: (
        <div className="d-flex align-items-center justify-content-between" style={{ background: '#F9F9F9' }}>
          <div style={{ width: '330px', padding: '12px' }}>
            <h6>Prescription Packing List:</h6>
            <Document
              file={links.prescriptionLink}
              onLoadError={console.error}
              noData={'Prescription Packing List is not generated'}
              className="cursor-pointer"
              onClick={() => (links.prescriptionLink ? window.open(links.prescriptionLink, '_blank') : null)}
            >
              <Page pageNumber={1} width={300} scale={0.95} renderMode="canvas" />
            </Document>
          </div>
          <div style={{ width: '330px', padding: '12px' }}>
            <h6>Biostation Products Packing List:</h6>
            <Document
              file={links.bProductLink}
              onLoadError={console.error}
              noData={'bProducts Packing List is not generated'}
              className="cursor-pointer"
              onClick={() => (links.bProductLink ? window.open(links.bProductLink, '_blank') : null)}
            >
              <Page pageNumber={1} width={300} scale={0.95} renderMode="canvas" />
            </Document>
          </div>
          <div style={{ width: '330px', padding: '12px' }}>
            <h6>Invoice:</h6>
            <Document
              file={links.invoiceLink}
              onLoadError={console.error}
              noData={'Invoice is not generated'}
              className="cursor-pointer"
              onClick={() => (links.invoiceLink ? window.open(links.invoiceLink, '_blank') : null)}
            >
              <Page pageNumber={1} width={300} scale={0.95} renderMode="canvas" />
            </Document>
          </div>
        </div>
      ),
      onOk: () => onSuccess(links, refillOpportunity, orderId),
    });
  };

  const changeFeeAndDiscount = async (amount, type) => {
    if (type === 'fee') {
      setFee(amount);
      dispatch(
        updateTreatmentPlan({
          ...currentTreatmentPlan,
          fee: { ...currentTreatmentPlan.fee, amount },
        })
      );
    } else {
      setDiscount(amount);
      dispatch(
        updateTreatmentPlan({
          ...currentTreatmentPlan,
          discountInDollar: { ...currentTreatmentPlan.discountInDollar, amount },
        })
      );
    }
    try {
      await updateTreatmentPlanFeeAndDiscount(currentTreatmentPlan._id, amount, type);
    } catch (e) {
      message.error('Failed to update fee and discount');
    }
  };

  useEffect(() => {
    const current = currentTreatmentPlan || { forLabResult: [], forSymptomsAndGoals: [] };
    setAbnormalBiomarkers([...currentLabResult.filter((r) => r.isAbnormal)]);
    setPatientGoals([...currentPatient.symptomsAndGoals]);
    setFee(current.fee?.amount || 0);
    setDiscount(current.discountInDollar?.amount || 0);
  }, [currentTreatmentPlan]);

  useEffect(() => {
    checkTreatmentStatus();
  }, [currentPatient]);

  useEffect(() => {
    if (editMode !== null) {
      init(treatmentPlan, editMode);
    }
  }, [editMode]);

  useEffect(() => {
    if (!!currentPatient.currentTreatmentId) {
      const current = JSON.parse(
        JSON.stringify(patientTreatmentPlans.find((p) => p._id === currentPatient.currentTreatmentId))
      );
      setCurrentTreatmentPlan(current);
      const treatmentPlanReplica = JSON.parse(
        JSON.stringify([...current.forLabResult, ...current.forSymptomsAndGoals])
      );
      setTreatmentPlan(treatmentPlanReplica);
    }
  }, [currentPatient.currentTreatmentId, patientTreatmentPlans]);

  useEffect(() => {
    if (treatmentPlan.length) {
      init(treatmentPlan, editMode);
    }
  }, [treatmentPlan]);

  const checkTreatmentStatus = () => {
    let message = 'We will generate a new BioID with no treatment plan. Please confirm your action';
    if (currentPatient.currentTreatmentId) {
      setHasTreatment(true);
      message = 'We will generate a new BioID with latest treatment plan. Please confirm your action';
    }
    setPopupMsg(message);
  };

  const generateBioID = async ({ yourResult, monitor1, monitor2 }) => {
    setGenerateBioIdLoading(true);
    try {
      const res = await createBioId(currentPatient, yourResult, monitor1, monitor2);
      setBioIdGenerateResult(
        <Result
          status="success"
          title="Successfully Created BioId!"
          subTitle="What you can do next:"
          extra={[
            <Button
              type="primary"
              key="checkpdf"
              onClick={() => {
                const base64Data = res.data.base64;
                const src = 'data:application/pdf;base64,' + base64Data;
                const newTab = window.open();
                newTab.document.write('<iframe src="' + src + '" style="width:100%; height:100vh;"></iframe>');
                newTab.focus();
              }}
            >
              Check This BioId
            </Button>,
            <Button
              key="checkall"
              onClick={() => {
                setOpenBioIdPreviewModal(false);
                navigate(`/patient/${currentPatient.patientId}/files`);
              }}
            >
              Check All BioIds
            </Button>,
          ]}
        />
      );
    } catch (e) {
      message.error('Unable to generate BioId', 0);
      throw e;
    } finally {
      setGenerateBioIdLoading(false);
    }
  };

  const handleUrl = async (type, patientId) => {
    setButtonLoading(true);
    try {
      const urlData = await checkPresignUrl(type, patientId);
      console.log('urlData', urlData);
      if (urlData.data.status) {
        const base64Data = urlData.data.base64;
        const src = 'data:application/pdf;base64,' + base64Data;

        const newTab = window.open();
        if (newTab) {
          newTab.document.write('<iframe src="' + src + '" style="width:100%; height:100vh;"></iframe>');
          newTab.focus();
        } else {
          console.error('The pop-up was blocked by the browser.');
        }
      } else {
        messageApi.open({
          type: 'error',
          content: 'Failed to open bioId , please make sure treatment plan is created.',
        });
      }
    } catch (err) {
      messageApi.open({
        type: 'error',
        content: 'Failed to open bioId , please make sure treatment plan is created.',
      });
    }
    setButtonLoading(false);
  };

  const confirmTreatmentOrder = async () => {
    setButtonLoading(true);
    try {
      await confirmTreatmentOrderWithPatient(currentTreatmentPlan._id);

      const newPlan = JSON.parse(JSON.stringify(currentTreatmentPlan));
      newPlan.confirmedByPatient = true;
      dispatch(updateTreatmentPlan(newPlan));
    } catch (e) {
      message.error('Failed to confirm treatment order');
    } finally {
      setButtonLoading(false);
    }
  };

  const generateButtons = () => {
    if (!currentTreatmentPlan.confirmedByPatient) {
      return (
        <>
          <Button
            type={'primary'}
            hidden={editMode}
            loading={buttonLoading}
            onClick={confirmTreatmentOrder}
            className="me-3"
          >
            Confirm Treatment Order
          </Button>
          <Button variant="outline-primary" className="me-3" hidden={!!editMode} onClick={enableEdit}>
            Update Treatment Order
          </Button>
          <Button type="primary" className="me-3" hidden={!editMode} onClick={submitTreatmentChange}>
            OK
          </Button>
        </>
      );
    } else if (currentTreatmentPlan.confirmedByPatient && !currentTreatmentPlan.initialOrder) {
      if (AccessRight(ApplicationRight.Orders_Generation)) {
        return (
          <OrderConfirmModal
            prefilledInfo={{
              serviceLocation: currentPatient.profile.primaryServiceCenter,
              shipping: currentPatient.profile.shippingAddress,
              billing: currentPatient.profile.billingAddress,
              firstName: currentPatient.profile.firstName,
              lastName: currentPatient.profile.lastName,
            }}
            okText={'Confirm And Place Orders'}
            onConfirm={(shipment, location, followUpDate) =>
              placeOrders(
                'Treatment',
                treatmentPlan.filter((p) => !p.cancelled),
                shipment,
                location,
                followUpDate,
                fee,
                discount,
                treatmentPlan.reduce(calcPriceReducer, 0) + fee - discount,
                ({ prescriptionLink, bProductLink }, refillOpportunity, orderId) => {
                  const newPlan = JSON.parse(JSON.stringify(currentTreatmentPlan));
                  newPlan.initialOrder = orderId;
                  newPlan.refillOpportunity = refillOpportunity;
                  dispatch(updateTreatmentPlan(newPlan));
                }
              )
            }
            placeholderButtonText={'Place Treatment Orders'}
          />
        );
      } else {
        return <></>;
      }
    } else if (currentTreatmentPlan.confirmedByPatient && currentTreatmentPlan.initialOrder) {
      return <RefillOrderSelectionModal currentTreatmentPlan={currentTreatmentPlan} placeOrders={placeOrders} />;
    }
  };

  return (
    <div>
      {contextHolder}
      <div className="d-flex justify-content-between align-content-center mb-4">
        <h4>BioID Brief</h4>
        <div>{!!currentTreatmentPlan && generateButtons()}</div>
      </div>
      <Row gutter={30}>
        <Col span={18}>
          {tableSetting && tableData ? (
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginInline: 4 }}>
                <Space>
                  <Typography.Text type={'secondary'}>Subtotal:</Typography.Text>
                  <Typography.Text>${treatmentPlan.reduce(calcPriceReducer, 0)}</Typography.Text>
                </Space>

                <Space>
                  <Typography.Text type={'secondary'}>Shipping And Handling Fee:</Typography.Text>
                  <SettingInput
                    key={'fee'}
                    value={fee}
                    placeholder={'Set Fee Here'}
                    editable={currentTreatmentPlan && !currentTreatmentPlan.confirmedByPatient}
                    editingRender={{
                      elementType: 'number',
                      formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      parser: (value) => value?.replace(/\$\s?|(,*)/g, ''),
                      min: 0,
                      save: (value) => changeFeeAndDiscount(value, 'fee'),
                    }}
                  />
                </Space>
                <Space>
                  <Typography.Text type={'secondary'}>Extra Discount:</Typography.Text>
                  (
                  <SettingInput
                    key={'discount'}
                    value={discount}
                    placeholder={'Set Discount Here'}
                    editable={currentTreatmentPlan && !currentTreatmentPlan.confirmedByPatient}
                    editingRender={{
                      elementType: 'number',
                      formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      parser: (value) => value?.replace(/\$\s?|(,*)/g, ''),
                      min: 0,
                      max: treatmentPlan.reduce(calcPriceReducer, 0),
                      save: (value) => changeFeeAndDiscount(value, 'discount'),
                    }}
                  />
                  )
                </Space>
                <Space>
                  <Typography.Text type={'secondary'}>Total Price:</Typography.Text>
                  <Typography.Text strong>
                    ${treatmentPlan.reduce(calcPriceReducer, 0) + fee - discount}
                  </Typography.Text>
                </Space>
              </div>
              <DataTable data={tableData} settings={tableSetting} />
              <div style={{ marginLeft: 8 }}>
                <h6 className="mt-3 mb-1">Follow up notes</h6>
                <p style={{ whiteSpace: 'pre-line' }}>{currentTreatmentPlan?.followUpNotes}</p>
              </div>
            </div>
          ) : (
            <div>There's no treatment plan for this patient</div>
          )}
          {currentTreatmentPlan ? (
            <>
              <Button
                hidden={editMode}
                loading={buttonLoading}
                onClick={() => handleUrl('bio-id', currentTreatmentPlan.patient)}
                target="_blank"
                className="mt-2 mr-2"
              >
                Download BioID
              </Button>
              <Popconfirm title={popupMsg} onConfirm={() => setOpenBioIdPreviewModal(true)}>
                <Button
                  hidden={editMode}
                  icon={<FilePdfOutlined />}
                  style={{ marginLeft: '5px' }}
                  disabled={!currentLabResult.length}
                >
                  Generate BioID from latest {hasTreatment ? 'treatment' : 'lab report'}
                </Button>
              </Popconfirm>
            </>
          ) : (
            <></>
          )}
        </Col>
        <Col span={6}>
          <Paper style={{ borderRadius: 20, paddingTop: 20, paddingBottom: 20, marginBottom: 10 }}>
            <div className="mb-2 ps-2 pe-2">
              <Typography.Title level={5}>Lab Results (Out Of Range)</Typography.Title>
            </div>
            <div className="mb-2">
              <Collapse>
                {abnormalBiomarkers.map((result, i) => (
                  <Collapse.Panel header={`${result.biomarker} (${result.labData})`} key={i}>
                    <p>{result.symptom}</p>
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>
            <div className="mb-2 ps-2 pe-2">
              <Typography.Title level={5}>Symptoms / Goals</Typography.Title>
            </div>
            <div className="mb-2 ps-3 pe-3">
              {patientGoals.map((g, i) => (
                <p key={i}>{g}</p>
              ))}
            </div>
          </Paper>
        </Col>
      </Row>
      <Modal
        title={'BioId Lab Results Data Setting'}
        open={openBioIdPreviewModal}
        destroyOnClose
        footer={null}
        maskClosable={false}
        onCancel={() => setOpenBioIdPreviewModal(false)}
        closable={!generateBioIdLoading}
        width={800}
      >
        {!!bioIdGenerateResult ? (
          <>{bioIdGenerateResult}</>
        ) : (
          <BioIDGenerateSettingComponent
            onOk={(selection) => generateBioID(selection)}
            onCancel={() => setOpenBioIdPreviewModal(false)}
          />
        )}
      </Modal>
    </div>
  );
};
export default BioIdBriefPage;
