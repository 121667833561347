import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import patientTreatmentPlans from './patient-treatment-plan.store';
import treatmentProposal from './treatment-proposal.store';
import treatmentCheatsheet from './treatment-cheatsheet.store';
import currentPatient from './current-patient.store';
import currentAppuser from './current-appuser.store';
import currentLabResult from './current-lab-result.store';
import newsPosts from './news-posts.store';
import ongoingConversation from './ongoing-conversaion.store';
import authenticatedUser from './authenticated-user.store';
import patientConversations from './patient-conversations.store';
import timezone from './timezone.store';
import { listenerMiddleware } from './middleware';

const authenticationState = JSON.parse(localStorage.getItem('user') || 'null');
const timezoneState = localStorage.getItem('timezone') || '';

const reducer = combineReducers({
  authenticatedUser,
  patientTreatmentPlans,
  treatmentProposal,
  currentPatient,
  currentAppuser,
  treatmentCheatsheet,
  currentLabResult,
  newsPosts,
  ongoingConversation,
  patientConversations,
  timezone,
});

const store = configureStore({
  preloadedState: {
    authenticatedUser: authenticationState,
    timezone: timezoneState,
  },
  reducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), listenerMiddleware.middleware],
});

export default store;
