import React from "react";
import { Col, Row } from "react-bootstrap";
import DataTable from "../../../components/data-table/data-table.component";
import {
  AutoComplete,
  Button,
  Input,
  message,
  Select,
  Space,
  Upload,
  Tooltip,
} from "antd";
import "./main.css";
import moment from "moment";
import { getShippingOptions, searchOrders } from "../service";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import TrackingNumberFormModal from "./TrackingNumberModal";
import { Link } from "react-router-dom";
import { UPLOAD_TRACKING_NUMBER_URL } from "../../../api/URLs";
import bioApi from "../../../api/bioApi";
import DatePickerWithMoment from "../../../components/date/DatePickerWithMoment";
import { checkPresignUrl } from "../../patient/service";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

function ShipmentPage() {
  const [shipmentInfo, setShipmentInfo] = React.useState([]);
  const [searchType, setSearchType] = React.useState("Search by OrderID");
  const [type, setType] = React.useState("orderID");
  const [loading, setLoading] = React.useState(true);
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [sortType, setSortType] = React.useState("date");
  const [searchContent, setSearchContent] = React.useState("");
  const [rangeDate, setRangeDate] = React.useState([
    moment(),
    moment().add(1, "days"),
  ]);
  const [messageApi, contextHolder] = message.useMessage();
  const [buttonLoading, setButtonLoading] = React.useState({});
  
  const handleUrl = async (type, patientId,orderId) => {
    setButtonLoading(prevState => ({ ...prevState, [orderId]: true }));
    try {
        const urlData = await checkPresignUrl(type, patientId,null,orderId);
        if (urlData.data.status) {
            const base64Data = urlData.data.base64;
            const src = 'data:application/pdf;base64,' + base64Data;
    
            const newTab = window.open();
            if (newTab) {
            newTab.document.write('<iframe src="' + src + '" style="width:100%; height:100vh;"></iframe>');
            newTab.focus();
            } else {
            console.error('The pop-up was blocked by the browser.');
            }
        } else {
            messageApi.open({
            type: 'error',
            content: 'Failed to open file',
            });
        }
    } catch (err) {
      messageApi.open({
        type: 'error',
        content: 'Failed to open file',
      });
    }
    setButtonLoading(prevState => ({ ...prevState, [orderId]: false }));
  };

  const tableSettings = {
    appendable: false,
    removable: false,
    bordered: true,
    size: "small",
    pagination: {
      showLessItems: true,
      pageSize: 10,
    },
    columns: [
      {
        title: 'Create Date',
        dataIndex: 'creationTS',
        render: (text, record, index) =>
          moment(text).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY'),
      },
      {
        title: 'Order ID',
        dataIndex: 'orderID',
      },
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Invoice',
        render: (_, record) => {
          return record.invoice?.pdfLink ? (
            <Button
              loading={buttonLoading[record.orderID] || false}
              onClick={() => handleUrl('order', record.patientID, record.orderID + '_invoice')}
              target="_blank"
            >
              View
            </Button>
          ) : (
            'No Invoice'
          );
        },
      },
    ],
    expandable: {
      expandedRowRender: (record) => {
        const data = [];
        if (record.prescriptionOrder) {
          data.push({
            ...record.prescriptionOrder,
            patientID: record.patientID,
            patientName : record.patientName,
            orderID: record.orderID,
            type: 'prescription',
            subOrder: 'Prescription Order',
            _id: record._id + '_p',
          });
        }
        if (record.bProductOrder) {
          data.push({
            ...record.bProductOrder,
            patientID: record.patientID,
            patientName : record.patientName,
            orderID: record.orderID,
            type: 'bproduct',
            subOrder: 'biostation Product Order',
            _id: record._id + '_b',
          });
        }
        return <DataTable data={data} settings={innerTableSettings} />;
      },
    },
  };

  const innerTableSettings = {
    appendable: false,
    removable: false,
    bordered: true,
    size: 'small',
    operationColumnTitle: 'Action',
    pagination: false,
    columns: [
      {
        title: 'SubOrder',
        dataIndex: 'subOrder',
      },
      {
        title: 'Ship From',
        dataIndex: 'shipFrom',
      },
      {
        title: "Patient Name",
        dataIndex: "patientName",
        render: (record, data) => {
          if (data.patientID) {
            return (
              <Link to={"/patient/" + data.patientID + "/details"}>
                {" "}
                {data.patientName}
              </Link>
            );
          } else {
            return <span> {data.patientName}</span>;
          }
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'ETA Delivery Date',
        dataIndex: 'deliveryDate',
      },
      {
        title: 'Carrier',
        dataIndex: 'carrier',
      },
      {
        title: 'Tracking Number',
        dataIndex: 'trackingNumber',
      },
      {
        title: 'Destination',
        dataIndex: 'destination',
      },
    ],
    customActions: [
      {
        element: (record, data) => (
          <div className="d-flex">
            <Tooltip color="volcano" title={record.pdfLink ? '' : 'No packlist PDF is associated'}>
              <Button 
                  className='action-btn' 
                  disabled={!record.pdfLink} 
                  loading={buttonLoading[record.orderID] || false}
                  onClick={() => handleUrl('order', record.patientID, record.orderID + '_' + record.type)}
                  target="_blank">Print Packing List</Button>
            </Tooltip>
            {!record.trackingNumber || record.trackingNumber === null ? (
              <TrackingNumberFormModal
                orderID={record.orderID + '_' + record.type}
                fetchData={fetchShipmentData}
              />
            ) : (
              ""
            )}
          </div>
        ),
      },
    ],
  };

  const updateSearchType = (e) => {
    setSearchOptions([]);
    setSearchType("Search By " + e);
    setType(e);
  };

  const showSearchOptions = async (value) => {
    if (value != "") {
      const allOptions = await getShippingOptions(type, value);
      setSearchOptions(allOptions.data);
    } else {
      setSearchOptions([]);
      fetchShipmentData();
    }
  };

  const updateSortTypeAndSearch = async (value) => {
    setLoading(true);
    setSortType(value);
    const patientDataRequest = await searchOrders(
      type,
      searchContent,
      value,
      rangeDate,
    );
    if (patientDataRequest.data.data) {
      setShipmentInfo(patientDataRequest.data.data.map((order) => ({ ...order, key: order._id })));
    } else {
      setShipmentInfo();
    }
    setLoading(false);
  };
  const updateRangeDate = async (value) => {
    setLoading(true);
    setRangeDate(value);
    const patientDataRequest = await searchOrders(
      type,
      searchContent,
      sortType,
      value,
    );
    if (patientDataRequest.data.data) {
      setShipmentInfo(patientDataRequest.data.data.map((order) => ({ ...order, key: order._id })));
    } else {
      setShipmentInfo();
    }
    setLoading(false);
  };

  const uploadProps = {
    name: "file",
    maxCount: 1,
    action: bioApi.defaults.baseURL + UPLOAD_TRACKING_NUMBER_URL,
    async onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} uploaded successfully`);
        fetchShipmentData();
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} upload failed.`);
      }
    },
    withCredentials: true,
  };

  const searchShipmentData = async () => {
    setLoading(true);
    let patientDataRequest;
    patientDataRequest = await searchOrders(
      type,
      searchContent,
      sortType,
      rangeDate,
    );

    if (patientDataRequest.data.data) {
      setShipmentInfo(patientDataRequest.data.data.map((order) => ({ ...order, key: order._id })));
    } else {
      setShipmentInfo();
    }
    setLoading(false);
  };
  const fetchShipmentData = async () => {
    try {
      setLoading(true);
      const patientDataRequest = await searchOrders(
        type,
        searchContent,
        sortType,
        rangeDate,
      );
      if (patientDataRequest.data.data) {
        setShipmentInfo(patientDataRequest.data.data.map((order) => ({ ...order, key: order._id })));
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSearchContent = (value) => {
    setSearchContent(value);
  };

  const handleEnterKeySearch = (e) => {
    if (e.key === "Enter") {
      searchShipmentData();
    }
  };

  React.useEffect(() => {
    fetchShipmentData();
  }, [searchContent]);

  return (
    <>
      {contextHolder}
      <Row>
        <Col sm={12} className="pb-4">
          <Row className="align-items-end">
            <Col sm={6}>
              <Input.Group size="large" compact>
                <Select
                  defaultValue={type}
                  onSelect={updateSearchType}
                  size="large"
                  style={{
                    width: 150,
                    borderRadius: "4px",
                  }}
                >
                  <Option value="orderID">OrderID</Option>
                  <Option value="patientName">Patient Name</Option>
                </Select>
                <Space direction="vertical">
                  <AutoComplete
                    onSearch={showSearchOptions}
                    onSelect={searchShipmentData}
                    onChange={handleSearchContent}
                    style={{
                      width: 450,
                    }}
                    notFoundContent="No order found with searched key"
                    options={searchOptions}
                    onKeyDown={(e) => handleEnterKeySearch(e)}
                  >
                    <Input
                      size="large"
                      placeholder={searchType}
                      suffix={
                        <SearchOutlined onClick={() => searchShipmentData()} />
                      }
                      allowClear
                    />
                  </AutoComplete>
                </Space>
              </Input.Group>
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={3} className="sort-input">
                  <Input.Group compact>
                    <div
                      className="sort-label"
                      style={{ display: "inline-grid" }}
                    >
                      <label>Sort By</label>
                      <Select
                        defaultValue={sortType}
                        size="large"
                        onSelect={updateSortTypeAndSearch}
                        style={{
                          width: 150,
                        }}
                      >
                        <Option value="date">Date</Option>
                        <Option value="orderID">OrderID</Option>
                        <Option value="patientName">Patient Name</Option>
                        <Option value="shipFrom">Ship From</Option>
                        <Option value="status">Status</Option>
                        <Option value="destination">Destination</Option>
                      </Select>
                    </div>
                  </Input.Group>
                </Col>
                <Col sm={5} className="date-input">
                  <Input.Group size="large">
                    <div
                      className="date-label"
                      style={{ display: "inline-grid" }}
                    >
                      <label>Date</label>
                      <div className="date-input">
                        <DatePickerWithMoment.RangePicker
                          size={"large"}
                          defaultValue={rangeDate}
                          format={dateFormat}
                          placement="bottomLeft"
                          onChange={updateRangeDate}
                        />
                      </div>
                    </div>
                  </Input.Group>
                </Col>
                <Col
                  sm={4}
                  className="create-row d-flex justify-content-end align-items-end upload"
                >
                  <Upload {...uploadProps}>
                    <Button size="large" icon={<UploadOutlined />}>
                      Upload
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col sm={12}>
          <DataTable
            loading={loading}
            data={shipmentInfo}
            settings={tableSettings}
          />
        </Col>
      </Row>
    </>
  );
}

export default ShipmentPage;
