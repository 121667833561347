import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { treatmentPlanStatusDisplayMap } from '../patient/child-pages/treatment-review.page';
import { Link } from 'react-router-dom';
import bioApi from '../../api/bioApi';
import { TREATMENT_FROM_ME_URL } from '../../api/URLs';
import { message, Popover, Typography } from 'antd';
import DataTable from '../../components/data-table/data-table.component';

export const MyTreatmentSubmissionsPage = () => {
  const [treatments, setTreatments] = useState([]);
  const [loading, setLoading] = useState(true);

  const tableSetting = {
    columns: [
      {
        title: 'Creation Date',
        dataIndex: 'createdDate',
        render: (createdDate) => moment(createdDate).format('YYYY-MM-DD hh:mma (z)'),
        sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
      },
      {
        title: 'Last Updated Date',
        dataIndex: ['statusHistory', '0', 'ts'],
        render: (d) => moment(d).format('YYYY-MM-DD hh:mma (z)'),
        sorter: (a, b) => moment(a.statusHistory[0].ts).unix() - moment(b.statusHistory[0].ts).unix(),
      },
      {
        title: 'Reviewers',
        render: (_, record) => {
          let popover = <></>;
          if (record.reviewers.length > 3) {
            popover = (
              <Popover
                content={record.reviewers
                  .filter((r, i) => i > 2)
                  .map((r) => (
                    <div key={r.name}>{r.name}</div>
                  ))}
              >
                <Typography.Link> and {record.reviewers.length - 3} more...</Typography.Link>
              </Popover>
            );
          }
          return (
            <div>
              {record.reviewers
                .filter((r, i) => i <= 2)
                .map((r) => r.name)
                .join(', ')}
              {popover}
            </div>
          );
        },
      },
      {
        title: 'Current Status',
        dataIndex: ['statusHistory', '0', 'status'],
        render: (status) => treatmentPlanStatusDisplayMap[status],
        filterable: true,
      },
      {
        title: 'Patient Name',
        dataIndex: 'patientName',
        render: (patientName, record) => <Link to={`../patient/${record.patientId}/treatment`}>{patientName}</Link>,
      },
    ],
  };

  useEffect(() => {
    (async () => {
      try {
        const treatmentsFromMe = await bioApi.get(TREATMENT_FROM_ME_URL);
        setTreatments(treatmentsFromMe.data);
      } catch (e) {
        message.error('Failed to load treatments');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div style={{ marginInline: 12 }}>
      <Typography.Title level={4}>Pending Treatments Created By Me</Typography.Title>
      <DataTable data={treatments} settings={tableSetting} loading={loading} />
    </div>
  );
};
