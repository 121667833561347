import { Avatar, Card, Input, message, Modal, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { getStaffSchedules } from './service';
import { scheduleBlockedTime } from '../patient/service';
import moment from 'moment';
import DatePickerWithMoment from '../../components/date/DatePickerWithMoment';

const AddBlockedTimeModal = ({ show, staff, onFinish }) => {
  const [open, setOpen] = useState(show);
  const [selectedDate, setSelectedDate] = useState('');
  const [startEnd, setStartEnd] = useState([null, null]);
  const [description, setDescription] = useState('');
  const [enableOk, setEnableOK] = useState(false);
  const [currentDayExistingEvents, setCurrentDayExistingEvents] = useState([]);
  const [isOverlapping, setIsOverlapping] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [currentDaySchedule, setCurrentDaySchedule] = useState(undefined);

  const isTimeOverlap = (start1, end1, start2, end2) => {
    const start1_ms = new Date(`1970-01-01T${start1}:00Z`).getTime();
    const end1_ms = new Date(`1970-01-01T${end1}:00Z`).getTime();
    const start2_ms = new Date(`1970-01-01T${start2}:00Z`).getTime();
    const end2_ms = new Date(`1970-01-01T${end2}:00Z`).getTime();

    if (start1_ms < end2_ms && start2_ms < end1_ms) {
      return true;
    }

    // Check if one range contains another
    if (start1_ms >= start2_ms && end1_ms <= end2_ms) {
      return true;
    }
    if (start2_ms >= start1_ms && end2_ms <= end1_ms) {
      return true;
    }

    // Otherwise, no overlap or containment
    return false;
  };

  const bookBlockedTime = async () => {
    try {
      const startHour = startEnd[0].split(':')[0];
      const startMinute = startEnd[0].split(':')[1];
      const endHour = startEnd[1].split(':')[0];
      const endMinute = startEnd[1].split(':')[1];

      setSubmitting(true);

      const payload = {
        start: moment
          .tz(selectedDate, 'YYYY-MM-DD', currentDaySchedule.locationDetail.timezone)
          .set('hour', startHour)
          .set('minute', startMinute)
          .toDate(),
        end: moment
          .tz(selectedDate, 'YYYY-MM-DD', currentDaySchedule.locationDetail.timezone)
          .set('hour', endHour)
          .set('minute', endMinute)
          .toDate(),
        date: selectedDate,
        staffId: staff.staffId,
        description,
      };
      await scheduleBlockedTime(payload);
      onFinish(true);
    } catch (e) {
      message.error('Unable to book the blocked time!');
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (selectedDate && !!currentDaySchedule && startEnd[0] && startEnd[1]) {
      const hasOverlapping = !!currentDayExistingEvents.find((a) =>
        isTimeOverlap(
          moment.tz(a.start, currentDaySchedule.locationDetail.timezone).format('HH:mm'),
          moment.tz(a.end, currentDaySchedule.locationDetail.timezone).format('HH:mm'),
          startEnd[0],
          startEnd[1]
        )
      );
      if (hasOverlapping) {
        setIsOverlapping(true);
        setEnableOK(false);
      } else {
        setIsOverlapping(false);
        if (description) {
          setEnableOK(true);
        }
      }
    } else {
      setEnableOK(false);
    }
  }, [startEnd, currentDayExistingEvents]);

  useEffect(() => {
    if (selectedDate && startEnd[0] && startEnd[1] && !isOverlapping && description) {
      setEnableOK(true);
    }
  }, [description]);

  useEffect(() => {
    if (selectedDate) {
      (async () => {
        try {
          const schedulesRequest = await getStaffSchedules({
            staff: staff.staffId,
            date: selectedDate,
          });
          const schedules = schedulesRequest.data.data;
          setCurrentDayExistingEvents(
            schedules[0].appointments.filter((a) => a.status === 'Booked').concat(schedules[0].blockedTimes)
          );
          setCurrentDaySchedule(schedules[0]);
        } catch (e) {
          setCurrentDaySchedule(null);
        }
      })();
    }
  }, [selectedDate]);

  useEffect(() => setOpen(show), [show]);

  return (
    <Modal
      open={open}
      width={360}
      title={'Add Blocked Time'}
      onCancel={onFinish}
      okButtonProps={{
        disabled: !enableOk || !currentDaySchedule,
        loading: submitting,
      }}
      onOk={bookBlockedTime}
    >
      <Space direction="vertical">
        <div>
          <Typography.Title level={5}>Date:</Typography.Title>
          <DatePickerWithMoment
            size={'large'}
            format={'dddd, MMMM Do, YYYY'}
            style={{ width: 300 }}
            onChange={(date, dateString) => setSelectedDate(date.format('YYYY-MM-DD'))}
          />
          {currentDaySchedule === null && (
            <Typography.Text type="danger">
              No schedule was set for the provider in this particular day.
            </Typography.Text>
          )}
        </div>
        <div>
          <Typography.Title level={5}>Provider:</Typography.Title>
          <Card style={{ width: 300 }}>
            <Avatar size="large" src={staff.coverImage} style={{ marginRight: 10 }} />
            <span>{`${staff.firstName} ${staff.lastName}`}</span>
          </Card>
          {!!currentDaySchedule && (
            <Typography.Text type="secondary">@ {currentDaySchedule.locationDetail.name}</Typography.Text>
          )}
        </div>
        <div>
          <Typography.Title level={5}>Time:</Typography.Title>
          <DatePickerWithMoment.RangePicker
            picker="time"
            disabledTime={() => ({
              disabledHours: () => {
                if (!currentDaySchedule) {
                  return [...Array(24).keys()];
                }
                const startHour = moment
                  .tz(currentDaySchedule.start, currentDaySchedule.locationDetail.timezone)
                  .get('hour');
                const endHour = moment
                  .tz(currentDaySchedule.end, currentDaySchedule.locationDetail.timezone)
                  .get('hour');

                // Initialize an array to hold the hours outside the range
                const outsideRangeHours = [];

                // Loop through all the hours in the day (0-23)
                for (let hour = 0; hour < 24; hour++) {
                  // Check if the hour is outside the range
                  if (hour < startHour || hour > endHour) {
                    outsideRangeHours.push(hour);
                  }
                }
                return outsideRangeHours;
              },
              disabledMinutes: (selectedHour) => {
                if (!currentDaySchedule) {
                  return [...Array(60).keys()];
                }
                const minutes = [];
                const scheduleStartHour = moment
                  .tz(currentDaySchedule.start, currentDaySchedule.locationDetail.timezone)
                  .get('hour');
                const scheduleEndHour = moment
                  .tz(currentDaySchedule.end, currentDaySchedule.locationDetail.timezone)
                  .get('hour');
                const scheduleStartMinute = moment
                  .tz(currentDaySchedule.start, currentDaySchedule.locationDetail.timezone)
                  .get('minute');
                const scheduleEndMinute = moment
                  .tz(currentDaySchedule.end, currentDaySchedule.locationDetail.timezone)
                  .get('minute');
                if (selectedHour === scheduleStartHour) {
                  for (let minute = 0; minute < scheduleStartMinute; minute++) {
                    minutes.push(minute);
                  }
                } else if (selectedHour === scheduleEndHour) {
                  for (let minute = scheduleEndMinute + 1; minute < 60; minute++) {
                    minutes.push(minute);
                  }
                }
                return minutes;
              },
            })}
            disabled={[!currentDaySchedule, !currentDaySchedule]}
            showSecond={false}
            hideDisabledOptions={true}
            minuteStep={10}
            allowEmpty={[false, false]}
            allowClear={false}
            order={true}
            format={'HH:mm'}
            style={{ width: 300 }}
            onChange={(time, timeString) => {
              setStartEnd([timeString[0], timeString[1]]);
            }}
            status={isOverlapping ? 'error' : ''}
            needConfirm={false}
          />
        </div>
        {isOverlapping && (
          <Typography.Text type="danger">
            The chosen time overlaps with an existing appointment or blocked time. You must address the conflicts before
            submitting this blocked time.
          </Typography.Text>
        )}
        <div>
          <Typography.Title level={5}>Description:</Typography.Title>
          <Input.TextArea
            placeholder={'e.g. lunch meeting'}
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={!currentDaySchedule}
          />
        </div>
      </Space>
    </Modal>
  );
};

export default AddBlockedTimeModal;
