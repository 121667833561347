import { Button, Checkbox, Input, message, Modal, Space, Tooltip, Upload } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replyMessage } from './message.service';
import { appendToOngoingConversationIfMatches } from '../../stores/ongoing-conversaion.store';
import { addReplyMessage } from '../../stores/patient-conversations.store';
import useMessage from 'antd/es/message/useMessage';
import { fileToBase64 } from '../../share/StringHelper';
import Viewer from 'react-viewer';

const MessageSendingComponent = () => {
  const [inputText, setInputText] = useState('');
  const [messageSending, setMessageSending] = useState(false);
  const [enterToSend, setEnterToSend] = useState(true);
  const [attachedImage, setAttachedImage] = useState({ base64: '', filename: '' });
  const [inputStatus, setInputStatus] = useState('');
  const [messageApi, context] = useMessage();
  const [previewOpen, setPreviewOpen] = useState(false);

  const ongoingConversation = useSelector((state) => state.ongoingConversation);
  const user = useSelector((state) => state.authenticatedUser);

  const dispatch = useDispatch();

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async () => {
    setPreviewOpen(true);
  };

  const sendMessage = async () => {
    try {
      setMessageSending(true);
      let message;

      if (attachedImage.base64) {
        if (inputText) {
          message = {
            text: inputText,
            image: { ...attachedImage, base64: attachedImage.base64.split(',')[1] },
          };
        } else {
          message = {
            image: { ...attachedImage, base64: attachedImage.base64.split(',')[1] },
          };
        }
      } else {
        message = {
          text: inputText,
        };
      }

      const replyMessageReq = await replyMessage(ongoingConversation.patient.appuserId, message);

      const savedMessages = replyMessageReq.data.map((d) => ({
        _id: d._id,
        type: d.type,
        content: d.content,
        associatedTo: d.associatedTo,
        timestamp: new Date().getTime(),
        from: 'staff',
        staff: {
          name: user.name,
          coverImage: user.coverImage,
          staffId: user.staffId,
        },
      }));

      let imageFailed = false;

      savedMessages
        .filter((m) => !m._id)
        .forEach((m, i) => {
          if (m.type === 'image_url') {
            imageFailed = true;
          }
          imageFailed = true;
          messageApi.open({
            key: 'error_sending_' + i,
            type: 'error',
            content: 'unable to send: ' + m.type === 'text' ? message.text : 'image',
            duration: 0,
            onClick: (e) => messageApi.destroy('error_sending_' + i),
          });
        });

      savedMessages
        .filter((m) => !!m._id)
        .forEach((m) => {
          dispatch(
            appendToOngoingConversationIfMatches({
              patient: ongoingConversation.patient,
              message: m,
            })
          );
          dispatch(
            addReplyMessage({
              appuserId: ongoingConversation.patient.appuserId,
              message: m,
            })
          );
        });

      setInputText('');

      if (!imageFailed) {
        setAttachedImage({ base64: '', filename: '' });
      }
    } catch (e) {
      message.error('Unable to send message', 40);
    } finally {
      setMessageSending(false);
    }
  };

  const handlePaste = async (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const blob = items[i].getAsFile();
        const base64 = await fileToBase64(blob);

        setAttachedImage({ base64, filename: blob.name ? blob.name : 'screenshot.jpg' });
      }
    }
  };

  useEffect(() => {
    if (inputText.trim().length || attachedImage.base64) {
      setInputStatus('');
    }
  }, [inputText, attachedImage]);

  return (
    <div
      className={`message-sending ${inputStatus}`}
      style={{ display: 'flex', flexDirection: 'column', border: '1px solid #d9d9d9', borderRadius: 6 }}
    >
      {context}
      <Input.TextArea
        autoSize={{ minRows: 1 }}
        placeholder="Type your message or directly paste your screenshot..."
        value={inputText}
        onChange={(e) => {
          setInputText(e.target.value);
        }}
        style={{
          border: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        disabled={!ongoingConversation.patient || messageSending}
        onPaste={handlePaste}
        onPressEnter={async (e) => {
          if (e.key === 'Enter') {
            if (!enterToSend) {
              return;
            }
            if (!(attachedImage.base64 || inputText.trim().length)) {
              if (!e.shiftKey) {
                setInputStatus('error');
                e.preventDefault();
                return;
              }
            }
            if (!e.shiftKey && enterToSend) {
              await sendMessage();
            }
          }
        }}
      />
      {!!attachedImage.base64 && (
        <Space style={{ background: '#ffffff' }}>
          <Upload
            listType={'picture'}
            style={{ width: 200 }}
            maxCount={1}
            fileList={[
              {
                uid: '-1',
                name: attachedImage.filename,
                status: 'done',
                url: attachedImage.base64,
              },
            ]}
            disabled={messageSending}
            onPreview={handlePreview}
            onRemove={() => setAttachedImage({ base64: '', filename: '' })}
          />
        </Space>
      )}
      <Space
        style={{
          background: !ongoingConversation.patient || messageSending ? 'rgba(0,0,0, 0.04)' : '#f9f9f9f9',
          justifyContent: 'space-between',
          padding: 4,
          borderRadius: 6,
          borderTop: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Upload
          beforeUpload={async (file) => setAttachedImage({ base64: await fileToBase64(file), filename: file.name })}
          className={'attach-image-button'}
        >
          <Button
            title={attachedImage.base64 ? 'Only 1 image is allowed' : 'Attach image'}
            disabled={!ongoingConversation.patient || messageSending || attachedImage.base64}
            icon={<PictureOutlined />}
            size={'small'}
          />
        </Upload>
        <Space>
          <Tooltip title="Shift + Enter for new line" placement={'left'}>
            <Checkbox defaultChecked checked={enterToSend} onChange={() => setEnterToSend(!enterToSend)}>
              ENTER to send
            </Checkbox>
          </Tooltip>
          <Button
            disabled={
              !ongoingConversation.patient || messageSending || (!inputText.trim().length && !attachedImage.base64)
            }
            loading={messageSending}
            onClick={sendMessage}
            size={'small'}
          >
            Send
          </Button>
        </Space>
      </Space>
      <Viewer
        visible={previewOpen}
        onClose={() => setPreviewOpen(false)}
        images={[{ src: attachedImage.base64, alt: attachedImage.filename }]}
        onMaskClick={() => setPreviewOpen(false)}
        rotatable={false}
        scalable={false}
        noNavbar
      />
    </div>
  );
};

export default MessageSendingComponent;
