import React from 'react';
import reviewIcon from '../../../assets/images/review.png';
import { FormatDateToString } from '../../../share/StringHelper';
import bioApi from '../../../api/bioApi';
import Pager from '../../../components/Pager/PagerControl';
import { WithBio } from '../../../components/mycomponent/WithBio';
import { Table, Alert } from 'react-bootstrap';
import { Modal } from 'antd';

const AnnouncementList = ({staffId}) => {

  const handlePageIndexChange = (i) => {
    setPagerSetting(ps => ({...ps, pageIndex: i}));
    fetchAnnouncements(i+1);
  };

  const [announcements, setAnnouncements] = React.useState(null);
  const [pagerSetting, setPagerSetting] = React.useState({
    totalCount: 0,
    pageSize: 10,
    pageIndex: 0,
    firstText: 'First',
    prevText: 'Prev',
    nextText: 'Next',
    lastText: 'Last',
    recordTextFormat: "{0}/{1}",
    goWidth: 50,
    showLinkNum: 5,
    callBack: handlePageIndexChange
  });
  const [loading, setLoading] = React.useState(false);
  const [al, setAl] = React.useState(null);
  const [showDetails, setShowDetails] = React.useState(null);

  React.useEffect(() => {
    fetchAnnouncements();
  },[]);

  const fetchAnnouncements = async (overwritepage) => {
    setLoading(true);
    try{
      const results = await bioApi.post('/announcement/list', {
        "staffId": staffId, "limit": 10, page: overwritepage || pagerSetting.pageIndex + 1
      }).then(res => res.data);
      if(results.status === 200){
        setPagerSetting(ps => ({...ps, totalCount: results.totalCount}))
        setAnnouncements(results.data);
      } else {
        setAl({text: results.message || `Cannot fetch at this time`, variant: "danger"});
      }
    }catch(err){
      setAl({text: err.message, variant: "danger"});
    };
    setLoading(false);
  };

  const viewContent = (id) => {
    setShowDetails(id);
  };

  const deleteContent = async (i) => {
    if (!window.confirm(`Are you sure you want to revoke ${i.title}?`)) return;
    setLoading(true);
    try{
      const results = await bioApi.post('/announcement/inactive', {
        "announcementObjectID": i._id
      }).then(res => res.data);
      if(results.status === 200){
        var tempann = [... announcements];
        var tx = tempann.find(j => j.announcementID === i.announcementID);
        tx.isActive = false;
        tempann[tempann.indexOf(tx)] = tx;
        setAnnouncements(tempann);
        setAl({text: results.messasge || `Successfully revoked ${i.title} - ${i.announcementID}`, variant: "success"});
      } else {
        setAl({text: results.messasge || `Cannot revoke at this time! Please try again later`, variant: "danger"});
      }
    }catch(err){
      setAl({text: err.message, variant: "danger"});
    };
    setLoading(false);
  };

  return (
    <div className="box">
      {
        al ? <Alert variant={al.variant} onClose={() => setAl(null)} dismissible>
          {al.text}
        </Alert> : null
      }
      {
        loading && <Alert>
          Loading ... 
        </Alert>
      }
      {
        announcements && announcements.length && <Table bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Send Date</th>
            <th>Announcement ID</th>
            <th>Title</th>
            <th>Subtitle</th>
            <th>Validity</th>
            <th>Status</th>
            <th>CreatedBy</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            announcements.map((ann, anni) => (
              <tr key={ann.announcementID}>
                <td>{anni+1}</td>
                <td>{FormatDateToString(ann.createDate)}</td>
                <td>{ann.announcementID}</td>
                <td>{ann.title}</td>
                <td>{ann.subTitle}</td>
                <td>{`${FormatDateToString(ann.validStart)} - ${FormatDateToString(ann.validEnd)}`}</td>
                <td>{ann.isActive ? 'active' : 'inactive'}</td>
                <td>{`HQ`}</td>
                <td>
                  <div onClick={!loading ? () => viewContent(ann) : () => {}} style={{display: "flex", flexDirection: "row", height: '100%', alignItems: 'center', cursor: "pointer"}}>
                    <div style={{marginRight: 8}}>
                      <img src={reviewIcon} alt="edit" className="icon-edit" />
                    </div>
                    {ann.isActive && <div onClick={!loading ? () => deleteContent(ann) : () => {}}  className="btn btn-blue btn-sm" style={{ padding: 5, backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 80%)", color: "#e52d47", cursor: "pointer" }}>
                      Revoke
                    </div>}
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
      }
      {!loading && announcements && announcements.length && <div style={{ display: "flex" }}>
        <div style={{ flex: "1" }}></div>
        <div className="panel-body" style={{ padding: "10px 15px" }}>
          <Pager {...pagerSetting} />
        </div>
      </div>}

      {showDetails && <Modal onOk={() => setShowDetails(null)} onCancel={() => setShowDetails(null)} onCl title={`Announcement Details ${showDetails.announcementID}`} open={true} cancelButtonProps={{ disabled: true, hidden: true }}>
        <p style={{fontSize: 20}}>{showDetails.title}</p>
        {
          showDetails.details.map((d, di) => (
            <div key={`${showDetails._id} - ${di} - annd`}>
              <div style={{fontSize: 12, color: "rgba(0,0,0,.5)"}}>{d.detail_type}</div>
              <div style={{fontWeight: 600}}>{d.text}</div>
            </div>
          ))
        }
      </Modal>}

    </div>
  );
};

export default WithBio(AnnouncementList);