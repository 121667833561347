import React, { useEffect, useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Col,
  Collapse,
  Divider,
  List,
  message,
  Modal,
  Popconfirm,
  Popover,
  Result,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { BellTwoTone, DeleteOutlined, EditTwoTone, QuestionCircleTwoTone } from '@ant-design/icons';
import SettingTextAreaInput from '../../components/setting/SettingTextAreaInput';
import OptimalRangeLogicComponent from './optimal-range-logic.component';
import bioApi from '../../api/bioApi';
import { SEARCH_PRODUCTS } from '../../api/URLs';
import MedicineConditionLogicComponent from './medicine-condition-logic.component';
import { updateBiomarker } from './service';

const ModifyBiomarkerModal = ({ biomarker, open, onSubmit, onClose, biomarkerList }) => {
  const [show, setShow] = useState(open);
  const [data, setData] = useState(null);
  const [productSearchResult, setProductSearchResult] = useState([]);
  const [title, setTitle] = useState(<></>);
  const [splashButtons, setSplashButtons] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchThrottling, setSearchThrottling] = useState(false);
  const [addedMedicineList, setAddedMedicineList] = useState([]);
  const [disableOK, setDisableOK] = useState(true);
  const [alertMsg, setAlertMsg] = useState(<></>);
  const [loading, setLoading] = useState(false);
  const [originalBiomarkerData, setOriginalBiomarkerData] = useState('');

  const handleCancel = () => {
    setShow(false);
    onClose();
  };

  const handleOk = async () => {
    const payload = {
      _id: data._id,
      range: data.range,
      optimalRange: data.optimalRange,
      description: data.description,
      symptoms: data.symptoms,
      medications: data.medications,
      type: biomarker.type,
    };
    try {
      setLoading(true);
      await updateBiomarker(payload);
      message.success('Successfully updated Biomarker: ' + biomarker.name + ' ' + data.gender);
      onSubmit(data);
    } catch (e) {
      message.error('Unable to update Biomarker: ' + biomarker.name + ' ' + data.gender);
    }
    setLoading(false);
  };

  const setModalTitle = (marker) => {
    let modalTitle;
    if (marker.gender === 'male') {
      modalTitle = (
        <Space>
          <span>
            <EditTwoTone /> {biomarker.name}
          </span>
          <Badge count={'Male'} color={'rgb(45, 183, 245)'} />
        </Space>
      );
    } else if (marker.gender === 'female') {
      let text;
      if (marker.biologicalStage === 'menopause') {
        text = 'Female - Menopause';
      } else if (marker.biologicalStage === 'luteal') {
        text = 'Female - Luteal';
      } else {
        text = 'Female';
      }
      modalTitle = (
        <Space>
          <span>
            <EditTwoTone /> {biomarker.name}
          </span>
          <Badge count={text} color={'#f50'} />
        </Space>
      );
    } else {
      modalTitle = (
        <Space>
          <span>
            <EditTwoTone /> {biomarker.name}
          </span>
          <Badge count={'Male'} color={'rgb(45, 183, 245)'} /> & <Badge count={'Female'} color={'#f50'} />
        </Space>
      );
    }
    setTitle(modalTitle);
  };

  let searchDebounceTimeout;
  let searchCriteria;
  let lastSearch = '';
  const debouncedSearch = (newValue) => {
    clearTimeout(searchDebounceTimeout);
    searchDebounceTimeout = setTimeout(async () => {
      if (newValue && newValue.length > 3) {
        searchCriteria = newValue;
        await immediateSearch(newValue, true);
      }
    }, 1000);
  };

  const immediateSearch = async (newValue, fromDebounce) => {
    if (lastSearch === newValue || searchThrottling) return;
    setIsSearching(true);
    if (newValue) {
      lastSearch = newValue;
      setSearchThrottling(true);
      const result = await bioApi.get(SEARCH_PRODUCTS, { params: { query: newValue, exclude: 'MedicalSupply' } });
      if (fromDebounce) {
        if (searchCriteria === newValue) {
          setProductSearchResult(result.data);
        }
      } else {
        setProductSearchResult(result.data);
      }
    }
    setTimeout(() => setSearchThrottling(false), 1000);
    setIsSearching(false);
  };

  const checkIfConditionOverlaps = (medications) => {
    const checkConditionOverlap = (condition1, condition2) => {
      const greaterThan1 =
        condition1 && (condition1['>'] !== undefined || !isNaN(condition1['>'])) ? Number(condition1['>']) : -Infinity;
      const lessThan1 =
        condition1 && (condition1['<'] !== undefined || !isNaN(condition1['<'])) ? condition1['<'] : Infinity;
      const greaterThan2 =
        condition2 && (condition2['>'] !== undefined || !isNaN(condition2['>'])) ? condition2['>'] : -Infinity;
      const lessThan2 =
        condition2 && (condition2['<'] !== undefined || !isNaN(condition2['<'])) ? condition2['<'] : Infinity;
      const biologicalStage1 = condition1 ? condition1['biologicalStage'] : undefined;
      const biologicalStage2 = condition2 ? condition2['biologicalStage'] : undefined;

      return greaterThan1 < lessThan2 && greaterThan2 < lessThan1 && biologicalStage1 === biologicalStage2;
    };

    const allConditions = medications.map((m) => m.when);

    const overlappingConditions = [];

    for (let i = 0; i < allConditions.length; i++) {
      for (let j = i + 1; j < allConditions.length; j++) {
        if (checkConditionOverlap(allConditions[i], allConditions[j])) {
          overlappingConditions.push({
            condition1: allConditions[i],
            condition2: allConditions[j],
          });
        }
      }
    }

    return !!overlappingConditions.length;
  };

  const findDuplicateMedications = (medications) => {
    const seen = new Map();
    const dupMedications = [];

    medications.forEach((medication) => {
      const key = `${medication.product._id}-${JSON.stringify(medication.when)}`;
      if (seen.has(key)) {
        dupMedications.push(medication);
      } else {
        const conditionOverlaps = checkIfConditionOverlaps(
          medications.filter((m) => m.product._id === medication.product._id)
        );
        if (conditionOverlaps) {
          if (!seen.has(medication.product._id)) {
            seen.set(medication.product._id, true);
            dupMedications.push(medication);
          }
        } else {
          seen.set(key, true);
        }
      }
    });

    return dupMedications;
  };

  useEffect(() => {
    if (biomarker && biomarker.tabs.length) {
      if (biomarker.tabs.length > 1) {
        setTitle(
          <span>
            <EditTwoTone /> {biomarker.name}
          </span>
        );
        setSplashButtons(
          biomarker.tabs.map((tab) => {
            let text, isFemale;
            if (tab.gender === 'male') {
              text = 'Male';
              isFemale = false;
            } else {
              isFemale = true;
              if (tab.biologicalStage) {
                if (tab.biologicalStage === 'menopause') {
                  text = 'Female - Menopause';
                } else {
                  text = 'Female - Luteal';
                }
              } else {
                text = 'Female';
              }
            }
            return (
              <Button
                size={'large'}
                type="primary"
                onClick={() => {
                  setData(JSON.parse(JSON.stringify(tab)));
                  setModalTitle(tab);
                }}
                danger={isFemale}
              >
                {text}
              </Button>
            );
          })
        );
      } else {
        setSplashButtons([]);
        setModalTitle(biomarker.tabs[0]);
        setData(JSON.parse(JSON.stringify(biomarker.tabs[0])));
      }
    }
  }, [biomarker]);

  useEffect(() => setShow(open), [open]);

  useEffect(() => {
    if (data) {
      const duplication = findDuplicateMedications(data.medications);
      setAlertMsg(
        !!duplication.length ? (
          <div>
            There are same medications with the same or overlapped applying condition:
            {duplication.map((d) => (
              <div>{d.product.name}</div>
            ))}
          </div>
        ) : (
          <></>
        )
      );
      if (!originalBiomarkerData) {
        setOriginalBiomarkerData(JSON.stringify(data));
      } else {
        setDisableOK(originalBiomarkerData === JSON.stringify(data) || !!duplication.length);
      }
    }
  }, [data]);

  return (
    <Modal
      title={title}
      open={!!show}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
      destroyOnClose
      width={1000}
    >
      {!data ? (
        <Result
          icon={<QuestionCircleTwoTone />}
          subTitle="Choose the one you want to modify"
          extra={
            <Space>
              {splashButtons.map((sb, i) => (
                <div key={i}>{sb}</div>
              ))}
            </Space>
          }
        />
      ) : (
        <Row gutter={16}>
          <Col span={10}>
            <Row gutter={16} align={'middle'}>
              <Col span={8}>
                <Typography.Text type={'secondary'}>Optimal Range:</Typography.Text>
              </Col>
              <Col span={16}>
                <OptimalRangeLogicComponent
                  logic={data.optimalRange}
                  title={'Change Optimal Range'}
                  biomarkerList={biomarkerList
                    .filter((b) => b.name !== biomarker.name)
                    .filter((b) => [data.gender, 'unisex'].includes(b.gender))
                    .map((b) => b.name)}
                  onConfirm={(newOptimalRange, rangeDisplay) => {
                    data.optimalRange = newOptimalRange;
                    data.range = rangeDisplay;
                    setData({ ...data });
                  }}
                  disabled={biomarker.type === 'Symptoms and Goals'}
                />
              </Col>
            </Row>
            <Row>
              <SettingTextAreaInput
                value={data.symptoms}
                label={'Symptoms'}
                editable
                editingRender={{
                  save: (value) => {
                    data.symptoms = value;
                    setData({ ...data });
                  },
                }}
              />
            </Row>
            <Row>
              <SettingTextAreaInput
                value={data.description}
                label={'Description'}
                editable
                editingRender={{
                  save: (value) => {
                    data.description = value;
                    setData({ ...data });
                  },
                }}
              />
            </Row>
          </Col>
          <Col span={14}>
            <Row>
              <Collapse
                size="small"
                activeKey={'medicine'}
                style={{ width: '100%' }}
                items={[
                  {
                    key: 'medicine',
                    label: `Associated Medicines (${data.medications.length})`,
                    extra: (
                      <Select
                        showSearch
                        notFoundContent={null}
                        size="small"
                        style={{ width: 304 }}
                        onSearch={(value) => debouncedSearch(value)}
                        loading={isSearching}
                        placeholder="Search Product/ Medicine to add"
                        allowClear
                        onClear={() => setProductSearchResult([])}
                        optionLabelProp={'label'}
                        defaultActiveFirstOption={false}
                        filterOption={false}
                      >
                        {productSearchResult.map((p, i) => {
                          const alreadyAdded = !!data.medications.find((m) => m.product._id === p._id);

                          return (
                            <Select.Option value={p._id} label={p.name} key={i} product={p}>
                              <Popover
                                content={
                                  <div style={{ maxWidth: 300 }}>
                                    <Space direction={'vertical'}>
                                      <Space>
                                        <Typography.Text type={'secondary'}>List Price:</Typography.Text>
                                        <Typography.Text>${p.listPrice}</Typography.Text>
                                      </Space>
                                      <Space>
                                        <Typography.Text type={'secondary'}>Type:</Typography.Text>
                                        <Typography.Text>{p.type}</Typography.Text>
                                      </Space>
                                      <Space>
                                        <Typography.Text type={'secondary'}>Therapy Level:</Typography.Text>
                                        <Typography.Text>{p.therapyLevel}</Typography.Text>
                                      </Space>
                                      <Space direction={'vertical'}>
                                        <Typography.Text type={'secondary'}>Dosages:</Typography.Text>
                                        <Typography.Text>
                                          {p.dosageList ? p.dosageList.map((d) => <p>{d.dosage}</p>) : <></>}
                                        </Typography.Text>
                                      </Space>
                                      {alreadyAdded && (
                                        <>
                                          <Divider />
                                          <Typography.Text type={'warning'}>
                                            This medicine/ product is already in the list, but you can still add it and
                                            configure it with a different apply condition
                                          </Typography.Text>
                                        </>
                                      )}
                                    </Space>
                                  </div>
                                }
                                placement={'left'}
                                color={'#fff'}
                              >
                                <div
                                  style={{
                                    marginRight: 8,
                                    wordBreak: 'break-all',
                                    wordWrap: 'break-word',
                                    whiteSpace: 'initial',
                                  }}
                                >
                                  <div>
                                    {alreadyAdded && <BellTwoTone />} {p.name}
                                  </div>
                                  <Button
                                    danger={alreadyAdded}
                                    size={'small'}
                                    block
                                    onClick={() => {
                                      data.medications.unshift({
                                        isDefault: false,
                                        recommend: false,
                                        product: p,
                                      });
                                      setData({ ...data });
                                      setAddedMedicineList([...addedMedicineList, p._id]);
                                    }}
                                  >
                                    ADD TO LIST
                                  </Button>
                                </div>
                              </Popover>
                            </Select.Option>
                          );
                        })}
                      </Select>
                    ),
                    children: (
                      <div style={{ height: 300, overflowY: 'auto' }}>
                        <List
                          itemLayout="vertical"
                          dataSource={data.medications}
                          size="small"
                          renderItem={(medicine, i) => {
                            const isNewAdded = addedMedicineList.includes(medicine.product._id);

                            return (
                              <List.Item key={i}>
                                <Row className="d-flex justify-content-between align-items-center" gutter={8}>
                                  <Col span={18}>
                                    <div style={{ wordBreak: 'break-all' }}>
                                      {isNewAdded && (
                                        <Badge status="processing" style={{ marginRight: 4 }} title={'New Add'} />
                                      )}
                                      {medicine.product.name}
                                    </div>
                                    <div>
                                      <MedicineConditionLogicComponent
                                        logic={medicine.when}
                                        optimalRangeDisplay={data.range}
                                        title={`Modify applying condition for medicine: ${medicine.product.name}`}
                                        biomarkerList={biomarkerList
                                          .filter((b) => b.name !== biomarker.name)
                                          .filter((b) => [data.gender, 'unisex'].includes(b.gender))
                                          .map((b) => b.name)}
                                        onConfirm={(newLogic) => {
                                          medicine.when = newLogic;
                                          setData({ ...data });
                                        }}
                                        disabled={biomarker.type === 'Symptoms and Goals'}
                                      />
                                    </div>
                                  </Col>

                                  <Col span={6}>
                                    <Space>
                                      <Button
                                        size={'small'}
                                        style={
                                          medicine.recommend
                                            ? { background: 'orange', color: 'white' }
                                            : { background: 'white' }
                                        }
                                        onClick={() => {
                                          medicine.recommend = !medicine.recommend;
                                          setData({ ...data });
                                        }}
                                      >
                                        R
                                      </Button>
                                      <Button
                                        size={'small'}
                                        style={
                                          medicine.isDefault
                                            ? { background: 'orange', color: 'white' }
                                            : { background: 'white' }
                                        }
                                        onClick={() => {
                                          medicine.isDefault = !medicine.isDefault;
                                          setData({ ...data });
                                        }}
                                      >
                                        D
                                      </Button>
                                      <Divider type="vertical" />
                                      <Popconfirm
                                        title="Remove medicine from biomarker"
                                        description="Are you sure to remove this medicine?"
                                        onConfirm={() => {
                                          data.medications = data.medications.filter((m, index) => i !== index);
                                          setData({ ...data });
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Button size={'small'} danger icon={<DeleteOutlined />} />
                                      </Popconfirm>
                                    </Space>
                                  </Col>
                                </Row>
                              </List.Item>
                            );
                          }}
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </Row>
          </Col>
          <Divider />
          {alertMsg && <Alert message={alertMsg} type={'warning'} />}
          <Row className="d-flex justify-content-end w-100">
            <Space>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type={'primary'} disabled={disableOK} onClick={handleOk} loading={loading}>
                OK
              </Button>
            </Space>
          </Row>
        </Row>
      )}
    </Modal>
  );
};

export default ModifyBiomarkerModal;
