import { Button, Checkbox, InputNumber, Modal, Space, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { OrderConfirmModal } from './order-confirm-modal';
import { updateTreatmentPlan } from '../../../../stores/patient-treatment-plan.store';
import DataTable from '../../../../components/data-table/data-table.component';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import SettingInput from '../../../../components/setting/SettingInput';
import { calcPriceReducer } from '../utils/price-sum-reducer';
import { RxCornerBottomLeft } from 'react-icons/rx';
import { FaSyringe } from 'react-icons/fa';

export const RefillOrderSelectionModal = ({ currentTreatmentPlan, placeOrders }) => {
  const currentPatient = useSelector((state) => state.currentPatient);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [refillMedicines, setRefillMedicines] = React.useState([]);
  const [fee, setFee] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [refillTableData, setRefillTableData] = useState([]);
  const [refillTableSetting, setRefillTableSetting] = useState({});

  useEffect(() => {
    const plans = [...currentTreatmentPlan.forLabResult, ...currentTreatmentPlan.forSymptomsAndGoals];

    plans.forEach((p, i) => {
      const refilled = refillMedicines.find((m) => m._id === p._id);
      if (refilled) {
        plans.splice(i, 1, refilled);
      }
    });

    const basePlan = plans.filter((p) => p.level === 'Base');
    const correctionalPlan = plans.filter((p) => p.level === 'Correctional');
    const electivePlan = plans.filter((p) => p.level === 'Elective');
    const ciPlan = plans.filter((p) => p.level === 'Cardiovascular & Inflammation');
    const gwPlan = plans.filter((p) => p.level === 'General wellness');
    const otherPlan = plans.filter((p) => p.level === 'Other');

    const planMapFunc = (p, i) => {
      if (i === 0) {
        return { ...p };
      } else {
        return {
          ...p,
          level: '',
        };
      }
    };

    const planIterateFunc = (planTableData) => (p) => {
      planTableData.push(p);
      if (p.medicalSupplies && p.medicalSupplies.length > 0) {
        p.medicalSupplies.forEach((ms) => {
          planTableData.push({
            for: p._id,
            level: '',
            product: {
              name: ms.name,
              _id: ms._id,
            },
            quantity: ms.quantity,
            overridingQuantity: ms.overridingQuantity,
            price: ms.price,
            refill: '',
            dosage: '',
            isMedicalSupply: true,
          });
        });
      }
    };

    const basePlanTableData = [];
    basePlan.map(planMapFunc).forEach(planIterateFunc(basePlanTableData));
    const correctionalPlanTableData = [];
    correctionalPlan.map(planMapFunc).forEach(planIterateFunc(correctionalPlanTableData));
    const electivePlanTableData = [];
    electivePlan.map(planMapFunc).forEach(planIterateFunc(electivePlanTableData));
    const ciPlanTableData = [];
    ciPlan.map(planMapFunc).forEach(planIterateFunc(ciPlanTableData));
    const gwPlanTableData = [];
    gwPlan.map(planMapFunc).forEach(planIterateFunc(gwPlanTableData));
    const otherPlanTableData = [];
    otherPlan.map(planMapFunc).forEach(planIterateFunc(otherPlanTableData));

    const rowSpan = {
      '': 0,
      priceRow: 0,
      Base: basePlanTableData.length,
      Correctional: correctionalPlanTableData.length,
      Elective: electivePlanTableData.length,
      'Cardiovascular & Inflammation': ciPlanTableData.length,
      'General wellness': gwPlanTableData.length,
      Other: otherPlanTableData.length,
    };

    const finalTableData = [];
    if (basePlanTableData.length) {
      finalTableData.push(...basePlanTableData);
    }
    if (correctionalPlanTableData.length) {
      finalTableData.push(...correctionalPlanTableData);
    }
    if (electivePlanTableData.length) {
      finalTableData.push(...electivePlanTableData);
    }
    if (ciPlanTableData.length) {
      finalTableData.push(...ciPlanTableData);
    }
    if (gwPlanTableData.length) {
      finalTableData.push(...gwPlanTableData);
    }
    if (otherPlanTableData.length) {
      finalTableData.push(...otherPlanTableData);
    }

    setRefillTableData([...finalTableData.map((t) => ({ ...t, key: t._id || Math.random() }))]);

    setRefillTableSetting({
      pagination: false,
      bordered: true,
      columns: [
        {
          title: 'Therapy',
          dataIndex: 'level',
          onCell: (record) => ({
            rowSpan: rowSpan[record.level] || 0,
          }),
        },
        {
          title: 'Medication / Product',
          dataIndex: ['product', 'name'],
          render: (text, record) => {
            const plan = plans.find((t) => t._id === record._id || t._id === record.for);
            if (plan) {
              if (!record.isMedicalSupply) {
                return (
                  <Checkbox
                    defaultChecked={false}
                    checked={refillMedicines.find((m) => m._id === record._id)}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setRefillMedicines([...refillMedicines, plan]);
                      } else {
                        setRefillMedicines(refillMedicines.filter((m) => m._id !== record._id));
                      }
                    }}
                  >
                    {text}
                  </Checkbox>
                );
              } else {
                const showError = record.overridingQuantity === 0;
                const refillChecked = refillMedicines.find((m) => m._id === record.for);
                return (
                  <span style={{ marginLeft: 6 }}>
                    <RxCornerBottomLeft size={18} style={{ position: 'relative', top: -4 }} />
                    <Tag
                      icon={<FaSyringe />}
                      color={showError ? 'error' : 'processing'}
                      style={{
                        userSelect: 'none',
                      }}
                    >
                      <Typography.Text delete={!refillChecked}>{text}</Typography.Text>
                    </Tag>
                  </span>
                );
              }
            }
          },
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          render: (text, record) => {
            if (record.isMedicalSupply) {
              const refillChecked = refillMedicines.find((m) => m._id === record.for);

              return (
                <InputNumber
                  style={{ width: 60 }}
                  disabled={!refillChecked}
                  min={0}
                  value={record.overridingQuantity ?? record.quantity}
                  onChange={(value) => {
                    const medicalSupply = refillMedicines
                      .find((m) => m._id === record.for)
                      .medicalSupplies.find((m) => m._id === record.product._id);
                    medicalSupply.overridingQuantity = value;
                    setRefillMedicines([...refillMedicines]);
                  }}
                />
              );
            } else {
              return text;
            }
          },
        },
        {
          title: 'Dosage',
          dataIndex: 'dosage',
        },
        {
          title: 'Price',
          dataIndex: 'price',
          render: (text, record) => {
            return (
              <span>{`$${text ? Number(text) * ((record.overridingQuantity ?? record.quantity) || 0) : 'TBD'}`}</span>
            );
          },
        },
      ],
    });
  }, [refillMedicines]);

  return (
    <>
      <Tooltip
        title={
          !currentTreatmentPlan.refillOpportunity
            ? 'You need an open refill opportunity against current treatment plan to enable this button'
            : `Refill follow up date: ${dayjs
                .utc(currentTreatmentPlan.refillOpportunity.followUpDate)
                .format('MM/DD/YYYY')}`
        }
      >
        <Button onClick={() => setOpen(true)} disabled={!currentTreatmentPlan.refillOpportunity}>
          Choose Refills
        </Button>
      </Tooltip>

      {open && (
        <Modal
          title={'Choose Refills'}
          open={open}
          destroyOnClose
          style={{ top: 20 }}
          footer={
            <OrderConfirmModal
              prefilledInfo={{
                serviceLocation: currentPatient.profile.primaryServiceCenter,
                shipping: currentPatient.profile.shippingAddress,
                billing: currentPatient.profile.billingAddress,
                firstName: currentPatient.profile.firstName,
                lastName: currentPatient.profile.lastName,
              }}
              okText={'Confirm And Place Orders'}
              placeholderButtonText={'Place Refill Orders'}
              placeholderButtonDisabled={!refillMedicines.length}
              onConfirm={(shipment, location, followUpDate) =>
                placeOrders(
                  'Refill',
                  refillMedicines,
                  shipment,
                  location,
                  followUpDate,
                  fee,
                  discount,
                  refillMedicines.reduce(calcPriceReducer, 0) + (fee || 0) - (discount || 0),
                  ({ prescriptionLink, bProductLink }, refillOpportunity, orderId) => {
                    const newPlan = JSON.parse(JSON.stringify(currentTreatmentPlan));
                    if (!newPlan.refillOrders || !newPlan.refillOrders.length) {
                      newPlan.refillOrders = [orderId];
                    } else {
                      newPlan.refillOrders.push(orderId);
                    }
                    newPlan.refillOpportunity = refillOpportunity;
                    dispatch(updateTreatmentPlan(newPlan));
                    setOpen(false);
                  }
                )
              }
              placeholderText={'Place Refill Orders'}
            />
          }
          onCancel={() => setOpen(false)}
          width={980}
        >
          {!!refillTableSetting && !!refillTableData && (
            <DataTable data={refillTableData} settings={refillTableSetting} />
          )}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Space>
              <Typography.Text type={'secondary'}>Shipping And Handling Fee:</Typography.Text>
              <SettingInput
                key={'fee'}
                value={fee}
                placeholder={'Set Fee Here'}
                editable={true}
                editingRender={{
                  elementType: 'number',
                  formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  parser: (value) => value?.replace(/\$\s?|(,*)/g, ''),
                  min: 0,
                  save: setFee,
                }}
              />
            </Space>
            <Space>
              <Typography.Text type={'secondary'}>Extra Discount:</Typography.Text>
              (
              <SettingInput
                key={'discount'}
                value={discount}
                placeholder={refillMedicines.length ? 'Set Discount Here' : 'Choose Refill First'}
                editable={refillMedicines.length > 0}
                editingRender={{
                  elementType: 'number',
                  formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  parser: (value) => value?.replace(/\$\s?|(,*)/g, ''),
                  min: 0,
                  max: refillMedicines.reduce(calcPriceReducer, 0),
                  save: setDiscount,
                }}
              />
              )
            </Space>
            <div>
              <Typography.Text strong type={'success'}>
                Total Price of this refill:{' '}
                {`$ ${refillMedicines.reduce(calcPriceReducer, 0) + (fee || 0) - (discount || 0)}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}
              </Typography.Text>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
