import React, { useState } from 'react';
import { Button, Modal, Timeline,Tooltip } from 'antd';
import moment from 'moment';

const HistoryOrderModal = ({ trackingNumber,selectedCarrier, orderHistory}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      if (selectedCarrier.toLowerCase() === "usps") {
        openInNewTab(`https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${trackingNumber}`)
      } else if (selectedCarrier.toLowerCase() === "ups") {
        openInNewTab(`https://www.ups.com/track?track=yes&trackNums=${trackingNumber}&loc=en_US&requester=ST/`)
      } else {
        setIsModalOpen(true);
      }
    };

    const openInNewTab = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    };

    const handleOk = () => {
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };
  return (
    <>
    <Tooltip color="volcano" title={trackingNumber ? '' : 'No Tracking Number'}>
      <Button className='action-btn' onClick={showModal} disabled={!trackingNumber}>
        Track Order
      </Button>
    </Tooltip>
        <Modal 
            title="Tracking Information" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            footer={[
                <Button type='primary' onClick={handleOk}>
                  OK
                </Button>
            ]}
        >
        <Timeline>
        {
            orderHistory && orderHistory.map((order,i) => (  
                <Timeline.Item key={i} color={i === 0 ? 'blue' : 'gray'} >
                    <p>{moment(order.date).format('MMMM Do YYYY, h:mm:ss a')}</p>
                    <p>{order.derivedStatus}</p>
                    {
                      selectedCarrier == 'usps' ?
                      <>
                         <p>{order.location}</p>
                      </> :
                      <>
                        <p>{order.eventDescription}</p>
                        <p>{order.scanLocation.streetLines[0] != '' ? order.scanLocation.streetLines[0] + ',' : ''} {order.scanLocation.city} {order.scanLocation.countryName} {order.scanLocation.postalCode}</p>
                      </>
                    }
                </Timeline.Item> 
            ))
        }   
        </Timeline>
      </Modal>
    </>
  );
};

export default HistoryOrderModal;