import { Outlet } from 'react-router-dom';
import { useState } from 'react';

const OrderManagementPage = () => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="procurement-order">
      {!loading && (
        <div style={{ background: '#F0F2F5 0% 0% no-repeat padding-box', padding: 32 }}>
          <h2>CRE8 Order Management</h2>
          <Outlet />
        </div>
      )}
    </div>
  );
};
export default OrderManagementPage;
