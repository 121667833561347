import { BIOMARKER_CRUD_URL } from '../../api/URLs';
import bioApi from '../../api/bioApi';

export const getBiomarkers = () => {
  return bioApi.get(BIOMARKER_CRUD_URL);
};

export const updateBiomarker = (payload) => {
  return bioApi.post(BIOMARKER_CRUD_URL, payload);
};
