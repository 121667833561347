import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import DataTable from "../../../components/data-table/data-table.component";
import {
  AutoComplete,
  Button,
  Input,
  Select,
  Space,
} from "antd";
import "./main.css";
import moment from "moment";
import { getShippingOptions, searchOrdersPharmacies } from "../service";
import { SearchOutlined } from "@ant-design/icons";
import DatePickerWithMoment from "../../../components/date/DatePickerWithMoment";
import OrderNumberModal from "./OrderNumberModal";
import OrderNumberDetailModal from "./OrderNumberDetailModal";
const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

function OrderListPage() {
  const [shipmentInfo, setShipmentInfo] = React.useState([]);
  const [searchType, setSearchType] = React.useState("Search by OrderID");
  const [type, setType] = React.useState("orderId");
  const [loading, setLoading] = React.useState(true);
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [sortType, setSortType] = React.useState("date");
  const [searchContent, setSearchContent] = React.useState("");
  const [rangeDate, setRangeDate] = React.useState([
    moment(),
    moment().add(1, "days"),
  ]);
  const [openOrder, setOpenOrder] = useState(false)
  const [openOrderDetail, setOpenOrderDetail] = useState(null)

  const tableSettings = {
    appendable: false,
    removable: false,
    bordered: true,
    size: "small",
    pagination: {
      showLessItems: true,
      pageSize: 10,
    },
    columns: [
      {
        title: 'Create Date',
        dataIndex: 'creationTS',
        render: (text, record, index) =>
          moment(text).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY'),
      },
      {
        title: 'Order ID',
        dataIndex: 'orderId',
      },
      {
        title: 'Patient Name',
        dataIndex: 'patientName',
      },
      {
        title: 'Status',
        width: 100,
        render: (_, record) => {
          return (
            <>
              {/* New, Shipped, Delivered */}
            <span>{record.status || ""}</span>
            </>
          )
        },
      },
      {
        title: <div style={{textAlign: "center"}}>Action</div>,
        width: 100,
        render: (_, record) => {
          return (
            <>
            <Button
              onClick={() => setOpenOrderDetail(record)}
              target="_blank"
              className="ms-2"
            >
              Detail
            </Button>
            </>
          )
        },
      },
    ]
  };

  const updateSearchType = (e) => {
    setSearchOptions([]);
    setSearchType("Search By " + e);
    setType(e);
  };

  const showSearchOptions = async (value) => {
    if (value != "") {
      const allOptions = await getShippingOptions(type, value);
      setSearchOptions(allOptions.data);
    } else {
      setSearchOptions([]);
      fetchShipmentData();
    }
  };

  const updateSortTypeAndSearch = async (value) => {
    setLoading(true);
    setSortType(value);
    const patientDataRequest = await searchOrdersPharmacies(
      type,
      searchContent,
      value,
      rangeDate,
    );
    if (patientDataRequest.data.data) {
      setShipmentInfo(patientDataRequest.data.data.map((order) => ({ ...order, key: order._id })));
    } else {
      setShipmentInfo();
    }
    setLoading(false);
  };
  const updateRangeDate = async (value) => {
    setLoading(true);
    setRangeDate(value);
    const patientDataRequest = await searchOrdersPharmacies(
      type,
      searchContent,
      sortType,
      value,
    );
    if (patientDataRequest.data.data) {
      setShipmentInfo(patientDataRequest.data.data.map((order) => ({ ...order, key: order._id })));
    } else {
      setShipmentInfo();
    }
    setLoading(false);
  };

  const searchShipmentData = async () => {
    setLoading(true);
    let patientDataRequest;
    patientDataRequest = await searchOrdersPharmacies(
      type,
      searchContent,
      sortType,
      rangeDate,
    );

    if (patientDataRequest.data.data) {
      setShipmentInfo(patientDataRequest.data.data.map((order) => ({ ...order, key: order._id })));
    } else {
      setShipmentInfo();
    }
    setLoading(false);
  };
  const fetchShipmentData = async () => {
    try {
      setLoading(true);
      const patientDataRequest = await searchOrdersPharmacies(
        type,
        searchContent,
        sortType,
        rangeDate,
      );
      if (patientDataRequest.data.data) {
        setShipmentInfo(patientDataRequest.data.data.map((order) => ({ ...order, key: order._id })));
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSearchContent = (value) => {
    setSearchContent(value);
  };

  const handleEnterKeySearch = (e) => {
    if (e.key === "Enter") {
      searchShipmentData();
    }
  };

  React.useEffect(() => {
    fetchShipmentData();
  }, [searchContent]);

  return (
    <>
      <Row>
        <Col sm={12} className="pb-4">
          <Row className="align-items-end">
            <Col sm={6}>
              <Input.Group size="large" compact>
                <Select
                  defaultValue={type}
                  onSelect={updateSearchType}
                  size="large"
                  style={{
                    width: 150,
                    borderRadius: "4px",
                  }}
                >
                  <Option value="orderId">OrderID</Option>
                  <Option value="patientName">Patient Name</Option>
                </Select>
                <Space direction="vertical">
                  <AutoComplete
                    onSearch={showSearchOptions}
                    onSelect={searchShipmentData}
                    onChange={handleSearchContent}
                    style={{
                      width: 450,
                    }}
                    notFoundContent="No order found with searched key"
                    options={searchOptions}
                    onKeyDown={(e) => handleEnterKeySearch(e)}
                  >
                    <Input
                      size="large"
                      placeholder={searchType}
                      suffix={
                        <SearchOutlined onClick={() => searchShipmentData()} />
                      }
                      allowClear
                    />
                  </AutoComplete>
                </Space>
              </Input.Group>
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={3} className="sort-input">
                  <Input.Group compact>
                    <div
                      className="sort-label"
                      style={{ display: "inline-grid" }}
                    >
                      <label>Sort By</label>
                      <Select
                        defaultValue={sortType}
                        size="large"
                        onSelect={updateSortTypeAndSearch}
                        style={{
                          width: 150,
                        }}
                      >
                        <Option value="date">Date</Option>
                        <Option value="orderId">OrderID</Option>
                        <Option value="patientName">Patient Name</Option>
                        <Option value="status">Status</Option>
                      </Select>
                    </div>
                  </Input.Group>
                </Col>
                <Col sm={5} className="date-input">
                  <Input.Group size="large">
                    <div
                      className="date-label"
                      style={{ display: "inline-grid" }}
                    >
                      <label>Date</label>
                      <div className="date-input">
                        <DatePickerWithMoment.RangePicker
                          size={"large"}
                          defaultValue={rangeDate}
                          format={dateFormat}
                          placement="bottomLeft"
                          onChange={updateRangeDate}
                        />
                      </div>
                    </div>
                  </Input.Group>
                </Col>
                <Col
                  sm={4}
                  className="create-row d-flex justify-content-end align-items-end upload"
                >
                  <Button type="primary" onClick={() => setOpenOrder(true)} size={'large'} >
                    + New Order
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col sm={12}>
          <DataTable
            loading={loading}
            data={shipmentInfo}
            settings={tableSettings}
          />
        </Col>
      </Row>
      {openOrder ? <OrderNumberModal
      open={openOrder} setOpen={setOpenOrder}
      getAllDataRequest={fetchShipmentData}
       /> : ""}
       {openOrderDetail ? <OrderNumberDetailModal
       open={openOrderDetail} setOpen={setOpenOrderDetail}
        /> : ""}
    </>
  );
}

export default OrderListPage;
