import { useEffect, useState } from 'react';
import { Button, Checkbox, Col, DatePicker, Divider, message, Modal, Row, Space } from 'antd';
import { FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import { LOCATION_LIST_URL } from '../../../../api/URLs';
import bioApi from '../../../../api/bioApi';
import dayjs from 'dayjs';

export const OrderConfirmModal = ({
  prefilledInfo,
  onConfirm,
  okText,
  placeholderButtonText,
  placeholderButtonDisabled,
}) => {
  const [shippingAddress, setShippingAddress] = useState(prefilledInfo.shipping);
  const [billingAddress, setBillingAddress] = useState(prefilledInfo.billing);
  const [firstName, setFirstName] = useState(prefilledInfo.firstName);
  const [lastName, setLastName] = useState(prefilledInfo.lastName);
  const [serviceLocation, setServiceLocation] = useState(prefilledInfo.serviceLocation);
  const [serviceLocations, setServiceLocations] = useState([]);
  const [open, setOpen] = useState(false);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [confirming, setConfirming] = useState(false);
  const [followUpDate, setFollowUpDate] = useState(undefined);

  const confirm = async () => {
    setConfirming(true);
    try {
      await onConfirm({ shippingAddress, billingAddress, firstName, lastName }, serviceLocation, followUpDate);
      setOpen(false);
    } catch (e) {
      message.error('Failed to confirm order');
    } finally {
      setConfirming(false);
    }
  };

  useEffect(() => {
    (async () => {
      const { data } = await bioApi.post(LOCATION_LIST_URL, {
        limit: 100,
        page: 1,
      });
      setServiceLocations(data.data);
    })();
  }, []);

  useEffect(() => {
    setDisableConfirm(
      !shippingAddress?.street ||
        !shippingAddress?.city ||
        !shippingAddress?.state ||
        !shippingAddress?.postalCode ||
        !billingAddress?.street ||
        !billingAddress?.city ||
        !billingAddress?.state ||
        !billingAddress?.postalCode ||
        !firstName ||
        !lastName ||
        followUpDate === null
    );
  }, [shippingAddress, billingAddress, firstName, lastName, followUpDate]);

  return (
    <>
      <Button type={'primary'} onClick={() => setOpen(true)} disabled={placeholderButtonDisabled}>
        {placeholderButtonText}
      </Button>
      <Modal
        destroyOnClose
        title={'Confirm Shipment Info'}
        open={open}
        okText={okText}
        onOk={confirm}
        confirmLoading={confirming}
        okButtonProps={{ disabled: disableConfirm }}
        onCancel={() => setOpen(false)}
        style={{ top: 20 }}
        width={650}
      >
        <Row gutter={8}>
          <Col span={14}>
            <Divider>Name</Divider>
            <Row gutter={8}>
              <Col span={12}>
                <TextField
                  error={!firstName}
                  label="First Name"
                  variant="outlined"
                  value={firstName || ''}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Col>
              <Col span={12}>
                <TextField
                  error={!lastName}
                  label="Last Name"
                  variant="outlined"
                  value={lastName || ''}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Divider>Service Center</Divider>
            <Select
              value={serviceLocation?._id || ''}
              fullWidth
              error={!serviceLocation?._id}
              onChange={(e) => setServiceLocation(serviceLocations.find((l) => l._id === e.target.value))}
            >
              {serviceLocations.map((l) => (
                <MenuItem key={l._id} value={l._id}>
                  {l.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText hidden={!!prefilledInfo.serviceLocation?._id}>
              We couldn't locate the shipping service center based on the patient's primary service location. This could
              be because either the patient's primary service location is missing or the Salesforce name in the system's
              location data is incorrectly set.
            </FormHelperText>
            <FormHelperText
              hidden={!(serviceLocation?._id && prefilledInfo.serviceLocation?._id !== serviceLocation?._id)}
            >
              Orders will be sent to a service center different from the patient's primary one.
            </FormHelperText>
          </Col>
        </Row>
        <Divider>
          Shipping Address{' '}
          <Button size={'small'} onClick={() => setShippingAddress({ ...shippingAddress, ...billingAddress })}>
            Use Billing Address
          </Button>
        </Divider>
        <Row gutter={8}>
          <Col span={24} style={{ marginBottom: 8 }}>
            <TextField
              fullWidth={true}
              error={!shippingAddress?.street}
              label="Street"
              variant="outlined"
              value={shippingAddress?.street || ''}
              onChange={(e) => setShippingAddress({ ...shippingAddress, street: e.target.value })}
            />
          </Col>
          <Col span={8}>
            <TextField
              error={!shippingAddress?.city}
              label="City"
              variant="outlined"
              value={shippingAddress?.city || ''}
              onChange={(e) => setShippingAddress({ ...shippingAddress, city: e.target.value })}
            />
          </Col>
          <Col span={8}>
            <TextField
              error={!shippingAddress?.state}
              label="State"
              variant="outlined"
              value={shippingAddress?.state || ''}
              onChange={(e) => setShippingAddress({ ...shippingAddress, state: e.target.value })}
            />
          </Col>
          <Col span={8}>
            <TextField
              error={!shippingAddress?.postalCode}
              label="Zip"
              variant="outlined"
              value={shippingAddress?.postalCode || ''}
              onChange={(e) => setShippingAddress({ ...shippingAddress, postalCode: e.target.value })}
            />
          </Col>
        </Row>
        <Divider>
          Billing Address{' '}
          <Button size={'small'} onClick={() => setBillingAddress({ ...billingAddress, ...shippingAddress })}>
            Use Shipping Address
          </Button>
        </Divider>
        <Row gutter={8}>
          <Col span={24} style={{ marginBottom: 8 }}>
            <TextField
              error={!billingAddress?.street}
              fullWidth={true}
              label="Street"
              variant="outlined"
              value={billingAddress?.street || ''}
              onChange={(e) => setBillingAddress({ ...billingAddress, address: e.target.value })}
            />
          </Col>
          <Col span={8}>
            <TextField
              error={!billingAddress?.city}
              label="City"
              variant="outlined"
              value={billingAddress?.city || ''}
              onChange={(e) => setBillingAddress({ ...billingAddress, city: e.target.value })}
            />
          </Col>
          <Col span={8}>
            <TextField
              error={!billingAddress?.state}
              label="State"
              variant="outlined"
              value={billingAddress?.state || ''}
              onChange={(e) => setBillingAddress({ ...billingAddress, state: e.target.value })}
            />
          </Col>
          <Col span={8}>
            <TextField
              error={!billingAddress?.postalCode}
              label="Zip"
              variant="outlined"
              value={billingAddress?.postalCode || ''}
              onChange={(e) => setBillingAddress({ ...billingAddress, postalCode: e.target.value })}
            />
          </Col>
        </Row>
        <Row>
          <Divider>
            <Checkbox
              defaultChecked={followUpDate !== undefined}
              onChange={(e) => {
                if (!e.target.checked) {
                  setFollowUpDate(undefined);
                } else {
                  setFollowUpDate(null);
                }
              }}
            >
              Also create refill opportunity with follow up date
            </Checkbox>
          </Divider>
          {followUpDate !== undefined && (
            <Space>
              Refill opportunity follow up date:
              <DatePicker
                minDate={dayjs()}
                status={followUpDate === null ? 'error' : undefined}
                onChange={(newValue) => setFollowUpDate(newValue)}
              />
            </Space>
          )}
        </Row>
      </Modal>
    </>
  );
};
