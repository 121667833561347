import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Result,
  Row,
  Space,
  Spin,
  Steps,
  Typography,
  Upload,
} from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { getBiomarkers } from '../../../biomarker-management/service';
import bioApi from '../../../../api/bioApi';
import { PATIENT_MANUAL_LAB_REPORT_SUBMIT } from '../../../../api/URLs';
import Countdown from 'react-countdown';
import dayjs from 'dayjs';

const LabReportDataCollectingModal = ({ patient, prefill, visible, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [biomarkers, setBiomarkers] = useState({ h: [], ci: [], gw: [], th: [] });
  const [finalReport, setFinalReport] = useState({});
  const [loading, setLoading] = useState(false);

  const biomarkerHandling = (values) => {
    const oldData = finalReport.data || [];
    const newData = Object.entries(values).map(([k, v]) => ({
      test: k,
      value: v,
    }));

    setFinalReport({ ...finalReport, data: [...oldData, ...newData] });
  };

  const pages = [
    {
      formItem: (
        <Form.Item>
          <p>
            Before everything, please make sure following information on the report matches the information of this
            patient:{' '}
          </p>
          <p>
            Name:{' '}
            <Typography.Text mark>
              {patient.profile.firstName} {patient.profile.lastName}
            </Typography.Text>
          </p>
          <p>
            Gender: <Typography.Text mark>{patient.profile.gender}</Typography.Text>
          </p>
          <p>
            Date of birth: <Typography.Text mark>{patient.profile.dob}</Typography.Text>
          </p>
        </Form.Item>
      ),
      onNext: () => {},
    },
    {
      formItem: (
        <>
          <p>Please find following info on this report and fill in:</p>
          <Form.Item label="Laboratory Name" name="lab" rules={[{ required: true, message: 'Please input lab name!' }]}>
            <Input placeholder="Lab Name" />
          </Form.Item>
          <Form.Item
            label="Specimen Collection Date"
            name="collectionDate"
            rules={[{ required: true, message: 'Please provide collection date!' }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Specimen ID"
            rules={[{ required: true, message: 'Please provide specimen id!' }]}
            name={'specimenId'}
          >
            <Input placeholder="Specimen ID" />
          </Form.Item>
        </>
      ),
      onNext: (values) => {
        const collectionDate = values.collectionDate.format('MM/DD/YYYY');
        setFinalReport({ ...finalReport, ...values, collectionDate });
      },
    },
    {
      formItem: (
        <Row>
          <p>Please find following Hormone biomarkers from this report and fill in:</p>
          {biomarkers.h.map((h) => (
            <Form.Item label={h} name={h} style={{ marginInline: 8 }}>
              <Input placeholder={h} />
            </Form.Item>
          ))}
        </Row>
      ),
      onNext: biomarkerHandling,
    },
    {
      formItem: (
        <Row>
          <p>Please find following Cardiovascular & Inflammation biomarkers from this report and fill in:</p>
          {biomarkers.ci.map((h) => (
            <Form.Item label={h} name={h} style={{ marginInline: 8 }}>
              <Input placeholder={h} />
            </Form.Item>
          ))}
        </Row>
      ),
      onNext: biomarkerHandling,
    },
    {
      formItem: (
        <Row>
          <p>Please find following General Wellness biomarkers from this report and fill in:</p>
          {biomarkers.gw.map((h) => (
            <Form.Item label={h} name={h} style={{ marginInline: 8 }}>
              <Input placeholder={h} />
            </Form.Item>
          ))}
        </Row>
      ),
      onNext: biomarkerHandling,
    },
    {
      formItem: (
        <Row>
          <p>Please find following Thyroid Health biomarkers from this report and fill in:</p>
          {biomarkers.th.map((h) => (
            <Form.Item label={h} name={h} style={{ marginInline: 8 }}>
              <Input placeholder={h} />
            </Form.Item>
          ))}
        </Row>
      ),
      onNext: biomarkerHandling,
    },
    {
      type: 'done',
      formItem: prefill?.file && (
        <>
          <Result title="Before submitting, ensure all data is correct. Click next to complete the submission. Note that if this is the latest lab report, all non-approved treatments will be marked as expired." />
          <Form.Item label="The Lab Report File You Just Uploaded:" required={true}>
            <Upload
              disabled
              maxCount={1}
              fileList={[
                {
                  uid: '-1',
                  name: prefill.file.name,
                  status: 'done',
                },
              ]}
            />
          </Form.Item>
        </>
      ),
      onNext: async () => {
        try {
          const url = PATIENT_MANUAL_LAB_REPORT_SUBMIT.replace('{id}', patient.patientId);

          const formData = new FormData();

          if (prefill?.file) {
            formData.append('file', prefill.file.originFileObj);
          } else {
            message.error('Must upload the report');
            return;
          }

          setLoading(true);

          Object.keys(finalReport).forEach((key) => {
            let value = finalReport[key];
            if (typeof value === 'object') {
              value = JSON.stringify(finalReport[key]);
            }
            formData.append(key, value);
          });

          await bioApi.post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          Modal.success({
            title: 'Successfully Uploaded Lab Report!',
            content: (
              <span>
                Pulling new data in{' '}
                <Countdown
                  date={Date.now() + 3000}
                  renderer={({ hours, minutes, seconds, completed }) => <span>{seconds}</span>}
                />{' '}
                seconds
              </span>
            ),
            closable: false,
            okButtonProps: {
              style: { display: 'none' },
            },
          });
          setTimeout(() => window.location.reload(), 4000);
        } catch (e) {
          message.error('unable to upload this manual report!');
        } finally {
          setLoading(false);
        }
      },
    },
  ];

  useEffect(() => {
    (async () => {
      const rawBiomarkers = await getBiomarkers();
      const groupedBiomarkers = {
        h: [],
        ci: [],
        gw: [],
        th: [],
      };
      const patientGender = patient.profile.gender || '';
      rawBiomarkers.data
        .filter((d) => d.gender === 'unisex' || d.gender.toLowerCase() === patientGender.toLowerCase())
        .forEach((b) => {
          if (b.type === 'Cardiovascular & Inflammation') {
            if (!groupedBiomarkers.ci.includes(b.name)) {
              groupedBiomarkers.ci.push(b.name);
            }
          } else if (b.type === 'Hormones') {
            if (!groupedBiomarkers.h.includes(b.name)) {
              groupedBiomarkers.h.push(b.name);
            }
          } else if (b.type === 'General Wellness') {
            if (!groupedBiomarkers.gw.includes(b.name)) {
              groupedBiomarkers.gw.push(b.name);
            }
          } else if (b.type === 'Thyroid Health') {
            if (!groupedBiomarkers.th.includes(b.name)) {
              groupedBiomarkers.th.push(b.name);
            }
          }
        });
      setBiomarkers(groupedBiomarkers);
    })();
  }, []);

  useEffect(() => {
    setCurrentStep(0);
    setFinalReport({});
  }, [visible]);

  return (
    <Modal
      open={visible}
      width={800}
      title="Lab Report Entry"
      footer={null}
      onCancel={onClose}
      destroyOnClose={true}
      maskClosable={false}
      style={{
        top: 10,
      }}
    >
      <Spin spinning={loading} fullscreen />
      {prefill && Object.keys(prefill.data).length > 0 && (
        <>
          {!prefill.data.mismatch && (
            <Alert
              style={{ marginBlock: 8 }}
              type={'success'}
              closable
              message={'Successfully extracted lab data from your upload. Please validate before submit'}
            />
          )}
          {prefill.data.mismatch && (
            <Alert
              style={{ marginBlock: 8 }}
              type={'error'}
              closable
              message={
                <span>
                  There's mismatch between your upload and our patient record: <b>{prefill.data.msg}</b>. You can still
                  upload this report but please be aware this might be a dangerous action that could mess up patient
                  data.
                </span>
              }
            />
          )}
        </>
      )}
      {prefill && Object.keys(prefill.data).length === 0 && (
        <Alert
          style={{ marginBlock: 8 }}
          type={'warning'}
          closable
          message={'Unable to identify and extract lab data from your upload. Please manually enter the required data'}
        />
      )}
      <Row>
        <Col span={8}>
          <Steps
            size="small"
            direction={'vertical'}
            current={currentStep}
            items={[
              {
                title: 'Validation',
                status: currentStep === 0 ? 'process' : 'finish',
              },
              {
                title: 'Reporting Basics',
                status: currentStep === 1 ? 'process' : currentStep < 1 ? 'wait' : 'finish',
              },
              {
                title: 'Hormones',
                status: currentStep === 2 ? 'process' : currentStep < 2 ? 'wait' : 'finish',
              },
              {
                title: 'Cardiovascular & Inflammation',
                status: currentStep === 3 ? 'process' : currentStep < 3 ? 'wait' : 'finish',
              },
              {
                title: 'General Wellness',
                status: currentStep === 4 ? 'process' : currentStep < 4 ? 'wait' : 'finish',
              },
              {
                title: 'Thyroid Health',
                status: currentStep === 5 ? 'process' : currentStep < 5 ? 'wait' : 'finish',
              },
              {
                title: 'Done',
                status: currentStep === 6 ? 'finish' : 'wait',
                icon: <SmileOutlined />,
              },
            ]}
          />
        </Col>
        <Col span={1}>
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col span={15}>
          <Form
            layout={'vertical'}
            onFinish={(values) => {
              pages[currentStep].onNext(values);
              if (currentStep < pages.length - 1) {
                setCurrentStep(currentStep + 1);
              }
            }}
            initialValues={prefill?.data?.data?.reduce((acc, d) => ({ ...acc, [d.biomarker]: d.labData }), {
              collectionDate: dayjs(prefill?.data?.collectionDate),
              specimenId: prefill?.data?.specimenId,
              lab: prefill?.data?.lab,
            })}
          >
            {pages[currentStep].formItem}
            <Row>
              <Space>
                <Form.Item>
                  <Button onClick={() => setCurrentStep(currentStep - 1)} disabled={currentStep === 0 || loading}>
                    Previous
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={loading}>
                    Next
                  </Button>
                </Form.Item>
              </Space>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default LabReportDataCollectingModal;
