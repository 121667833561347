import { Avatar, Badge, Button, Divider, Layout, Popover, Select, Space, Spin, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setOngoingConversation } from '../../stores/ongoing-conversaion.store';
import MessageStreamDisplayComponent from './message-stream-display.component';
import { loadMessages, markRead, markUnread } from './message.service';
import moment from 'moment';
import {
  loadMessagesToConversation,
  markConversationRead,
  markConversationUnread,
  startNewConversation,
} from '../../stores/patient-conversations.store';
import MessageSendingComponent from './message-sending.component';
import bioApi from '../../api/bioApi';
import { APP_USER_SEARCH_URL } from '../../api/URLs';
import VirtualList from 'rc-virtual-list';
import { ListItemIcon, MenuItem, MenuList } from '@mui/material';
import { TbCircleLetterPFilled, TbCircleLetterS } from 'react-icons/tb';

const { Header, Content, Footer, Sider } = Layout;

const MessageBoardPage = () => {
  const [chatBoxContainer, setChatBoxContainer] = useState(null);
  const [appUserResult, setAppUserResult] = useState([]);
  const [conversationList, setConversationList] = useState([]);
  const [messageLoading, setMessageLoading] = useState(false);
  const [sortBy, setSortBy] = useState({ value: 'latest' });
  const [selectedAppUserId, setSelectedAppUserId] = useState(null);

  const dispatch = useDispatch();
  const patientConversations = useSelector((state) => state.patientConversations);
  const ongoingConversation = useSelector((state) => state.ongoingConversation);

  const chooseConversation = async (appUser) => {
    const { appuserId } = appUser;

    setSelectedAppUserId(appuserId);
    setMessageLoading(true);

    const theConversation = patientConversations.find((c) => c.patient.appuserId === appuserId);
    if (theConversation) {
      if (!!theConversation.messages) {
        dispatch(setOngoingConversation(theConversation));
      } else {
        const patientConversation = await loadMessages(appuserId);

        dispatch(loadMessagesToConversation({ messages: patientConversation.data.messages, appuserId }));

        const completeConversation = {
          patient: theConversation.patient,
          messages: patientConversation.data.messages,
        };

        dispatch(setOngoingConversation(completeConversation));
      }
      if (theConversation.hasUnreadMessage) {
        await markRead(appuserId);
        dispatch(markConversationRead(appuserId));
      }
    } else {
      dispatch(startNewConversation(appUser));
      dispatch(
        setOngoingConversation({
          patient: appUser,
          messages: [],
        })
      );
    }

    setMessageLoading(false);
  };

  const getSortFunc = (type) => {
    const isOngoingConversation = (a) =>
      !!a.patient && !!ongoingConversation.patient && a.patient.appuserId === ongoingConversation.patient.appuserId;
    const nameSort = (a, b) => a.patient.name.toLowerCase().localeCompare(b.patient.name.toLowerCase());
    const unreadSort = (a, b) => (a.hasUnreadMessage === b.hasUnreadMessage ? 0 : a.hasUnreadMessage ? -1 : 1);
    const timeSort = (a, b) => {
      if (!a.latestMessageTime || !b.latestMessageTime) {
        if (!a.latestMessageTime) {
          return -1;
        } else {
          return 1;
        }
      }
      return new Date(b.latestMessageTime).getTime() - new Date(a.latestMessageTime).getTime();
    };

    return (a, b) => {
      const aFixTop = isOngoingConversation(a);
      const bFixTop = isOngoingConversation(b);

      if (aFixTop) {
        return -1;
      } else if (bFixTop) {
        return 1;
      }

      let result = unreadSort(a, b);
      if (result === 0) {
        if (type === 'name') {
          result = nameSort(a, b);
          if (result === 0) {
            result = timeSort(a, b);
          }
        } else if (type === 'latest') {
          result = timeSort(a, b);
          if (result === 0) {
            result = nameSort(a, b);
          }
        }
      }
      return result;
    };
  };

  useEffect(() => {
    if (ongoingConversation.patient) {
      setSortBy({ ...sortBy });
    }
  }, [ongoingConversation]);

  useEffect(() => {
    if (patientConversations.length) {
      setConversationList([...patientConversations]);
    }
  }, [patientConversations]);

  let appUserSearchDebounceTimeout;
  let currentCriteria;

  const handleConversationSearch = (newValue) => {
    if (newValue && newValue.length > 3) {
      if (appUserSearchDebounceTimeout) {
        clearTimeout(appUserSearchDebounceTimeout);
        appUserSearchDebounceTimeout = null;
      }
      currentCriteria = newValue;

      appUserSearchDebounceTimeout = setTimeout(async () => {
        const result = await bioApi.get(APP_USER_SEARCH_URL + encodeURIComponent(newValue));
        if (currentCriteria === newValue) {
          setAppUserResult(result.data);
        }
      }, 300);
    } else {
      setAppUserResult([]);
    }
  };

  const handleSelect = async (value, option) => {
    await chooseConversation(option);
  };

  return (
    <div className={'message-board'} style={{ paddingInline: 16 }}>
      <Layout hasSider style={{ height: 'calc(100vh - 32px - 34px - 8px )', border: '1px solid #dfdfdf' }}>
        <Layout>
          <Header>
            <Typography.Text style={{ fontSize: '18px', color: 'white' }}>
              {!!ongoingConversation.patient ? (
                <>
                  Conversation with
                  <Tooltip title={'To patient detail page'}>
                    <Link
                      to={`/patient/${ongoingConversation.patient.patientId}`}
                      style={{ fontSize: '18px', color: 'white', marginInline: 8, borderBottom: '1px dashed #fff' }}
                    >
                      {ongoingConversation.patient.name}
                    </Link>
                  </Tooltip>
                  <Popover
                    content={
                      <>
                        <div>
                          {ongoingConversation.patient.gender}, {ongoingConversation.patient.age}
                        </div>
                      </>
                    }
                    title="Basic Info"
                  >
                    <InfoCircleTwoTone />
                  </Popover>
                </>
              ) : (
                <>Select a conversation</>
              )}
            </Typography.Text>
          </Header>
          <Content
            style={{
              margin: '16px 12px 0',
              padding: '0 16px',
              overflowY: 'auto',
            }}
            ref={setChatBoxContainer}
          >
            {messageLoading ? (
              <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Spin size={'large'}>Conversation is loading</Spin>
              </div>
            ) : (
              <MessageStreamDisplayComponent chatBoxContainer={chatBoxContainer} />
            )}
          </Content>
          <Footer>
            <MessageSendingComponent />
          </Footer>
        </Layout>
        <Sider theme={'light'} width={333}>
          <Space direction={'vertical'} style={{ width: '100%', padding: 8 }}>
            <div>
              <Select
                showSearch
                allowClear
                placeholder={'Search Name, Phone or Email'}
                style={{ width: '100%' }}
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                notFoundContent={null}
                onSearch={handleConversationSearch}
                onChange={handleSelect}
                options={appUserResult.map((r) => ({ ...r, label: r.name, value: r.appuserId }))}
                optionRender={(option) => (
                  <div className="d-flex align-items-center">
                    <Avatar size="30" style={{ marginRight: 10 }}>
                      {option.data.name
                        .split(' ')
                        .map((n) => n.slice(0, 1))
                        .join('')
                        .toUpperCase()}
                    </Avatar>
                    <div>
                      <Space>
                        <Typography.Text strong>{option.data.label}</Typography.Text>
                        <Typography.Text type={'secondary'}>
                          {(option.data.phone || '').replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
                        </Typography.Text>
                      </Space>
                      <div>
                        <Typography.Text type={'secondary'}>{option.data.email}</Typography.Text>
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', width: '100%' }}>
              <div>
                <Button
                  variant={'filled'}
                  color={'primary'}
                  disabled={!selectedAppUserId}
                  onClick={async () => {
                    await markUnread(selectedAppUserId);
                    dispatch(markConversationUnread(selectedAppUserId));
                  }}
                >
                  Mark Unread
                </Button>
              </div>
              <div style={{ textAlign: 'right' }}>
                <span style={{ marginRight: 4 }}>Sort by:</span>
                <Select
                  defaultValue={sortBy}
                  style={{ width: 150 }}
                  options={[
                    { value: 'latest', label: 'Latest Message' },
                    { value: 'name', label: 'Name' },
                  ]}
                  onChange={(value) => setSortBy({ value })}
                />
              </div>
            </div>
            <Divider style={{ margin: 0 }} />
          </Space>

          <div style={{ overflow: 'auto', height: 'calc(100% - 105px)' }}>
            <MenuList>
              <VirtualList
                data={conversationList.sort(getSortFunc(sortBy.value))}
                itemKey={'patient.appuserId'}
                height={window.innerHeight - 200}
                itemHeight={45}
              >
                {(c) => (
                  <MenuItem
                    key={c.patient.appuserId}
                    style={{ width: '100%', paddingBlock: 2, paddingLeft: 32 }}
                    selected={
                      ongoingConversation.patient
                        ? ongoingConversation.patient.appuserId === c.patient.appuserId
                        : false
                    }
                    onClick={() => chooseConversation({ appuserId: c.patient.appuserId })}
                  >
                    <ListItemIcon>
                      <Space>
                        <Badge dot={c.hasUnreadMessage}>
                          <Avatar shape={'square'} style={{ backgroundColor: '#f56a00' }}>
                            {c.patient.name
                              .split(' ')
                              .map((n) => n.slice(0, 1))
                              .join('')
                              .toUpperCase()}
                          </Avatar>
                        </Badge>
                      </Space>
                    </ListItemIcon>
                    <Space
                      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                    >
                      <div style={{ fontSize: 14, marginLeft: 8 }}>{c.patient.name}</div>
                      <Space.Compact direction={'vertical'} style={{ alignItems: 'end' }}>
                        <Typography.Text
                          type={'secondary'}
                          style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}
                        >
                          {c.latestMessageTime ? moment(c.latestMessageTime).format('hh:mma') : ''}
                          {c.latestMessageFrom === 'staff' && <TbCircleLetterS style={{ fontSize: 16 }} />}
                          {c.latestMessageFrom === 'patient' && <TbCircleLetterPFilled style={{ fontSize: 16 }} />}
                        </Typography.Text>
                        <Typography.Text type={'secondary'} style={{ fontSize: 12 }}>
                          {c.latestMessageTime ? moment(c.latestMessageTime).format('YYYY-MM-DD') : ''}
                        </Typography.Text>
                      </Space.Compact>
                    </Space>
                  </MenuItem>
                )}
              </VirtualList>
            </MenuList>
          </div>
        </Sider>
      </Layout>
    </div>
  );
};

export default MessageBoardPage;
