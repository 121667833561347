import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, message, Modal, Popover, Row, Select, Space, Switch } from 'antd';
import { CloseOutlined, DeleteRowOutlined } from '@ant-design/icons';
import bioApi from '../../api/bioApi';
import { GET_MEDICAL_SUPPLIES, PRODUCT_CRUD_URL } from '../../api/URLs';
import { FaSyringe } from 'react-icons/fa';

const ProductModal = ({ originalProduct, show, onSubmit, onCancel }) => {
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState(originalProduct);
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [disableForm, setDisableForm] = useState(false);
  const [medicalSupplies, setMedicalSupplies] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setOpen(show);
    if (show) {
      setDataLoading(true);
      bioApi
        .get(GET_MEDICAL_SUPPLIES)
        .then(({ data }) => {
          setMedicalSupplies(data);
        })
        .catch((e) => {
          setMedicalSupplies([]);
        })
        .finally(() => setDataLoading(false));
    }
  }, [show]);
  useEffect(() => {
    form.resetFields();
    setProduct(originalProduct);
    setTitle(originalProduct ? 'Modify Product' : 'New Product');
  }, [originalProduct]);

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        setDisableForm(true);
        let upsertResult;
        try {
          if (product) {
            // update
            upsertResult = await bioApi.patch(PRODUCT_CRUD_URL.concat(product._id), values);
          } else {
            // create
            upsertResult = await bioApi.post(PRODUCT_CRUD_URL, values);
          }
          onSubmit(upsertResult.data);
          message.success(`Successfully ${product ? 'updated' : 'created'} product: [${values.name}]`, 15);
        } catch (e) {
          message.error(`Unable to  ${product ? 'updated' : 'created'} product: [${values.name}]`, 15);
        } finally {
          setLoading(false);
          setDisableForm(false);
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title={title}
      open={open}
      onOk={handleOk}
      okText={product ? 'Update' : 'Create'}
      onCancel={onCancel}
      width={'1050px'}
      style={{ top: 10 }}
      confirmLoading={loading}
      destroyOnClose
    >
      <Form
        form={form}
        initialValues={{
          ...product,
        }}
        labelCol={{
          span: 8,
        }}
        layout="horizontal"
        disabled={disableForm}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Product name"
              name="name"
              rules={[{ required: true, message: 'Product name cannot be empty' }]}
            >
              <Input.TextArea rows={2} />
            </Form.Item>
            <Row style={{ marginLeft: 65 }} gutter={16}>
              <Col span={16}>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[{ required: true, message: 'Product type cannot be empty' }]}
                >
                  <Select>
                    <Select.Option value="Supplement">Supplement</Select.Option>
                    <Select.Option value="Prescription">Prescription</Select.Option>
                    <Select.Option value="Service">Service</Select.Option>
                    <Select.Option value="MedicalSupply">Medical Supply</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Active" valuePropName="checked" name="active" labelCol={{ span: 12 }}>
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginLeft: 84 }}>
              <Col span={11}>
                <Form.Item
                  label="List price"
                  name="listPrice"
                  labelCol={12}
                  rules={[{ required: true, message: 'List Price cannot be empty' }]}
                >
                  <InputNumber step="0.01" prefix="$" min={0} />
                </Form.Item>
              </Col>
              <Col span={13}>
                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}>
                  {({ getFieldValue }) =>
                    getFieldValue('type') !== 'MedicalSupply' && (
                      <Form.Item
                        label="Default Quantity"
                        name="defaultQuantity"
                        labelCol={12}
                        rules={[{ required: true, message: 'Default Quantity cannot be empty' }]}
                      >
                        <InputNumber step="1" min={1} />
                      </Form.Item>
                    )
                  }
                </Form.Item>
              </Col>
            </Row>

            <Form.Item noStyle shouldUpdate={true}>
              {({ getFieldValue }) =>
                getFieldValue('type') !== 'MedicalSupply' && (
                  <Form.Item label="Preset Dosages">
                    <Form.List name="dosageList">
                      {(dosageFields, dosageOperation, { errors }) => (
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                          {dosageFields.map((dosageField, i) => {
                            return (
                              <Space.Compact key={dosageField.key} style={{ columnGap: 8 }}>
                                <Form.Item
                                  noStyle
                                  name={[dosageField.name, 'dosage']}
                                  rules={[{ required: true, message: 'Dosage input is required' }]}
                                >
                                  <Input
                                    suffix={
                                      <Form.Item noStyle shouldUpdate={true}>
                                        {({ getFieldValue }) => (
                                          <Popover
                                            style={{ width: 1000 }}
                                            placement={'right'}
                                            content={
                                              <Form.List name={[dosageField.name, 'medicalSupplies']}>
                                                {(medicalSupplyFields, medicalSupplyOperation) => (
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                      rowGap: 16,
                                                      width: 500,
                                                    }}
                                                  >
                                                    {medicalSupplyFields.map((medicalSupplyField, j) => (
                                                      <Space.Compact
                                                        key={medicalSupplyField.key}
                                                        style={{ columnGap: 4 }}
                                                      >
                                                        <Form.Item
                                                          noStyle
                                                          name={[medicalSupplyField.name, 'product']}
                                                          rules={[
                                                            { required: true, message: 'Please select medical supply' },
                                                          ]}
                                                        >
                                                          <Select
                                                            style={{ width: 375 }}
                                                            placeholder={'Search Medical Supply'}
                                                            showSearch
                                                            loading={dataLoading}
                                                            filterOption={(input, option) =>
                                                              (option?.label ?? '')
                                                                .toLowerCase()
                                                                .includes(input.toLowerCase())
                                                            }
                                                            options={medicalSupplies.map((d) => ({
                                                              value: d._id,
                                                              label: d.name,
                                                            }))}
                                                          />
                                                        </Form.Item>
                                                        <CloseOutlined />
                                                        <Form.Item
                                                          noStyle
                                                          name={[medicalSupplyField.name, 'quantity']}
                                                          rules={[{ required: true, message: 'Please give quantity' }]}
                                                        >
                                                          <InputNumber placeholder="quantity" min={1} />
                                                        </Form.Item>
                                                        <DeleteRowOutlined
                                                          style={{ color: 'red' }}
                                                          onClick={() => {
                                                            medicalSupplyOperation.remove(medicalSupplyField.name);
                                                          }}
                                                        />
                                                      </Space.Compact>
                                                    ))}
                                                    <Button
                                                      type="dashed"
                                                      onClick={() => medicalSupplyOperation.add()}
                                                      block
                                                    >
                                                      + Add Medical Supply
                                                    </Button>
                                                  </div>
                                                )}
                                              </Form.List>
                                            }
                                            title="Medical Supplies Associated To This Dosage"
                                            trigger="click"
                                          >
                                            <Button
                                              size={'small'}
                                              icon={<FaSyringe />}
                                              color={
                                                !!getFieldValue('dosageList')[i]?.medicalSupplies?.length
                                                  ? 'primary'
                                                  : 'default'
                                              }
                                              variant="filled"
                                            />
                                          </Popover>
                                        )}
                                      </Form.Item>
                                    }
                                    placeholder={`Dosage ${i + 1}`}
                                  />
                                </Form.Item>
                                <CloseOutlined
                                  onClick={() => {
                                    dosageOperation.remove(dosageField.name);
                                  }}
                                />
                              </Space.Compact>
                            );
                          })}
                          <Button type="dashed" onClick={() => dosageOperation.add()} block>
                            + Add Preset Dosage
                          </Button>
                        </div>
                      )}
                    </Form.List>
                  </Form.Item>
                )
              }
            </Form.Item>

            <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}>
              {({ getFieldValue }) => (
                <>
                  {getFieldValue('type') !== 'MedicalSupply' && (
                    <Form.Item label="Therapy Level" name="therapyLevel">
                      <Select>
                        <Select.Option value="Base">Base</Select.Option>
                        <Select.Option value="Correctional">Correctional</Select.Option>
                        <Select.Option value="Elective">Elective</Select.Option>
                        <Select.Option value={'Cardiovascular & Inflammation'}>
                          Cardiovascular & Inflammation
                        </Select.Option>
                        <Select.Option value={'General wellness'}>General wellness</Select.Option>
                        <Select.Option value="Other">Other</Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                </>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Description" name="description">
              <Input.TextArea />
            </Form.Item>
            <Form.Item label="Cost">
              <Form.List name="cost">
                {(costFields, costOperation) => (
                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                    {costFields.map((costField) => (
                      <Space key={costField.key}>
                        <Form.Item noStyle name={[costField.name, 'pharmacy']}>
                          <Input placeholder="Pharmacy" />
                        </Form.Item>
                        <Form.Item noStyle name={[costField.name, 'cost']}>
                          <InputNumber step="0.01" prefix="$" placeholder="Cost" min={0} />
                        </Form.Item>
                        <CloseOutlined
                          onClick={() => {
                            costOperation.remove(costField.name);
                          }}
                        />
                      </Space>
                    ))}
                    <Button type="dashed" onClick={() => costOperation.add()} block>
                      + Add Item
                    </Button>
                  </div>
                )}
              </Form.List>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProductModal;
