import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import Layout from '../components/layouts';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { fileToBase64 } from '../share/StringHelper';
import StatesList from '../share/StatesList';
import bioApi from '../api/bioApi';
import { LOCATION_SAVE_URL, UPLOAD_IMAGE_URL } from '../api/URLs';
import BoxLoader from '../components/loading/BoxLoader';
import { useLocation } from 'react-router-dom';
import { TimezoneList } from '../share/TimezoneList';

// /location/single
// {
//     "stationId": "biostation-east-delray-beach-0"
// }

const WeekString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const ServiceString = [
  'botox-xeomin',
  'hrt-for-men',
  'hrt-for-women',
  'womens-sexual-health',
  'hormone-replacement-therapy',
  'testosterone-therapy',
  'diagnostic-blood-testing',
  'hcg-weight-loss',
];

const LocationCreatePage = (props) => {
  const params = useLocation();

  const [loading, setloading] = React.useState(false);
  const [locationData, setlocationData] = React.useState({
    stationId: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipcode: '',
      country: 'United States',
    },
    coordinates: [0, 0],
    coverimage: '',
    email: '',
    images: [],
    name: '',
    operatinghours: {
      0: null,
      1: ['0900', '1700'],
      2: ['0900', '1700'],
      3: ['0900', '1700'],
      4: ['0900', '1700'],
      5: ['0900', '1700'],
      6: null,
    },
    phonenumber: {
      countryCode: '1',
      number: '',
    },
    services: [],
    timezone: 'US/Eastern',
  });
  const coverImageRef = React.useRef(null);
  const thumbnailRef = React.useRef(null);

  React.useEffect(() => {
    if (params.state) {
      setlocationData(params.state);
    }
  }, []);

  const handleCoverImage = () => {
    coverImageRef.current.click();
  };

  const handleAddCoverImage = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    setloading(true);
    const file = await fileToBase64(e.target.files[0]);
    try {
      bioApi
        .post(UPLOAD_IMAGE_URL, {
          folderType: 'location_upload',
          image: file.split(',')[1],
        })
        .then((response) => {
          if (response.data.result) {
            var location = { ...locationData };
            location.coverimage = response.data.data;
            setlocationData(location);
          } else {
            alert(response.data.message);
          }
          setloading(false);
        });
    } catch (error) {
      setloading(false);
      alert(error);
    }
  };

  const handleThumbnail = () => {
    thumbnailRef.current.click();
  };

  const handleAddThumbnail = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    setloading(true);
    const file = await fileToBase64(e.target.files[0]);
    try {
      bioApi
        .post(UPLOAD_IMAGE_URL, {
          folderType: 'location_upload',
          image: file.split(',')[1],
        })
        .then((response) => {
          if (response.data.result) {
            var location = { ...locationData };
            location.images.push(response.data.data);
            setlocationData(location);
          } else {
            alert(response.data.message);
          }
          setloading(false);
        });
    } catch (error) {
      setloading(false);
      alert(error);
    }
  };

  const handleSubmit = async () => {
    setloading(true);
    try {
      var location = { ...locationData };
      delete location['_id'];
      delete location['__v'];
      bioApi
        .post(LOCATION_SAVE_URL, {
          ...location,
        })
        .then((response) => {
          if (response.data.status !== 200) {
            alert(response.data.message);
          } else {
            window.location = '/location';
          }
          setloading(false);
        });
    } catch (error) {
      setloading(false);
      alert(error);
    }
  };

  return (
    <Layout>
      <BoxLoader show={loading} loadingStyle={2} />

      <Col style={{ marginLeft: 40, marginBottom: 10 }}>
        <Col>
          <Form.Label column="lg">Location Create</Form.Label>
        </Col>
      </Col>

      <Col style={{ margin: 80, marginTop: 20, marginBottom: 20 }}>
        <Col>
          <Form>
            <Col className="mb-2" style={{ display: 'flex' }}>
              <Form.Label column="sm" style={{ flex: 1.8, margin: 'auto' }}>
                Location ID
              </Form.Label>
              <Form.Control
                column="sm"
                style={{ flex: 8.2 }}
                type="text"
                placeholder="Please input location id"
                value={locationData.stationId}
                onChange={(event) => {
                  var value = event.target.value;
                  var location = { ...locationData };
                  location.stationId = value;
                  setlocationData(location);
                }}
              />
            </Col>
            <Col className="mb-2" style={{ display: 'flex' }}>
              <Form.Label column="sm" style={{ flex: 1.8, margin: 'auto' }}>
                Location Name
              </Form.Label>
              <Form.Control
                column="sm"
                style={{ flex: 8.2 }}
                type="text"
                placeholder="Please input location name"
                value={locationData.name}
                onChange={(event) => {
                  var value = event.target.value;
                  var location = { ...locationData };
                  location.name = value;
                  setlocationData(location);
                }}
              />
            </Col>
            <Col className="mb-2" style={{ display: 'flex' }}>
              <Form.Label column="sm" style={{ flex: 1.8, margin: 'auto' }}>
                Name on <b>Salesforce</b>
              </Form.Label>
              <Form.Control
                column="sm"
                style={{ flex: 8.2 }}
                type="text"
                placeholder="Please input this location's name on salesforce"
                value={locationData.sfName}
                onChange={(event) => {
                  var value = event.target.value;
                  var location = { ...locationData };
                  location.sfName = value;
                  setlocationData(location);
                }}
              />
            </Col>
            <Col className="mb-2">
              <Form.Label>Location Address</Form.Label>
              <Col style={{ margin: 10 }}>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label column="sm" style={{ flex: 1.6, margin: 'auto' }}>
                    Line1
                  </Form.Label>
                  <Form.Control
                    column="sm"
                    style={{ flex: 8.4 }}
                    type="text"
                    placeholder="Please input line1"
                    value={locationData.address.line1}
                    onChange={(event) => {
                      var value = event.target.value;
                      var location = { ...locationData };
                      location.address.line1 = value;
                      setlocationData(location);
                    }}
                  />
                </Col>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label column="sm" style={{ flex: 1.6, margin: 'auto' }}>
                    Line2
                  </Form.Label>
                  <Form.Control
                    column="sm"
                    style={{ flex: 8.4 }}
                    type="text"
                    placeholder="Please input line2"
                    value={locationData.address.line2}
                    onChange={(event) => {
                      var value = event.target.value;
                      var location = { ...locationData };
                      location.address.line2 = value;
                      setlocationData(location);
                    }}
                  />
                </Col>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label column="sm" style={{ flex: 1.6, margin: 'auto' }}>
                    City
                  </Form.Label>
                  <Form.Control
                    column="sm"
                    style={{ flex: 8.4 }}
                    type="text"
                    placeholder="Please input city"
                    value={locationData.address.city}
                    onChange={(event) => {
                      var value = event.target.value;
                      var location = { ...locationData };
                      location.address.city = value;
                      setlocationData(location);
                    }}
                  />
                </Col>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label column="sm" style={{ flex: 1.6, margin: 'auto' }}>
                    State
                  </Form.Label>
                  <select
                    style={{ flex: 8.4 }}
                    className="form-control input-sm"
                    value={locationData.address.state}
                    onChange={(event) => {
                      var value = event.target.value;
                      var location = { ...locationData };
                      location.address.state = value;
                      setlocationData(location);
                    }}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {StatesList.map((StateCon) => {
                      return (
                        <option key={StateCon.name} value={StateCon.name}>
                          {StateCon.name}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label column="sm" style={{ flex: 1.6, margin: 'auto' }}>
                    Zip/Postal code
                  </Form.Label>
                  <Form.Control
                    column="sm"
                    style={{ flex: 8.4 }}
                    type="text"
                    placeholder="Please input zip/Postal code"
                    value={locationData.address.zipcode}
                    onChange={(event) => {
                      var value = event.target.value;
                      var location = { ...locationData };
                      location.address.zipcode = value;
                      setlocationData(location);
                    }}
                  />
                </Col>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label column="sm" style={{ flex: 1.6, margin: 'auto' }}>
                    Country
                  </Form.Label>
                  <Form.Control
                    column="sm"
                    style={{ flex: 8.4 }}
                    type="text"
                    disabled={true}
                    placeholder="Please input country"
                    value={locationData.address.country}
                    onChange={(event) => {
                      var value = event.target.value;
                      var location = { ...locationData };
                      location.address.country = value;
                      setlocationData(location);
                    }}
                  />
                </Col>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label column="sm" style={{ flex: 1.55, margin: 'auto' }}>
                    Coordinates
                  </Form.Label>
                  <Col style={{ display: 'flex', flex: 8.45 }}>
                    <Form.Control
                      column="sm"
                      type="number"
                      placeholder="Latitude"
                      value={locationData.coordinates[0]}
                      onChange={(event) => {
                        var value = event.target.value;
                        var location = { ...locationData };
                        location.coordinates.splice(0, 1, value);
                        setlocationData(location);
                      }}
                    />
                    <Form.Text style={{ margin: 'auto' }}>&nbsp;&nbsp;/&nbsp;&nbsp;</Form.Text>
                    <Form.Control
                      column="sm"
                      type="number"
                      placeholder="Longitude"
                      value={locationData.coordinates[1]}
                      onChange={(event) => {
                        var value = event.target.value;
                        var location = { ...locationData };
                        location.coordinates.splice(1, 1, value);
                        setlocationData(location);
                      }}
                    />
                  </Col>
                </Col>
              </Col>
            </Col>
            <Col className="mb-2" style={{ display: 'flex' }}>
              <Form.Label column="sm" style={{ flex: 1.8, margin: 'auto' }}>
                Timezone
              </Form.Label>
              <select
                style={{ flex: 8.2 }}
                value={locationData.timezone}
                className="form-control input-sm"
                onChange={(event) => {
                  var location = { ...locationData };
                  location.timezone = event.target.value;
                  setlocationData(location);
                }}
              >
                {TimezoneList.flatMap((tl) => tl.timeZones).map((t, i) => (
                  <option key={i} value={t}>
                    {t}
                  </option>
                ))}
              </select>
            </Col>
            <Col className="mb-2" style={{ display: 'flex' }}>
              <Form.Label column="sm" style={{ flex: 1.8, margin: 'auto' }}>
                Email
              </Form.Label>
              <Form.Control
                column="sm"
                style={{ flex: 8.2 }}
                type="text"
                placeholder="Please input email"
                value={locationData.email}
                onChange={(event) => {
                  var value = event.target.value;
                  var location = { ...locationData };
                  location.email = value;
                  setlocationData(location);
                }}
              />
            </Col>
            <Col className="mb-2" style={{ display: 'flex' }}>
              <Form.Label column="sm" style={{ flex: 1.75, margin: 'auto' }}>
                Phone Number
              </Form.Label>
              <Col style={{ display: 'flex', flex: 8.25 }}>
                <Form.Control
                  column="sm"
                  style={{ flex: 2, marginRight: 5 }}
                  type="number"
                  placeholder="Country Code"
                  value={locationData.phonenumber.countryCode}
                  onChange={(event) => {
                    var value = event.target.value;
                    var location = { ...locationData };
                    location.phonenumber.countryCode = value;
                    setlocationData(location);
                  }}
                />
                <Form.Control
                  column="sm"
                  style={{ flex: 8, marginLeft: 5 }}
                  type="text"
                  placeholder="Phone Number"
                  value={locationData.phonenumber.number}
                  onChange={(event) => {
                    var value = event.target.value;
                    var location = { ...locationData };
                    location.phonenumber.number = value;
                    setlocationData(location);
                  }}
                />
              </Col>
            </Col>
            <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
              <Row className="mb-2">
                <Col style={{ display: 'flex' }}>
                  <Form.Label column="sm" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    Services
                  </Form.Label>
                </Col>
              </Row>
              <Col
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {ServiceString.map((item, index) => {
                  return (
                    <Form.Check
                      column="sm"
                      checked={locationData.services.indexOf(item) > -1}
                      style={{ width: '33.3%' }}
                      label={item}
                      onClick={() => {
                        var location = { ...locationData };
                        var index = location.services.indexOf(item);
                        if (index > -1) {
                          location.services.splice(index, 1);
                        } else {
                          location.services.push(item);
                        }
                        setlocationData(location);
                      }}
                    />
                  );
                })}
              </Col>
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
              <Row className="mb-2">
                <Col style={{ display: 'flex' }}>
                  <Form.Label column="sm" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    Cover Image
                  </Form.Label>
                </Col>
                <Col style={{ display: 'flex' }}>
                  <Col style={{ flex: 1 }}></Col>
                  <Button variant="outline-primary" style={{ marginLeft: 10 }} onClick={handleCoverImage}>
                    Upload
                  </Button>
                  <input
                    style={{
                      display: 'none',
                    }}
                    ref={coverImageRef}
                    type="file"
                    accept="image/*"
                    onChange={handleAddCoverImage}
                  />
                </Col>
              </Row>
              <Col style={{ display: 'flex' }}>
                {locationData.coverimage && locationData.coverimage !== '' ? (
                  <Card style={{ margin: 'auto', width: '30%', marginBottom: 10 }}>
                    <Card.Img variant="top" src={locationData.coverimage} />
                    <Card.Body>
                      <Col style={{ display: 'flex' }}>
                        <Button
                          variant="outline-danger"
                          style={{ margin: 'auto' }}
                          onClick={() => {
                            var location = { ...locationData };
                            location.coverimage = '';
                            setlocationData(location);
                          }}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Card.Body>
                  </Card>
                ) : null}
              </Col>
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
              <Row className="mb-2">
                <Col style={{ display: 'flex' }}>
                  <Form.Label style={{ marginTop: 'auto', marginBottom: 'auto' }}>Thumbnail(s)</Form.Label>
                </Col>
                <Col style={{ display: 'flex' }}>
                  <Button variant="outline-primary" style={{ marginLeft: 'auto' }} onClick={handleThumbnail}>
                    Upload
                  </Button>
                  <input
                    style={{
                      display: 'none',
                    }}
                    ref={thumbnailRef}
                    type="file"
                    accept="image/*"
                    onChange={handleAddThumbnail}
                  />
                </Col>
              </Row>
              <Col style={{ display: 'flex', flexWrap: 'wrap' }}>
                {locationData.images.length > 0 &&
                  locationData.images.map((item, index) => {
                    return (
                      <Card style={{ margin: 'auto', width: '30%', marginBottom: 10 }}>
                        <Card.Img variant="top" src={item} />
                        <Card.Body>
                          <Col style={{ display: 'flex' }}>
                            <Button
                              variant="outline-danger"
                              style={{ margin: 'auto' }}
                              onClick={() => {
                                var location = { ...locationData };
                                location.images.splice(index, 1);
                                setlocationData(location);
                              }}
                            >
                              Delete
                            </Button>
                          </Col>
                        </Card.Body>
                      </Card>
                    );
                  })}
              </Col>
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="mb-2" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                Operation Hours
              </Form.Label>
              <Col>
                {WeekString.map((item, index) => {
                  return (
                    <Col className="mb-2" style={{ display: 'flex' }}>
                      <Col style={{ marginLeft: 5, marginRight: 5 }}>
                        <Form.Text style={{ width: 100, marginTop: 'auto', marginBottom: 'auto' }}>{item}</Form.Text>
                      </Col>
                      <Col style={{ marginLeft: 5, marginRight: 5 }}>
                        <TimePicker
                          showSecond={false}
                          disabled={locationData.operatinghours[index + ''] === null}
                          use12Hours
                          format="hh:mm a"
                          value={
                            locationData.operatinghours[index + ''] === null
                              ? ''
                              : moment(locationData.operatinghours[index + ''][0], 'hhmm')
                          }
                          onChange={(time) => {
                            if (time !== null) {
                              var timestring = moment(time).format('HHmm');
                              var location = { ...locationData };
                              location.operatinghours[index + ''].splice(0, 1, timestring);
                              setlocationData(location);
                            }
                          }}
                        />
                      </Col>
                      <Col style={{ marginLeft: 5, marginRight: 5 }}>
                        <TimePicker
                          showSecond={false}
                          disabled={locationData.operatinghours[index + ''] === null}
                          use12Hours
                          format="hh:mm a"
                          value={
                            locationData.operatinghours[index + ''] === null
                              ? ''
                              : moment(locationData.operatinghours[index + ''][1], 'hhmm')
                          }
                          onChange={(time) => {
                            if (time !== null) {
                              var timestring = moment(time).format('HHmm');
                              var location = { ...locationData };
                              location.operatinghours[index + ''].splice(1, 1, timestring);
                              setlocationData(location);
                            }
                          }}
                        />
                      </Col>
                      <Form.Check
                        checked={locationData.operatinghours[index + ''] === null}
                        style={{ marginLeft: 5, marginTop: 'auto', marginBottom: 'auto' }}
                        label="Non Operational Day"
                        onClick={() => {
                          var location = { ...locationData };
                          if (locationData.operatinghours[index + ''] === null) {
                            location.operatinghours[index + ''] = ['0900', '1700'];
                          } else {
                            location.operatinghours[index + ''] = null;
                          }
                          setlocationData(location);
                        }}
                      />
                    </Col>
                  );
                })}
              </Col>
            </Form.Group>
            <Row className="mt-4 mb-2">
              <Col style={{ display: 'flex' }}>
                <Button variant="primary" style={{ margin: 'auto' }} onClick={handleSubmit}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Col>
    </Layout>
  );
};

export default LocationCreatePage;
