import { createSlice } from '@reduxjs/toolkit';

const patientTreatmentPlanSlice = createSlice({
  name: 'patientTreatmentPlans',
  initialState: [],
  reducers: {
    setTreatmentPlans: (state, action) => {
      return action.payload;
    },
    addTreatmentPlan: (state, action) => {
      return [action.payload, ...state];
    },
    updateTreatmentPlan: (state, action) => {
      return [action.payload, ...state.filter((p) => p._id !== action.payload._id)];
    },
    deleteTreatmentPlan: (state, action) => {
      return state.filter((p) => p._id !== action.payload._id);
    },
  },
});

export const { setTreatmentPlans, addTreatmentPlan, updateTreatmentPlan, deleteTreatmentPlan } =
  patientTreatmentPlanSlice.actions;
export default patientTreatmentPlanSlice.reducer;
