import bioApi from '../../api/bioApi';
import { STAFF_AUTO_COMPLETE_URL, STAFF_LIST_URL, STAFF_SEARCH_URL } from '../../api/URLs';

export const listAllStaff = () => {
  return bioApi.get(STAFF_LIST_URL);
};

export const searchStaff = (option, query) => {
  const url = STAFF_SEARCH_URL.replace(':option', option).replace(':query', query);
  return bioApi.get(url);
};

export const autoCompleteStaff = (option, query) => {
  const url = STAFF_AUTO_COMPLETE_URL.replace(':option', option).replace(':query', query);
  return bioApi.get(url);
};
