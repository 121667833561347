import React from 'react';
import { Table } from 'antd';
import InputComponent from '../input/InputComponent';
import TextAreaComponent from '../input/TextAreaComponent';
import InputNumberComponent from '../input/InputNumberComponent';
import SelectComponent from '../select/SelectComponent';
import { DeleteRowOutlined } from '@ant-design/icons';

const TableComponent = ({isSubmit, dataSource, setDataSource, productData, setProductData, showText}) => {

    const columns = [
        {
            title: 'Product',
            dataIndex: 'product',
            render: (_, record) => (
                showText === "view" ? record.product : <SelectComponent options={productData} width="100%" size={'middle'} status={isSubmit && !record.product && `error`} value={record.product} setValue={(value) => {
                    record.product = value
                    setDataSource([...dataSource])
                }} placeholder={`Select Product`} />
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 150,
            render: (_, record) => (
                showText === "view" ? record.quantity : <InputNumberComponent size={'middle'} status={isSubmit && !record.quantity && `error`} value={record.quantity} setValue={(value) => {
                    record.quantity = value
                    setDataSource([...dataSource])
                }} min={0} placeholder={`Quantity`} />
            ),
        },
        {
            title: 'Refill',
            dataIndex: 'refill',
            render: (_, record) => (
                showText === "view" ? record.refill : <InputComponent size={'middle'} status={isSubmit && !record.refill && `error`} value={record.refill} setValue={(value) => {
                    record.refill = value
                    setDataSource([...dataSource])
                }} placeholder={`Refill`} />
            ),
        },
        {
            title: 'Dosage',
            dataIndex: 'dosage',
            render: (_, record) => (
                showText === "view" ? record.dosage : <InputComponent size={'middle'} status={isSubmit && !record.dosage && `error`} value={record.dosage} setValue={(value) => {
                    record.dosage = value
                    setDataSource([...dataSource])
                }} placeholder={`Dosage`} />
            ),
        },
        {
            title: 'Note',
            dataIndex: 'note',
            render: (_, record, index) => (
                showText === "view" ? record.note : <div className='d-flex align-items-center'>
                <TextAreaComponent height={"16px"} size={'small'} status={isSubmit && !record.note && `error`} value={record.note} setValue={(value) => {
                    record.note = value
                    setDataSource([...dataSource])
                }} placeholder={`Note`} />
                <span 
                onClick={() => {
                    dataSource.splice(index, 1);
                    setDataSource([...dataSource])
                }}
                className='ms-2' style={{color: "red", cursor: "pointer"}}>
                    <DeleteRowOutlined />
                </span>
                </div>
            ),
        },
    ];
    
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            // pagination={{
            //     pageSize: 50,
            // }}
            pagination={false}  // Disable pagination
            scroll={{
                y: 55 * 5,
            }}
        />
    );
};
export default TableComponent;