import React, { useEffect } from 'react';
import { Alert, Avatar, Badge, Popover, Select, Space, Spin, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../stores/authenticated-user.store';
import bioApi from '../../api/bioApi';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import CustomMenu from './CustomMenu';
import { CloudServerOutlined, PoweroffOutlined } from '@ant-design/icons';
import { setTimezone } from '../../stores/timezone.store';
import { isInSameTimezone, TimezoneList } from '../../share/TimezoneList';

const NavigationTopper = () => {
  const user = useSelector((state) => state.authenticatedUser);
  const timezone = useSelector((state) => state.timezone);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [color] = React.useState('#00a2ae');
  const [systemStatus, setSystemStatus] = React.useState(null);
  const [systemStatusColor, setSystemStatusColor] = React.useState('green');

  const pollSystemStatus = async () => {
    const statusQuery = await bioApi.get('/system-status');
    setSystemStatus(statusQuery.data);
  };
  useEffect(() => {
    pollSystemStatus();
    const statusPoll = setInterval(pollSystemStatus, 50000);

    return () => {
      clearInterval(statusPoll);
    };
  }, []);

  useEffect(() => {
    if (!!systemStatus) {
      if (!systemStatus.sfApiStatus || !systemStatus.zohoApiStatus || !systemStatus.fedexApiStatus) {
        setSystemStatusColor('red');
      } else {
        setSystemStatusColor('green');
      }
    }
  }, [systemStatus]);
  let firstNameInitial = '';
  let lastNameInitial = '';
  if (user) {
    const nameParts = user.name.split(' ');
    firstNameInitial = nameParts[0].charAt(0) ?? '';
    lastNameInitial = nameParts[1].charAt(0) ?? '';
  }

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [
    /*
    //example of nested items
    getItem('Navigation One', 'sub1', <MailOutlined />, [
      getItem('Option 1', '1'),
      getItem('Option 2', '2'),
      getItem('Option 3', '3'),
      getItem('Option 4', '4'),
    ]),
    getItem('Navigation Two', 'sub2', <AppstoreOutlined />, [
      getItem('Option 5', '5'),
      getItem('Option 6', '6'),
      getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')]),
    ]),*/
    {
      type: 'divider',
    },
    getItem(
      <a
        style={{
          color: 'red',
        }}
        onClick={async () => {
          navigate('/login');
          await bioApi.post('/auth/logout');
          dispatch(userLogout());
        }}
        rel="noopener noreferrer"
      >
        Logout
      </a>,
      'logout',
      <PoweroffOutlined
        style={{
          color: 'red',
        }}
      />
    ),
  ];

  const content = (
    <div
      style={{
        padding: 8,
        maxWidth: '260px',
      }}
    >
      {user && (
        <>
          <Grid
            container
            gap={2}
            style={{
              paddingBottom: '6px',
            }}
          >
            <Grid item sm={2}>
              <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size="large">
                {firstNameInitial + lastNameInitial || 'U'}
              </Avatar>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={12}>
                  <span>{user.name}</span>{' '}
                  <span
                    style={{
                      color: 'lightGrey',
                    }}
                  >
                    {user.staffId || ''}
                  </span>
                </Grid>
                <Grid item sm={12}>
                  <span>{user.email}</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={12}>
              <CustomMenu items={items} />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );

  return (
    <div
      style={{
        padding: 8,
        height: `60px`,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        width: '100%',
        zIndex: 111,
        justifyContent: 'space-between',
      }}
    >
      <Space
        style={{
          display: 'flex',
          alignItems: 'end',
        }}
      >
        <img src={require('../../assets/images/biostation-logo.png')} style={{ height: 44 }} alt="logo" />
        <Popover
          placement={'rightBottom'}
          content={
            !!systemStatus ? (
              <div style={{ maxWidth: 400 }}>
                <p>
                  <div>
                    <Badge status={systemStatus.sfApiStatus ? 'success' : 'error'} text="Salesforce Integration" />
                  </div>
                  {!systemStatus.sfApiStatus && (
                    <Typography.Text type={'secondary'}>
                      Communication with Salesforce is down. This could lead to discrepancies in displaying and updating
                      patient data. Please try to avoid update patient data in details page until the issue is resolved.
                    </Typography.Text>
                  )}
                </p>
                <p>
                  <div>
                    <Badge status={systemStatus.zohoApiStatus ? 'success' : 'error'} text="Zoho Integration" />
                  </div>
                  {!systemStatus.zohoApiStatus && (
                    <Typography.Text type={'secondary'}>
                      Communication with Zoho is down. You will not be able to send consent to patient.
                    </Typography.Text>
                  )}
                </p>
                <div>
                  <div>
                    <Badge status={systemStatus.fedexApiStatus ? 'success' : 'error'} text="Fedex Integration" />
                  </div>
                  {!systemStatus.fedexApiStatus && (
                    <Typography.Text type={'secondary'}>
                      Communication with Fedex is down. Order shipping information display will be limited.
                    </Typography.Text>
                  )}
                </div>
              </div>
            ) : (
              <Spin />
            )
          }
        >
          <Badge dot color={systemStatusColor}>
            <CloudServerOutlined style={{ fontSize: 24 }} />
          </Badge>
        </Popover>
      </Space>
      <Space>
        {user && (
          <>
            {!timezone && (
              <Alert
                type={'error'}
                message={
                  'You need to choose a primary location in staff configuration to work properly on time related features'
                }
              />
            )}
            <Space>
              <Typography.Text>Selected Timezone:</Typography.Text>
              <Tooltip
                title="Not your primary location timezone"
                color={'orange'}
                open={!isInSameTimezone(user.timezone, timezone)}
                overlayStyle={{ opacity: 0.8 }}
              >
                <Select
                  showSearch
                  placeholder="Select a timezone"
                  style={{ width: 300 }}
                  value={timezone}
                  onChange={(value) => {
                    dispatch(setTimezone(value));
                  }}
                  options={TimezoneList.map((tl) => ({
                    label: tl.zoneName,
                    title: tl.zoneName,
                    options: tl.timeZones.map((tz) => ({
                      label: tz,
                      value: tz,
                    })),
                  }))}
                />
              </Tooltip>
              <Popover content={content} trigger="click">
                <div
                  style={{
                    cursor: 'pointer',
                  }}
                  className="userGroup"
                >
                  <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size="large">
                    {firstNameInitial + lastNameInitial || 'U'}
                  </Avatar>
                </div>
              </Popover>
            </Space>
          </>
        )}
      </Space>
    </div>
  );
};

export default NavigationTopper;
