import React from 'react';
import AnnouncementList from './AnnouncementList'
import NewAnnouncement from "./NewAnnouncement";
import Page from '../../../components/page/Page';
import { Tab, Tabs } from 'react-bootstrap';

const AnnouncementPage = ({history}) => {
    const [type, setType] = React.useState(0);
    return(
        <Page title={'Announcement Management'} isCustom={false}>
            <div style={{ width: "100%", height: "100%" }}>
                <Tabs id="uncontrolled-tab-example" className="mb-3" defaultActiveKey={type}>
                    <Tab eventKey={0} title="Send Announcement" onClick={() => setType(0)}>
                        <NewAnnouncement />
                    </Tab>
                    <Tab eventKey={1} title="Announcement History" onClick={() => setType(1)}>
                        <AnnouncementList
                            switch={false}
                            type={type}
                            history={history} />
                    </Tab>
                </Tabs>
            </div>
        </Page>
    )
};

export default AnnouncementPage;