export const ApplicationRight = {
  Lab_Info_Access: 'Lab_Info_Access',
  Patient_Detail_Access: 'Patient_Detail_Access',
  Medical_Notes_Access: 'Medical_Notes_Access',
  Treatment_Creation: 'Treatment_Creation',
  Treatment_Review: 'Treatment_Review',
  BioID_Brief_Access: 'BioID_Brief_Access',
  Orders_Generation: 'Orders_Generation',
  Orders_Tracking: 'Orders_Tracking',
  CRE8_Orders_Management: 'CRE8_Orders_Management',
  Appointment_Read_Access: 'Appointment_Read_Access',
  Appointment_Creation: 'Appointment_Creation',
  Appointment_Editing: 'Appointment_Editing',
  Payment_History_Access: 'Payment_History_Access',
  Files_Access: 'Files_Access',
  Staff_Schedule_Access: 'Staff_Schedule_Access',
  Staff_Blocked_Time_Management: 'Staff_Blocked_Time_Management',
  Lab_Order_Creating: 'Lab_Order_Creating',
  Lab_Order_Management: 'Lab_Order_Management',
  News_Posts_Access: 'News_Posts_Access',
  Notification_Access: 'Notification_Access',
  Announcement_Access: 'Announcement_Access',
  Reward_Settings_Access: 'Reward_Settings_Access',
  Reward_Redemption_Access: 'Reward_Redemption_Access',
  Location_Management_Access: 'Location_Management_Access',
  Staff_Management_Access: 'Staff_Management_Access',
  System_Setting_Access: 'System_Setting_Access',
  Minimum_Version_Access: 'Minimum_Version_Access',
  App_Version_Config_Access: 'App_Version_Config_Access',
  Patient_Creating: 'Patient_Creating',
  Product_Management_Access: 'Product_Management_Access',
  Biomarker_Management_Access: 'Biomarker_Management_Access',
  Assigned_Patient_Message_Access: 'Assigned_Patient_Message_Access',
  All_Patient_Message_Access: 'All_Patient_Message_Access',
  Assigned_Patient_Request_Access: 'Assigned_Patient_Request_Access',
  All_Patient_Request_Access: 'All_Patient_Request_Access',
  PA_Group_Access: 'PA_Group_Access',
  Assigned_Opportunities_Access: 'Assigned_Opportunities_Access',
  All_Opportunities_Access: 'All_Opportunities_Access',
  Schedule_And_Appointment_Acceptance: 'Schedule_&_Appointment_Acceptance',
};
