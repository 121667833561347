import bioApi from '../../api/bioApi';
import { PATIENT_REQUESTS_URL } from '../../api/URLs';

export const getPatientRequests = async () => {
  return await bioApi.get(PATIENT_REQUESTS_URL);
};

export const changePatientRequestStatus = async (id, newStatus, note) => {
  return await bioApi.post(PATIENT_REQUESTS_URL + `/${id}`, { newStatus, note });
};
