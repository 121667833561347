import { createSlice } from '@reduxjs/toolkit';

const currentPatientSlice = createSlice({
  name: 'currentPatient',
  initialState: null,
  reducers: {
    setCurrentPatient: (state, action) => {
      return action.payload;
    },
    updateCurrentPatient: (state, action) => {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    },
    updateCurrentPatientProfile: (state, action) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          [action.payload.key]: action.payload.value,
        },
      };
    },
  },
});

export const { setCurrentPatient, updateCurrentPatient, updateCurrentPatientProfile } = currentPatientSlice.actions;
export default currentPatientSlice.reducer;
