import React from 'react';
import PropTypes from '../../utils/propTypes';
import { FiArrowLeftCircle } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Explanation from '../../components/explanation/Explanation';
import ReactHtmlParser from 'react-html-parser';

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowMinHeight: '100%',
    };
  }

  render() {
    const { title, description, children, URL, explanation, gobackCallback } = this.props;

    return (
      <div className="content-wrapper" style={{ minHeight: this.state.windowMinHeight }}>
        <section className="content-header">
          <div style={{ display: 'flex', alignItems: 'center', height: 30 }}>
            {URL ? (
              <Link to={URL}>
                <FiArrowLeftCircle className="back-icon" />
              </Link>
            ) : null}
            {gobackCallback ? (
              <FiArrowLeftCircle
                onClick={() => {
                  gobackCallback();
                }}
                className="back-icon"
              />
            ) : null}
            <h3
              className="title-page"
              style={{
                fontSize: '16px',
                marginTop: '0',
                marginBottom: '5px',
                color: '#333',
                fontFamily: 'Roboto',
              }}
            >
              {title}
            </h3>
            {explanation ? <Explanation title={title}>{ReactHtmlParser(explanation)}</Explanation> : null}
            {description && description.length > 0 ? (
              <div style={{ flex: 1, display: 'flex' }}>
                <div style={{ flex: 1 }}></div>
                <div>{description}</div>
              </div>
            ) : null}
          </div>
        </section>
        <section className="content container-fluid">{children}</section>
      </div>
    );
  }
}

Page.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isCustom: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
};

Page.defaultProps = {
  title: '',
  isCustom: false,
};

export default Page;
