import React from 'react';
import { Button, Carousel, Col, Form, Row } from 'react-bootstrap';
import Layout from '../components/layouts';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import { useLocation } from 'react-router-dom';

// /location/single
// {
//     "stationId": "biostation-east-delray-beach-0"
// }

const WeekString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const LocationReviewPage = (props) => {
  const params = useLocation();

  const [locationData, setlocationData] = React.useState({
    stationId: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipcode: '',
      country: 'United States',
    },
    coordinates: [0, 0],
    coverimage: '',
    email: '',
    images: [],
    name: '',
    operatinghours: {
      0: null,
      1: ['0900', '1700'],
      2: ['0900', '1700'],
      3: ['0900', '1700'],
      4: ['0900', '1700'],
      5: ['0900', '1700'],
      6: null,
    },
    phonenumber: {
      countryCode: '1',
      number: '',
    },
    services: [],
    timezone: 'US/Eastern',
  });

  React.useEffect(() => {
    if (params.state) {
      setlocationData(params.state);
    }
  }, []);

  return (
    <Layout>
      <Row style={{ margin: 10 }}>
        <Col>
          <Row style={{ marginBottom: 10 }}>
            <Col>
              <Form.Label column="lg">Location Review</Form.Label>
            </Col>
          </Row>

          <Form>
            <Col className="mb-2" style={{ display: 'flex' }}>
              <Form.Label style={{ flex: 1.8, margin: 'auto' }}>Location ID</Form.Label>
              <Form.Text>{locationData.stationId}</Form.Text>
            </Col>
            <Col className="mb-2" style={{ display: 'flex' }}>
              <Form.Label style={{ flex: 1.8, margin: 'auto' }}>Location Name</Form.Label>
              <Form.Text>{locationData.name}</Form.Text>
            </Col>
            <Col className="mb-2" style={{ display: 'flex' }}>
              <Form.Label style={{ flex: 1.8, margin: 'auto' }}>Name on Salesforce</Form.Label>
              <Form.Text>{locationData.sfName}</Form.Text>
            </Col>
            <Col className="mb-2">
              <Form.Label>Location Address</Form.Label>
              <Col style={{ margin: 10 }}>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label style={{ flex: 1.6, margin: 'auto' }}>Line1</Form.Label>
                  <Form.Text>{locationData.address.line1}</Form.Text>
                </Col>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label style={{ flex: 1.6, margin: 'auto' }}>Line2</Form.Label>
                  <Form.Text>{locationData.address.line2}</Form.Text>
                </Col>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label style={{ flex: 1.6, margin: 'auto' }}>City</Form.Label>
                  <Form.Text>{locationData.address.city}</Form.Text>
                </Col>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label style={{ flex: 1.6, margin: 'auto' }}>State</Form.Label>
                  <Form.Text>{locationData.address.state}</Form.Text>
                </Col>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label style={{ flex: 1.6, margin: 'auto' }}>Zip/Postal code</Form.Label>
                  <Form.Text>{locationData.address.zipcode}</Form.Text>
                </Col>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label style={{ flex: 1.6, margin: 'auto' }}>Country</Form.Label>
                  <Form.Text>{locationData.address.country}</Form.Text>
                </Col>
                <Col className="mb-2" style={{ display: 'flex' }}>
                  <Form.Label style={{ flex: 1.55, margin: 'auto' }}>Coordinates</Form.Label>
                  <Form.Text>{locationData.coordinates[0] + ', ' + locationData.coordinates[1]}</Form.Text>
                </Col>
              </Col>
            </Col>
            <Col className="mb-2" style={{ display: 'flex' }}>
              <Form.Label style={{ flex: 1.8, margin: 'auto' }}>Timezone</Form.Label>
              <Form.Text>{locationData.timezone}</Form.Text>
            </Col>
            <Col className="mb-2" style={{ display: 'flex' }}>
              <Form.Label style={{ flex: 1.8, margin: 'auto' }}>Email</Form.Label>
              <Form.Text>{locationData.email}</Form.Text>
            </Col>
            <Col className="mb-2" style={{ display: 'flex' }}>
              <Form.Label style={{ flex: 1.75, margin: 'auto' }}>Phone Number</Form.Label>
              <Form.Text>
                {'+' + locationData.phonenumber.countryCode + ' ' + locationData.phonenumber.number}
              </Form.Text>
            </Col>
            <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
              <Row className="mb-2">
                <Col style={{ display: 'flex' }}>
                  <Form.Label style={{ marginTop: 'auto', marginBottom: 'auto' }}>Cover Image</Form.Label>
                </Col>
              </Row>
              <Col style={{ display: 'flex' }}>
                {locationData.coverimage && locationData.coverimage !== '' ? (
                  <img
                    style={{ margin: 'auto' }}
                    className="d-block w-50"
                    src={locationData.coverimage}
                    alt={'coverslide'}
                  />
                ) : null}
              </Col>
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
              <Row className="mb-2">
                <Col style={{ display: 'flex' }}>
                  <Form.Label style={{ marginTop: 'auto', marginBottom: 'auto' }}>Thumbnail(s)</Form.Label>
                </Col>
              </Row>
              <Col style={{ display: 'flex' }}>
                {locationData.images.length > 0 ? (
                  <Carousel style={{ flex: 0.5, margin: 'auto' }}>
                    {locationData.images.map((item, index) => {
                      return (
                        <Carousel.Item>
                          <img className="d-block w-100" src={item} alt={'slide' + index} />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                ) : null}
              </Col>
            </Form.Group>
            <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="mb-2" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                Operation Hours
              </Form.Label>
              <Col>
                {WeekString.map((item, index) => {
                  return (
                    <Col className="mb-2" style={{ display: 'flex' }}>
                      <Col style={{ marginLeft: 5, marginRight: 5 }}>
                        <Form.Text
                          style={{
                            width: 100,
                            marginTop: 'auto',
                            marginBottom: 'auto',
                          }}
                        >
                          {item}
                        </Form.Text>
                      </Col>
                      <Col style={{ marginLeft: 5, marginRight: 5 }}>
                        <Form.Text
                          style={{
                            width: 100,
                            marginTop: 'auto',
                            marginBottom: 'auto',
                          }}
                        >
                          {locationData.operatinghours[index + ''] === null
                            ? ''
                            : moment(locationData.operatinghours[index + ''][0], 'hhmm').format('HH:mm a')}
                        </Form.Text>
                      </Col>
                      <Col style={{ marginLeft: 5, marginRight: 5 }}>
                        <Form.Text
                          style={{
                            width: 100,
                            marginTop: 'auto',
                            marginBottom: 'auto',
                          }}
                        >
                          {locationData.operatinghours[index + ''] === null
                            ? ''
                            : moment(locationData.operatinghours[index + ''][1], 'hhmm').format('HH:mm a')}
                        </Form.Text>
                      </Col>
                      {locationData.operatinghours[index + ''] === null ? (
                        <Col style={{ marginLeft: 5, marginRight: 5 }}>
                          <Form.Text
                            style={{
                              width: 100,
                              marginTop: 'auto',
                              marginBottom: 'auto',
                            }}
                          >
                            Non Operational Day
                          </Form.Text>
                        </Col>
                      ) : (
                        <Col style={{ marginLeft: 5, marginRight: 5 }}></Col>
                      )}
                    </Col>
                  );
                })}
              </Col>
            </Form.Group>
            <Row className="mt-4 mb-2">
              <Col style={{ display: 'flex' }}>
                <Button variant="primary" style={{ margin: 'auto' }} onClick={() => (window.location = '/location')}>
                  Close
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Layout>
  );
};

export default LocationReviewPage;
