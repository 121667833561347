import DataTable from '../../../components/data-table/data-table.component';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Popover,
  Result,
  Space,
  Tag,
  Timeline,
  Typography,
} from 'antd';
import TreatmentModal from './components/treatment.modal';
import { approveTreatmentPlan, deleteDraftTreatment, requestTreatmentChange } from '../service';
import { deleteTreatmentPlan, updateTreatmentPlan } from '../../../stores/patient-treatment-plan.store';
import moment from 'moment/moment';
import { updateCurrentPatient } from '../../../stores/current-patient.store';
import { RxCornerBottomLeft } from 'react-icons/rx';
import { FaRegCommentDots, FaSyringe } from 'react-icons/fa';
import { BiWater } from 'react-icons/bi';
import { RiDraftLine } from 'react-icons/ri';
import { TbMailQuestion } from 'react-icons/tb';
import { HiOutlineHandRaised } from 'react-icons/hi2';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { LiaCalendarTimes } from 'react-icons/lia';
import { ApplicationRight } from '../../../share/RightList';
import { IoCreateOutline } from 'react-icons/io5';
import { VscGitPullRequestNewChanges } from 'react-icons/vsc';

export const treatmentPlanStatusDisplayMap = {
  created: (
    <Space>
      <IoCreateOutline />
      Created
    </Space>
  ),
  draft: (
    <Space>
      <RiDraftLine />
      Draft
    </Space>
  ),
  'sent-for-approval': (
    <Space>
      <TbMailQuestion />
      Sent for Approval
    </Space>
  ),
  'change-requested': (
    <Space>
      <HiOutlineHandRaised />
      Change Requested
    </Space>
  ),
  approved: (
    <Space>
      <IoMdCheckmarkCircleOutline />
      Approved
    </Space>
  ),
  expired: (
    <Space>
      <LiaCalendarTimes />
      Expired
    </Space>
  ),
};

const TreatmentReviewPage = () => {
  const dispatch = useDispatch();
  const allTreatments = useSelector((state) => state.patientTreatmentPlans);
  const currentPatient = useSelector((state) => state.currentPatient);
  const currentLabResult = useSelector((state) => state.currentLabResult);
  const treatmentCheatsheet = useSelector((state) => state.treatmentCheatsheet);
  const user = useSelector((state) => state.authenticatedUser);

  const [currentTreatmentTableData, setCurrentTreatmentTableData] = useState([]);
  const [currentTreatmentTableSetting, setCurrentTreatmentTableSetting] = useState({
    pagination: false,
    bordered: true,
    columns: [
      {
        title: 'Therapy',
        dataIndex: 'level',
      },
      {
        title: 'Medication / Product',
        dataIndex: ['product', 'name'],
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Refill',
        dataIndex: 'refill',
      },
      {
        title: 'Dosage',
        dataIndex: 'dosage',
      },
    ],
  });

  const [treatmentModal, setTreatmentModal] = useState({ open: false, behavior: '', prefill: null });
  const [approvalModal, setApprovalModal] = useState({
    open: false,
    stage: '',
    approving: null,
    previewTableData: null,
    previewTableSetting: null,
  });
  const [viewingModal, setViewingModal] = useState({
    open: false,
    tableData: null,
    tableSetting: null,
    followUpNotes: '',
    treatment: null,
    title: '',
  });
  const [timelineModal, setTimelineModal] = useState({ open: false, history: [] });

  const [current, setCurrent] = useState(null);
  const [allTreatmentsTableSetting, setAllTreatmentsTableSetting] = useState(null);

  const statusEventMap = (status, { comment, submission, by }) => {
    const viewSubmission = (
      <Button
        shape="circle"
        variant={'outlined'}
        color="primary"
        icon={<VscGitPullRequestNewChanges />}
        size={'small'}
        title={'View Submission'}
        onClick={() => {
          const { data, setting } = calcTreatmentTableDataAndSetting(submission);
          setViewingModal({
            open: true,
            tableData: data,
            tableSetting: setting,
            followUpNotes: submission.followUpNotes || '',
            treatment: null,
            title: 'Submission Details',
          });
        }}
      />
    );
    const map = {
      'created-and-approved': (
        <Space>
          <span>Created And Approved</span>
          {!!by && !!by.name && (
            <Typography.Text type={'secondary'} style={{ fontSize: 10 }}>
              by {by.name}
            </Typography.Text>
          )}
        </Space>
      ),
      approved: (
        <Space>
          <span>Approved</span>
          {!!by && !!by.name && (
            <Typography.Text type={'secondary'} style={{ fontSize: 12 }}>
              by {by.name}
            </Typography.Text>
          )}
        </Space>
      ),
      draft: <Space>Created as draft</Space>,
      expired: <Space>Expired</Space>,

      'created-and-submitted': <Space>Created And Submitted {viewSubmission}</Space>,
      'submitted-for-approval': (
        <Space>
          Submitted For Approval
          {viewSubmission}{' '}
        </Space>
      ),
      'sent-for-approval': (
        <Space>
          Updated
          {viewSubmission}{' '}
        </Space>
      ),
      'change-requested': (
        <Space>
          Change Requested
          <Popover
            content={comment}
            title={
              <span>
                Comment from <Typography.Text type={'secondary'}>{by?.name}</Typography.Text>
              </span>
            }
            placement={'right'}
          >
            <Typography.Link>
              <FaRegCommentDots />
            </Typography.Link>
          </Popover>
        </Space>
      ),
    };
    return map[status];
  };

  const approveTreatment = async () => {
    try {
      const result = await approveTreatmentPlan(currentPatient, approvalModal.approving);
      dispatch(updateCurrentPatient({ key: 'currentTreatmentId', value: approvalModal.approving._id }));
      dispatch(updateTreatmentPlan(result.data));
      setApprovalModal({ ...approvalModal, stage: 'success' });
    } catch (e) {
      message.error('Unable to approve treatment');
      throw e;
    }
  };

  const calcTreatmentTableDataAndSetting = (plan) => {
    const treatmentDataToTableDataMapper = (p, i) => {
      if (i === 0) {
        return p;
      } else {
        return {
          ...p,
          level: '',
        };
      }
    };

    const plans = [...(plan?.forLabResult || []), ...(plan?.forSymptomsAndGoals || [])];

    const basePlan = plans.filter((p) => p.level === 'Base');
    const correctionalPlan = plans.filter((p) => p.level === 'Correctional');
    const electivePlan = plans.filter((p) => p.level === 'Elective');
    const ciPlan = plans.filter((p) => p.level === 'Cardiovascular & Inflammation');
    const gwPlan = plans.filter((p) => p.level === 'General wellness');
    const otherPlan = plans.filter((p) => p.level === 'Other');

    const basePlanTableData = basePlan.map(treatmentDataToTableDataMapper);
    const correctionalPlanTableData = correctionalPlan.map(treatmentDataToTableDataMapper);
    const electivePlanTableData = electivePlan.map(treatmentDataToTableDataMapper);
    const ciPlanTableData = ciPlan.map(treatmentDataToTableDataMapper);
    const gwPlanTableData = gwPlan.map(treatmentDataToTableDataMapper);
    const otherPlanTableData = otherPlan.map(treatmentDataToTableDataMapper);

    const rowSpan = {
      '': 0,
      Base: basePlanTableData.length,
      Correctional: correctionalPlanTableData.length,
      Elective: electivePlanTableData.length,
      'Cardiovascular & Inflammation': ciPlanTableData.length,
      'General wellness': gwPlanTableData.length,
      Other: otherPlanTableData.length,
    };

    return {
      data: [
        ...basePlanTableData,
        ...correctionalPlanTableData,
        ...electivePlanTableData,
        ...ciPlanTableData,
        ...gwPlanTableData,
        ...otherPlanTableData,
      ],
      setting: {
        pagination: false,
        bordered: true,
        columns: [
          {
            title: 'Therapy',
            dataIndex: 'level',
            onCell: (record) => ({
              rowSpan: rowSpan[record.level],
            }),
          },
          {
            title: 'Medication / Product',
            dataIndex: ['product', 'name'],
            render: (text, record) => (
              <>
                {!!record.medicalSupplies?.length ? (
                  <div>
                    <div>{text}</div>
                    {record.medicalSupplies.map((ms) => (
                      <div style={{ marginLeft: 6, marginBottom: 2 }}>
                        <RxCornerBottomLeft size={18} style={{ position: 'relative', top: -4 }} />
                        <Tag
                          icon={<FaSyringe />}
                          color="processing"
                          style={{
                            userSelect: 'none',
                          }}
                        >
                          {ms.name}
                          <b> X {ms.quantity}</b>
                        </Tag>
                      </div>
                    ))}
                  </div>
                ) : (
                  <span>{text}</span>
                )}
              </>
            ),
          },
          {
            title: 'Quantity',
            dataIndex: 'quantity',
          },
          {
            title: 'Refill',
            dataIndex: 'refill',
          },
          {
            title: 'Dosage',
            dataIndex: 'dosage',
          },
        ],
      },
    };
  };

  useEffect(() => {
    setCurrent(allTreatments.find((t) => t._id === currentPatient.currentTreatmentId));
  }, [allTreatments, currentPatient.currentTreatmentId]);

  useEffect(() => {
    if (current) {
      const { data, setting } = calcTreatmentTableDataAndSetting(current);
      setCurrentTreatmentTableData(data);
      setCurrentTreatmentTableSetting(setting);
      setAllTreatmentsTableSetting({
        pagination: true,
        bordered: true,
        columns: [
          {
            title: 'Current',
            width: 70,
            render: (text, record) =>
              record._id === current._id ? (
                <div style={{ textAlign: 'center' }}>
                  <BiWater size={18} color="#4287f5" />
                </div>
              ) : (
                ''
              ),
          },
          {
            title: 'Creation Date',
            dataIndex: 'createdDate',
            render: (text) => moment(text).format('MMM DD, YYYY - hh:mma - z'),
          },
          {
            title: (
              <Space>
                Lab Result{' '}
                <Badge status={'processing'} text={<Typography.Text type={'secondary'}>Latest</Typography.Text>} />
              </Space>
            ),
            render: (_, record) =>
              record.labReport?.report && (
                <div>
                  <Space>
                    {record.labReport._id === currentPatient.currentLabReportId && <Badge status={'processing'} />}
                    {record.labReport.report.lab}
                  </Space>
                  <div>
                    <Typography.Text type={'secondary'}>@{record.labReport.report.collectionDate}</Typography.Text>
                  </div>
                </div>
              ),
          },
          {
            title: 'Created By',
            dataIndex: ['creator', 'name'],
          },
          {
            title: 'Status',
            render: (_, record) => {
              if (record.statusHistory[0].status === 'change-requested') {
                return (
                  <Popover content={record.statusHistory[0].comment} title="Comment">
                    <Typography.Link>
                      <HiOutlineHandRaised /> Change Requested
                    </Typography.Link>
                  </Popover>
                );
              }
              return treatmentPlanStatusDisplayMap[record.statusHistory[0].status];
            },
          },
        ],
        customActions: [
          {
            text: 'View',
            whenPerform: async (record) => {
              const { data, setting } = calcTreatmentTableDataAndSetting(record);
              setViewingModal({
                open: true,
                tableData: data,
                tableSetting: setting,
                followUpNotes: record.followUpNotes || '',
                treatment: record,
              });
            },
            shallNotRender: (record) => record.statusHistory[0].status !== 'approved',
          },
          {
            text: 'Update',
            whenPerform: async (record) => {
              setTreatmentModal({ open: true, prefill: record, behavior: 'updating' });
            },
            shallNotRender: (record) => {
              if (!user.rightList.includes(ApplicationRight.Treatment_Creation)) {
                return true;
              } else {
                return !['sent-for-approval', 'change-requested'].includes(record.statusHistory[0].status);
              }
            },
          },
          {
            text: <b>Review</b>,
            whenPerform: async (record) => {
              const { data, setting } = calcTreatmentTableDataAndSetting(record);
              setApprovalModal({
                open: true,
                approving: record,
                previewTableData: data,
                previewTableSetting: setting,
                stage: 'preview',
              });
            },
            shallNotRender: (record) => {
              if (!user.rightList.includes(ApplicationRight.Treatment_Review)) {
                return true;
              } else {
                return record.statusHistory[0].status !== 'sent-for-approval';
              }
            },
          },
          {
            text: 'Timeline',
            whenPerform: async (record) => {
              let history;

              const earliestStatus = record.statusHistory[record.statusHistory.length - 1].status;

              if (earliestStatus === 'sent-for-approval') {
                history = [
                  {
                    ...record.statusHistory[record.statusHistory.length - 1],
                    status: 'created-and-submitted',
                    ts: record.createdDate,
                  },
                  ...[...record.statusHistory.filter((_, i) => i !== record.statusHistory.length - 1)].reverse(),
                ];
              } else if (earliestStatus === 'approved') {
                history = [
                  {
                    ...record.statusHistory[record.statusHistory.length - 1],
                    status: 'created-and-approved',
                    ts: record.createdDate,
                  },
                ];
              } else {
                const reversedHistory = [...record.statusHistory].reverse();
                history = reversedHistory.map((h, i) => ({
                  ...h,
                  status: i === 1 && h.status === 'sent-for-approval' ? 'submitted-for-approval' : h.status,
                }));
              }

              setTimelineModal({ open: true, history });
            },
            shallNotRender: (record) => record.statusHistory[0].status === 'draft',
          },
          {
            needConfirm: false,
            text: 'Convert To Treatment',
            whenPerform: async (record) => {
              setTreatmentModal({ open: true, prefill: record, behavior: 'converting' });
            },
            shallNotRender: (record) => record.statusHistory[0].status !== 'draft',
          },
          {
            needConfirm: {
              text: () => 'Do you want to delete this draft?',
            },
            text: 'Delete',
            whenPerform: async (record) => {
              try {
                await deleteDraftTreatment(record._id);
                dispatch(deleteTreatmentPlan(record));
              } catch (e) {
                message.error('Unable to remove draft plan');
              }
            },
            shallNotRender: (record) => record.statusHistory[0].status !== 'draft',
          },
        ],
        operationColumnTitle: 'Action',
      });
    }
  }, [current]);

  return (
    <div>
      <div className="mb-3">
        <div className="d-flex justify-content-between mb-1">
          <h4>Current Treatment Plan</h4>
          <div>
            <Button
              type="primary"
              onClick={() => {
                setTreatmentModal({ open: true, behavior: 'creating', prefill: { ...current } });
              }}
              style={{ marginRight: 8 }}
              disabled={!current || currentPatient.profile.nonBinaryGender || !currentTreatmentTableData.length}
            >
              Copy Treatment
            </Button>
            <Button
              type="primary"
              onClick={() => setTreatmentModal({ open: true, behavior: 'creating', prefill: null })}
              disabled={currentPatient.profile.nonBinaryGender}
              title={
                currentPatient.profile.nonBinaryGender
                  ? "Unavailable due to patient's non-binary gender"
                  : 'Setup new Treatment'
              }
            >
              Setup new Treatment
            </Button>
          </div>
        </div>
        {currentTreatmentTableSetting && (
          <>
            <DataTable data={currentTreatmentTableData} settings={currentTreatmentTableSetting} />
            <div style={{ marginLeft: 8 }}>
              <h6 className="mt-3 mb-1">Follow up notes</h6>
              <p style={{ whiteSpace: 'pre-line' }}>{current?.followUpNotes}</p>
            </div>
          </>
        )}
      </div>
      <Divider />
      {allTreatmentsTableSetting && (
        <div className="mb-3">
          <h5>All Treatment Plans</h5>
          <DataTable
            data={[...allTreatments].sort((a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate))}
            settings={allTreatmentsTableSetting}
          />
        </div>
      )}

      {treatmentModal.open && (
        <TreatmentModal
          patient={currentPatient}
          treatmentCheatsheet={treatmentCheatsheet}
          prefill={treatmentModal.prefill}
          behavior={treatmentModal.behavior}
          labResult={currentLabResult}
          visible={treatmentModal.open}
          onClose={() => {
            setTreatmentModal({ open: false, behavior: '', prefill: null });
          }}
        />
      )}

      {approvalModal.open && (
        <Modal
          centered
          title="Provide you decision on this treatment"
          open={approvalModal.open}
          width={'95%'}
          footer={null}
          destroyOnClose={true}
          onCancel={() =>
            setApprovalModal({
              open: false,
              stage: '',
              approving: null,
              previewTableData: null,
              previewTableSetting: null,
            })
          }
        >
          {approvalModal.stage === 'preview' && (
            <div>
              <div style={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}>
                <DataTable data={approvalModal.previewTableData} settings={approvalModal.previewTableSetting} />
                <Card title={'Follow up notes'} style={{ marginTop: 4 }}>
                  {approvalModal.approving.followUpNotes}
                </Card>
              </div>
              <div className="mt-3" style={{ textAlign: 'right' }}>
                <Popconfirm title={'Are you sure to approve this treatment plan?'} onConfirm={approveTreatment}>
                  <Button type="primary" style={{ marginRight: 8 }}>
                    Approve
                  </Button>
                </Popconfirm>

                <Popover
                  trigger={'click'}
                  content={
                    <>
                      <Typography.Title level={5}>Leave Comment</Typography.Title>
                      <Form
                        style={{ width: 400 }}
                        onFinish={async (values) => {
                          const comment = values.comment;
                          const result = await requestTreatmentChange(approvalModal.approving._id, comment);
                          setApprovalModal({ ...approvalModal, stage: 'rejected' });
                          dispatch(updateTreatmentPlan(result.data));
                        }}
                      >
                        <Form.Item
                          name={'comment'}
                          rules={[
                            {
                              required: true,
                              message: 'Please leave a comment',
                            },
                          ]}
                        >
                          <Input.TextArea rows={6} />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'right' }}>
                          <Button htmlType="submit" type="default" variant={'filled'}>
                            Request Change
                          </Button>
                        </Form.Item>
                      </Form>
                    </>
                  }
                >
                  <Button type="default" danger>
                    Second Thought
                  </Button>
                </Popover>
              </div>
            </div>
          )}
          {approvalModal.stage === 'success' && (
            <Result
              status="success"
              title="Successfully approved treatment plan! This treatment plan is now current."
              extra={[
                <Button
                  type="primary"
                  key="console"
                  onClick={() => {
                    setApprovalModal({
                      open: false,
                      stage: '',
                      approving: null,
                      previewTableSetting: null,
                      previewTableData: null,
                    });
                  }}
                >
                  Go Back To Treatment Review
                </Button>,
              ]}
            />
          )}
          {approvalModal.stage === 'rejected' && (
            <Result
              status="info"
              title="Change request has been submitted. Plan creator has been notified."
              extra={[
                <Button
                  type="primary"
                  key="console"
                  onClick={() => {
                    setApprovalModal({
                      open: false,
                      stage: '',
                      approving: null,
                      previewTableSetting: null,
                      previewTableData: null,
                    });
                  }}
                >
                  Go Back To Treatment Review
                </Button>,
              ]}
            />
          )}
        </Modal>
      )}

      {viewingModal.open && (
        <Modal
          centered
          title={
            viewingModal.title || (
              <span>
                Treatment Plan Created @{' '}
                {moment(viewingModal.treatment.createdDate).format('MMM DD, YYYY - hh:mma - z')}
                {viewingModal.treatment._id === currentPatient.currentTreatmentId && (
                  <Tag color="processing" style={{ marginLeft: 8 }}>
                    Current
                  </Tag>
                )}
              </span>
            )
          }
          open={viewingModal.open}
          width={'95%'}
          footer={null}
          destroyOnClose={true}
          onCancel={() =>
            setViewingModal({
              open: false,
              tableData: null,
              tableSetting: null,
              followUpNotes: '',
              treatment: null,
              title: '',
            })
          }
        >
          <DataTable data={viewingModal.tableData} settings={viewingModal.tableSetting} />
          <h6 className="mt-3 mb-1">Follow up notes</h6>
          <p style={{ whiteSpace: 'pre-line' }}>{viewingModal.followUpNotes}</p>
        </Modal>
      )}
      {timelineModal.open && (
        <Modal
          centered
          title="Treatment Plan Timeline"
          open={timelineModal.open}
          footer={null}
          width={600}
          destroyOnClose={true}
          onCancel={() => setTimelineModal({ open: false, history: [] })}
        >
          <Timeline
            style={{ marginTop: 18 }}
            mode={'left'}
            items={timelineModal.history.map((h) => ({
              label: moment(h.ts).format('MM/DD/YYYY hh:mma(z)'),
              children: statusEventMap(h.status, { comment: h.comment, submission: h.submission, by: h.by }),
              color: h.status.includes('approved')
                ? 'green'
                : h.status === 'change-requested'
                ? 'orange'
                : h.status === 'expired'
                ? 'red'
                : 'blue',
            }))}
          />
        </Modal>
      )}
    </div>
  );
};

export default TreatmentReviewPage;
