import { message, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { initializeConversationList, receiveNewMessage } from '../../stores/patient-conversations.store';
import { appendToOngoingConversationIfMatches } from '../../stores/ongoing-conversaion.store';

const PatientMessageEventSourceComponent = () => {
  const [connectionStatus, setConnectionStatus] = useState({ connected: false, exponentialBackoff: 0 });
  const [unexpectedDisconnect, setUnexpectedDisconnect] = useState(false);
  const [askedToLeave, setAskedToLeave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!connectionStatus.connected && connectionStatus.exponentialBackoff < 5) {
      if (askedToLeave) {
        return;
      }
      let url = process.env.REACT_APP_BASE_URL_REV + '/messages/sse';
      if (unexpectedDisconnect) {
        url = url + '?reconnect=true';
      }

      setTimeout(
        () => {
          const eventSource = new EventSource(url, {
            withCredentials: true,
          });

          eventSource.onmessage = (event) => {
            if (['Connected', 'Reconnected'].includes(event.data)) {
              setUnexpectedDisconnect(false);
              setConnectionStatus({ connected: true, exponentialBackoff: 1 });
              messageApi.destroy();
              if (event.data === 'Reconnected') {
                messageApi.open({
                  type: 'success',
                  content: 'Reconnected',
                  duration: 5,
                  onClick: () => messageApi.destroy(),
                });
              }
              return;
            }

            if (event.data === 'Heartbeat') {
              return;
            }

            if (event.data === 'Disconnect') {
              setAskedToLeave(true);
              eventSource.close();
            }

            const incomingEvent = JSON.parse(event.data);

            if (incomingEvent.type === 'initialize') {
              dispatch(initializeConversationList(incomingEvent.data));
            } else if (incomingEvent.type === 'newMessage') {
              setTimeout(() => {
                // in case new message comes faster than reply response
                dispatch(appendToOngoingConversationIfMatches(incomingEvent.data));
                dispatch(receiveNewMessage(incomingEvent.data));
              }, 500);
            }
          };

          eventSource.onerror = (error) => {
            setUnexpectedDisconnect(true);
            setConnectionStatus({ connected: false, exponentialBackoff: connectionStatus.exponentialBackoff + 1 });
            console.log(error);
            messageApi.open({
              type: 'loading',
              content: `Reconnecting Exponentially x ${connectionStatus.exponentialBackoff + 1}`,
              duration: Math.pow(connectionStatus.exponentialBackoff + 1, 2),
              onClose: () => {
                if (connectionStatus.exponentialBackoff === 4)
                  messageApi.open({
                    type: 'error',
                    content: 'Unable to re-establish connection to message server',
                    duration: 0,
                    onClick: () => messageApi.destroy(),
                  });
              },
            });
            eventSource.close();
          };
        },
        Math.pow(connectionStatus.exponentialBackoff, 2) * 1000
      );
    }
  }, [connectionStatus]);

  return <div style={{ display: 'none' }}>{contextHolder}</div>;
};

export default PatientMessageEventSourceComponent;
