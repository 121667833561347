import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkPresignUrl, getOrders } from '../service';
import DataTable from '../../../components/data-table/data-table.component';
import { Button, message, Tooltip } from 'antd';
import moment from 'moment';
import HistoryOrderModal from '../../procurement/child-pages/HistoryOrderModal';

const OrdersPage = () => {
  const [tableData, setTableData] = useState([]);
  const currentPatient = useSelector((state) => state.currentPatient);
  const [messageApi, contextHolder] = message.useMessage();
  const [buttonLoading, setButtonLoading] = useState({});
  const [loading, setLoading] = useState(true);

  const handleUrl = async (type, patientId, orderId) => {
    setButtonLoading((prevState) => ({ ...prevState, [orderId]: true }));
    try {
      const urlData = await checkPresignUrl(type, patientId, null, orderId);
      if (urlData.data.status) {
        const base64Data = urlData.data.base64;
        const src = 'data:application/pdf;base64,' + base64Data;

        const newTab = window.open();
        if (newTab) {
          newTab.document.write('<iframe src="' + src + '" style="width:100%; height:100vh;"></iframe>');
          newTab.focus();
        } else {
          console.error('The pop-up was blocked by the browser.');
        }
      } else {
        messageApi.open({
          type: 'error',
          content: 'Failed to open file',
        });
      }
    } catch (err) {
      messageApi.open({
        type: 'error',
        content: 'Failed to open file',
      });
    }
    setButtonLoading((prevState) => ({ ...prevState, [orderId]: false }));
  };

  const outerTableSettings = {
    appendable: false,
    removable: false,
    bordered: true,
    size: 'small',
    pagination: {
      showLessItems: true,
      pageSize: 10,
    },
    columns: [
      {
        title: 'Create Date',
        dataIndex: 'creationTS',
        render: (text, record, index) =>
          moment(text).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY'),
      },
      {
        title: 'Order ID',
        dataIndex: 'orderID',
      },
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Invoice',
        render: (_, record) => {
          return record.invoice?.pdfLink ? (
            <Button
              loading={buttonLoading[record.orderID] || false}
              onClick={() => handleUrl('order', record.patientID, record.orderID + '_invoice')}
              target="_blank"
            >
              View
            </Button>
          ) : (
            'No Invoice'
          );
        },
      },
    ],
    expandable: {
      expandedRowRender: (record) => {
        const data = [];
        if (record.prescriptionOrder) {
          data.push({
            ...record.prescriptionOrder,
            patientID: record.patientID,
            orderID: record.orderID,
            type: 'prescription',
            subOrder: 'Prescription Order',
            _id: record._id + '_p',
          });
        }
        if (record.bProductOrder) {
          data.push({
            ...record.bProductOrder,
            patientID: record.patientID,
            orderID: record.orderID,
            type: 'bproduct',
            subOrder: 'biostation Product Order',
            _id: record._id + '_b',
          });
        }
        return <DataTable data={data} settings={innerTableSettings} />;
      },
    },
  };

  const innerTableSettings = {
    appendable: false,
    removable: false,
    bordered: true,
    size: 'small',
    operationColumnTitle: 'Action',
    pagination: false,
    columns: [
      {
        title: 'SubOrder',
        dataIndex: 'subOrder',
      },
      {
        title: 'Ship From',
        dataIndex: 'shipFrom',
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'ETA Delivery Date',
        dataIndex: 'deliveryDate',
      },
      {
        title: 'Carrier',
        dataIndex: 'carrier',
      },
      {
        title: 'Tracking Number',
        dataIndex: 'trackingNumber',
      },
      {
        title: 'Destination',
        dataIndex: 'destination',
      },
    ],
    customActions: [
      {
        element: (record, data) => (
          <div className="d-flex">
            <Tooltip color="volcano" title={record.pdfLink ? '' : 'No packlist PDF is associated'}>
              <Button
                className="action-btn"
                disabled={!record.pdfLink}
                loading={buttonLoading[record.orderID] || false}
                onClick={() => handleUrl('order', record.patientID, record.orderID + '_' + record.type)}
                target="_blank"
              >
                View
              </Button>
            </Tooltip>
            <HistoryOrderModal
              trackingNumber={record.trackingNumber}
              selectedCarrier={record.carrier}
              orderHistory={record.orderHistory}
            />
          </div>
        ),
      },
    ],
  };

  useEffect(() => {
    (async () => {
      const orders = await getOrders(currentPatient);
      const sortedOrders = orders.data.sort((a, b) => moment(b.creationTS) - moment(a.creationTS));
      setTableData(sortedOrders.map((order) => ({ ...order, key: order._id })));
      setLoading(false);
    })();
  }, [currentPatient]);

  return (
    <>
      {contextHolder}
      <DataTable loading={loading} data={tableData} settings={outerTableSettings} />
    </>
  );
};

export default OrdersPage;
