import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SettingInput from '../../../components/setting/SettingInput';
import './patient.css';
import { useDispatch, useSelector } from 'react-redux';
import { calculateAge, postPatientData } from '../service';
import { updateCurrentPatientProfile } from '../../../stores/current-patient.store';
import { message } from 'antd';

const DetailsPage = () => {
  const currentPatient = useSelector((state) => state.currentPatient);
  const dispatch = useDispatch();

  const [patientDetails, setPatientDetails] = React.useState([]);
  const [ptNotes, setPtNotes] = React.useState([]);
  const [businessInfo, setBusinessInfo] = React.useState([]);
  const [emergencyContact, setEmergencyContact] = React.useState([]);

  const updatePatientData = async (field, value) => {
    try {
      await postPatientData(currentPatient.patientId, field, value);
      await dispatch(updateCurrentPatientProfile({ key: field, value }));
    } catch (e) {
      message.error('Unable to update salesforce record');
      throw e;
    }
  };

  const patchPatientDetails = () => {
    const profile = currentPatient.profile;
    const biz = currentPatient.business;

    const profileData = [
      {
        label: 'Account Owner',
        editable: false,
        value: profile.ownerId,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('ownerId', value),
        },
      },
      {
        label: 'Title',
        editable: true,
        value: profile.title,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('title', value),
        },
      },
      {
        label: 'First Name',
        editable: true,
        value: profile.firstName,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('firstName', value),
        },
      },
      {
        label: 'Last Name',
        editable: true,
        value: profile.lastName,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('lastName', value),
        },
      },
      {
        label: 'Nickname',
        editable: true,
        value: profile.nickName,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('nickName', value),
        },
      },
      {
        label: 'Primary Service Location',
        editable: true,
        value: profile.primaryServiceLocation,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('primaryServiceLocation', value),
        },
      },
      {
        label: 'Referral Contact',
        editable: true,
        value: profile.referralContact,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('referralContact', value),
        },
      },
      {
        label: 'Gender',
        editable: true,
        value: profile.gender,
        editingRender: {
          elementType: 'dropdown',
          items: ['Male', 'Female'],
          save: (value) => updatePatientData('gender', value),
        },
      },
      {
        label: 'Menstrual Cycle',
        editable: profile.gender === 'Female' || profile.gender === 'female',
        value: profile.gender === 'Female' || profile.gender === 'female' ? profile.menstrualCycle : 'N/A',
        editingRender: {
          elementType: 'dropdown',
          items: ['--None--', 'Luteal', 'Menopausal', 'Birth Control', 'Unknown'],
          save: (value) => updatePatientData('menstrualCycle', value),
        },
      },
      {
        label: 'Weight',
        editable: true,
        value: profile.weight,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('weight', value),
        },
      },
      {
        label: 'Height',
        editable: true,
        value: profile.height,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('height', value),
        },
      },
      {
        label: 'Patient Id',
        editable: false,
        value: currentPatient.patientId,
        editingRender: {
          elementType: 'text',
        },
      },
      {
        label: 'Most Recent PA Phone Call',
        editable: true,
        value: profile.mostRecentPAPhoneCall,
        editingRender: {
          elementType: 'date',
          save: (value) => updatePatientData('mostRecentPAPhoneCall', value),
        },
      },
      {
        label: 'Account Source',
        editable: true,
        value: profile.accountSource || '',
        editingRender: {
          elementType: 'dropdown',
          items: [
            '--None--',
            'AdWords',
            'Affiliate - FIO',
            'Affiliate - OTF Manny',
            'Affiliate - PIT Fitness',
            'Affiliate Referral',
            'Affiliate - Sweat 440',
            'Affiliate - TBT',
            'Amli',
            'Aqua-Jupiter',
            'Auberge',
            'b12/bLean Tokens',
            "Barry's Bootcamp",
            'Beauty Pass',
            'BHRC',
            'Bing Ads',
            'Bing Natural Search',
            'Boca Beach Club Pop Up',
            'Boca Magazine',
            'Boca New Club Member Text',
            'Boca Resort Direct Mail - 2021',
            'Boca Resort Marketing',
            'Body Project Movement',
            'CAMP',
            'Carillon',
            'Carillon Member',
            'Carillon Retreat',
            'City Fitness',
            'Club Pilates',
            'Core LT Team',
            'Direct Mail',
            'Dr. Gallo',
            'Dr. Hermann',
            'Dr. Matez List',
            'Dr. Referral',
            'Dr. Yan Trokel',
            'Email',
            'Employee Referral',
            'Erin & Ginger Miami',
            'Event',
            'Facebook',
            'Gio',
            'Google Natural Search',
            'Grand Wailea',
            'Hyde',
            'Instagram',
            'Jupiter Direct Mail 2021',
            'LinkedIn',
            'Live Chat',
            'Local Search Google+',
            'LT Members',
            'LT Tampa',
            'Miami Direct Mail - 2021',
            'Miami Modern Luxury Mag',
            'Midtown 29',
            'Midtown 4',
            'Midtown 5 Building',
            'Mizner Country Club',
            'Newspaper',
            'One Thousand Museum',
            'OSW',
            'Other',
            'Radio - ED Commercial',
            'RealSelf.com',
            'Referral of current patient',
            'Steve Kane',
            'Tampa $199 Promo Card',
            'TBT Mag',
            'Walk-In',
            'Waze',
            'Website',
            'West Delray Direct Mail - 2021',
            'Williams Island',
            'Williams Island Direct Mail',
            'Word of mouth',
            'Yahoo! Natural Search',
            'Yard 8',
            'Yelp',
            'Yelp Miami',
          ],
          save: (value) => updatePatientData('accountSource', value),
        },
      },
      {
        label: 'Preferred Communication Method',
        editable: true,
        value: profile.preferredCommunicationMethod,
        editingRender: {
          elementType: 'multi-select',
          delimiter: ';',
          items: ['DO NOT CONTACT', 'PHONE CALL', 'EMAIL', 'TEXT', 'Only'],
          save: (value) => updatePatientData('preferredCommunicationMethod', value),
        },
      },
      {
        label: 'Phone',
        editable: true,
        value: profile.phone,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('phone', value),
        },
      },
      {
        label: 'SMS Opt Out',
        editable: true,
        value: JSON.parse((profile.smsOptOut || 'false').toLowerCase()),
        editingRender: {
          elementType: 'checkbox',
          save: (value) => updatePatientData('smsOptOut', JSON.stringify(value)),
        },
      },
      {
        label: 'Email Address',
        editable: true,
        value: profile.email,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('email', value),
        },
      },
      {
        label: 'Date of Birth',
        editable: true,
        value: profile.dob,
        editingRender: {
          elementType: 'date',
          save: (value) => updatePatientData('dob', value),
        },
      },
      {
        label: 'Patient Age',
        editable: false,
        value: calculateAge(profile.dob),
        editingRender: {
          elementType: 'text',
        },
      },
      {
        label: 'Driver License',
        editable: true,
        value: profile.driverLicense,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('driverLicense', value),
        },
      },
      {
        label: 'SSN',
        editable: true,
        value: profile.ssn,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('ssn', value),
        },
      },
    ];
    const ptData = [
      {
        label: 'Practitioner Name',
        editable: true,
        value: profile.practitionerName,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('practitionerName', value),
        },
      },
      {
        label: 'Practitioner Phone',
        editable: true,
        value: profile.practitionerPhone,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('practitionerPhone', value),
        },
      },
      {
        label: 'Practitioner Fax',
        editable: true,
        value: profile.practitionerFax,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('practitionerFax', value),
        },
      },
    ];
    const businessData = [
      {
        label: 'First Opportunity',
        editable: false,
        value: biz.firstOpportunity,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('firstOpportunity', value),
        },
      },
      {
        label: 'Total Won Opportunities',
        editable: false,
        value: biz.totalWonOpportunities,
        editingRender: {
          elementType: 'text',
        },
      },
      {
        label: 'Closed Won Blood Opp',
        editable: false,
        value: biz.closedWonBloodOpp,
        editingRender: {
          elementType: 'text',
        },
      },
      {
        label: 'Closed Won HRT Opps',
        editable: false,
        value: biz.closedWonProtocolOpp,
        editingRender: {
          elementType: 'text',
        },
      },
      {
        label: 'Closed Won IV Infusion Opp',
        editable: false,
        value: biz.closedWonIvInfusionOpp,
        editingRender: {
          elementType: 'text',
        },
      },
      {
        label: 'Closed Won ED Opportunities',
        editable: false,
        value: biz.closedWonEdOpportunities,
        editingRender: {
          elementType: 'text',
        },
      },
      {
        label: 'Most Recent Opportunity',
        editable: false,
        value: biz.mostRecentOpportunity,
        editingRender: {
          elementType: 'text',
        },
      },
      {
        label: 'Total Spend',
        editable: false,
        value: biz.totalSpend,
        editingRender: {
          elementType: 'text',
        },
      },
      {
        label: 'Most Recent IV Opp Date',
        editable: false,
        value: biz.mostRecentIvOppDate,
        editingRender: {
          elementType: 'text',
        },
      },
      {
        label: 'Most Recent Full Protocol Opportunity',
        editable: false,
        value: biz.mostRecentFullProtocolOpportunity,
        editingRender: {
          elementType: 'text',
        },
      },
      {
        label: 'Most Recent HRT Opp Date',
        editable: false,
        value: biz.mostRecentHRTOppDate,
        editingRender: {
          elementType: 'text',
        },
      },
      {
        label: 'Most Recent OTC Opportunity',
        editable: false,
        value: biz.mostRecentOtcOpportunity,
        editingRender: {
          elementType: 'text',
        },
      },
      {
        label: 'Most Recent ED Opportunity',
        editable: false,
        value: biz.mostRecentEdOpportunity,
        editingRender: {
          elementType: 'text',
        },
      },
    ];
    const emergencyContactData = [
      {
        label: 'Emergency Contact Name',
        editable: true,
        value: profile.emergencyContactName,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('emergencyContactName', value),
        },
      },
      {
        label: 'Emergency Contact Phone',
        editable: true,
        value: profile.emergencyContactPhone,
        editingRender: {
          elementType: 'text',
          save: (value) => updatePatientData('emergencyContactPhone', value),
        },
      },
    ];

    setPtNotes(ptData);
    setBusinessInfo(businessData);
    setPatientDetails(profileData);
    setEmergencyContact(emergencyContactData);
  };

  React.useEffect(() => {
    if (currentPatient) {
      patchPatientDetails();
    }
  }, [currentPatient]);

  return (
    <>
      <Row className="profile">
        <Col sm={12}>
          <h2 className="section-title">Patient Profile</h2>
        </Col>
        <Col sm={12}>
          <Row className="section-content">
            <Col className="section-column" sm={12}>
              {patientDetails.map((detail, i) => {
                return (
                  <SettingInput
                    key={i}
                    label={detail.label}
                    value={detail.value}
                    editable={detail.editable}
                    editingRender={detail.editingRender}
                  />
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="notes-physician section">
        <Col sm={12}>
          <h2 className="section-title">PT Notes for physician</h2>
        </Col>
        <Col sm={12}>
          <Row className="section-content">
            <Col className="section-column" sm={12}>
              {ptNotes.map((detail, i) => {
                return (
                  <SettingInput
                    key={i}
                    label={detail.label}
                    value={detail.value}
                    editable={detail.editable}
                    editingRender={detail.editingRender}
                  />
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="business-info section">
        <Col sm={12}>
          <h2 className="section-title">Patient Business Info</h2>
        </Col>
        <Col sm={12}>
          <Row className="section-content">
            <Col className="section-column" sm={12}>
              {businessInfo.map((detail, i) => {
                return (
                  <SettingInput
                    key={i}
                    label={detail.label}
                    value={detail.value}
                    editable={detail.editable}
                    editingRender={detail.editingRender}
                  />
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="emergency-contact section">
        <Col sm={12}>
          <h2 className="section-title">Patient Emergency Contact</h2>
        </Col>
        <Col sm={12}>
          <Row className="section-content">
            <Col className="section-column" sm={12}>
              {emergencyContact.map((ec, i) => (
                <SettingInput
                  key={i}
                  label={ec.label}
                  value={ec.value}
                  editable={ec.editable}
                  editingRender={ec.editingRender}
                />
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DetailsPage;
