import DataTable from '../../components/data-table/data-table.component';
import { Link } from 'react-router-dom';
import {
  Badge,
  Col,
  Collapse,
  Descriptions,
  Dropdown,
  Modal,
  Popover,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ArrowRightOutlined, DownOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { changePatientRequestStatus, getPatientRequests } from './service';
import moment from 'moment/moment';
import { EditCalendar, LocalPharmacyOutlined, RateReview } from '@mui/icons-material';
import ExpandableText from '../../components/expandable-text/expandable-text';
import { TextField } from '@mui/material';
import { BiLinkExternal } from 'react-icons/bi';
import AppointmentDetail from '../appointments/appointment-detail.component';

const PatientRequestPage = () => {
  const [tableData, setTableData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [statusChangeModal, setStatusChangeModal] = useState({
    show: false,
    record: null,
    to: '',
    display: <></>,
    note: '',
  });
  const [statusChanging, setStatusChanging] = useState(false);

  const tableSetting = {
    columns: [
      {
        title: 'Patient',
        dataIndex: ['patientInfo', 'name'],
        filterable: true,
        render: (text, record) => (
          <>
            <Tooltip title={'To patient detail page'}>
              <Link to={`/patient/${record.patientId}`} style={{ marginRight: 8 }}>
                {text}
              </Link>
            </Tooltip>
            <Popover
              content={
                <>
                  <div>
                    {record.patientInfo.gender}, {record.patientInfo.age}
                  </div>
                </>
              }
              title="Basic Info"
            >
              <InfoCircleTwoTone />
            </Popover>
          </>
        ),
        filterSearch: true,
        sorter: (a, b) => a.patientInfo.name.toLowerCase().localeCompare(b.patientInfo.name.toLowerCase()),
      },
      {
        title: 'Request Type',
        dataIndex: ['type'],
        filterable: true,
        render: (text, record) => {
          if (text.toLowerCase() === 'refill') {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LocalPharmacyOutlined style={{ marginRight: 8, fontSize: 'small' }} />
                Refill
              </div>
            );
          } else if (text.toLowerCase() === 'treatment-review') {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <RateReview style={{ marginRight: 8, fontSize: 'small' }} />
                Treatment Review
              </div>
            );
          } else if (text.toLowerCase() === 'appointment-adjustment') {
            const appointment = {
              service: record.service,
              patient: {
                patientId: record.patientId,
                firstName: record.patientInfo.name.split(' ')[0],
                lastName: record.patientInfo.name.split(' ')[1],
              },
              location: record.location,
              staff: record.staff,
              start: record.appointmentStart,
              end: record.appointmentEnd,
            };
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Popover content={<AppointmentDetail appointment={appointment} />} placement={'right'}>
                  <EditCalendar style={{ marginRight: 8, fontSize: 'small' }} />
                  Appointment Adjustment
                  <Link to={`/patient/${record.patientId}/appointment`} style={{ marginLeft: 8 }} target={'_blank'}>
                    <BiLinkExternal />
                  </Link>
                </Popover>
              </div>
            );
          }
        },
        sorter: (a, b) => a.type.toLowerCase().localeCompare(b.type.toLowerCase()),
      },
      {
        title: 'Request Time',
        dataIndex: ['creationTS'],
        render: (text) => moment(text).format('MMM DD, YYYY - hh:mma'),
        sorter: (a, b) => new Date(a.creationTS).getTime() - new Date(b.creationTS).getTime(),
        defaultSortOrder: 'descend',
      },
      {
        title: 'Patient Comment',
        dataIndex: ['comments'],
        render: (text) => <ExpandableText style={{ marginBottom: 0 }} text={text} maxChars={60} />,
        width: '30%',
      },
      {
        title: 'Status',
        dataIndex: ['status'],
        render: (text) => {
          if (text.toLowerCase() === 'new') {
            return <Badge status="default" text="Open" />;
          } else if (text.toLowerCase() === 'in progress') {
            return <Badge status="processing" text="In Progress" />;
          } else if (text.toLowerCase() === 'complete') {
            return <Badge status="success" text="Completed" />;
          } else {
            return text;
          }
        },
        filterable: true,
        sorter: (a, b) => a.status.toLowerCase().localeCompare(b.status.toLowerCase()),
      },
    ],
    numbered: true,
    operationColumnTitle: 'Action',
    tableHeader: <h5>Patient Requests</h5>,
    size: 'middle',
    customActions: [
      {
        element: (record, data) => {
          const menu = [];
          const status = record.status || '';
          if (status.toLowerCase() === 'new') {
            const toInProgress = {
              label: (
                <a
                  onClick={() =>
                    setStatusChangeModal({
                      show: true,
                      record,
                      to: 'In Progress',
                      display: (
                        <>
                          <Tag>Open</Tag>
                          <ArrowRightOutlined style={{ marginRight: 8 }} />
                          <Tag color="processing">In Progress</Tag>
                        </>
                      ),
                      note: '',
                    })
                  }
                >
                  <Tag>Open</Tag>
                  <ArrowRightOutlined style={{ marginRight: 8 }} />
                  <Tag color="processing">In Progress</Tag>
                </a>
              ),
              key: '1',
            };
            const toComplete = {
              label: (
                <a
                  onClick={() =>
                    setStatusChangeModal({
                      show: true,
                      record,
                      to: 'Complete',
                      display: (
                        <>
                          <Tag>Open</Tag>
                          <ArrowRightOutlined style={{ marginRight: 8 }} />
                          <Tag color="success">Completed</Tag>
                        </>
                      ),
                      note: '',
                    })
                  }
                >
                  <Tag>Open</Tag>
                  <ArrowRightOutlined style={{ marginRight: 8 }} />
                  <Tag color="success">Completed</Tag>
                </a>
              ),
              key: '2',
            };
            menu.push(toInProgress);
            menu.push({
              type: 'divider',
            });
            menu.push(toComplete);
          } else if (status.toLowerCase() === 'in progress') {
            const toOpen = {
              label: (
                <a
                  onClick={() =>
                    setStatusChangeModal({
                      show: true,
                      record,
                      to: 'New',
                      display: (
                        <>
                          <Tag color="processing">In Progress</Tag>
                          <ArrowRightOutlined style={{ marginRight: 8 }} />
                          <Tag>Open</Tag>
                        </>
                      ),
                      note: '',
                    })
                  }
                >
                  <Tag color="processing">In Progress</Tag>
                  <ArrowRightOutlined style={{ marginRight: 8 }} />
                  <Tag>Open</Tag>
                </a>
              ),
              key: '1',
            };
            const toComplete = {
              label: (
                <a
                  onClick={() =>
                    setStatusChangeModal({
                      show: true,
                      record,
                      to: 'Complete',
                      display: (
                        <>
                          <Tag color="processing">In Progress</Tag>
                          <ArrowRightOutlined style={{ marginRight: 8 }} />
                          <Tag color="success">Completed</Tag>
                        </>
                      ),
                      note: '',
                    })
                  }
                >
                  <Tag color="processing">In Progress</Tag>
                  <ArrowRightOutlined style={{ marginRight: 8 }} />
                  <Tag color="success">Completed</Tag>
                </a>
              ),
              key: '2',
            };
            menu.push(toOpen);
            menu.push({
              type: 'divider',
            });
            menu.push(toComplete);
          } else if (status.toLowerCase() === 'complete') {
            const toOpen = {
              label: (
                <a
                  onClick={() =>
                    setStatusChangeModal({
                      show: true,
                      record,
                      to: 'New',
                      display: (
                        <>
                          <Tag color="success">Complete</Tag>
                          <ArrowRightOutlined style={{ marginRight: 8 }} />
                          <Tag>Open</Tag>
                        </>
                      ),
                      note: '',
                    })
                  }
                >
                  <Tag color="success">Complete</Tag>
                  <ArrowRightOutlined style={{ marginRight: 8 }} />
                  <Tag>Open</Tag>
                </a>
              ),
              key: '1',
            };
            const toInProgress = {
              label: (
                <a
                  onClick={() =>
                    setStatusChangeModal({
                      show: true,
                      record,
                      to: 'In Progress',
                      display: (
                        <>
                          <Tag color="success">Complete</Tag>
                          <ArrowRightOutlined style={{ marginRight: 8 }} />
                          <Tag color="processing">In Progress</Tag>
                        </>
                      ),
                      note: '',
                    })
                  }
                >
                  <Tag color="success">Complete</Tag>
                  <ArrowRightOutlined style={{ marginRight: 8 }} />
                  <Tag color="processing">In Progress</Tag>
                </a>
              ),
              key: '2',
            };
            menu.push(toOpen);
            menu.push({
              type: 'divider',
            });
            menu.push(toInProgress);
          }

          return (
            <Dropdown
              menu={{
                items: menu,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Change Status
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          );
        },
      },
    ],
  };

  const changeStatus = async () => {
    setStatusChanging(true);
    await changePatientRequestStatus(statusChangeModal.record._id, statusChangeModal.to, statusChangeModal.note);
    const index = tableData.findIndex((d) => d._id === statusChangeModal.record._id);
    setTableData([
      ...tableData.slice(0, index),
      { ...tableData[index], status: statusChangeModal.to },
      ...tableData.slice(index + 1),
    ]);
    setStatusChanging(false);
    setStatusChangeModal({
      show: false,
      record: null,
      to: '',
      display: <></>,
      note: '',
    });
  };

  useEffect(() => {
    (async () => {
      const requests = await getPatientRequests();
      setDataLoading(false);
      setTableData(requests.data);
    })();
  }, []);

  return (
    <div style={{ marginInline: 16 }}>
      <DataTable settings={tableSetting} loading={dataLoading} data={tableData} />
      <Modal
        width={600}
        open={statusChangeModal.show}
        onCancel={() => setStatusChangeModal({ show: false, record: null, to: '', display: <></>, note: '' })}
        onOk={changeStatus}
        title={'Patient Request Status Change'}
        confirmLoading={statusChanging}
      >
        {statusChangeModal.record && (
          <>
            <Collapse
              items={[
                {
                  key: '1',
                  label: 'Request Detail',
                  children: (
                    <Descriptions bordered size={'small'} column={2}>
                      <Descriptions.Item label="Patient Name" span={1}>
                        {statusChangeModal.record.patientInfo.name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Request Type" span={1}>
                        {statusChangeModal.record.type}
                      </Descriptions.Item>
                      <Descriptions.Item label="Request Time" span={2}>
                        {moment(statusChangeModal.record.creationTS).format('MMM DD, YYYY - hh:mma')}
                      </Descriptions.Item>
                      <Descriptions.Item label="Patient Comments" span={2}>
                        {statusChangeModal.record.comments}
                      </Descriptions.Item>
                    </Descriptions>
                  ),
                },
              ]}
            />
            <Row style={{ marginTop: 12 }} gutter={8}>
              <Col>
                <Typography.Text strong>*Status change: </Typography.Text>
              </Col>
              <Col>
                <Space>{statusChangeModal.display}</Space>
              </Col>
            </Row>
            <Row style={{ marginTop: 2, paddingInline: 8 }}>
              <TextField
                value={statusChangeModal.note}
                id="patient-request-note-input"
                label="Add Note"
                variant="standard"
                style={{ width: '100%' }}
                onChange={(e) => setStatusChangeModal({ ...statusChangeModal, note: e.target.value })}
              />
            </Row>
          </>
        )}
      </Modal>
    </div>
  );
};

export default PatientRequestPage;
