export default function (currentLabResult, currentPatient) {
  const allLabResult = [];

  const allBiomarkers = currentLabResult.map((clr) => clr.biomarker);

  currentLabResult.forEach((clr) => {
    allLabResult.push({
      biomarker: clr.biomarker,
      results: [
        {
          value: clr.labData,
          date: '',
          isAbnormal: clr.isAbnormal,
        },
      ],
      type: clr.type,
      optimalRange: clr.optimalRange,
      description: clr.description,
      symptom: clr.symptom,
    });
  });

  const patientResults = [...currentPatient.allLabResults];

  patientResults.shift(); // remove current lab result

  const sortedPatientResults = patientResults.sort(
    (a, b) => new Date(b.collectionDate || '01/01/1970') - new Date(a.collectionDate || '01/01/1970')
  );

  sortedPatientResults.forEach((plr, i) => {
    allBiomarkers.forEach((biomarker) => {
      const oldResult = plr.data.find((result) => result.biomarker === biomarker);
      const existingLab = allLabResult.find((alr) => alr.biomarker === biomarker);
      existingLab.results.push({
        value: oldResult?.labData || '',
        date: plr.collectionDate,
        isAbnormal: oldResult?.isAbnormal || false,
      });
    });
  });

  return allLabResult;
}
