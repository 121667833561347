import React, { useEffect, useRef, useState } from 'react';
import DataTable from '../../../../components/data-table/data-table.component';
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Empty,
  Input,
  InputNumber,
  message,
  Modal,
  Popover,
  Result,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import { AiOutlineDeleteRow, AiOutlinePlusCircle } from 'react-icons/ai';
import {
  convertDraftToTreatment,
  getMedicalNotes,
  populateMedicationInfo,
  submitDraftPlan,
  submitNewTreatmentPlan,
  updateAndApproveTreatment,
  updateTreatmentSelection,
} from '../../service';
import { Autocomplete, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addTreatmentPlan, updateTreatmentPlan } from '../../../../stores/patient-treatment-plan.store';
import ESignForm from './e-sign.form';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { DragIndicatorOutlined } from '@mui/icons-material';
import Draggable from 'react-draggable';
import MedicalNoteDisplay from './medical-note.display';
import biomarkerOrder from './biomarker-order.constant.js';
import { updateCurrentPatient } from '../../../../stores/current-patient.store';
import bioApi from '../../../../api/bioApi';
import { GET_MEDICAL_SUPPLIES } from '../../../../api/URLs';
import { FaSyringe } from 'react-icons/fa';
import { RxCornerBottomLeft } from 'react-icons/rx';
import { ApplicationRight } from '../../../../share/RightList';

const parseDisplayLogic = (logic) => {
  const gt = logic['>'] || Number.MIN_SAFE_INTEGER;
  const gte = logic['>='] || Number.MIN_SAFE_INTEGER;
  const lt = logic['<'] || Number.MAX_SAFE_INTEGER;
  const lte = logic['<='] || Number.MAX_SAFE_INTEGER;

  const logicalBioStage = logic['biologicalStage'];

  const allowingBioStages = [];

  if (logicalBioStage) {
    allowingBioStages.push('logicalBioStage');
  } else {
    allowingBioStages.push('menopause');
    allowingBioStages.push('luteal');
    allowingBioStages.push('');
    allowingBioStages.push(null);
    allowingBioStages.push(undefined);
  }

  return (labData, biologicalStage) =>
    labData > gt && labData >= gte && labData < lt && labData <= lte && allowingBioStages.includes(biologicalStage);
};

const mongoObjectIdGenerator = () => {
  const timestamp = (((new Date().getTime() + 315569260000) / 1000) | 0).toString(16);
  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => ((Math.random() * 16) | 0).toString(16)).toLowerCase();
};

const TreatmentModal = ({ patient, labResult, treatmentCheatsheet, prefill, visible, onClose, behavior }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authenticatedUser);

  const [labResultsTableData, setLabResultsTableData] = useState([]);
  const [symptomsAndGoalsTableData, setSymptomsAndGoalsTableData] = useState([]);
  const [followUpNotes, setFollowUpNotes] = useState('');
  const [biomarkerRowSpan, setBiomarkerRowSpan] = useState(null);
  const [newTreatment, setNewTreatment] = useState(null);
  const [previewTableData, setPreviewTableData] = useState([]);
  const [previewTableSetting, setPreviewTableSetting] = useState(null);

  const [alwaysDisplayBiomarkers, setAlwaysDisplayBiomarkers] = useState([]);
  const [stage, setStage] = useState('editing');
  const [display, setDisplay] = useState(false);
  const [successMessage, setSuccessMessage] = useState({ title: '', subtitle: '' });
  const [medicalNotes, setMedicalNotes] = useState([]);
  const [showMedicalNote, setShowMedicalNote] = useState({ show: false, title: '', content: {} });
  const [allMedicalSupplies, setAllMedicalSupplies] = useState(null);

  const [signFormButtonText, setSignFormButtonText] = useState('');

  const [dragDisabled, setDragDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const biologicalStage = patient.profile.menstrualCycle === 'Menopausal' ? 'Menopause' : 'Luteal';
  const patientBioGender = patient.profile.gender.toLowerCase() === 'male' ? 'Male' : `Female${biologicalStage}`;

  const sortFunc = (a, b) => {
    const indexA = biomarkerOrder[patientBioGender].indexOf(a.biomarker);
    const indexB = biomarkerOrder[patientBioGender].indexOf(b.biomarker);
    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  };

  const originalLabResultDeepCopy = JSON.parse(JSON.stringify(labResult)).sort(sortFunc);
  const originalSymptomsAndGoalsDeepCopy = JSON.parse(JSON.stringify(patient.symptomsAndGoals));
  const existingTreatmentDeepCopy = JSON.parse(
    JSON.stringify(
      prefill || {
        forLabResult: [],
        forSymptomsAndGoals: [],
        followUpNotes: '',
      }
    )
  );

  const symptomGoalsTableSetting = {
    pagination: false,
    bordered: true,
    columns: [
      {
        title: 'Symptoms / Goals',
        dataIndex: 'symptomOrGoal',
      },
      {
        title: 'Medication / Product',
        dataIndex: ['treatment', 'product'],
        width: 450,
        render: (text, record, index) => (
          <>
            <Select
              className={'treatment-select'}
              variant={'borderless'}
              value={{
                value: record.treatment?.product?._id,
                label: record.treatment?.product?.name,
              }}
              style={{ width: '100%', height: '100%' }}
              allowClear
              size="small"
              labelRender={(label) => <span style={{ width: 450, whiteSpace: 'balance' }}>{label.label}</span>}
              onClear={() => {
                if (record.for) {
                  symptomsAndGoalsTableData.splice(index, 1);
                } else {
                  record.treatment = { product: null, dosage: '', refill: '', quantity: '', medicalSupplies: [] };
                }
                setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
              }}
              onSelect={(value) => {
                const product = treatmentCheatsheet[record.symptomOrGoal || record.for].find(
                  (treatment) => treatment.product._id === value
                ).product;
                record.treatment.product = product;
                record.treatment.quantity = product.defaultQuantity || 1;
                if (product.dosageList && product.dosageList.length === 1) {
                  record.treatment.dosage = product.dosageList[0].dosage;
                  record.treatment.medicalSupplies = product.dosageList[0].medicalSupplies.map((ms) => {
                    const medicalSupply = allMedicalSupplies.find((s) => s._id === ms.product);
                    return {
                      _id: ms.product,
                      name: medicalSupply.name,
                      unitQuantity: ms.quantity,
                    };
                  });
                } else {
                  record.treatment.dosage = '';
                  record.treatment.medicalSupplies = [];
                }

                setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
              }}
            >
              {(treatmentCheatsheet[record.symptomOrGoal || record.for] || []).map((treatment) => (
                <Select.Option value={treatment.product._id} key={treatment.product._id}>
                  {treatment.isDefault ? `🛎️ ${treatment.product.name}` : treatment.product.name}
                </Select.Option>
              ))}
            </Select>
            <div style={{ position: 'relative' }}>
              {record.symptomOrGoal && record.treatment.product && (
                <AiOutlinePlusCircle
                  className="icon-btn"
                  style={{
                    position: 'absolute',
                    left: '50%',
                    fontSize: '18px',
                    zIndex: 100,
                  }}
                  onClick={() => {
                    symptomsAndGoalsTableData.splice(index + 1, 0, {
                      for: record.symptomOrGoal,
                      treatment: {
                        product: null,
                        dosage: '',
                        medicalSupplies: [],
                      },
                    });
                    setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
                  }}
                />
              )}
            </div>
          </>
        ),
        className: 'action-column',
      },
      {
        title: 'Quantity',
        width: 60,
        dataIndex: ['treatment', 'quantity'],
        render: (text, record) => (
          <InputNumber
            min={1}
            style={{ width: 60, borderBottom: '1px solid #f0f0f0', borderRadius: 0, paddingBlock: 3 }}
            size="small"
            variant={'borderless'}
            value={text}
            disabled={!record.treatment.product}
            onChange={(e) => {
              record.treatment.quantity = e;
              setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
            }}
          />
        ),
        className: 'action-column',
      },
      {
        title: 'Refill',
        dataIndex: ['treatment', 'refill'],
        render: (text, record) => (
          <TextField
            fullWidth
            size="small"
            variant="standard"
            value={record.treatment.refill}
            onChange={(e) => {
              record.treatment.refill = e.target.value;
              setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
            }}
          />
        ),
        className: 'action-column',
      },
      {
        title: 'Dosage',
        minWidth: 440,
        dataIndex: ['treatment', 'dosage'],
        render: (text, record) => {
          const presetDosages = [];

          if (record.treatment.product) {
            const existingProduct = treatmentCheatsheet[record.symptomOrGoal || record.for].find(
              (treatment) => treatment.product._id === record.treatment.product._id
            );
            if (existingProduct) {
              existingProduct.product.dosageList.forEach((d) => presetDosages.push(d));
            }
          }

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Autocomplete
                style={{ width: '100%', marginRight: 2 }}
                freeSolo
                disablePortal
                value={text}
                variant="standard"
                options={presetDosages.map((d) => d.dosage)}
                size="small"
                disabled={!record.treatment.product}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline
                    variant="standard"
                    size="small"
                    placeholder="Select from preset dosages or just type"
                  />
                )}
                onInputChange={(event, value) => {
                  record.treatment.dosage = value;
                  setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
                }}
                onChange={(event, value) => {
                  record.treatment.medicalSupplies =
                    presetDosages
                      .find((d) => d.dosage === value)
                      ?.medicalSupplies.map((ms) => {
                        const medicalSupply = allMedicalSupplies.find((s) => s._id === ms.product);
                        return {
                          _id: ms.product,
                          name: medicalSupply.name,
                          unitQuantity: ms.quantity,
                        };
                      }) || [];
                  setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
                }}
              />
              <Popover
                content={
                  <div>
                    <p>Following medical supplies will be added to the treatment:</p>
                    {record.treatment.medicalSupplies?.length > 0 &&
                      record.treatment.medicalSupplies.map((ms) => (
                        <Space.Compact block style={{ marginBlock: 2 }}>
                          <Select
                            showSearch
                            value={ms._id}
                            options={allMedicalSupplies.map((s) => ({ value: s._id, label: s.name }))}
                            placeholder={'Select Medical Supply'}
                            style={{ width: 400 }}
                            onChange={(_, option) => {
                              const supply = record.treatment.medicalSupplies.find((m) => m._id === ms._id);
                              supply._id = option.value;
                              supply.name = option.label;
                              setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
                            }}
                          />
                          <InputNumber
                            style={{ width: 90 }}
                            addonBefore="X"
                            value={
                              ms.quantity !== undefined ? ms.quantity : ms.unitQuantity * record.treatment.quantity
                            }
                            onChange={(value) => {
                              ms.quantity = value;
                              setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
                            }}
                            min={1}
                          />
                          <Button
                            icon={<AiOutlineDeleteRow />}
                            danger
                            onClick={() => {
                              record.treatment.medicalSupplies = record.treatment.medicalSupplies.filter(
                                (m) => m._id !== ms._id
                              );
                              setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
                            }}
                          />
                        </Space.Compact>
                      ))}
                    <Button
                      type={'dashed'}
                      block
                      style={{ paddingInline: 16, marginTop: 8 }}
                      onClick={() => {
                        record.treatment.medicalSupplies.push({
                          _id: null,
                          name: '',
                          unitQuantity: 1,
                        });
                        setSymptomsAndGoalsTableData([...symptomsAndGoalsTableData]);
                      }}
                      disabled={!!record.treatment.medicalSupplies?.find((ms) => ms._id === null)}
                    >
                      + Add Medical Supply
                    </Button>
                  </div>
                }
                placement={'left'}
                trigger={'click'}
              >
                <Button
                  disabled={!record.treatment.product}
                  variant={'filled'}
                  color={
                    record.treatment.medicalSupplies && !!record.treatment.medicalSupplies.length
                      ? 'primary'
                      : 'default'
                  }
                  icon={<FaSyringe />}
                />
              </Popover>
            </div>
          );
        },
        className: 'action-column',
      },
    ],
  };

  const toPreview = async () => {
    setNewTreatment(await calculateProposal());
    setStage('preview');
  };

  const calculateProposal = async () => {
    const selectedLabResultTreatments = labResultsTableData
      .filter((t) => !!t.treatment?.product)
      .map((t) => ({
        for: t.biomarker || t.for,
        treatment: t.treatment,
        labResult: t.labData,
      }));
    const selectedSymptomsGoalsTreatments = symptomsAndGoalsTableData
      .filter((t) => !!t.treatment?.product)
      .map((t) => ({
        for: t.symptomOrGoal || t.for,
        treatment: t.treatment,
      }));

    const proposalRequest = await populateMedicationInfo(
      [...selectedLabResultTreatments, ...selectedSymptomsGoalsTreatments],
      patient.profile.gender
    );

    return {
      forLabResult: proposalRequest.data.filter((m) => selectedLabResultTreatments.find((s) => s.for === m.for)),
      forSymptomsAndGoals: proposalRequest.data.filter((m) =>
        selectedSymptomsGoalsTreatments.find((s) => s.for === m.for)
      ),
      followUpNotes,
    };
  };

  const toSign = async () => {
    setStage('esign');
  };

  const convertDraft = async (signatureLink, reviewers) => {
    try {
      const approved = user.rightList.includes(ApplicationRight.Treatment_Review);
      const result = await convertDraftToTreatment(prefill._id, newTreatment, signatureLink, reviewers, approved);
      dispatch(updateTreatmentPlan({ ...result.data }));

      if (!!signatureLink) {
        dispatch(updateCurrentPatient({ key: 'currentTreatmentId', value: result.data._id }));
      }

      setStage('success');
      setSuccessMessage({
        title: `Successfully Setup New Treatment Plan from draft!`,
        subtitle: approved
          ? "Patient's current treatment plan is set to this new treatment plan."
          : 'New Treatment Plan has been submitted for approval. Reviewers have been notified.',
      });
    } catch (e) {
      message.error('Unable to create treatment from this draft');
    }
  };

  const createDraft = async () => {
    try {
      const draftTreatment = await calculateProposal();

      const result = await submitDraftPlan({
        treatmentPlan: draftTreatment,
        patient: patient._id,
        labReport: patient.currentLabReportId,
      });

      dispatch(addTreatmentPlan({ ...result.data, createdBy: { name: user.name } }));
      setStage('success');
      setSuccessMessage({
        title: 'Successfully Saved as a draft!',
        subtitle: 'You can find it in the treatment plan list.',
      });
    } catch (e) {
      message.error('Unable to create draft');
    }
  };

  const createNewTreatment = async (signatureLink, reviewers) => {
    try {
      const approved = user.rightList.includes(ApplicationRight.Treatment_Review);
      const result = await submitNewTreatmentPlan({
        treatmentPlan: newTreatment,
        patient: patient._id,
        labReport: patient.currentLabReportId,
        signatureLink,
        approved,
        reviewers,
      });

      dispatch(addTreatmentPlan({ ...result.data, createdBy: { name: user.name } }));

      if (approved) {
        dispatch(updateCurrentPatient({ key: 'currentTreatmentId', value: result.data._id }));
      }

      setStage('success');
      setSuccessMessage({
        title: 'Successfully setup the new treatment plan!',
        subtitle: approved
          ? "Patient's current treatment plan is set to this new treatment plan."
          : 'New Treatment Plan has been submitted for approval. Reviewers have been notified.',
      });
    } catch (e) {
      message.error('Unable to create new treatment');
      throw e;
    }
  };

  const updateTreatmentWithDecision = async (signatureLink, selectedReviewers) => {
    try {
      let payload = { ...newTreatment };
      if (selectedReviewers) {
        payload['reviewers'] = selectedReviewers;
      }

      if (user.rightList.includes(ApplicationRight.Treatment_Review)) {
        const result = await updateAndApproveTreatment(prefill._id, payload, signatureLink);
        dispatch(updateTreatmentPlan(result.data));
        dispatch(updateCurrentPatient({ key: 'currentTreatmentId', value: result.data._id }));

        setStage('success');
        setSuccessMessage({
          title: 'Treatment plan is updated and approved',
          subtitle: "Patient's current treatment plan is set to this treatment plan. Plan creator has been notified",
        });
      } else {
        const result = await updateTreatmentSelection(prefill._id, payload, signatureLink);
        dispatch(updateTreatmentPlan({ ...result.data }));

        setStage('success');
        setSuccessMessage({
          title: 'Successfully Updated Treatment Plan!',
          subtitle: 'Your change has been submitted and will be reviewed.',
        });
      }
    } catch (e) {
      message.error('Unable to update treatment');
    }
  };

  const onModalClose = async () => {
    if (
      stage === 'success' ||
      (labResultsTableData.filter((t) => !!t.treatment?.product).length === 0 &&
        symptomsAndGoalsTableData.filter((t) => !!t.treatment?.product).length === 0)
    ) {
      onClose();
      init();
      setStage('editing');
    } else {
      let title, okText, cancelText, onOk, onCancel;
      if (behavior === 'updating') {
        title = 'Closing now will discard all changes you just made';
        okText = 'Close';
        cancelText = 'Stay';
        onOk = () => {
          onClose();
          init();
          setStage('editing');
        };
      }

      if (behavior === 'creating') {
        title = `Before you close, do you want to save this treatment as a draft?`;
        okText = 'Yes. Save as draft';
        cancelText = 'No. Close and discard';
        onOk = createDraft;
        onCancel = () => {
          onClose();
          init();
          setStage('editing');
        };
      }

      if (behavior === 'converting') {
        title = `If you close now, your changes on this draft will not be saved.`;
        okText = 'Close';
        cancelText = 'Stay';
        onOk = () => {
          onClose();
          init();
          setStage('editing');
        };
      }

      Modal.confirm({
        title: (
          <p>
            <div>{title}</div>
            <Typography.Text type={'secondary'}>Click outside to dismiss this dialog</Typography.Text>
          </p>
        ),
        okText,
        onOk,
        maskClosable: true,
        footer: (_, { OkBtn }) => (
          <>
            <Button
              onClick={() => {
                (onCancel ? onCancel : () => {})();
                Modal.destroyAll();
              }}
            >
              {cancelText}
            </Button>
            <OkBtn />
          </>
        ),
      });
    }
  };

  const init = () => {
    calculateTreatmentTable(originalLabResultDeepCopy, true, false);
    calculateSymptomsAndGoalsTable(originalSymptomsAndGoalsDeepCopy);
    setFollowUpNotes(existingTreatmentDeepCopy.followUpNotes);
    setNewTreatment(null);
  };

  const calculateTreatmentTable = (labResult, init, updateRowSpanOnly) => {
    const labTableData = [];
    labResult.forEach((r) => {
      const existing = existingTreatmentDeepCopy.forLabResult.filter((st) => st.for === r.biomarker);
      if (existing.length && init) {
        existing.forEach((et, i) => {
          if (i === 0) {
            labTableData.push({ ...r, treatment: et });
          } else {
            labTableData.push({ ...r, biomarker: '', optimalRange: '', for: r.biomarker, treatment: et });
          }
        });
      } else {
        const defaultTreatment = r.biomarker ? treatmentCheatsheet[r.biomarker].find((tc) => tc.isDefault) : null;

        if (
          !prefill &&
          r.isAbnormal &&
          defaultTreatment &&
          parseDisplayLogic(defaultTreatment.when || {})(r.labData, biologicalStage.toLowerCase())
        ) {
          const defaultProduct = defaultTreatment.product;
          let dosage = '';
          let medicalSupplies = [];
          const quantity = defaultProduct.defaultQuantity || 1;
          if (
            defaultTreatment.product &&
            defaultTreatment.product.dosageList &&
            defaultTreatment.product.dosageList.length === 1
          ) {
            dosage = defaultTreatment.product.dosageList[0].dosage;
            medicalSupplies = defaultTreatment.product.dosageList[0].medicalSupplies.map((ms) => {
              const medicalSupply = allMedicalSupplies.find((s) => s._id === ms.product);
              return {
                _id: ms.product,
                name: medicalSupply.name,
                unitQuantity: ms.quantity,
              };
            });
          }
          labTableData.push({
            ...r,
            treatment: { product: defaultProduct, dosage, quantity, medicalSupplies },
          });
        } else {
          if (r.for && r.treatment.product) {
            labTableData.push({ ...r });
          } else {
            labTableData.push({ ...r, treatment: { product: null, dosage: '', medicalSupplies: [] } });
          }
        }
      }
    });

    const biomarkerGroupMapping = (ltd, i) => {
      if (i === 0) {
        return ltd;
      } else {
        return {
          ...ltd,
          type: '',
          _type: ltd.type || ltd._type,
        };
      }
    };

    const hormonesBiomarkers = labTableData
      .filter((ltd) => [ltd.type, ltd._type].includes('Hormones'))
      .sort((a, b) => a.order - b.order)
      .map(biomarkerGroupMapping);
    const thyroidBiomarkers = labTableData
      .filter((ltd) => [ltd.type, ltd._type].includes('Thyroid Health'))
      .sort((a, b) => a.order - b.order)
      .map(biomarkerGroupMapping);
    const cardiBiomarkers = labTableData
      .filter((ltd) => [ltd.type, ltd._type].includes('Cardiovascular & Inflammation'))
      .sort((a, b) => a.order - b.order)
      .map(biomarkerGroupMapping);
    const generalWellnessBiomarkers = labTableData
      .filter((ltd) => [ltd.type, ltd._type].includes('General Wellness'))
      .sort((a, b) => a.order - b.order)
      .map(biomarkerGroupMapping);

    const biomarkerRowSpan = {
      Hormones: hormonesBiomarkers.length,
      'Thyroid Health': thyroidBiomarkers.length,
      'Cardiovascular & Inflammation': cardiBiomarkers.length,
      'General Wellness': generalWellnessBiomarkers.length,
    };

    setBiomarkerRowSpan(biomarkerRowSpan);

    if (updateRowSpanOnly) {
      return;
    }
    setLabResultsTableData([
      ...hormonesBiomarkers,
      ...thyroidBiomarkers,
      ...cardiBiomarkers,
      ...generalWellnessBiomarkers,
    ]);
  };

  const calculateSymptomsAndGoalsTable = (symptomsAndGoalsResult) => {
    const goalsTableData = [];
    symptomsAndGoalsResult.forEach((s) => {
      const existing = existingTreatmentDeepCopy.forSymptomsAndGoals.filter((st) => st.for === s);
      if (existing.length) {
        existing.forEach((et, i) => {
          if (i === 0) {
            goalsTableData.push({ symptomOrGoal: s, treatment: et });
          } else {
            goalsTableData.push({ symptomOrGoal: '', for: s, treatment: et });
          }
        });
      } else {
        const defaultTreatment = treatmentCheatsheet[s].find((tc) => tc.isDefault);
        if (!prefill && defaultTreatment) {
          let dosage = '';
          let medicalSupplies = [];
          const quantity = defaultTreatment.product.defaultQuantity || 1;
          if (
            defaultTreatment.product &&
            defaultTreatment.product.dosageList &&
            defaultTreatment.product.dosageList.length === 1
          ) {
            dosage = defaultTreatment.product.dosageList[0].dosage;

            medicalSupplies = defaultTreatment.product.dosageList[0].medicalSupplies.map((ms) => {
              const medicalSupply = allMedicalSupplies.find((s) => s._id === ms.product);
              return {
                _id: ms.product,
                name: medicalSupply.name,
                unitQuantity: ms.quantity,
              };
            });
          }
          goalsTableData.push({
            symptomOrGoal: s,
            treatment: {
              product: defaultTreatment.product,
              dosage,
              quantity,
              medicalSupplies,
            },
          });
        } else {
          goalsTableData.push({
            symptomOrGoal: s,
            treatment: {
              product: null,
              dosage: '',
              medicalSupplies: [],
            },
          });
        }
      }
    });
    setSymptomsAndGoalsTableData([...goalsTableData]);
  };

  const treatmentPlanTableSetting = {
    pagination: false,
    bordered: true,
    columns: [
      {
        title: 'Type',
        dataIndex: 'type',
        width: 120,
        onCell: (record) => ({
          rowSpan: biomarkerRowSpan[record.type] || 0,
        }),
        render: (text) => (
          <Typography.Text underline strong>
            {text}
          </Typography.Text>
        ),
      },
      {
        title: 'BioMarker',
        dataIndex: 'biomarker',
        width: 155,
      },
      {
        title: 'Lab Result',
        dataIndex: 'labData',
        width: 70,
        render: (text, record) => {
          return !record.for && <span style={{ color: record.isAbnormal ? 'red' : 'black' }}>{text}</span>;
        },
      },
      {
        title: 'Optimal Range',
        dataIndex: 'optimalRange',
        width: 90,
      },
      {
        title: 'Medication / Product',
        dataIndex: ['treatment', 'product'],
        width: 300,
        render: (text, record, index) => (
          <>
            <Select
              className={'treatment-select'}
              variant={'borderless'}
              labelInValue
              value={{
                value: record.treatment?.product?._id,
                label: record.treatment?.product?.name,
              }}
              style={{ width: '100%', height: '100%' }}
              allowClear
              size="small"
              labelRender={(label) => <span style={{ width: 300, whiteSpace: 'balance' }}>{label.label}</span>}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {(record.isAbnormal || alwaysDisplayBiomarkers.includes(record.biomarker || record.for)) &&
                    !treatmentCheatsheet[record.biomarker || record.for].length && (
                      <>
                        <Divider
                          style={{
                            margin: '8px 0',
                          }}
                        />
                        <Input.Search
                          placeholder="Medicine Name"
                          allowClear
                          enterButton="+"
                          size="middle"
                          className="p-1"
                          onSearch={(value) => {
                            record.treatment.product = {
                              _id: mongoObjectIdGenerator(),
                              name: value,
                            };
                            setLabResultsTableData([...labResultsTableData]);
                          }}
                        />
                      </>
                    )}
                </>
              )}
              onClear={() => {
                if (record.for) {
                  labResultsTableData.splice(index, 1);
                } else {
                  record.treatment = { product: null, dosage: '', refill: '', quantity: '', medicalSupplies: [] };
                }
                setLabResultsTableData([...labResultsTableData]);
              }}
              onSelect={(value) => {
                const product = treatmentCheatsheet[record.biomarker || record.for].find(
                  (treatment) => treatment.product._id === value.value
                ).product;
                record.treatment.product = product;
                record.treatment.quantity = product.defaultQuantity || 1;
                if (product.dosageList && product.dosageList.length === 1) {
                  record.treatment.dosage = product.dosageList[0].dosage;
                  record.treatment.medicalSupplies = product.dosageList[0].medicalSupplies.map((ms) => {
                    const medicalSupply = allMedicalSupplies.find((s) => s._id === ms.product);
                    return {
                      _id: ms.product,
                      name: medicalSupply.name,
                      unitQuantity: ms.quantity,
                    };
                  });
                } else {
                  record.treatment.dosage = '';
                  record.treatment.medicalSupplies = [];
                }
                setLabResultsTableData([...labResultsTableData]);
              }}
              notFoundContent={
                !record.isAbnormal && !alwaysDisplayBiomarkers.includes(record.biomarker || record.for) ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <div className="d-flex flex-column justify-content-center">
                        <span>This biomarker is in optimal range, are you sure to add treatment?</span>
                        <Button
                          variant="link"
                          onClick={() =>
                            setAlwaysDisplayBiomarkers([...alwaysDisplayBiomarkers, record.biomarker || record.for])
                          }
                        >
                          Add treatment for this biomarker
                        </Button>
                      </div>
                    }
                  />
                ) : treatmentCheatsheet[record.biomarker || record.for].length ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <div className="d-flex flex-column justify-content-center">
                        <span>
                          Despite the biomarker being outside the optimal range, we suggest considering treatments
                          within different ranges. Would you like to view all the available treatments for this
                          biomarker?
                        </span>
                        <Button
                          variant="link"
                          onClick={() =>
                            setAlwaysDisplayBiomarkers([...alwaysDisplayBiomarkers, record.biomarker || record.for])
                          }
                        >
                          Show all available treatments
                        </Button>
                      </div>
                    }
                  />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <div className="d-flex flex-column justify-content-center">
                        <p>We don't have suggested medication for this biomarker for now.</p>
                        <b>You can manually input medicine name below</b>
                      </div>
                    }
                  />
                )
              }
            >
              {treatmentCheatsheet[record.biomarker || record.for]
                .filter((treatment) => {
                  if (alwaysDisplayBiomarkers.includes(record.biomarker || record.for)) {
                    return true;
                  } else {
                    if (record.isAbnormal || !!record.for) {
                      return parseDisplayLogic(treatment.when || {})(record.labData, biologicalStage.toLowerCase());
                    } else {
                      return false;
                    }
                  }
                })
                .reduce((acc, current) => {
                  const existingItem = acc.find((item) => item.product?._id === current.product?._id);
                  if (!existingItem) {
                    acc.push(current);
                  }
                  return acc;
                }, [])
                .map((treatment) => (
                  <Select.Option value={treatment.product._id} key={treatment.product._id}>
                    {treatment.isDefault ? `🛎️ ${treatment.product.name}` : treatment.product.name}
                  </Select.Option>
                ))}
            </Select>

            {record.biomarker && record.treatment.product && (
              <AiOutlinePlusCircle
                className="icon-btn"
                style={{
                  position: 'absolute',
                  left: '50%',
                  bottom: -10,
                  fontSize: '18px',
                  zIndex: 100,
                }}
                onClick={() => {
                  labResultsTableData.splice(index + 1, 0, {
                    ...record,
                    biomarker: '',
                    optimalRange: '',
                    type: '',
                    _type: record._type || record.type,
                    for: record.biomarker,
                    treatment: {
                      product: null,
                      dosage: '',
                      medicalSupplies: [],
                    },
                  });
                  setLabResultsTableData([...labResultsTableData]);
                }}
              />
            )}
          </>
        ),
        className: 'action-column p-relative',
      },
      {
        title: 'Quantity',
        dataIndex: ['treatment', 'quantity'],
        render: (text, record) => (
          <InputNumber
            min={1}
            style={{ width: 60, borderBottom: '1px solid f0f0f0', borderRadius: 0, paddingBlock: 3 }}
            size="small"
            variant={'borderless'}
            value={text}
            disabled={!record.treatment.product}
            onChange={(e) => {
              record.treatment.quantity = e;
              setLabResultsTableData([...labResultsTableData]);
            }}
          />
        ),
        className: 'action-column',
      },
      {
        title: 'Refill',
        dataIndex: ['treatment', 'refill'],
        width: 120,
        render: (text, record) => (
          <TextField
            fullWidth
            size="small"
            variant="standard"
            value={text}
            disabled={!record.treatment.product}
            onChange={(e) => {
              record.treatment.refill = e.target.value;
              setLabResultsTableData([...labResultsTableData]);
            }}
          />
        ),
        className: 'action-column',
      },
      {
        title: 'Dosage',
        minWidth: 440,
        dataIndex: ['treatment', 'dosage'],
        render: (text, record) => {
          const presetDosages = [];

          if (record.treatment.product) {
            const existingProduct = treatmentCheatsheet[record.biomarker || record.for].find(
              (treatment) => treatment.product._id === record.treatment.product._id
            );
            if (existingProduct) {
              existingProduct.product.dosageList.forEach((d) => presetDosages.push(d));
            }
          }

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Autocomplete
                style={{ width: '100%', marginRight: 2 }}
                freeSolo
                disablePortal
                value={text}
                variant="standard"
                options={presetDosages.map((d) => d.dosage)}
                size="small"
                disabled={!record.treatment.product}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline
                    variant="standard"
                    size="small"
                    placeholder="Select from preset dosages or just type"
                  />
                )}
                onInputChange={(event, value) => {
                  record.treatment.dosage = value;
                  setLabResultsTableData([...labResultsTableData]);
                }}
                onChange={(event, value) => {
                  record.treatment.medicalSupplies =
                    presetDosages
                      .find((d) => d.dosage === value)
                      ?.medicalSupplies.map((ms) => {
                        const medicalSupply = allMedicalSupplies.find((s) => s._id === ms.product);
                        return {
                          _id: ms.product,
                          name: medicalSupply.name,
                          unitQuantity: ms.quantity,
                        };
                      }) || [];
                  setLabResultsTableData([...labResultsTableData]);
                }}
              />
              <Popover
                content={
                  <div>
                    <p>Following medical supplies will be added to the treatment:</p>
                    {record.treatment.medicalSupplies?.length > 0 &&
                      record.treatment.medicalSupplies.map((ms) => (
                        <Space.Compact block style={{ marginBlock: 2 }}>
                          <Select
                            showSearch
                            value={ms._id}
                            options={allMedicalSupplies.map((s) => ({ value: s._id, label: s.name }))}
                            placeholder={'Select Medical Supply'}
                            style={{ width: 400 }}
                            onChange={(_, option) => {
                              const supply = record.treatment.medicalSupplies.find((m) => m._id === ms._id);
                              supply._id = option.value;
                              supply.name = option.label;
                              setLabResultsTableData([...labResultsTableData]);
                            }}
                          />
                          <InputNumber
                            style={{ width: 90 }}
                            addonBefore="X"
                            value={
                              ms.quantity !== undefined ? ms.quantity : ms.unitQuantity * record.treatment.quantity
                            }
                            onChange={(value) => {
                              ms.quantity = value;
                              setLabResultsTableData([...labResultsTableData]);
                            }}
                            min={1}
                          />
                          <Button
                            icon={<AiOutlineDeleteRow />}
                            danger
                            onClick={() => {
                              record.treatment.medicalSupplies = record.treatment.medicalSupplies.filter(
                                (m) => m._id !== ms._id
                              );
                              setLabResultsTableData([...labResultsTableData]);
                            }}
                          />
                        </Space.Compact>
                      ))}
                    <Button
                      type={'dashed'}
                      block
                      style={{ paddingInline: 16, marginTop: 8 }}
                      onClick={() => {
                        record.treatment.medicalSupplies.push({
                          _id: null,
                          name: '',
                          unitQuantity: 1,
                        });
                        setLabResultsTableData([...labResultsTableData]);
                      }}
                      disabled={!!record.treatment.medicalSupplies?.find((ms) => ms._id === null)}
                    >
                      + Add Medical Supply
                    </Button>
                  </div>
                }
                placement={'left'}
                trigger={'click'}
              >
                <Button
                  disabled={!record.treatment.product}
                  variant={'filled'}
                  color={
                    record.treatment.medicalSupplies && !!record.treatment.medicalSupplies.length
                      ? 'primary'
                      : 'default'
                  }
                  icon={<FaSyringe />}
                />
              </Popover>
            </div>
          );
        },
        className: 'action-column',
      },
    ],
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  useEffect(() => {
    if (!newTreatment) {
      return;
    }
    const plans = [...newTreatment.forLabResult, ...newTreatment.forSymptomsAndGoals];

    const basePlan = plans.filter((p) => p.level === 'Base');
    const correctionalPlan = plans.filter((p) => p.level === 'Correctional');
    const electivePlan = plans.filter((p) => p.level === 'Elective');
    const ciPlan = plans.filter((p) => p.level === 'Cardiovascular & Inflammation');
    const gwPlan = plans.filter((p) => p.level === 'General wellness');
    const otherPlan = plans.filter((p) => p.level === 'Other');

    const planMapFunc = (p, i) => {
      if (i === 0) {
        return p;
      } else {
        return {
          ...p,
          level: '',
          _level: p.level,
        };
      }
    };

    const basePlanTableData = basePlan.map(planMapFunc);

    const correctionalPlanTableData = correctionalPlan.map(planMapFunc);

    const electivePlanTableData = electivePlan.map(planMapFunc);

    const ciPlanTableData = ciPlan.map(planMapFunc);

    const gwPlanTableData = gwPlan.map(planMapFunc);

    const otherPlanTableData = otherPlan.map(planMapFunc);

    const rowSpan = {
      '': 0,
      Base: basePlanTableData.length,
      Correctional: correctionalPlanTableData.length,
      Elective: electivePlanTableData.length,
      'Cardiovascular & Inflammation': ciPlanTableData.length,
      'General wellness': gwPlanTableData.length,
      Other: otherPlanTableData.length,
    };

    setPreviewTableData([
      ...basePlanTableData,
      ...correctionalPlanTableData,
      ...electivePlanTableData,
      ...ciPlanTableData,
      ...gwPlanTableData,
      ...otherPlanTableData,
    ]);
    setPreviewTableSetting({
      pagination: false,
      bordered: true,
      customActions: [
        {
          element: (record) => (
            <Select
              variant={'borderless'}
              value={record.level || record._level}
              style={{ width: '100%' }}
              size="small"
              onSelect={(value, option) => {
                const corresponding =
                  newTreatment.forLabResult.find((lr) => lr.for === record.for && lr.product === record.product) ||
                  newTreatment.forSymptomsAndGoals.find((lr) => lr.for === record.for && lr.product === record.product);
                if (corresponding) {
                  corresponding.level = option.value;
                  setNewTreatment({
                    forLabResult: [...newTreatment.forLabResult],
                    forSymptomsAndGoals: [...newTreatment.forSymptomsAndGoals],
                    followUpNotes,
                  });
                }
                setStage('');
                setTimeout(() => {
                  setStage('preview');
                });
              }}
            >
              <Select.Option value={'Base'}>Base</Select.Option>
              <Select.Option value={'Correctional'}>Correctional</Select.Option>
              <Select.Option value={'Elective'}>Elective</Select.Option>
              <Select.Option value={'Cardiovascular & Inflammation'}>Cardiovascular & Inflammation</Select.Option>
              <Select.Option value={'General wellness'}>General wellness</Select.Option>
              <Select.Option value={'Other'}>Other</Select.Option>
            </Select>
          ),
        },
      ],
      operationColumnTitle: 'Change Therapy Level',
      columns: [
        {
          title: 'Therapy',
          dataIndex: 'level',
          onCell: (record) => ({
            rowSpan: rowSpan[record.level],
          }),
        },
        {
          title: 'Medication / Product',
          dataIndex: ['product', 'name'],
          render: (text, record) => (
            <>
              {!!record.medicalSupplies?.length ? (
                <div>
                  <div>{text}</div>
                  {record.medicalSupplies.map((ms) => (
                    <div style={{ marginLeft: 6, marginBottom: 2 }}>
                      <RxCornerBottomLeft size={18} style={{ position: 'relative', top: -4 }} />
                      <Tag
                        icon={<FaSyringe />}
                        color="processing"
                        style={{
                          userSelect: 'none',
                        }}
                      >
                        {ms.name}
                        <b> X {ms.quantity}</b>
                      </Tag>
                    </div>
                  ))}
                </div>
              ) : (
                <span>{text}</span>
              )}
            </>
          ),
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
        },
        {
          title: 'Refill',
          dataIndex: 'refill',
        },
        {
          title: 'Dosage',
          dataIndex: 'dosage',
        },
      ],
    });
  }, [newTreatment]);

  useEffect(() => {
    setDisplay(visible);
    if (visible && allMedicalSupplies) {
      init();
    }
  }, [visible, allMedicalSupplies]);

  useEffect(() => {
    (async () => {
      const response = await getMedicalNotes(patient.patientId);
      setMedicalNotes(response.data);
    })();

    (async () => {
      await bioApi
        .get(GET_MEDICAL_SUPPLIES)
        .then(({ data }) => {
          setAllMedicalSupplies(data);
        })
        .catch((_) => {
          setAllMedicalSupplies(null);
        });
    })();

    switch (behavior) {
      case 'creating':
        if (user.rightList.includes(ApplicationRight.Treatment_Review)) {
          setSignFormButtonText('Create, Sign and Approve');
        } else {
          setSignFormButtonText('Create, Sign and Submit for approval');
        }
        break;
      case 'updating':
        if (user.rightList.includes(ApplicationRight.Treatment_Review)) {
          setSignFormButtonText('Update, Sign and Approve');
        } else {
          setSignFormButtonText('Update, Sign and Submit for approval');
        }
        break;
      case 'converting':
        if (user.rightList.includes(ApplicationRight.Treatment_Review)) {
          setSignFormButtonText('Convert, Sign and Approve');
        } else {
          setSignFormButtonText('Convert, Sign and Submit for approval');
        }
        break;
    }
  }, []);

  useEffect(() => {
    if (allMedicalSupplies) {
      calculateTreatmentTable(labResultsTableData, false, true);
    }
  }, [labResultsTableData.length, allMedicalSupplies]);

  return (
    <>
      {!allMedicalSupplies && <Spin fullscreen />}
      {!!allMedicalSupplies && (
        <Modal
          centered
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: 40 }}>
              <h5>
                {behavior === 'creating' && 'Setup New Treatment'}
                {behavior === 'updating' && 'Update Treatment'}
                {behavior === 'converting' && 'Convert Draft Treatment'}
                <hr />
              </h5>
              <div>
                <Dropdown
                  menu={{
                    items: medicalNotes.slice(0, 10).map((mn, i) => ({
                      key: i,
                      label: <span>Note created at {moment(mn.creationDate).format('MMM Do, yyyy')}</span>,
                    })),
                    onClick: (e) => {
                      setShowMedicalNote({
                        show: true,
                        content: medicalNotes[e.key].content,
                        title: (
                          <span>Note created at {moment(medicalNotes[e.key].creationDate).format('MMM Do, yyyy')}</span>
                        ),
                      });
                    },
                  }}
                  placement="bottomRight"
                >
                  <Button>
                    <Space>
                      Medical Notes (recent 10 only)
                      <DownOutlined style={{ position: 'relative', top: '-2px' }} />
                    </Space>
                  </Button>
                </Dropdown>
              </div>
            </div>
          }
          open={display}
          width={'95%'}
          footer={null}
          destroyOnClose={true}
          onCancel={onModalClose}
          maskClosable={false}
        >
          {stage === 'editing' && !!treatmentPlanTableSetting && (
            <div>
              <div className="d-flex justify-content-between mb-1">
                <h5 className="mb-0">For Lab Result</h5>
                <Button type="primary" onClick={toPreview}>
                  Next
                </Button>
              </div>
              <DataTable data={labResultsTableData} settings={treatmentPlanTableSetting} />
              <h5 className="mt-3 mb-1">For Patient's Symptoms and Goals</h5>
              <DataTable data={symptomsAndGoalsTableData} settings={symptomGoalsTableSetting} />
              <h5 className="mt-3 mb-1">Follow up notes</h5>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                placeholder="Follow up notes"
                rows={4}
                style={{ marginBottom: 16 }}
                value={followUpNotes}
                onChange={(e) => setFollowUpNotes(e.target.value)}
              />
            </div>
          )}
          {stage === 'preview' && !!previewTableSetting && (
            <div>
              <div className="d-flex justify-content-between mb-1">
                <h5>Preview</h5>
                <Space>
                  <Button onClick={() => setStage('editing')}>Go back and Edit</Button>
                  <Button type="primary" onClick={toSign}>
                    Proceed
                  </Button>
                </Space>
              </div>
              <DataTable data={previewTableData} settings={previewTableSetting} />
              <Card title={'Follow up notes'} style={{ marginTop: 4 }}>
                {followUpNotes}
              </Card>
            </div>
          )}
          {stage === 'esign' && (
            <ESignForm
              confirmButtonText={signFormButtonText}
              signingTreatment={newTreatment}
              goBackButtonClick={() => setStage('editing')}
              preSelectedReviewers={existingTreatmentDeepCopy.reviewers}
              onSigning={async (signatureLink, reviewers) => {
                if (behavior === 'converting') {
                  await convertDraft(signatureLink, reviewers);
                } else if (behavior === 'creating') {
                  await createNewTreatment(signatureLink, reviewers);
                } else if (behavior === 'updating') {
                  await updateTreatmentWithDecision(signatureLink, reviewers);
                }
              }}
            />
          )}
          {stage === 'success' && (
            <Result
              status="success"
              title={successMessage.title}
              subTitle={successMessage.subtitle}
              extra={[
                <Button
                  type="primary"
                  key="console"
                  onClick={() => {
                    onClose();
                    init();
                    setStage('editing');
                  }}
                >
                  Go Back To Treatment Review
                </Button>,
              ]}
            />
          )}
          <Modal
            open={showMedicalNote.show}
            title={
              <>
                <div
                  style={{
                    width: '100%',
                    cursor: 'move',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onMouseOver={() => {
                    if (dragDisabled) {
                      setDragDisabled(false);
                    }
                  }}
                  onMouseOut={() => {
                    setDragDisabled(true);
                  }}
                >
                  <DragIndicatorOutlined />
                  {showMedicalNote.title}
                  <Typography.Text type={'secondary'} style={{ marginLeft: 8 }}>
                    Draggable
                  </Typography.Text>
                </div>
                <hr />
              </>
            }
            onCancel={() => setShowMedicalNote({ show: false, title: '', content: {} })}
            modalRender={(modal) => (
              <Draggable disabled={dragDisabled} bounds={bounds} onStart={(event, uiData) => onStart(event, uiData)}>
                <div ref={draggleRef}>{modal}</div>
              </Draggable>
            )}
            mask={false}
            maskClosable={false}
            wrapClassName={'penetrable-click-modal'}
            width={'60vw'}
            style={{ top: 20 }}
            footer={null}
          >
            <div style={{ height: '300px', overflowY: 'scroll' }}>
              <MedicalNoteDisplay
                medicalNote={showMedicalNote.content}
                gender={patient.profile.gender.charAt(0).toUpperCase() + patient.profile.gender.slice(1)}
              ></MedicalNoteDisplay>
            </div>
          </Modal>
        </Modal>
      )}
    </>
  );
};
export default TreatmentModal;
