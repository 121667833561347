import React from 'react';
import { Table } from 'antd';
const TableOrderComponent = ({
    setIsviewPdf, hanldeImport,
    dataSource, setDataSource
}) => {

    const columns = [
        {
            title: 'Quote Number',
            dataIndex: 'quoteNumber',
            width: 200,
            render: (_, record) => (
                <div>
                    <span onClick={() => setIsviewPdf && setIsviewPdf(record)} style={{ cursor: "pointer", color: "rgb(0, 117, 255)" }}>{record.quoteNumber ? record.quoteNumber : ""}</span>
                </div>
            ),
        },
        {
            title: 'Account Name',
            dataIndex: 'accountName',
            width: 200,
            render: (_, record) => (
                <div>
                    {record.accountName ? record.accountName : ""}
                </div>
            ),
        },
        {
            title: 'Shipping Instructions',
            dataIndex: 'shippingInstructions',
            render: (_, record) => (
                <div>
                    {record.shippingInstructions ? record.shippingInstructions : ""}
                </div>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 100,
            render: (_, record) => (
                <div>
                    <span 
                    onClick={() => {
                        hanldeImport && hanldeImport(record)
                    }}
                    style={{ cursor: "pointer", color: "rgb(0, 117, 255)" }}>Import</span>
                </div>
            ),
        },
    ];
    console.log(`skhsk-dataSource: `, dataSource)
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{
                y: 55 * 5,
            }}
        />
    );
};
export default TableOrderComponent;