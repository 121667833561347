import { useEffect, useState } from 'react';
import { List, Switch } from 'antd';
import bioApi from '../../../../api/bioApi';
import { FEATURE_FLAG_URL } from '../../../../api/URLs';

const FeatureFlagPage = () => {
  const [flags, setFlags] = useState([]);
  const [loading, setLoading] = useState(true);

  const onChange = async (name, active) => {
    setLoading(true);
    await bioApi.post(FEATURE_FLAG_URL, { name, active });
    flags.forEach((f) => {
      if (f.name === name) {
        f.active = active;
      }
    });
    setFlags([...flags]);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      const featureFlags = await bioApi.get(FEATURE_FLAG_URL);
      setFlags(featureFlags.data);
      setLoading(false);
    })();
  }, []);

  return (
    <div>
      <List
        loading={loading}
        itemLayout="horizontal"
        dataSource={flags}
        renderItem={(flag, index) => (
          <List.Item>
            <span>{flag.name}</span>
            <span>
              <Switch checked={flag.active} onChange={() => onChange(flag.name, !flag.active)} />
            </span>
          </List.Item>
        )}
      />
    </div>
  );
};

export default FeatureFlagPage;
